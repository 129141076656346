import { ChangeEvent, useState, useEffect, useMemo } from 'react'
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material'
import {
  Table,
  TableBody,
  TablePagination,
  Button,
  SideBar,
  NotificationDialog,
  ConfirmDialog,
  IDialogType,
} from 'components'
import { IDevice, IDeviceList, ILocation, IPriceList, ISizeList } from 'models'
import { deviceApi, priceApi, sizeApi } from 'resources'
import DeviceRow from './DeviceRow'
import DeviceForm from './DeviceForm'
import LocationForm from 'pages/Locations/components/LocationForm'
import { DEFAULT_PAGE, DEVICES_PER_PAGE } from 'constants/pagination'
import { parseCurrency } from 'utils/helpers'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import UnlockByLocationDialog from './UnlockByLocationDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import MultiEditForm from './MultiEditForm'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import useLoadingState from 'hooks/useLoadingState'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { DEVICE_STATUSES } from 'pages/Doors/constants'
import { DropdownOption, ToolbarControl } from 'types'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import useLocations from 'hooks/useLocations'

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

const Devices = (): React.ReactElement => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)
  const [devices, setDevices] = useState<IDeviceList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [devicesBackup, setDevicesBackup] = useState<IDevice[]>([])
  const [sizes, setSizes] = useState<ISizeList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [prices, setPrices] = useState<IPriceList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [currentDevice, setCurrentDevice] = useState<IDevice | undefined>()
  const [currentLocation, setCurrentLocation] = useState<
    ILocation | undefined
  >()
  const [openDeviceSidebar, setOpenDeviceSidebar] = useState<boolean>(false)
  const [openLocationForm, setOpenLocationForm] = useState<boolean>(false)
  const [openUnlockByLocation, setOpenUnlockByLocation] =
    useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(DEVICES_PER_PAGE)
  const [search, setSearch] = useState('')
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRowData, setSelectedRowsData] = useState<any>([])
  const [action, setAction] = useState<string>('')
  const [openMultiEditForm, setOpenMultiEditForm] = useState<boolean>(false)
  const [selectedLocation, setSelectedLocation] = useState<string>('all')
  const [selectedStatus, setSelectedStatus] = useState<string>('all')
  const [dialog, setDialog] = useState<{
    isOpen: boolean
    message: string
    action: string
    type?: IDialogType
  }>({
    isOpen: false,
    message: '',
    action: '',
    type: '',
  })
  const [disableUnlockAllButton, setUnlockAllButton] = useState<boolean>(false)

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'locker_number',
      entity: 'devices',
      nestedProps: [
        {
          columnValue: 'location',
          path: 'location.name',
          defaultValue: 0,
        },
        {
          columnValue: 'size',
          path: 'size.name',
          defaultValue: '',
        },
        {
          // State column is lock_status prop
          // this is actually not a nested prop
          // but this works fine with properties
          // that have different names to its
          //  corresponding column
          columnValue: 'state',
          path: 'lock_status',
          defaultValue: '',
        },
      ],
    })

  const displayMessage = (
    message: string | JSX.Element,
    type?: IDialogType,
  ) => {
    setDialog({
      isOpen: true,
      message: message as string,
      action: 'displayMessage',
      type,
    })
  }

  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })

  const { getMany: getDevices, unlockAll: unlockDevices, remove } = deviceApi()
  const { getMany: getSizes } = sizeApi()
  const { getMany: getPrices } = priceApi()

  const { locations, fetchLocations, loadingLocations, locationsOptions } =
    useLocations()

  const { loading, setLoading } = useLoadingState(true)

  const theme = useTheme()

  const sizeOptions = useMemo(
    () =>
      sizes.items.map((size) => ({
        value: size.id,
        label: `${size.name} (${size.width}" x ${size.depth}" x ${size.height}")`,
      })),
    [sizes],
  )

  const priceOptions = useMemo(
    () =>
      prices.items.map((price) => ({
        value: price.id,
        label: `${price.name} (${parseCurrency(price.currency)} ${
          price.amount
        } / ${price.unit_amount} ${price.unit}${
          price.unit_amount !== 1 ? 's' : ''
        })`,
        priceType: price.price_type || '',
      })),
    [prices],
  )

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    device: IDevice,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, device.id]
        : previousValue.filter((rowId) => rowId !== device.id),
    )

    // Function setSelectedRowsData is used to handle export of CSV files.
    // The code above is used only to handle selection of device IDs.
    //
    // I tried incorporating the setSelectedRows code but resulted in
    // multiple areas of this component failing to work properly.
    setSelectedRowsData((previousValue) => {
      return checked
        ? [...previousValue, device]
        : previousValue.filter(
            (selectedDevice) => selectedDevice.id !== device.id,
          )
    })
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? devices.items.map((device) => device.id) : [],
    )

    setSelectedRowsData(checked ? devices.items : [])
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchDevices()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleEdit = (device: IDevice): void => {
    setCurrentDevice(device)
    setOpenDeviceSidebar(true)
  }

  const fetchDevices = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const devices = await getDevices(
        currentPage + 1,
        rowsPerPage,
        search,
        selectedStatus !== 'all' ? selectedStatus : undefined,
        selectedLocation !== 'all' ? selectedLocation : undefined,
      ) //TODO: implement proper pagination here
      // currently, it was causing a bug where upon refreshing page 2, it would try to fetch the devices from 10 000 to 20 000, assuming that page 1 was 1 to 10 000.
      setDevicesBackup([...devices.items])
      setDevices(devices)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
      handleItemColumnVisibility()
    }
  }

  const backgroundReloadDevices = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    setTimeout(async () => {
      // Backgrund task to refresh devices table after "unlock" is successful.
      //
      // DCLocks get updated every second. At the moment we don't have a WebSockets
      // server or a way to receieve real time alerts of a successful DCLock unlock,
      // only a database UPDATE to the status column in the device table.
      //
      // This grace period is executed 3 seconds after the device table is updated
      // to allow the status column to display the new status of the lock:
      //
      // It would be a good idea in the future to implement a WS server that
      // sends data back after successful unlocks.
      try {
        const devices = await getDevices(
          currentPage + 1,
          rowsPerPage,
          search,
          selectedStatus !== 'all' ? selectedStatus : undefined,
          selectedLocation !== 'all' ? selectedLocation : undefined,
        ) //TODO: implement proper pagination here
        // currently, it was causing a bug where upon refreshing page 2, it would try to fetch the devices from 10 000 to 20 000, assuming that page 1 was 1 to 10 000.
        setDevicesBackup([...devices.items])
        setDevices(devices)
        if (showNewestFirst) {
          setOrder('default')
        }
      } catch (error) {
        console.error('Error refreshing devices list:', error)
      }
    }, 5000)
  }

  const fetchSizes = async (): Promise<void> => {
    try {
      const sizes = await getSizes(1, 10000)
      setSizes(sizes)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchPrices = async (): Promise<void> => {
    try {
      const prices = await getPrices(1, 10000, search)
      setPrices(prices)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const unlockSelectedDevices = () => {
    const selectedDevices = JSON.parse(
      localStorage.getItem('selectedDevices') || '[]',
    ) as IDevice[]

    if (selectedDevices.length === 0) {
      displayMessage('Please select at least one device', 'warning')
      return
    }

    const deviceUUIDs: string[] = selectedDevices.map((device) => device.id)

    unlockDevices(deviceUUIDs).then(() => {
      localStorage.setItem('selectedDevices', '[]')
      fetchDevices()
      setDialog({
        isOpen: true,
        message: 'Unlock request sent to selected devices',
        action: 'unlockAll',
        type: 'success',
      })
    })
  }

  const handleUnlockAllClick = () => {
    const selectedDevices = JSON.parse(
      localStorage.getItem('selectedDevices') || '[]',
    ) as IDevice[]

    if (selectedDevices.length === 0) {
      // displayMessage('Please select at least one device')

      setOpenUnlockByLocation(true)
      return
    }

    setConfirmationMessage({
      isOpen: true,
      message: 'Are you sure you want to unlock all selected devices?',
      action: 'unlockAll',
    })
  }

  const handleUnlockButtonState = () => {
    const selectedDevices = JSON.parse(
      localStorage.getItem('selectedDevices') || '[]',
    ) as IDevice[]

    if (selectedDevices.length === 0) {
      return setUnlockAllButton(false)
    }

    const reservedDevices = selectedDevices.filter(
      (device) => device.status !== 'available',
    )

    if (reservedDevices.length > 0) {
      return setUnlockAllButton(true)
    }

    return setUnlockAllButton(false)
  }

  const handleSelectLocation = (
    event: React.SyntheticEvent,
    value: DropdownOption | null,
  ) => {
    setSelectedLocation(value ? value.value : 'all')
  }

  const handleSelectStatus = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value)
  }

  const handleItemColumnVisibility = () => {
    if (devicesBackup.length === 0) return

    // If there are no devices on "rental" mode, do not show the "Item"
    // column.
    const hasRentalDevices = devicesBackup.find((d) => d.mode === 'rental')

    if (hasRentalDevices) {
      getDefaultFilters()
      return
    }

    const noItemRowColumns = filteredColumns.filter((fc) => fc.value !== 'item')
    setFilteredColumns(noItemRowColumns)
  }

  const fetchData = async () => {
    getDefaultFilters()
    fetchDevices()
    fetchSizes()
    fetchPrices()
    fetchLocations()
    localStorage.setItem('selectedDevices', '[]')
  }

  useEffect(() => {
    handleItemColumnVisibility()
  }, [devicesBackup])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchDevices()
  }, [search, currentPage, rowsPerPage, selectedLocation, selectedStatus])

  useEffect(() => {
    if (!openDeviceSidebar && currentDevice) setCurrentDevice(undefined)
  }, [openDeviceSidebar])

  const visibleRows = useMemo(() => {
    const rows = getVisibleRowsSorted(devices.items, devicesBackup)
    return rows
  }, [devices, order, orderBy])

  useEffect(() => {
    if (action === 'edit') {
      setOpenMultiEditForm(true)
    }
  }, [action])

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          key="1"
          variant="contained"
          name="addDevice"
          onClick={() => setOpenDeviceSidebar(true)}
          disabled={loading || loadingLocations}
        >
          Add Device
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Button
          key="2"
          variant="contained"
          name="unlockSelected"
          onClick={handleUnlockAllClick}
          disabled={disableUnlockAllButton || loading || loadingLocations}
        >
          Unlock All
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Autocomplete
          key="3"
          disablePortal
          options={locationsOptions}
          renderInput={(params) => (
            <TextField {...params} label="Select a location..." />
          )}
          onChange={handleSelectLocation}
          size="small"
          disabled={loadingLocations || loading}
          loadingText="Loading..."
        />
      ),
    },
    {
      display: true,
      render: (
        <FormControl size="small">
          <InputLabel>Device Status</InputLabel>
          <Select
            disabled={loadingLocations || loading}
            defaultValue={selectedStatus}
            label="Device Status"
            onChange={handleSelectStatus}
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundImage: 'none',
                    boxShadow: 3,
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#2A2E34' : '#f7f7f7',
                  },
                },
              },
            }}
          >
            <MenuItem key="All" value="all">
              All statuses
            </MenuItem>
            {DEVICE_STATUSES.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelectedData={selectedRowData}
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
          setAction={setAction}
          actionsAllowed={['delete', 'edit']}
        />
      )}
      {!loading && visibleRows.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((device) => (
                <DeviceRow
                  key={device.id}
                  device={device}
                  success={() => {
                    backgroundReloadDevices()
                    fetchDevices()
                  }}
                  handleEdit={handleEdit}
                  displayMessage={displayMessage}
                  filteredColumns={filteredColumns}
                  openLocationForm={() => setOpenLocationForm(true)}
                  setCurrentLocation={setCurrentLocation}
                  handleUnlockButtonState={handleUnlockButtonState}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(device.id)}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={devices.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingTableData isLoading={loading} />

      <NoData condition={!loading && visibleRows.length === 0} />

      {openDeviceSidebar && (
        <SideBar
          open={openDeviceSidebar}
          onClose={() => setOpenDeviceSidebar(false)}
        >
          <DeviceForm
            allDevices={devices.items}
            device={currentDevice}
            success={fetchDevices}
            onClose={() => {
              setOpenDeviceSidebar(false)
            }}
            sizeOptions={sizeOptions} // TO DO MOVE THIS PROPERTY TO A CONTEXT
            priceOptions={priceOptions} // TO DO MOVE THIS PROPERTY TO A CONTEXT
            locationOptions={locationsOptions} // TO DO MOVE THIS PROPERTY TO A CONTEXT
            displayMessage={displayMessage}
            allowEdit={currentDevice?.status !== 'reserved'}
          />
        </SideBar>
      )}

      <SideBar
        open={openMultiEditForm}
        onClose={() => {
          setAction('')
          setOpenMultiEditForm(false)
        }}
      >
        <MultiEditForm
          devices={selectedRows}
          success={() => fetchDevices()}
          onClose={() => {
            setAction('')
            setOpenMultiEditForm(false)
          }}
          sizeOptions={sizeOptions}
          priceOptions={priceOptions}
          locationOptions={locationsOptions}
          displayMessage={displayMessage}
          setSelectedRows={setSelectedRows}
        />
      </SideBar>

      {openLocationForm && (
        <SideBar
          open={openLocationForm}
          onClose={() => setOpenLocationForm(false)}
        >
          <LocationForm
            location={currentLocation}
            onClose={() => {
              setOpenLocationForm(false)
              setCurrentLocation(undefined)
            }}
            allowEdit={false}
          />
        </SideBar>
      )}
      <NotificationDialog
        open={dialog.isOpen}
        onClose={() => {
          dialog.action === 'unlockAll'
            ? window.location.reload()
            : setDialog({ isOpen: false, message: '', action: '' })

          backgroundReloadDevices()
        }}
        message={dialog.message}
        type={dialog.type}
      />
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={() => unlockSelectedDevices()}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
      <UnlockByLocationDialog
        locations={locations}
        open={openUnlockByLocation}
        setOpenUnlockByLocation={setOpenUnlockByLocation}
      />
    </>
  )
}

export default Devices
