import {
  Card,
  CardContent,
  Typography,
  Box,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import { IMetric } from 'models/Analytics'
import { useEffect } from 'react'
import { DropdownOption } from 'types'
import { getCookie } from 'utils/helpers'

export interface IMetricCard {
  metric: IMetric | null
  id: number
  buildMetricObject: (id: number, metric: string) => Promise<void>
  metricsOptions: DropdownOption[]
  setCurrentFetchingMetric: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
}

const MetricCard = ({
  metric,
  id,
  buildMetricObject,
  metricsOptions,
  setCurrentFetchingMetric,
  loading,
}: IMetricCard) => {
  const theme = useTheme()

  const handleSelectMetric = async (
    event: SelectChangeEvent<string>,
    id: number,
  ) => {
    const { value } = event.target
    // Set cookie to save the selected metric by id
    const expirationDate = new Date(
      'Fri, 31 Dec 9999 23:59:59 GMT',
    ).toUTCString()

    if (value)
      document.cookie = `metric_${id}=${value}; expires=${expirationDate}; path=/`

    setCurrentFetchingMetric(value)
    await buildMetricObject(id, value)
  }

  useEffect(() => {
    const fetchSavedMetrics = async () => {
      const metricCookie = getCookie(`metric_${id}`)

      if (metricCookie) {
        await buildMetricObject(id, metricCookie)
      }
    }

    // If no metric is loaded, fetch the saved metric
    if (!metric) {
      fetchSavedMetrics()
    }
  }, [metric, id, buildMetricObject])

  return (
    <Card
      sx={{
        height: '200px',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <FormControl sx={{ width: '100%' }} size="small">
          <InputLabel>Select Metric</InputLabel>
          <Select
            label="Select Metric"
            defaultValue={getCookie(`metric_${id}`) || ''}
            onChange={(event: SelectChangeEvent<string>) =>
              handleSelectMetric(event, id)
            }
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundImage: 'none',
                    boxShadow: 3,
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#2A2E34' : '#f7f7f7',
                  },
                },
              },
            }}
          >
            {metricsOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="10px"
          >
            {loading ? (
              <Skeleton
                animation="wave"
                variant="circular"
                width={30}
                height={30}
              />
            ) : (
              metric?.icon
            )}
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{
                marginBottom: '0',
              }}
              gutterBottom
            >
              {loading ? (
                <Skeleton animation="wave" width="100px" />
              ) : (
                metric?.title
              )}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            fontWeight="500"
            sx={{
              margin: '7px 0 0 30px',
            }}
            gutterBottom
          >
            {loading ? <Skeleton width="300px" /> : metric?.value}
          </Typography>
          <Typography
            variant="h6"
            fontWeight="200"
            sx={{
              margin: '0 0 0 30px',
              opacity: '0.6',
            }}
            gutterBottom
          >
            {loading ? <Skeleton width="300px" /> : metric?.legend || ''}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default MetricCard
