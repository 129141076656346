import { SIGN_IN_FORM_FIELDS, SIGN_IN_FORM } from '../../../constants'
import { Box } from '@mui/material'
import { SignInFormtyles } from './styles'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { ISignInFormProps, Form, TextField, Button } from 'components'
import { ISignIn } from 'models'
import { ReactElement } from 'react'

const SignInForm = ({ children, onSignIn }: ISignInFormProps): ReactElement => {
  const { SX } = SignInFormtyles()

  const methods = useForm<ISignIn>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit: SubmitHandler<ISignIn> = (newLogin): void => {
    onSignIn({ email: newLogin.email, password: newLogin.password })
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <Box sx={SX.CONTAINER}>
          <Box sx={SX.FIELDS_CONTAINER}>
            <TextField
              name={SIGN_IN_FORM_FIELDS.EMAIL.NAME}
              label={SIGN_IN_FORM_FIELDS.EMAIL.LABEL}
              placeholder={SIGN_IN_FORM_FIELDS.EMAIL.PLACEHOLDER}
              rules={SIGN_IN_FORM_FIELDS.EMAIL.RULES}
            />
            <TextField
              type="password"
              name={SIGN_IN_FORM_FIELDS.PASSWORD.NAME}
              label={SIGN_IN_FORM_FIELDS.PASSWORD.LABEL}
              placeholder={SIGN_IN_FORM_FIELDS.PASSWORD.PLACEHOLDER}
              rules={SIGN_IN_FORM_FIELDS.PASSWORD.RULES}
            />
            {children}
          </Box>
          <Box sx={SX.BUTTONS_CONTAINER}>
            <Button variant="contained" type="submit">
              {SIGN_IN_FORM.SUBMIT_BUTTON_LABEL}
            </Button>
          </Box>
        </Box>
      </Form>
    </FormProvider>
  )
}
export default SignInForm
