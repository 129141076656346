import { useApiRequest } from 'utils'
import { API_URL, PUBLIC_API_URL } from '../../../constants'
import {
  ICreateDelivery,
  ICompleteDelivery,
  ITransactionList,
  PublicTransactionDTO,
  ITransaction,
  PenaltyReason,
  ITransactionLog,
  CodeDeliveryResponse,
} from 'models'
import { getErrorMessage } from 'utils/helpers'
import { DropdownOption } from 'types'

const transactionsApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    currentPage: number,
    itemsPerPage: number,
    transactionType: string,
    search = '',
  ): Promise<ITransactionList> => {
    const type = transactionType === 'all' ? '' : `&by_type=${transactionType}`
    const endpoint = `events?${type}&page=${currentPage}&size=${itemsPerPage}&search=${search}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getByIdPublic = async (
    transactionId: string,
  ): Promise<PublicTransactionDTO | null> => {
    if (transactionId) {
      const endpoint = `${API_URL}event/${transactionId}/public`
      const method = 'GET'
      try {
        const response = await fetch(endpoint, { method })
        if (!response.ok) {
          const result = await response.json()
          throw result.detail
        }
        return response.json()
      } catch (error) {
        throw new Error('Something went wrong! ' + getErrorMessage(error))
      }
    }
    return null
  }

  const service = async ({
    transactionId,
    step,
    deviceId,
    weight,
  }: {
    transactionId: string
    step: string
    deviceId?: string
    weight?: number
  }): Promise<{
    status: number
    message: ITransaction | { detail: string }
  }> => {
    let endpoint = `events/${transactionId}/service?step=${step}`
    const method = 'PATCH'

    if (step === 'charge') {
      endpoint += `&weight=${weight}`
    }
    if (step === 'dropoff') {
      endpoint += `&id_device=${deviceId}`
    }

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const endBy = async (
    transactionId: string,
    cancel_at: Date | undefined,
  ): Promise<{
    status: number
    message: ITransaction | { detail: string }
  }> => {
    const endpoint = `events/${transactionId}/cancel${
      cancel_at ? `?cancel_at=${cancel_at}` : ''
    }`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const refund = async ({
    transactionId,
    amount,
  }: {
    transactionId: string
    amount: number
  }) => {
    const endpoint = `events/${transactionId}/refund?amount=${amount}`
    const method = 'PATCH'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const search = async ({
    currentPage,
    itemsPerPage,
    search,
  }: {
    currentPage: number
    itemsPerPage: number
    search: string
  }): Promise<ITransactionList> => {
    const endpoint = `events?search=${search}&page=${currentPage}&size=${itemsPerPage}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const penalize = async (
    transactionId: string,
    amount: number,
    reason: PenaltyReason,
  ) => {
    const endpoint = `events/${transactionId}/penalize?amount=${amount}&reason=${reason}`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const deliveryStart = async ({
    phoneNumber,
    deviceId,
  }: ICreateDelivery): Promise<{
    status: number
    message: ITransaction | { detail: string }
  }> => {
    const endpoint = `event/delivery?id_device=${encodeURIComponent(
      deviceId,
    )}&phone_number=${encodeURIComponent(phoneNumber)}`

    const method = 'POST'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const deliveryComplete = async ({
    transactionId,
    deviceId,
  }: ICompleteDelivery): Promise<{
    status: number
    message: ITransaction | { detail: string }
  }> => {
    const endpoint = `${PUBLIC_API_URL}delivery/${transactionId}/complete?id_device=${deviceId}`
    const method = 'PATCH'

    try {
      const response = await fetch(endpoint, { method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const sendCode = async (
    idEvent: string,
    phoneNumber: string | null = null,
    email: string | null = null,
  ): Promise<CodeDeliveryResponse> => {
    const urlParams = new URLSearchParams({
      ...(phoneNumber && { phone_number: phoneNumber }),
      ...(email && { email }),
    })

    const endpoint = `events/${idEvent}/share?${urlParams.toString()}`
    const method = 'POST'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw error
    }
  }

  const getEventStatuses = async (): Promise<DropdownOption[]> => {
    const endpoint = `events/statuses`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getEventByInvoiceId = async (
    invoiceId: string,
  ): Promise<ITransaction | null> => {
    const endpoint = `events?key=invoice_id&value=${invoiceId}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        return null
      }
      return response.json()
    } catch (error) {
      return null
    }
  }

  const getEventsByUserId = async (
    idUser: string,
    onlyActive: boolean = true,
  ): Promise<ITransaction[]> => {
    const urlParams = new URLSearchParams({
      active: onlyActive.toString(),
    })

    const endpoint = `events_by_user/${idUser}?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const endMany = async (ids: string[]) => {
    const endpoint = 'events'
    const method = 'PATCH'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTransactionHistory = async (
    transactionId: string,
  ): Promise<ITransactionLog[]> => {
    const endpoint = `event-logs/${transactionId}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    getByIdPublic,
    service,
    endBy,
    refund,
    search,
    penalize,
    deliveryStart,
    deliveryComplete,
    sendCode,
    getEventStatuses,
    getEventByInvoiceId,
    getEventsByUserId,
    endMany,
    getTransactionHistory,
  }
}
export default transactionsApi
