import { FormField } from 'types'

export const SIGN_IN_FORM_FIELDS: { [key: string]: FormField } = {
  EMAIL: {
    NAME: 'email',
    LABEL: 'Email',
    PLACEHOLDER: 'Email',
    RULES: {
      required: 'Email required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  PASSWORD: {
    NAME: 'password',
    LABEL: 'Password',
    PLACEHOLDER: 'Password',
    RULES: {
      required: 'Password required',
    },
  },
}

export const SIGN_IN_FORM: { [key: string]: string } = {
  SUBMIT_BUTTON_LABEL: 'Sign In',
}
