import { Button } from 'components'
import { SubmitButtonProps } from './SubmitButton.model'

export const SubmitButton = ({
  name,
  disabled,
  variant,
  children,
  sx,
}: SubmitButtonProps) => {
  return (
    <Button
      name={name}
      variant={variant}
      disabled={disabled}
      sx={sx}
      type="submit"
    >
      {children}
    </Button>
  )
}
export default SubmitButton
