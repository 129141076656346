import { StyleSheet } from '@react-pdf/renderer'

export const STYLES = StyleSheet.create({
  TITLE: {
    margin: 5,
    marginBottom: 15,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  QR: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  TRANSACTION_DETAILS_CONTAINER: {
    margin: 'auto',
    flexDirection: 'row',
  },
  TRANSACTION_DETAILS: {
    fontSize: 12,
    margin: 5,
    fontWeight: 'normal',
  },
})
