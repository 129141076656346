import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { TableBody } from 'components'
import useDialog from 'hooks/useDialog'
import { ITopLocations } from 'models/Analytics'
import { useEffect, useState } from 'react'
import { reportApi } from 'resources'
import { TOP_LOCATIONS_HEADERS } from '../constants'
import { SummarizedText } from 'components/SummarizedText'

interface ITopLocationsProps {
  targetOrg: string | undefined
}

const TopLocations = ({ targetOrg }: ITopLocationsProps) => {
  const { getLocations } = reportApi()

  const { dialog, displayMessage, closeDialog } = useDialog()

  const [topLocations, setTopLocations] = useState<ITopLocations[]>([])

  const fetchTopLocations = async () => {
    try {
      const topLocations = await getLocations(targetOrg)
      if (topLocations.length === 0) {
        setTopLocations([])
      } else {
        setTopLocations(
          topLocations.map((item) => ({ ...item, location: item.Location })),
        )
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
    }
  }

  useEffect(() => {
    fetchTopLocations()
  }, [targetOrg])

  return (
    <Card>
      <CardHeader
        title={
          <Typography color="primary" variant="h3">
            Top Locations
          </Typography>
        }
      />
      <CardContent>
        <Box
          sx={{
            overflow: 'auto',
            mt: -3,
          }}
        >
          <Table
            aria-label="top users table"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <TableHead>
              <TableRow>
                {TOP_LOCATIONS_HEADERS.map((header) => (
                  <TableCell key={header.label}>
                    <Typography
                      color="primary"
                      variant="h5"
                      textAlign={header.align}
                    >
                      {header.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {topLocations.length > 0 &&
                topLocations.map((row) => (
                  <TableRow key={row.location.id}>
                    <TableCell>
                      <Typography textAlign="left" variant="h6">
                        {row.location.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography textAlign="center">
                        <SummarizedText text={row.location.address} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography textAlign="right" variant="h6">
                        {row.count}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              {topLocations.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>No data to show</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TopLocations
