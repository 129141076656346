import { Card } from '@mui/material'
import { NotificationDialog, Tabs } from 'components'
import useDialog from 'hooks/useDialog'
import { SyntheticEvent, useState } from 'react'
import LiteAppSettings from './LiteAppSettings'
import ReservationWidgetSettings from './ReservationWidgetSettings'

const TABS = [
  {
    label: 'Lite App',
    value: 'lite_app',
    children: <LiteAppSettings />,
  },
  {
    label: 'Reservation Widget',
    value: 'reservation_widget',
    children: <ReservationWidgetSettings />,
  },
]

const AppsAndWidgets = () => {
  const [currentTab, setCurrentTab] = useState<string>('lite_app')
  const { dialog, displayMessage, closeDialog } = useDialog()

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  return (
    <>
      <Card>
        <Tabs
          tabs={TABS}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
        <NotificationDialog
          open={dialog.isOpen}
          onClose={closeDialog}
          message={dialog.message}
          type={dialog.type}
        />
      </Card>
    </>
  )
}

export default AppsAndWidgets
