/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { createTheme } from '@mui/material/styles'
import { useSelector, RootStateOrAny } from 'react-redux'
import typography from './Typography'
import Override from './Override'
import shadows from './Shadows'

// ##############################import {
import {
  BLUE_THEME,
  GREEN_THEME,
  RED_THEME,
  BLACK_THEME,
  PURPLE_THEME,
  INDIGO_THEME,
  ORANGE_THEME,
} from '../../redux/constants'
// // // Global Variables
// ##############################

const SidebarWidth = 265
const TopbarHeight = 70

const baseTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#1a97f5',
      light: '#e6f4ff',
      dark: '#1682d4',
    },
    secondary: {
      main: '#1e4db7',
      light: '#ddebff',
      dark: '#173f98',
    },

    success: {
      main: '#00c292',
      light: '#ebfaf2',
      dark: '#00964b',
      contrastText: '#ffffff',
    },
    danger: {
      main: '#e46a76',
      light: '#fdf3f5',
    },
    info: {
      main: '#0bb2fb',
      light: '#a7e3f4',
    },
    error: {
      main: '#e46a76',
      light: '#fdf3f5',
      dark: '#e45a68',
    },
    warning: {
      main: '#fec90f',
      light: '#fff4e5',
      dark: '#dcb014',
      contrastText: '#ffffff',
    },
    text: {
      secondary: '#777e89',
      danger: '#fc4b6c',
    },
    grey: {
      A100: '#ecf0f2',
      A200: '#99abb4',
      A400: '#767e89',
      A700: '#e6f4ff',
    },
    cancel: {
      main: '#616161',
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: 'rgba(0, 0, 0, 0.03)',
    },
  },

  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      color: '#949db2',
      '@media(min-width:1280px)': {
        minHeight: TopbarHeight,
        padding: '0 30px',
      },
      '@media(max-width:1280px)': {
        minHeight: '64px',
      },
    },
  },
  status: {
    danger: '#e53e3e',
  },
  typography,
  shadows,
}

const themesOptions = [
  {
    name: BLUE_THEME,
    palette: {
      primary: {
        main: '#1a97f5',
        light: '#e6f4ff',
        dark: '#1682d4',
      },
      secondary: {
        main: '#1e4db7',
        light: '#ddebff',
        dark: '#173f98',
      },
    },
  },
  {
    name: GREEN_THEME,
    palette: {
      primary: {
        main: '#00cec3',
        light: '#d7f8f6',
        dark: '#02b3a9',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#066a73',
      },
    },
  },
  {
    name: PURPLE_THEME,
    palette: {
      primary: {
        main: '#7352ff',
        light: '#e5e0fa',
        dark: '#5739d6',
      },
      secondary: {
        main: '#402e8d',
      },
    },
  },
  {
    name: INDIGO_THEME,
    palette: {
      primary: {
        main: '#1e4db7',
        light: '#e6f4ff',
        dark: '#0c399e',
      },
      secondary: {
        main: '#11397b',
      },
    },
  },
  {
    name: ORANGE_THEME,
    palette: {
      primary: {
        main: '#fb9678',
        light: '#e1876c',
        dark: '#e79f89',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#fb9678',
        light: '#e1876c',
        dark: '#e79f89',
        contrastText: '#ffffff',
      },
    },
  },
  {
    name: RED_THEME,
    palette: {
      primary: {
        main: '#ff5c8e',
        light: '#fce6ed',
        dark: '#d43653',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#5e244d',
      },
    },
  },
  {
    name: BLACK_THEME,
    palette: {
      primary: {
        main: '#1c2025',
      },
    },
  },
]

declare module '@mui/material/styles' {
  interface Palette {
    cancel: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    cancel?: PaletteOptions['primary']
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cancel: true
  }
}

export const BuildTheme = (config) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme)
  const customizer = useSelector(
    (state: RootStateOrAny) => state.CustomizerReducer,
  )
  const { components } = Override()

  const base = { ...baseTheme }

  const baseMode = {
    palette: {
      mode: customizer.activeMode,
      background: {
        default: customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
        dark: customizer.activeMode === 'dark' ? '#1c2025' : '#ffffff',
        paper: customizer.activeMode === 'dark' ? '#282C34' : '#ffffff',
      },
      text: {
        primary:
          customizer.activeMode === 'dark' ? '#e6e5e8' : 'rgba(0, 0, 0, 0.87)',
        secondary: customizer.activeMode === 'dark' ? '#adb0bb' : '#777e89',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 1000,
        lg: 1300,
        xl: 1636,
      },
    },
    components: {
      ...components,
      MuiInput: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input .MuiOutlinedInput-input': {
              '-webkit-box-shadow': 'none !important',
              '-webkit-text-fill-color': 'none !important',
            },
          },
        },
      },
    },
  }

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  function setCSSVariables() {
    document.documentElement.style.setProperty(
      '--palete-primary-main',
      theme.palette.primary.main,
    )
    document.documentElement.style.setProperty(
      '--palete-background-default',
      theme.palette.background.paper,
    )
    document.documentElement.style.setProperty(
      '--palete-text-primary',
      theme.palette.text.primary,
    )
    document.documentElement.style.setProperty(
      '--palete-text-primary',
      theme.palette.text.primary,
    )
  }

  const theme = createTheme(
    _.merge({}, base, baseMode, themeOptions, {
      direction: config.direction,
    }),
  )
  setCSSVariables()
  return theme
}

export { TopbarHeight, SidebarWidth, baseTheme }
