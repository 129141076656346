import { Order } from 'components/Table/components/TableHead/SortableTableHead'
import {
  IDevice,
  IGroup,
  IIssue,
  ILocation,
  IMember,
  ISubscription,
  IPrice,
  IPromo,
  ISize,
  ITransaction,
  IUser,
} from 'models'
import { ICondition } from 'models/Condition'
import { IProduct } from 'models/Product'
import { IProductGroup } from 'models/ProductGroup'
import { IReport } from 'models/Report'
import { IReservation } from 'models/Reservations'
import React, { useState } from 'react'
import { INotification } from 'types'

export type NestedProperty = {
  columnValue: string
  path: string
  defaultValue: string | number // fallback value in case of unexisting prop
}

type Entity =
  | IDevice
  | ILocation
  | IPromo
  | IPrice
  | ISubscription
  | ISize
  | ITransaction
  | IReservation
  | IUser
  | IMember
  | IGroup
  | IIssue
  | IProduct
  | IProductGroup
  | ICondition
  | IReport
  | INotification

interface IUseSortableHeader {
  defaultOrder?: Order
  defaultOrderBy: string
  entity: string
  nestedProps?: NestedProperty[] // Useful to get nested or different named props
}

const useSortableHeader = ({
  defaultOrder = 'default',
  defaultOrderBy,
  entity,
  nestedProps = [],
}: IUseSortableHeader) => {
  const [order, setOrder] = useState<Order>(defaultOrder)
  const [orderBy, setOrderBy] = useState<string>(defaultOrderBy)

  const getValueFromNestedKey = (
    object: Entity,
    path: string,
    defaultValue: string | number,
  ) => {
    const value = path
      .replace(/\[(\d+)\]/g, '.$1') // Convert array indices to dot notation (e.g., devices[0] -> devices.0)
      .split('.')
      .reduce((accumulator, currentValue) => {
        if (accumulator && accumulator[currentValue] !== undefined) {
          return accumulator[currentValue]
        }
        return defaultValue
      }, object)
    return value
  }

  const getDataTyped = (data: Entity[]) => {
    switch (entity) {
      case 'pay-per':
        return data as IPrice[]
      case 'subscriptions':
        return data as ISubscription[]
      case 'promo-codes':
        return data as IPromo[]
      case 'locations':
        return data as ILocation[]
      case 'devices':
        return data as IDevice[]
      case 'sizes':
        return data as ISize[]
      case 'pricing':
        return data as IPrice[]
      case 'inventory':
        return data as IProduct[]
      case 'product-groups':
        return data as IProductGroup[]
      case 'conditions':
        return data as ICondition[]
      case 'users':
        return data as IUser[]
      case 'groups':
        return data as IGroup[]
      case 'members':
        return data as IMember[]
      case 'transactions':
        return data as ITransaction[]
      case 'reservations':
        return data as any[]
      case 'subscribers':
        return data as any[]
      case 'issues':
        return data as IIssue[]
      case 'notifications':
        return data as INotification[]
      default:
        return data as any[]
    }
  }

  const parseValue = (value: any) => {
    switch (typeof value) {
      case 'number':
        return value
      case 'string':
        return value.toLowerCase()
      default:
        return value ? value.toString() : ''
    }
  }

  const comparator = (a: Entity, b: Entity) => {
    const nestedKey = nestedProps.find(
      (property) => property.columnValue === orderBy,
    )
    const itemA = nestedKey
      ? getValueFromNestedKey(a, nestedKey.path, nestedKey.defaultValue)
      : a[orderBy]

    const itemB = nestedKey
      ? getValueFromNestedKey(b, nestedKey.path, nestedKey.defaultValue)
      : b[orderBy]

    const valueA = parseValue(itemA)
    const valueB = parseValue(itemB)

    if (valueB < valueA) {
      return -1
    }
    if (valueB > valueA) {
      return 1
    }
    return 0
  }

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: string,
  ) => {
    //fallback to default order to asc just in case we're switching to a "new" column being sorted
    let newOrder: Order = 'asc'
    //If we're just cycling, then go through the switch statement
    if (orderBy === property) {
      switch (order) {
        case 'default':
          newOrder = 'asc'
          break
        case 'asc':
          newOrder = 'desc'
          break
        default:
          newOrder = 'default'
      }
    }
    setOrder(newOrder)
    setOrderBy(property)
  }

  const getVisibleRowsSorted = (
    data: Array<Entity>,
    dataBackup: Array<Entity>,
  ) => {
    if (order !== 'default') {
      const comparatorFn =
        order === 'asc'
          ? (a: Entity, b: Entity) => -comparator(a, b)
          : (a: Entity, b: Entity) => comparator(a, b)
      return getDataTyped(data.sort((a, b) => comparatorFn(a, b)))
    }
    return data
  }

  return {
    order,
    setOrder,
    orderBy,
    handleRequestSort,
    getVisibleRowsSorted,
  }
}

export default useSortableHeader
