import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import { Box, Tooltip } from '@mui/material'
import { CSSProperties, PropsWithChildren, useState } from 'react'

interface IHelpProps {
  helpText: string
  styles?: CSSProperties
}

const Help = ({
  helpText,
  styles,
  children,
}: PropsWithChildren<IHelpProps>) => {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{ ...styles, display: 'flex', alignItems: 'center', gap: '0.25rem' }}
    >
      {children}
      {hover && (
        <Tooltip title={helpText} sx={{ cursor: 'pointer' }} arrow>
          <HelpOutlineRoundedIcon fontSize="small" />
        </Tooltip>
      )}
    </Box>
  )
}

export default Help
