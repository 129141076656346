import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material'
import {
  Box,
  Typography,
  TextField as MUITextField,
  Select,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  Paper,
  Divider,
  Grid,
} from '@mui/material'
import { Form, IDialogType, Image, TextField } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import LoadingFormData from 'components/PageBase/LoadingFormData'
import useDeviceModes from 'hooks/useDeviceModes'
import useHardwareTypes from 'hooks/useHardwareTypes'
import useLoadingState from 'hooks/useLoadingState'
import { IMember, IOrganization, OrgForm } from 'models'
import { DEVICE_TYPES } from 'pages/Doors/constants'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { memberApi, organizationApi, settingsApi } from 'resources'

interface IOrganizationFormProps {
  displayMessage: (message: string, type?: IDialogType) => void
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>
  success: () => void
  selectedOrg: IOrganization | undefined
  orgsNames: string[]
}

const OrganizationForm = ({
  displayMessage,
  setShowSidebar,
  success,
  selectedOrg,
  orgsNames,
}: IOrganizationFormProps) => {
  const [previewImage, setImagePreview] = useState('')
  const [image, setImage] = useState<any>(null)
  const [admins, setAdmins] = useState<IMember[]>([])
  const [isNameUnique, setIsNameUnique] = useState<boolean>(true)
  const [isValidOrgName, setIsValidOrgName] = useState<boolean>(true)
  const [selectAllModes, setSelectAllModes] = useState<boolean>(() => {
    if (selectedOrg) {
      const modeKeys = [
        'rental_mode',
        'storage_mode',
        'delivery_mode',
        'service_mode',
        'vending_mode',
      ]
      const allModesEnabled = modeKeys.every(
        (mode) => selectedOrg[mode] === true,
      )

      return allModesEnabled
    }

    return false
  })
  const [selectAllHardwares, setSelectAllHardwares] = useState<boolean>(() => {
    if (selectedOrg) {
      const hardwareKeys = [
        'linka_hardware',
        'ojmar_hardware',
        'gantner_hardware',
        'harbor_hardware',
        'dclock_hardware',
        'spintly_hardware',
      ]

      const allHardwaresEnabled = hardwareKeys.every(
        (hardware) => selectedOrg[hardware] === true,
      )

      return allHardwaresEnabled
    }

    return false
  })
  const [organizationOwner, setOrganizationOwner] = useState<string | null>(
    selectedOrg ? selectedOrg?.white_label?.organization_owner : null,
  )
  const [selectedModes, setSelectedModes] = useState({
    rental_mode: selectedOrg ? selectedOrg.rental_mode : false,
    storage_mode: selectedOrg ? selectedOrg.storage_mode : false,
    delivery_mode: selectedOrg ? selectedOrg.delivery_mode : false,
    service_mode: selectedOrg ? selectedOrg.service_mode : false,
    vending_mode: selectedOrg ? selectedOrg.vending_mode : false,
  })

  const [selectedHardwares, setSelectedHardwares] = useState({
    linka_hardware: selectedOrg ? selectedOrg.linka_hardware : false,
    ojmar_hardware: selectedOrg ? selectedOrg.ojmar_hardware : false,
    gantner_hardware: selectedOrg ? selectedOrg.gantner_hardware : false,
    harbor_hardware: selectedOrg ? selectedOrg.harbor_hardware : false,
    dclock_hardware: selectedOrg ? selectedOrg.dclock_hardware : false,
    spintly_hardware: selectedOrg ? selectedOrg.spintly_hardware : false,
  })

  const { create, patchOrgFeatures } = organizationApi()
  const { getMany } = memberApi()
  const { updateOrgWhiteLabel } = settingsApi()
  const { modes, isLoadingModes } = useDeviceModes()

  const { loading, setLoading } = useLoadingState()
  const { loading: loadingAdmins, setLoading: setLoadingAdmins } =
    useLoadingState()

  const { orgHardware } = useHardwareTypes()

  const { name } = JSON.parse(localStorage.getItem('currentOrg') || '{}')

  const ref = useRef(true)

  const readOnly =
    selectedOrg &&
    selectedOrg.white_label?.organization_owner === null &&
    name !== selectedOrg?.name

  const getFormTitle = useCallback(() => {
    if (selectedOrg && !readOnly) {
      return 'Edit Organization'
    } else if (!selectedOrg) {
      return 'Add Organization'
    } else if (selectedOrg && readOnly) return 'Organization Details'
  }, [])

  const adminEmails = useMemo(
    () => admins.map((admin) => ({ value: admin.email, label: admin.email })),
    [admins],
  )

  const methods = useForm<OrgForm>({
    defaultValues: {
      id: selectedOrg?.id,
      name: selectedOrg?.white_label?.app_name,
      primary_color: selectedOrg?.white_label?.primary_color,
      secondary_color: selectedOrg?.white_label?.secondary_color,
      tertiary_color: selectedOrg?.white_label?.tertiary_color,
      email: selectedOrg?.white_label?.organization_owner,
      pricing: selectedOrg?.pricing,
      product: selectedOrg?.product,
      notifications: selectedOrg?.notifications,
      multi_tenant: selectedOrg?.multi_tenant,
      toolbox: selectedOrg?.toolbox,
      lite_app_enabled: selectedOrg?.lite_app_enabled,
      super_tenant: selectedOrg?.super_tenant,
      shared_locations: selectedOrg?.shared_locations,
    },
  })

  const orgName: string = methods.watch('name')

  const fetchAdmins = async () => {
    try {
      setLoadingAdmins(true)
      const members = await getMany(1, 1000)
      const admins: IMember[] = members.items.filter(
        (member) => member.role === 'admin',
      )
      setAdmins(admins)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoadingAdmins(false)
    }
  }

  // **** modes dropdown functions ****

  const handleToggleSelectAllModes = () => {
    setSelectAllModes((previousState) => {
      setSelectedModes((previousModes) => {
        const modes = { ...previousModes }
        Object.keys(modes).forEach((mode) => {
          modes[mode] = !previousState
        })
        return modes // Return the updated modes object
      })
      return !previousState // Toggle the select all state
    })
  }

  const handleSelectModes = (event: any, value: any) => {
    // handles selectedModes state update
    const selectedModes = value.map((mode) => mode.value)

    const newState = {
      rental_mode: selectedModes.includes('rental'),
      storage_mode: selectedModes.includes('storage'),
      delivery_mode: selectedModes.includes('delivery'),
      service_mode: selectedModes.includes('service'),
      vending_mode: selectedModes.includes('vending'),
    }

    setSelectedModes(newState)
    if (
      selectAllModes &&
      Object.keys(newState).some((mode) => newState[mode] === false)
    ) {
      setSelectAllModes(false)
    }
    if (
      !selectAllModes &&
      Object.keys(newState).every((mode) => newState[mode] === true)
    ) {
      setSelectAllModes(true)
    }
  }

  const handleModesValues = () => {
    // handles value property in Mode autocomplete
    return modes.filter((mode) => {
      return (
        (mode.value === 'rental' && selectedModes.rental_mode) ||
        (mode.value === 'storage' && selectedModes.storage_mode) ||
        (mode.value === 'delivery' && selectedModes.delivery_mode) ||
        (mode.value === 'service' && selectedModes.service_mode) ||
        (mode.value === 'vending' && selectedModes.vending_mode)
      )
    })
  }

  const handleOrgModesLoad = () => {
    // loads initial options depending on the selected org
    return modes.filter((mode) => {
      if (selectedOrg) {
        return (
          (mode.value === 'rental' && selectedOrg.rental_mode) ||
          (mode.value === 'storage' && selectedOrg.storage_mode) ||
          (mode.value === 'delivery' && selectedOrg.delivery_mode) ||
          (mode.value === 'service' && selectedOrg.service_mode) ||
          (mode.value === 'vending' && selectedOrg.vending_mode)
        )
      }

      return false
    })
  }

  // **** hardware types dropdown functions ****

  const handleToggleSelectAllHardwares = () => {
    setSelectAllHardwares((previousState) => {
      setSelectedHardwares((previousHardwareTypes) => {
        const hardwares = { ...previousHardwareTypes }
        Object.keys(hardwares).forEach((hardware) => {
          hardwares[hardware] = !previousState
        })
        return hardwares // Ensure to return the updated hardware object
      })
      return !previousState
    })
  }

  const handleSelectHardwares = (event: any, value: any) => {
    // handles selectedHardwares state update
    const selectedHardwares = value.map((mode) => mode.value)

    const newState = {
      linka_hardware: selectedHardwares.includes('linka'),
      ojmar_hardware: selectedHardwares.includes('ojmar'),
      gantner_hardware: selectedHardwares.includes('gantner'),
      harbor_hardware: selectedHardwares.includes('harbor'),
      dclock_hardware: selectedHardwares.includes('dclock'),
      spintly_hardware: selectedHardwares.includes('spintly'),
    }

    setSelectedHardwares(newState)
    if (
      selectAllHardwares &&
      Object.keys(newState).some((mode) => newState[mode] === false)
    ) {
      setSelectAllHardwares(false)
    }
    if (
      !selectAllHardwares &&
      Object.keys(newState).every((mode) => newState[mode] === true)
    ) {
      setSelectAllHardwares(true)
    }
  }

  const handleHardwaresValues = () => {
    // handles value property in Mode autocomplete
    return DEVICE_TYPES.filter((device) => {
      return (
        (device.value === 'linka' && selectedHardwares.linka_hardware) ||
        (device.value === 'ojmar' && selectedHardwares.ojmar_hardware) ||
        (device.value === 'gantner' && selectedHardwares.gantner_hardware) ||
        (device.value === 'harbor' && selectedHardwares.harbor_hardware) ||
        (device.value === 'dclock' && selectedHardwares.dclock_hardware) ||
        (device.value === 'spintly' && selectedHardwares.spintly_hardware)
      )
    })
  }

  const handleOrgHardwareLoad = () => {
    // loads initial options depending on the selected org
    return DEVICE_TYPES.filter((device) => {
      if (selectedOrg) {
        return (
          (device.value === 'linka' && selectedOrg.linka_hardware) ||
          (device.value === 'ojmar' && selectedOrg.ojmar_hardware) ||
          (device.value === 'gantner' && selectedOrg.gantner_hardware) ||
          (device.value === 'harbor' && selectedOrg.harbor_hardware) ||
          (device.value === 'dclock' && selectedOrg.dclock_hardware) ||
          (device.value === 'spintly' && selectedOrg.spintly_hardware)
        )
      }

      return false
    })
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = event?.target?.files?.[0]
    const isImage = file && /^image\//.test(file.type)

    if (!isImage) {
      displayMessage('A valid logo image file is required.', 'info')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) =>
      e.target && e.target.result && setImagePreview(e.target.result as string)

    console.log(file)

    setImage(file ?? null)
  }

  const buildPayload = (newOrg): FormData => {
    const request = new FormData()
    request.append('app_name', newOrg.name)
    request.append('primary_color', newOrg.primary_color)
    if (newOrg.secondary_color) {
      request.append('secondary_color', newOrg.secondary_color)
      request.append('link_text_color', newOrg.secondary_color)
      request.append('button_text_color', newOrg.secondary_color)
    }
    if (newOrg.tertiary_color) {
      request.append('tertiary_color', newOrg.tertiary_color)
    }
    if (image) {
      request.append('image', image)
    }
    if (selectedOrg) {
      request.append('organization_owner', newOrg.email.toLowerCase().trim())
    } else {
      request.append('email', newOrg.email.toLowerCase().trim())
      request.append('name', `${newOrg.first_name} ${newOrg.last_name}`)
      request.append('first_name', newOrg.first_name)
      request.append('last_name', newOrg.last_name)
    }

    // Creation of org requires features and body is of type multipart/form-data
    request.append('rental_mode', newOrg.rental_mode)
    request.append('storage_mode', newOrg.storage_mode)
    request.append('delivery_mode', newOrg.delivery_mode)
    request.append('service_mode', newOrg.service_mode)
    request.append('vending_mode', newOrg.vending_mode)

    request.append('linka_hardware', newOrg.linka_hardware)
    request.append('ojmar_hardware', newOrg.ojmar_hardware)
    request.append('gantner_hardware', newOrg.gantner_hardware)
    request.append('harbor_hardware', newOrg.harbor_hardware)
    request.append('dclock_hardware', newOrg.dclock_hardware)
    request.append('spintly_hardware', newOrg.spintly_hardware)

    request.append('pricing', newOrg.pricing)
    request.append('product', newOrg.product)
    request.append('notifications', newOrg.notifications)
    request.append('multi_tenant', newOrg.multi_tenant)
    request.append('toolbox', newOrg.toolbox)
    request.append('super_tenant', newOrg.super_tenant)
    request.append('lite_app_enabled', newOrg.lite_app_enabled)
    request.append('shared_locations', newOrg.shared_locations)

    return request
  }

  const buildOrgFeaturesPayload = (newOrg) => {
    // Update of org features is application/json and only requires
    // the following body:
    return {
      rental_mode: newOrg.rental_mode,
      storage_mode: newOrg.storage_mode,
      delivery_mode: newOrg.delivery_mode,
      service_mode: newOrg.service_mode,
      vending_mode: newOrg.vending_mode,

      linka_hardware: newOrg.linka_hardware,
      ojmar_hardware: newOrg.ojmar_hardware,
      gantner_hardware: newOrg.gantner_hardware,
      harbor_hardware: newOrg.harbor_hardware,
      dclock_hardware: newOrg.dclock_hardware,
      spintly_hardware: newOrg.spintly_hardware,

      pricing: newOrg.pricing,
      product: newOrg.product,
      notifications: newOrg.notifications,
      multi_tenant: newOrg.multi_tenant,
      toolbox: newOrg.toolbox,
      super_tenant: newOrg.super_tenant,
      lite_app_enabled: newOrg.lite_app_enabled,
    }
  }

  const onSubmit = async (newOrg: OrgForm): Promise<void> => {
    setLoading(true)
    try {
      if (!image && !selectedOrg?.white_label?.app_logo) {
        displayMessage('A valid logo image file is required.', 'info')
        return
      }

      if (Object.values(selectedHardwares).every((value) => value === false)) {
        displayMessage(
          'You need to select at least one hardware type for this organization.',
          'info',
        )
        return
      }

      const payload = buildPayload({
        ...newOrg,
        ...selectedModes,
        ...selectedHardwares,
      })

      if (selectedOrg) {
        const orgFeaturesPayload = buildOrgFeaturesPayload({
          ...selectedModes,
          ...selectedHardwares,
        })
        await updateOrgWhiteLabel(selectedOrg.id, payload)
        await patchOrgFeatures(selectedOrg.id, orgFeaturesPayload)
        displayMessage('Organization updated successfully!', 'success')
      } else {
        await create(payload)
        displayMessage('Organization created successfully!', 'success')
      }
      setShowSidebar(false)
      methods.reset()
      success()
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleOrganizationOwnerChange = (event) => {
    methods.setValue('email', event.target.value)
    setOrganizationOwner(event.target.value)
  }

  useEffect(() => {
    if (selectedOrg) {
      fetchAdmins()
    }
  }, [])

  useEffect(() => {
    if (orgName) {
      // Filter out the selected org name from the list of org names
      // to check if the name is unique. If the selected org is being edited,
      // we don't want to include it in the list of org names
      // to check for uniqueness.
      //
      // We only check for uniqueness against the rest of orgs in the list.
      const parsedOrgNames = orgsNames.filter(
        (name) => name !== selectedOrg?.name,
      )

      const formattedName = orgName.toLocaleLowerCase().replace(' ', '-')

      if (parsedOrgNames.includes(formattedName)) {
        setIsNameUnique(false)
      } else {
        setIsNameUnique(true)
      }
    } else {
      setIsNameUnique(true)
    }

    setIsValidOrgName(/^[a-zA-Z0-9\s]*$/g.test(orgName))
  }, [orgName])

  useEffect(() => {
    if (selectedOrg?.white_label) {
      setOrganizationOwner(selectedOrg.white_label?.organization_owner)
    }
  }, [selectedOrg])

  useEffect(() => {
    if (selectedOrg?.white_label) {
      setOrganizationOwner(selectedOrg.white_label?.organization_owner)
    }
  }, [modes])

  useEffect(() => {
    if (selectedOrg && modes.length > 0) {
      if (ref.current) {
        // Handle first render
        ref.current = false // Set to false to prevent this running again as a "first render"
      }
      // Set selected modes after initial render or when selectedOrg/modes update
      setSelectedModes({
        rental_mode: selectedOrg.rental_mode,
        storage_mode: selectedOrg.storage_mode,
        delivery_mode: selectedOrg.delivery_mode,
        service_mode: selectedOrg.service_mode,
        vending_mode: selectedOrg.vending_mode,
      })
      setSelectedHardwares({
        linka_hardware: selectedOrg.linka_hardware,
        ojmar_hardware: selectedOrg.ojmar_hardware,
        gantner_hardware: selectedOrg.gantner_hardware,
        harbor_hardware: selectedOrg.harbor_hardware,
        dclock_hardware: selectedOrg.dclock_hardware,
        spintly_hardware: selectedOrg.spintly_hardware,
      })
    }
  }, [selectedOrg, modes]) // This runs whenever selectedOrg or modes change

  const disableSubmit =
    !isValidOrgName ||
    !isNameUnique ||
    Object.keys(methods.formState.errors).length > 0

  if (isLoadingModes || loadingAdmins) {
    return <LoadingFormData />
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title={getFormTitle()}>
          {selectedOrg?.white_label?.app_logo && !previewImage && (
            <>
              <Typography variant="h6">Organization Logo</Typography>
              <Image
                src={selectedOrg?.white_label?.app_logo}
                alt="Organization Logo"
                style={{ outline: 'none' }}
              />
            </>
          )}
          {previewImage && (
            <>
              <Typography>
                Preview: <b>{image ? image.name : ''}</b>
              </Typography>
              <Image
                src={previewImage}
                alt="Organization Image"
                style={{ outline: 'none' }}
              />
            </>
          )}
          <ImageUploader
            buttonText={
              selectedOrg?.white_label?.app_logo ? 'Update Logo' : 'Upload Logo'
            }
            helpText="Upload a rectangular image preferably of size 400x250 in high resolution for better display results."
            onChange={handleImageChange}
          />

          <>
            {selectedOrg && (
              <TextField name="id" label="Organization ID" disabled={true} />
            )}
            <TextField
              name="name"
              label="Organization Name"
              placeholder="Type your organization name"
              rules={{
                required: 'Organization name required',
              }}
              disabled={(selectedOrg && readOnly) || loading}
            />
            {!isNameUnique && (
              <Typography
                sx={{
                  color: '#ff0000',
                  fontSize: '0.9rem',
                  fontWeight: 'bold',
                  margin: '0.1rem',
                }}
              >
                An organization with this name already exists. Please use an
                unique name.
              </Typography>
            )}
            {!isValidOrgName && (
              <Typography
                sx={{
                  color: '#ff0000',
                  fontSize: '0.9rem',
                  // fontWeight: 'bold',
                  margin: '0.1rem',
                }}
              >
                Organization name can only contain letters, numbers and spaces.{' '}
                <strong>Example: ABC Luggage</strong>. Remove any special
                characters such as -, @, #, $, %.
              </Typography>
            )}
          </>

          <Typography variant="h6">
            Click any of the fields below to select a color.
          </Typography>

          <Box
            sx={{
              position: 'relative',

              verticalAlign: 'middle',
            }}
          >
            <MUITextField
              label="Primary Color"
              placeholder="#HEX"
              type="color"
              fullWidth
              sx={{
                height: '4em',
                width: '125px',
                position: 'relative',

                input: {
                  height: '3em',
                  margin: 0,
                  padding: '10px 25px',
                },
              }}
              {...methods.register('primary_color')}
              disabled={(selectedOrg && readOnly) || loading}
            />
            <Typography
              sx={{
                display: 'inline-block',
                marginTop: '1.2em',
                marginLeft: '1.2em',
              }}
            >
              {methods.watch('primary_color') ||
                selectedOrg?.white_label?.primary_color ||
                '#000000'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <MUITextField
                label="Secondary Color"
                placeholder="#HEX"
                type="color"
                fullWidth
                sx={{
                  height: '4em',
                  width: '125px',

                  input: {
                    height: '3em',
                    margin: 0,
                    padding: '10px 25px',
                  },
                }}
                {...methods.register('secondary_color')}
                disabled={(selectedOrg && readOnly) || loading}
              />

              <Typography
                sx={{
                  display: 'inline-block',
                  marginTop: '1.2em',
                  marginLeft: '1.2em',
                }}
              >
                {methods.watch('secondary_color') ||
                  selectedOrg?.white_label?.secondary_color ||
                  '#000000'}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <MUITextField
                label="Tertiary Color"
                placeholder="#HEX"
                type="color"
                fullWidth
                sx={{
                  height: '4em',
                  width: '125px',

                  input: {
                    height: '3em',
                    margin: 0,
                    padding: '10px 25px',
                  },
                }}
                {...methods.register('tertiary_color')}
                disabled={(selectedOrg && readOnly) || loading}
              />

              <Typography
                sx={{
                  display: 'inline-block',
                  marginTop: '1.2em',
                  marginLeft: '1em',
                }}
              >
                {methods.watch('tertiary_color') ||
                  selectedOrg?.white_label?.tertiary_color ||
                  '#000000'}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              marginTop: '1em',
            }}
          >
            {/* Conditional rendering is necessary to load defaultValue correctly: */}
            {!isLoadingModes && (
              <Autocomplete
                multiple
                freeSolo={false}
                filterSelectedOptions={true}
                options={modes}
                value={
                  ref.current && selectedOrg
                    ? handleOrgModesLoad()
                    : handleModesValues()
                }
                defaultValue={handleOrgModesLoad()}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBoxOutlined fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <MUITextField {...params} label="Select a mode" />
                )}
                onChange={handleSelectModes}
                loading={isLoadingModes}
                PaperComponent={(paperProps) => {
                  const { children, ...restPaperProps } = paperProps
                  return (
                    <Paper {...restPaperProps}>
                      <Box
                        onMouseDown={(e) => e.preventDefault()}
                        pl={1.5}
                        py={0.5}
                      >
                        <FormControlLabel
                          onClick={(e) => {
                            e.preventDefault()
                            handleToggleSelectAllModes()
                          }}
                          label="Select all modes"
                          control={
                            <Checkbox
                              id="select-all-checkbox"
                              checked={selectAllModes}
                            />
                          }
                        />
                      </Box>
                      <Divider />
                      {children}
                    </Paper>
                  )
                }}
              />
            )}
          </Box>

          <Autocomplete
            multiple
            freeSolo={false}
            filterSelectedOptions={true}
            options={orgHardware}
            value={
              ref.current && selectedOrg
                ? handleOrgHardwareLoad()
                : handleHardwaresValues()
            }
            defaultValue={handleOrgHardwareLoad()}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBoxOutlined fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )
            }}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <MUITextField {...params} label="Select hardware" />
            )}
            onChange={handleSelectHardwares}
            loading={orgHardware.length === 0}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()}
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault()
                        handleToggleSelectAllHardwares()
                      }}
                      label="Select all hardwares"
                      control={
                        <Checkbox
                          id="select-all-checkbox"
                          checked={selectAllHardwares}
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              )
            }}
          />
          <Box>
            <Typography variant="h6">Allow access for</Typography>
            <Grid container>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('multi_tenant')}
                      defaultChecked={selectedOrg?.multi_tenant}
                    />
                  }
                  label="Multi-Tenant"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('pricing')}
                      defaultChecked={selectedOrg?.pricing}
                    />
                  }
                  label="Pricing"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('product')}
                      defaultChecked={selectedOrg?.product}
                    />
                  }
                  label="Products"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('notifications')}
                      defaultChecked={selectedOrg?.notifications}
                    />
                  }
                  label="Notifications"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('lite_app_enabled')}
                      defaultChecked={selectedOrg?.lite_app_enabled}
                    />
                  }
                  label="Lite app"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('toolbox')}
                      defaultChecked={selectedOrg?.toolbox}
                    />
                  }
                  label="Toolbox"
                />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('super_tenant')}
                      defaultChecked={selectedOrg?.super_tenant}
                    />
                  }
                  label="Super-Tenant"
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('shared_locations')}
                      defaultChecked={selectedOrg?.shared_locations}
                    />
                  }
                  label="Shared Locations"
                />
              </Grid>
            </Grid>
          </Box>

          <Typography variant="h6">
            Organization&apos;s Owner Information
          </Typography>
          {!selectedOrg && (
            <>
              <TextField
                name="email"
                label="Email"
                placeholder="Enter your organization's owner email"
                rules={{
                  required: 'Email required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                disabled={loading}
              />
              <Box sx={{ display: 'flex', gap: '0 15px' }}>
                <TextField
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  rules={{
                    required: 'First name required',
                  }}
                  disabled={loading}
                />
                <TextField
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  disabled={loading}
                />
              </Box>
            </>
          )}
          {selectedOrg && selectedOrg?.white_label?.organization_owner && (
            <TextField
              name="email"
              label="Email"
              placeholder="Enter your organization's owner email"
              disabled={true}
            />
          )}
          {selectedOrg && !readOnly && (
            <>
              <Typography>
                Below you can find all admins of the organization. You can
                change the organization owner when you want
              </Typography>

              <FormControl fullWidth>
                <Select
                  name="email"
                  value={organizationOwner}
                  onChange={handleOrganizationOwnerChange}
                >
                  {adminEmails.map((admin, index) => (
                    <MenuItem key={index} value={admin.value}>
                      {admin.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <FormActions
            loading={loading}
            onClose={() => setShowSidebar(false)}
            disableSubmit={disableSubmit || readOnly}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )
}

export default OrganizationForm
