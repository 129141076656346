import { useApiRequest } from 'utils'
import { HTTP_CODES } from 'constants/HTTP'
import {
  IAverageRevenuePerTransaction,
  IDoorHealth,
  IEarnings,
  INewUserGrowth,
  IOccupancyRate,
  IReportedIssueRate,
  ITotalDoors,
  ITotalLocations,
  ITotalTransactions,
  ITransactionRate,
  ITransactionsPerLocker,
} from 'models/Analytics'
import { getErrorMessage } from 'utils/helpers'
import { IReport, IReportList } from 'models/Report'

const GET_ERRORS = {
  [HTTP_CODES.NOT_FOUND]: 'No users found',
  [HTTP_CODES.INTERNAL_SERVER_ERROR]: 'An internal error occurred',
}

const reportApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const createReport = async (data: Partial<IReport>): Promise<any> => {
    const endpoint = 'reports'
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(data)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const updateReport = async (
    reportId: string,
    data: Partial<IReport>,
  ): Promise<any> => {
    const endpoint = `reports/${reportId}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(data)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const downloadReport = async (reportId: string): Promise<any> => {
    const endpoint = `reports/download/${reportId}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.blob()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]): Promise<IReport> => {
    const endpoint = `reports`
    const method = 'DELETE'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getAllReports = async (
    page: number,
    size: number,
    search: string = '',
    targetOrg: string | null = null,
  ): Promise<IReportList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
      ...(targetOrg && {
        target_org: targetOrg,
      }),
    })
    const endpoint = `reports/all?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const usersJSON = await response.json()
        return usersJSON
      }
      throw new Error(
        (await response.json()).detail ||
          'An unknown or unexpected error has occurred',
      )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getEarnings = async (
    targetOrg?: string,
  ): Promise<IEarnings | string> => {
    const urlParams = new URLSearchParams({
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/earnings?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const usersJSON = await response.json()
        return usersJSON
      }
      // throw new Error(GET_ERRORS[response.status])
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getUserGrowth = async (
    interval?: string,
    targetOrg?: string,
  ): Promise<INewUserGrowth | string> => {
    const urlParams = new URLSearchParams({
      ...(interval && { interval }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/user_growth?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getDoorHealth = async (
    targetOrg?: string,
  ): Promise<IDoorHealth[] | string> => {
    const urlParams = new URLSearchParams({
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/door_health?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getIssueRate = async (
    fromDate?: Date,
    toDate?: Date,
    locations?: string[],
    targetOrg?: string,
  ): Promise<IReportedIssueRate | string> => {
    const urlParams = new URLSearchParams({
      ...(fromDate && { from_date: fromDate.toISOString() }),
      ...(toDate && { to_date: toDate.toISOString() }),
      ...(locations &&
        locations?.length > 0 && { locations: JSON.stringify(locations) }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/issue_rate?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getOccupancyRate = async (
    fromDate?: Date,
    toDate?: Date,
    targetOrg?: string,
  ): Promise<IOccupancyRate[] | string> => {
    const urlParams = new URLSearchParams({
      ...(fromDate && { from_date: fromDate.toISOString() }),
      ...(toDate && { to_date: toDate.toISOString() }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/occupancy_rate?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getNewTransactionRate = async (
    fromDate?: Date,
    toDate?: Date,
    targetOrg?: string,
  ): Promise<ITransactionRate | string> => {
    const urlParams = new URLSearchParams({
      ...(fromDate && { from_date: fromDate.toISOString() }),
      ...(toDate && { to_date: toDate.toISOString() }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/transaction_rate?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTransactionsPerLocker = async (
    locationId?: string,
    fromDate?: Date,
    toDate?: Date,
    targetOrg?: string,
  ): Promise<ITransactionsPerLocker | string> => {
    const urlParams = new URLSearchParams({
      ...(locationId && { id_location: locationId }),
      ...(fromDate && { start_date: fromDate.toISOString() }),
      ...(toDate && { end_date: toDate.toISOString() }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/transactions_per_locker?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTotalTransactions = async (
    locationId?: string,
    date?: Date,
    targetOrg?: string,
  ): Promise<ITotalTransactions | string> => {
    const urlParams = new URLSearchParams({
      ...(locationId && { id_location: locationId }),
      ...(date && { date: date.toISOString() }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/total_transactions?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTransactionTimeAverage = async (
    startDate?: Date,
    endDate?: Date,
    targetOrg?: string,
  ): Promise<number | string> => {
    const urlParams = new URLSearchParams({
      ...(startDate && { start_date: startDate.toISOString() }),
      ...(endDate && { end_date: endDate.toISOString() }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/avg_transaction_time?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTotalUsers = async (
    targetOrg?: string,
  ): Promise<number | string> => {
    const urlParams = new URLSearchParams({
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/total_users?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTotalLocations = async (
    targetOrg?: string,
  ): Promise<ITotalLocations | string> => {
    const urlParams = new URLSearchParams({
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/total_locations?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getAverageRevenuePerTransaction = async (
    targetOrg?: string,
  ): Promise<IAverageRevenuePerTransaction | string> => {
    const urlParams = new URLSearchParams({
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/avg_revenue_per_transaction?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTotalDoors = async (
    locationId?: string,
    targetOrg?: string,
  ): Promise<ITotalDoors | string> => {
    const urlParams = new URLSearchParams({
      ...(locationId && { id_location: locationId }),
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/door_counts?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      return 'No data found'
      // throw new Error(
      //   (await response.json()).detail ||
      //     'An unknown or unexpected error has occurred',
      // )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getUsers = async (): Promise<any> => {
    const endpoint = `reports/users`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const usersJSON = await response.json()
        return usersJSON
      }
      throw new Error(GET_ERRORS[response.status])
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTransactions = async (): Promise<any> => {
    const endpoint = `reports/transactions`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const usersJSON = await response.json()
        return usersJSON
      }
      throw new Error(GET_ERRORS[response.status])
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getTopUsers = async (targetOrg?: string): Promise<any> => {
    const urlParams = new URLSearchParams({
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/top_users?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const usersJSON = await response.json()
        return usersJSON
      }
      throw new Error(GET_ERRORS[response.status])
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getLocations = async (targetOrg?: string): Promise<any> => {
    const urlParams = new URLSearchParams({
      ...(targetOrg && { target_org: targetOrg }),
    })
    const endpoint = `reports/locations?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const locationsJSON = await response.json()
        return locationsJSON
      }
      throw new Error(GET_ERRORS[response.status])
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getReports = async (): Promise<any> => {
    const endpoint = `reports`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportsJSON = await response.json()
        return reportsJSON
      }
      throw new Error(GET_ERRORS[response.status])
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getReport = async (reportType: string): Promise<any> => {
    const endpoint = `reports/${reportType}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (response.ok) {
        const reportData = await response.json()
        return reportData
      }
      throw new Error(
        (await response.json()).detail ||
          'An unknown or unexpected error has occurred',
      )
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    createReport,
    updateReport,
    downloadReport,
    getAllReports,
    remove,
    getEarnings,
    getUserGrowth,
    getDoorHealth,
    getIssueRate,
    getOccupancyRate,
    getNewTransactionRate,
    getTransactionsPerLocker,
    getTotalTransactions,
    getTransactionTimeAverage,
    getTotalUsers,
    getTotalLocations,
    getAverageRevenuePerTransaction,
    getTotalDoors,
    //old endpoints below
    getUsers,
    getTransactions,
    getTopUsers,
    getLocations,
    getReports,
  }
}

export default reportApi
