import { useMemo } from 'react'
import { isSuperTenant } from 'utils/helpers'

const useIsSuperTenantOrg = () => {
  const isSuperTenantOrg = useMemo(() => isSuperTenant(), [])

  return isSuperTenantOrg
}

export default useIsSuperTenantOrg
