import { Tooltip, Typography } from '@mui/material'

interface ISummarizedTextProps {
  text: string
  charLimit?: number
  fontSize?: string | number
}

const SummarizedText = ({
  text,
  fontSize = 'inherit',
  charLimit = 25,
}: ISummarizedTextProps) => {
  return (
    <Tooltip title={text}>
      <Typography sx={{ cursor: 'pointer', fontSize }}>
        {text.length > charLimit ? `${text.substring(0, charLimit)}...` : text}
      </Typography>
    </Tooltip>
  )
}

export default SummarizedText
