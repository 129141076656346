import {
  CodeOutlined,
  HelpOutline,
  InterestsOutlined,
  ListRounded,
  MenuBookOutlined,
  MonitorOutlined,
  QuestionAnswerOutlined,
  StarOutlineOutlined,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import { SideBar } from 'components'
import {
  API_DOCS_LINK,
  RESOURCES_DOCS_LINK,
  TRAINING_CENTER_LINK,
  WHATS_NEW_LINK,
} from '../../../constants/help.docs'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { openDoc } from 'utils/helpers'
import { PROJECT_VERSION } from 'constants/config'

interface IHelpItems {
  isOpen: boolean
  onClose: () => void
  setFeedbackFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const HelpItems = ({ isOpen, onClose, setFeedbackFormOpen }: IHelpItems) => {
  const navigate = useNavigate()

  const handleGettingStartedClick = () => {
    // Initiate interactive tour
    navigate('/pricing-set-up?tour=1')
    onClose()
  }

  const helpItems = useMemo(
    () => [
      {
        text: 'Getting Started',
        onClick: handleGettingStartedClick,
        icon: <ListRounded />,
      },
      {
        text: 'Resources',
        onClick: () => openDoc(RESOURCES_DOCS_LINK),
        icon: <InterestsOutlined />,
      },
      {
        text: 'Training Center',
        onClick: () => openDoc(TRAINING_CENTER_LINK),
        icon: <MonitorOutlined />,
      },
      {
        text: 'API Documentation',
        render: (
          <Accordion
            sx={{
              backgroundImage: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <CodeOutlined />
              </ListItemIcon>
              <Typography>API Documentation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <Link
                    href={`${API_DOCS_LINK}/docs`}
                    sx={{
                      textDecoration: 'none',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemText primary="Swagger UI" />
                  </Link>
                </ListItem>

                <ListItem disablePadding>
                  <Link
                    href={`${API_DOCS_LINK}/redoc`}
                    sx={{
                      textDecoration: 'none',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemText primary="ReDoc" />
                  </Link>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        ),
      },
      {
        text: 'FAQ',
        icon: <HelpOutline />,
        onClick: () => {},
      },
      {
        text: 'Send Feedback',
        icon: <QuestionAnswerOutlined />,
        onClick: () => setFeedbackFormOpen(true),
      },
      {
        text: `What's new?`,
        icon: <StarOutlineOutlined />,
        onClick: () => openDoc(WHATS_NEW_LINK),
      },
    ],
    [],
  )

  return (
    <SideBar open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '1.5rem',
          padding: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h3"
            sx={{ mt: '10px', textAlign: 'center !important' }}
          >
            Help
          </Typography>
        </Box>

        <TextField
          name="search"
          label="Search"
          placeholder="Type here"
          fullWidth
        />

        <Box sx={{ display: 'flex' }}>
          <Typography variant="h4">How may we help?</Typography>
        </Box>

        <List
          sx={{
            width: '100%',
          }}
        >
          {helpItems.map((item) =>
            item.render ? (
              item.render
            ) : (
              <ListItem disablePadding key={item.text}>
                <ListItemButton onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ),
          )}
        </List>

        <Typography
          sx={{
            fontStyle: 'italic',
          }}
        >
          version {PROJECT_VERSION}
        </Typography>
      </Box>
    </SideBar>
  )
}

export default HelpItems
