import { Card, useMediaQuery } from '@mui/material'
import { PropsWithChildren } from 'react'
import { ToolbarControl } from 'types'

interface IToolbarProps {
  controls: ToolbarControl[]
}

const Toolbar = ({ children, controls }: PropsWithChildren<IToolbarProps>) => {
  const query = useMediaQuery(`(max-width:900px)`)
  const amountControls = controls.filter(
    (control) =>
      control.display && (control.isMenu === undefined || !control.isMenu),
  ).length
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: query && amountControls > 1 ? 'column' : 'row',
        justifyContent: amountControls > 0 ? 'space-between' : 'flex-end',
        alignItems: 'center',
        borderRadius: '5px',
        gap: '1rem',
        padding: '1rem',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Card>
  )
}

export default Toolbar
