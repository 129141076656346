import { useState, Fragment, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { NavLink, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  Link,
  Typography,
  Skeleton,
} from '@mui/material'
import { SidebarWidth } from '../../../assets/global/ThemeVariable'
import MenuItems from './SideMenuItems'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import GettingStartedItems from './GettingStartedItems'
import TourItem from './TourItem'
import { settingsApi } from 'resources'
import { Image } from 'components'
import useOrgFeaturePages from 'hooks/useOrgFeaturePages'

const Sidebar = ({ isMobileSidebarOpen, onSidebarClose, isSidebarOpen }) => {
  const [open, setOpen] = useState(true)
  const [logo, setLogo] = useState('')
  const [currentTour, setCurrentTour] = useState(null)
  const navigate = useNavigate()

  const { pathname } = useLocation()
  const { getWhiteLabel } = settingsApi()

  const [searchParams, setSearchParams] = useSearchParams()
  const { orgFeaturePages, isLoadingFeaturePages } = useOrgFeaturePages()

  const pathDirect = pathname
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'))
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen)
    } else {
      setOpen(index)
    }
  }

  const handleTourOnClose = () => {
    setCurrentTour(null)

    navigate(pathname, { replace: true })
  }

  const isFeaturePageEnabled = (featureName) => {
    if (!orgFeaturePages) return

    // IMPORTANT: ################
    //
    // At the moment Brian only wants a certain amount of features to be toggable.
    // If the property processed in this function is not present in the payload
    // of useOrgFeaturePages, it means the sidebar item is not supported to be toggled on or off by the BE.
    //
    // Return "true" if the property is not supported by the BE:
    if (!orgFeaturePages.hasOwnProperty(featureName)) return true
    // #################

    return orgFeaturePages[featureName]
  }

  useEffect(() => {
    if (searchParams.get('tour') !== '1') return setCurrentTour(null)

    const tourFound = GettingStartedItems.find((item) => item.path === pathname)

    if (!tourFound) return setCurrentTour(null)

    setCurrentTour(tourFound)
    if (tourFound.child) setOpen(tourFound.parentIndex)
    else setOpen(tourFound.parentIndex || tourFound.sideBarIndex)
  }, [searchParams, pathname])

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const whiteLabelData = await getWhiteLabel()
        setLogo(whiteLabelData?.app_logo)
      } catch (error) {
        // tricky lad:
        // displayMessage(`${(error as Error).message}`, 'error')
      }
    }
    fetchLogo()
  }, [])

  const SidebarContent = (
    <Box sx={{ p: 2 }}>
      {logo ? (
        <picture
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            objectFit: 'fit',
          }}
        >
          <Link underline="none" to="/">
            <Image
              style={{ width: '150px', height: 'auto' }}
              src={logo}
              alt="logo"
              isLogo={true}
            />
          </Link>
          <Typography fontSize={10} color="text.secondary">
            Powered by Koloni
          </Typography>
        </picture>
      ) : (
        <Box
          sx={{
            marginBottom: '7.8em',
          }}
        >
          <Skeleton
            sx={{
              width: '233px',
              height: '40px',
              padding: '2.3em',
            }}
          />
        </Box>
      )}

      <Box sx={{ mt: 8 }}>
        {isLoadingFeaturePages ? (
          <Box
            sx={{
              marginTop: '4em',
            }}
          >
            {MenuItems.map((item, index) => {
              if (item.children) {
                return (
                  <Skeleton
                    key={index}
                    sx={{
                      width: '233px',
                      height: '40px',
                      padding: '2.3em',
                      marginTop: '-18px',
                      borderRadius: '10px',
                    }}
                  />
                )
              }
            })}
          </Box>
        ) : (
          <>
            <List>
              {MenuItems.map((item, index) => {
                if (item.children) {
                  return (
                    <Fragment key={item.title}>
                      {!item.restriction &&
                        isFeaturePageEnabled(item.featureName) && (
                          <>
                            <ListItem
                              button
                              component="li"
                              onClick={() => handleClick(index)}
                              selected={pathWithoutLastPart === item.href}
                              sx={{
                                mb: 1,
                                ...(pathWithoutLastPart === item.href && {
                                  color: 'white',
                                  backgroundColor: (theme) =>
                                    `${theme.palette.primary.main}!important`,
                                }),
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  ...(pathWithoutLastPart === item.href && {
                                    color: 'white',
                                  }),
                                }}
                              >
                                {item.icon}
                              </ListItemIcon>
                              <ListItemText>{item.title}</ListItemText>
                              <ListItemIcon
                                sx={{
                                  ...(pathWithoutLastPart === item.href && {
                                    color: 'white',
                                  }),
                                }}
                              >
                                {index === open ? (
                                  <ExpandLessIcon color="action" />
                                ) : (
                                  <ExpandMoreIcon color="action" />
                                )}
                              </ListItemIcon>
                            </ListItem>
                            <Collapse
                              in={index === open}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="li">
                                {item.children.map((child, childrenIndex) => (
                                  <>
                                    <Box key={child.title}>
                                      {!child.restriction && (
                                        <Link underline="none">
                                          <ListItem
                                            key={child.title}
                                            button
                                            component={NavLink}
                                            to={child.href}
                                            onClick={onSidebarClose}
                                            selected={pathDirect === child.href}
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              gap: '8px',
                                              width: 'auto',
                                              padding: '5px',
                                              marginLeft: '35px',
                                            }}
                                          >
                                            <ListItemIcon
                                              sx={{
                                                svg: {
                                                  fill: (theme) =>
                                                    `${theme.palette.primary.main}!important`,
                                                  ...(pathDirect ===
                                                    item.href && {
                                                    color: 'white',
                                                  }),
                                                },
                                                minWidth: '30px',
                                              }}
                                            >
                                              {child.icon}
                                            </ListItemIcon>
                                            <ListItemText marginLeft="10px">
                                              {child.title}
                                            </ListItemText>
                                          </ListItem>
                                        </Link>
                                      )}
                                    </Box>
                                  </>
                                ))}
                              </List>
                            </Collapse>
                          </>
                        )}
                    </Fragment>
                  )
                  // {/********If Sub No Menu**********/}
                } else {
                  return (
                    <List component="li" disablePadding key={item.title}>
                      {!item.restriction &&
                        isFeaturePageEnabled(item.featureName) && (
                          <ListItem
                            onClick={() => handleClick(index)}
                            button
                            component={NavLink}
                            to={item.href}
                            selected={pathDirect === item.href}
                            sx={{
                              mb: 1,
                              ...(pathDirect === item.href && {
                                color: 'white',
                                backgroundColor: (theme) =>
                                  `${theme.palette.primary.main}!important`,
                              }),
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                ...(pathDirect === item.href && {
                                  color: 'white',
                                }),
                              }}
                            >
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText onClick={onSidebarClose}>
                              {item.title}
                            </ListItemText>
                          </ListItem>
                        )}
                    </List>
                  )
                }
              })}
            </List>
          </>
        )}
      </Box>
    </Box>
  )

  if (lgUp) {
    return (
      <>
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          variant="persistent"
          PaperProps={{
            sx: {
              width: SidebarWidth,
              border: '0 !important',
              boxShadow: '0px 7px 30px 0px rgb(113 122 131 / 11%)',
            },
          }}
        >
          {SidebarContent}
        </Drawer>

        {currentTour && (
          <TourItem tour={currentTour} onClose={handleTourOnClose} />
        )}
      </>
    )
  }

  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
          border: '0 !important',
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  )
}

Sidebar.propTypes = {
  isMobileSidebarOpen: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
}

export default Sidebar
