import {
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Typography,
} from '@mui/material'
import { lockerColors } from '../constants'
import { ILocker } from 'models/LockerWall'
import { Dispatch, SetStateAction } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

interface ILockerProps {
  locker: ILocker
  onClick: () => void
  setSwapLocker: Dispatch<SetStateAction<ILocker | undefined>>
  setConfirmationMessage: Dispatch<
    SetStateAction<{
      isOpen: boolean
      message: string
    }>
  >
  setHoverLocker: React.Dispatch<
    React.SetStateAction<{
      row: number
      column: number
    } | null>
  >
}

const Locker = ({
  locker,
  onClick,
  setSwapLocker,
  setConfirmationMessage,
  setHoverLocker,
}: ILockerProps) => {
  const isKiosk = locker.kiosk
  const isUnassigned = !locker.id && !isKiosk

  const bgColor = isUnassigned
    ? lockerColors[lockerColors.length - 1]
    : lockerColors.find((color) => color.accesor === locker.device?.status)
        ?.color

  const getTooltip = () => {
    if (isKiosk) {
      return 'Drag and drop to move Kiosk position to another locker'
    } else if (isUnassigned) {
      return 'Click to assign locker'
    } else {
      return 'Click to see locker details'
    }
  }

  const onDrop = (event) => {
    event.preventDefault()
    setSwapLocker(locker)
  }

  return (
    <>
      <Tooltip title={getTooltip()}>
        <Card
          onMouseEnter={() =>
            setHoverLocker({ row: locker.y, column: locker.x })
          }
          onMouseLeave={() => setHoverLocker(null)}
          onDragOver={!isKiosk ? (event) => event.preventDefault() : undefined}
          onDrop={!isKiosk ? onDrop : undefined}
          onDragStart={!isKiosk ? (event) => event.preventDefault() : undefined}
          draggable={isKiosk}
          onClick={!isKiosk ? onClick : undefined}
          sx={{
            backgroundImage: 'none',
            borderRadius: '5px',
            border: isKiosk
              ? '4px solid #aeaeae'
              : isUnassigned
              ? '1px solid #aeaeae'
              : undefined,
            width: '6.25rem',
            height: '6.25rem',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: isKiosk ? '#F9F9F9' : bgColor,
            color: (theme) =>
              theme.palette.mode === 'dark' && !isKiosk ? 'white' : '#aeaeae',
            cursor: 'pointer',
            padding: '0',
            minWidth: '100px',
            minHeight: '100px',
            '&:hover': { transform: 'matrix(1, 0, 0, 1, 0, -5)' },
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            {isUnassigned && <Typography fontSize={20}>+</Typography>}
            {locker.id && !isKiosk && <>#{locker.device?.locker_number}</>}
            {isKiosk && <Typography textAlign="center">Kiosk</Typography>}
          </CardContent>
          {isKiosk && (
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <DeleteIcon
                onClick={() =>
                  setConfirmationMessage({
                    isOpen: true,
                    message: 'Are you sure that you want to remove the Kiosk?',
                  })
                }
                sx={{ color: '#aeaeae', margin: '0 1px 1px 0' }}
              />
            </CardActions>
          )}
        </Card>
      </Tooltip>
    </>
  )
}

export default Locker
