import { Box, Switch, TextField, Typography } from '@mui/material'
import { Image } from 'components'
import { IIntegration } from 'models/Integration'

interface ITwilioSidebar {
  integration: IIntegration
}

const TwilioSidebar = ({ integration }: ITwilioSidebar) => {
  return (
    <Box
      // mx="auto"
      sx={{
        marginTop: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '5rem',
      }}
    >
      <Image
        key={integration.name}
        src={integration.image}
        alt={integration.name}
        style={{
          width: 160,
          height: 160,
          outline: 'none',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Enable Verification and Identity</Typography>
          <Switch />
        </Box>
        <Box
          sx={{
            width: '80%',
          }}
        >
          <Typography color="text.secondary">
            Use cases for OTP verifications and mobile intelligence signals.
            Protect your platform from fraud and keep customer accounts secure
            with mobile identity signals and multi factor user authentication
            across sign-up, login and account management.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Enable Alerts and Notifications</Typography>
          <Switch />
        </Box>
        <Box
          sx={{
            width: '80%',
          }}
        >
          <Typography color="text.secondary">
            Inform, engage, and drive customers to take action with personalized
            email. phone, and text alerts that are reliably delivered through
            Twilio.
          </Typography>
        </Box>
      </Box>
      <TextField
        fullWidth
        name="api_key"
        label="Enter API key"
        placeholder="Enter API key"
      />
    </Box>
  )
}

export default TwilioSidebar
