import { useApiRequest } from 'utils'
import { ILockerWall, ILockerWallList } from 'models/LockerWall'
import { getErrorMessage } from 'utils/helpers'

const lockerWallApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getLockerWallsByLocation = async (
    locationId: string,
  ): Promise<ILockerWall[]> => {
    const urlParams = new URLSearchParams({
      id_location: locationId,
    })
    const endpoint = `locker-walls?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      const lockerWalls: ILockerWallList = await response.json()
      const { items } = lockerWalls
      return items.filter((lockerWall) => lockerWall.id_location === locationId)
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (
    lockerWall: Partial<ILockerWall>,
    image: File | null,
  ): Promise<ILockerWall> => {
    const endpoint = `locker-walls`
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }

    try {
      if (image) {
        const imageEndopoint = `images`
        const imagePayload = new FormData()
        imagePayload.append('image', image)

        const imageResponse = await fetchPartnerEndpoint({
          endpoint: imageEndopoint,
          method,
          body: imagePayload,
        })
        if (!imageResponse.ok) {
          const result = await imageResponse.json()
          throw result.detail
        }
        const result = await imageResponse.json()
        lockerWall.image = result.detail
      }

      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(lockerWall),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (
    lockerWallId: string,
    lockerWall: Partial<ILockerWall>,
    image: File | string,
  ): Promise<ILockerWall> => {
    const endpoint = `locker-walls/${lockerWallId}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }

    try {
      if (image && typeof image !== 'string') {
        const imageEndopoint = `images`
        const imagePayload = new FormData()
        imagePayload.append('image', image)

        const imageResponse = await fetchPartnerEndpoint({
          endpoint: imageEndopoint,
          method: 'POST',
          body: imagePayload,
        })
        if (!imageResponse.ok) {
          const result = await imageResponse.json()
          throw result.detail
        }
        const result = await imageResponse.json()
        lockerWall.image = result.detail
      } else {
        lockerWall.image = image
      }

      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(lockerWall),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (lockerWallId: string): Promise<ILockerWall> => {
    const endpoint = `locker-walls/${lockerWallId}`
    const method = 'DELETE'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getLockerWallsByLocation, create, update, remove }
}
export default lockerWallApi
