import { Typography } from '@mui/material'
import { Dialog, Form, DateField, IDialogType } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { ITransaction } from 'models'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { transactionsApi } from 'resources'
import {
  getDifferenceInDays,
  getDifferenceInHours,
  getDifferenceInMinutes,
  getDifferenceInWeeks,
  parseCurrency,
} from 'utils/helpers'

interface IEndDialogProps {
  open: boolean
  onClose: () => void
  transaction: ITransaction
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
}

const EndDialog = ({
  open,
  onClose,
  transaction,
  displayMessage,
  success,
}: IEndDialogProps) => {
  const { loading, setLoading } = useLoadingState()
  const { endBy } = transactionsApi()

  const methods = useForm({
    defaultValues: {
      cancel_at: new Date().toISOString(),
    },
  })

  const onEnd = async (cancel_at: Date | undefined): Promise<void> => {
    try {
      setLoading(true)
      await endBy(transaction.id, cancel_at)
      success()
      displayMessage('Transaction ended successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<{ cancel_at: Date | undefined }> = (newEnd) => {
    try {
      onEnd(newEnd.cancel_at)
      onClose()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const calculateDuration = () => {
    if (transaction.device.price) {
      const { unit } = transaction.device.price
      const selectedDate = methods.watch('cancel_at')
      switch (unit) {
        case 'minute':
          return getDifferenceInMinutes(
            new Date(transaction.started_at),
            new Date(selectedDate),
          )
        case 'hour':
          return getDifferenceInHours(
            new Date(transaction.started_at),
            new Date(selectedDate),
          )
        case 'day':
          return getDifferenceInDays(
            new Date(transaction.started_at),
            new Date(selectedDate),
          )
        case 'week':
          return getDifferenceInWeeks(
            new Date(transaction.started_at),
            new Date(selectedDate),
          )
        default:
          return 1
      }
    }
    return 1
  }

  const calculateChargeAmount = () => {
    if (transaction.device.price) {
      const { amount, unit_amount } = transaction.device.price
      return (calculateDuration() * (amount / unit_amount)).toFixed(2)
    }
    return 0
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="End Transaction" isDialog={true}>
            <Typography textAlign="center" color="text.secondary" variant="h5">
              Select the end date for the transaction
            </Typography>
            {['rental', 'storage', 'delivery', 'service'].includes(
              transaction.event_type,
            ) && (
              <DateField
                name="cancel_at"
                label="End Date"
                disablePast={false}
                maxDate={new Date().toISOString()}
                minDate={transaction.started_at}
              />
            )}

            {transaction.device.price &&
              ['storage', 'rental'].includes(transaction.device.mode) && (
                <Typography
                  textAlign="center"
                  color="text.secondary"
                  variant="h5"
                >
                  Charge{' '}
                  {transaction.device.price.price_type === 'pay_per_time'
                    ? calculateChargeAmount()
                    : transaction.total}
                  {parseCurrency(transaction.device.price.currency)}
                </Typography>
              )}

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={!methods.watch('cancel_at')}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
export default EndDialog
