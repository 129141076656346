import { useEffect, useState } from 'react'
import { Box, Button, Grid, TextField, Typography, Link } from '@mui/material'
import { apikeysApi } from 'resources/apikeys'

const APIKeysForm = () => {
  const { get: getApiKeys, createApiKey } = apikeysApi()
  const [apiKey, setApiKey] = useState<string | null>(null)
  const [copied, setCopied] = useState<boolean>(false)

  getApiKeys().then((response: any) => {
    if (!response) return setApiKey('')

    setApiKey(response[0]?.key)
  })

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(apiKey || '')
    setCopied(true)
  }

  const handleGenerateAPIKey = () => {
    createApiKey().then((response: any) => {
      setApiKey(response.key)
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6">Koloni API Key</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1em',
            marginBottom: '1em',
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginRight: '1em',
            }}
            onClick={handleGenerateAPIKey}
            disabled={Boolean(apiKey)}
          >
            Generate API Key
          </Button>
          <TextField
            sx={{
              width: '450px',
            }}
            value={apiKey || 'No API Key found. Please generate one.'}
            disabled
          />
          <Button
            sx={{
              marginLeft: '1em',
            }}
            onClick={handleCopyToClipboard}
            disabled={!apiKey}
          >
            {copied ? 'Copied!' : 'Copy'}
          </Button>
        </Box>
      </Grid>
      {/* <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link>See instructions here</Link>
        </Box>
      </Grid> */}
    </Grid>
  )
}

export default APIKeysForm
