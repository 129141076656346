export const ISSUES_TABLE_HEADERS = [
  {
    value: 'issueId',
    label: 'Issue ID',
    active: true,
  },
  {
    value: 'userName',
    label: 'User Name',
    active: true,
  },
  {
    value: 'orderNumber',
    label: 'Order No.',
    active: true,
  },

  {
    value: 'status',
    label: 'Status',
    active: true,
  },
  {
    value: 'reportTime',
    label: 'Report Time',
    active: true,
  },
  {
    value: 'action',
    label: 'Action',
    active: true,
  },
]

export const ISSUE_STATUSES = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    value: 'resolved',
    label: 'Resolved',
  },
]
