import {
  Avatar,
  Box,
  Checkbox,
  Tooltip,
  TextField as MUITextField,
  Autocomplete,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import {
  DateField,
  Form,
  IDialogType,
  SelectField,
  Tabs,
  TextField,
} from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { DeviceMode, IDevice, ISize, IUser } from 'models'
import { IReservation } from 'models/Reservations'
import { DAYS } from 'pages/Events/constants'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { reservationApi } from 'resources/reservations'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import useLoadingState from 'hooks/useLoadingState'
import { DropdownOption } from 'types'
import useDeviceModes from 'hooks/useDeviceModes'
import LoadingFormData from 'components/PageBase/LoadingFormData'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import { capitalizeFirstLetter, getSettingsProperty } from 'utils/helpers'
import { getUserRole } from 'utils/helpers'
import { locationApi } from 'resources'
import useLocations from 'hooks/useLocations'

interface IReservationFormProps {
  reservation: IReservation | undefined
  onClose: () => void
  displayMessage: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
  users: IUser[]
  devices: IDevice[]
  sizes: ISize[]
  productsOptions: DropdownOption[]
  isLoadingDevices: boolean
}

const ReservationForm = ({
  reservation,
  onClose,
  displayMessage,
  success,
  users,
  devices,
  sizes,
  productsOptions,
  isLoadingDevices,
}: IReservationFormProps) => {
  const [daySelectionCriteria, setDaySelectionCriteria] = useState<
    'allDays' | 'custom'
  >(() => {
    let allDays = true
    if (reservation) {
      DAYS.forEach((day) => {
        if (!reservation[day.accesor]) {
          allDays = false
        }
      })
    }
    return allDays ? 'allDays' : 'custom'
  })
  const [durationCriteria, setDurationCriteria] = useState<
    'custom' | 'recurring'
  >(reservation?.recurring ? 'recurring' : 'custom')
  const [usersOptions, setUserOptions] = useState<DropdownOption[]>([])
  const [selectedUser, setSelectedUser] = useState<DropdownOption | null>(
    () => {
      if (reservation) {
        return {
          label: `${reservation.user.name || ''} (${
            reservation.user.email || reservation.user.phone_number
          })`,
          value: reservation.user?.id || '',
        }
      } else {
        return null
      }
    },
  )
  const [selectedLocation, setSelectedLocation] =
    useState<DropdownOption | null>(null)
  const [selectedDays, setSelectedDays] = useState(() => {
    const stateObj = {}

    if (reservation) {
      DAYS.forEach((day) => {
        stateObj[day.accesor] = reservation[day.accesor]
      })
    } else {
      DAYS.forEach((day) => {
        stateObj[day.accesor] = false
      })
    }

    return stateObj
  })
  const [currentTab, setCurrentTab] = useState<string>('form')
  const [sizesByLocation, setSizesByLocation] = useState<DropdownOption[]>([])

  const { create, update, checkUniqueTrackingNumber } = reservationApi()
  const { getSizesByLocation } = locationApi()
  const { loading, setLoading } = useLoadingState()
  const { loading: loadingSizes, setLoading: setLoadingSizes } =
    useLoadingState()
  const { loadingLocations, locations, locationsOptions } = useLocations()

  const { modes, isLoadingModes } = useDeviceModes()

  const methods = useForm<IReservation>({
    defaultValues: {
      ...reservation,
      id_product: reservation?.product?.id || '',
      id_size: reservation?.size?.id || '',
      id_device: reservation?.device?.id || '',
      phone_number: reservation?.user?.phone_number,
      email: reservation?.user?.email,
      user_name: reservation?.user?.name,
    },
  })

  const mode = methods.watch('mode')

  const lockerNumbers: DropdownOption[] = useMemo(() => {
    if (selectedLocation) {
      const options = devices
        .filter(
          (device) =>
            (device.location?.id === selectedLocation.value &&
              device.status === 'available' &&
              device.mode === mode &&
              methods.watch('id_size') &&
              device?.id_size === methods.watch('id_size')) ||
            device.id === reservation?.device?.id,
        )
        .map((device) => ({
          value: device?.id,
          label:
            device?.id === reservation?.device?.id
              ? `${String(device.locker_number)} - Current locker`
              : String(device.locker_number),
        }))

      return options.length === 0
        ? [
            {
              value: 'no_lockers',
              label: 'No lockers available.',
            },
          ]
        : options
    }
    return []
  }, [
    selectedLocation,
    mode,
    devices,
    isLoadingDevices,
    methods.watch('id_size'),
  ])

  const filteredOptions = useMemo(
    () => filterOptions(),
    [locationsOptions, mode],
  )

  const days = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {DAYS.map((day) => (
          <Box key={day.name}>
            <Tooltip title={day.name}>
              <Avatar
                onClick={
                  daySelectionCriteria === 'custom'
                    ? () => handleToggleCustomDay(day.accesor)
                    : undefined
                }
                sx={{
                  bgcolor: (theme) =>
                    selectedDays[day.accesor]
                      ? theme.palette.primary.main
                      : undefined,
                  padding: '1.5rem',
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'white' : 'inherit',
                  border: (theme) => `2px solid ${theme.palette.primary.main}`,
                  cursor:
                    daySelectionCriteria === 'custom' ? 'pointer' : undefined,
                }}
              >
                {day.abbreviation}
              </Avatar>
            </Tooltip>
          </Box>
        ))}
      </Box>
    )
  }, [selectedDays])

  const fetchSizesAtLocation = async () => {
    try {
      if (selectedLocation && mode) {
        setLoadingSizes(true)
        const sizesAtLocation: ISize[] = await getSizesByLocation(
          selectedLocation.value,
          mode,
          'available',
        )
        if (sizesAtLocation.length === 0) {
          setSizesByLocation([
            {
              value: 'no_sizes',
              label: 'No sizes available at this location.',
            },
          ])
        } else {
          const parsedSizes = sizesAtLocation.map((size) => ({
            value: size?.id,
            label: `${size.name} (${size.width}" x ${size.depth}" x ${
              size.height
            }") - ${size.available_devices} door${
              size.available_devices === 1 ? '' : 's'
            } available`,
          }))
          if (
            reservation &&
            !parsedSizes.some((s) => s.value === reservation.size?.id)
          ) {
            const currentSize = sizes.find((s) => s.id === reservation.size?.id)
            if (currentSize) {
              parsedSizes.push({
                value: currentSize?.id,
                label: `${currentSize.name} (${currentSize.width}" x ${currentSize.depth}" x ${currentSize.height}") - 0 doors available`,
              })
            }
            methods.setValue('id_size', reservation?.size?.id)
          }
          setSizesByLocation(parsedSizes)
        }
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoadingSizes(false)
    }
  }

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const handleToggleDayCriteria = (event) => {
    const { checked, name } = event.target
    if (checked && name !== daySelectionCriteria) {
      setDaySelectionCriteria((previousValue) =>
        previousValue === 'allDays' ? 'custom' : 'allDays',
      )
    }
  }

  const handleToggleCustomDay = (day: string) => {
    setSelectedDays((previousValue) => ({
      ...previousValue,
      [day]: !previousValue[day],
    }))
  }

  const handleToggleDurationCriteria = (event) => {
    const { checked, name } = event.target
    if (checked && name !== durationCriteria) {
      setDurationCriteria((previousValue) =>
        previousValue === 'recurring' ? 'custom' : 'recurring',
      )
    }
  }

  const handleUserSelection = (event: React.SyntheticEvent, value: any) => {
    setSelectedUser(value)
  }

  const handleSelectLocation = (event: React.SyntheticEvent, value: any) => {
    setSelectedLocation(value)
  }

  const buildDeliveryPayload = (newReservation: IReservation) => {
    const payload = {
      mode: newReservation.mode,
      tracking_number: newReservation.tracking_number,
      id_location: selectedLocation?.value,
      id_device: newReservation?.id_device,
      id_size: newReservation?.id_size,
      user_name: newReservation.user_name,
      phone_number: newReservation.phone_number
        ?.replace('+', '')
        .replace(' ', ''),
      email: newReservation.email,
      sender_details: newReservation.sender_details,
    }
    return payload
  }

  const buildPayload = (newReservation: IReservation) => {
    const days = {}
    Object.keys(selectedDays).forEach((day) => {
      days[day] = durationCriteria === 'recurring' ? selectedDays[day] : false
    })
    const from = `${newReservation.start_date.split('T')[1].split(':')[0]}:${
      newReservation.start_date.split('T')[1].split(':')[1]
    }`
    const to = `${newReservation.end_date.split('T')[1].split(':')[0]}:${
      newReservation.end_date.split('T')[1].split(':')[1]
    }`
    const payload = {
      ...days,
      start_date: newReservation.start_date,
      end_date: newReservation.end_date,
      recurring: mode === 'delivery' ? null : durationCriteria === 'recurring',
      from_time: from,
      to_time: to,
      id_location: selectedLocation?.value,
      id_device:
        newReservation?.id_device === '' ? null : newReservation?.id_device,
      id_user: selectedUser?.value,
      id_product: mode === 'rental' ? newReservation.id_product : undefined,
      id_size: newReservation?.id_size === '' ? null : newReservation?.id_size,
      mode,
    }
    return payload
  }

  const onSubmit = async (newReservation: IReservation) => {
    try {
      setLoading(true)
      const payload =
        mode === 'delivery'
          ? buildDeliveryPayload(newReservation)
          : buildPayload(newReservation)
      if (reservation) {
        await update(payload, newReservation?.id)
        onClose()
        displayMessage('Reservation updated successfully!', 'success')
        success()
      } else {
        await create(payload)
        onClose()
        displayMessage('Reservation created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const buildUsersOptions = () => {
    if (users.length > 0) {
      const autoCompleteOptions = users.map((user) => ({
        label: `${user.name || ''} (${user.email || user.phone_number})`,
        value: user?.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setUserOptions(sortedOptions)
    }
  }

  function filterOptions() {
    if (locations.length > 0 && locationsOptions.length > 0) {
      let locationsByMode
      if (mode) {
        locationsByMode = locations.filter((location) =>
          location.devices?.some((device) => device.mode === mode),
        )
      } else {
        locationsByMode = locations
      }
      const filteredOptions = locationsOptions.filter((option) =>
        locationsByMode.some((l) => l.id === option.value),
      )
      return filteredOptions
    }
    return []
  }

  useEffect(() => {
    buildUsersOptions()
  }, [users])

  useEffect(() => {
    if (daySelectionCriteria === 'allDays') {
      const clone = { ...selectedDays }
      Object.keys(clone).forEach((day) => {
        clone[day] = true
      })
      setSelectedDays(clone)
    } else {
      const clone = { ...selectedDays }
      DAYS.forEach((day) => {
        clone[day.accesor] =
          reservation && DAYS.some((item) => !reservation[item.accesor])
            ? reservation[day.accesor]
            : false
      })
      setSelectedDays(clone)
    }
  }, [daySelectionCriteria])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  useEffect(() => {
    methods.resetField('id_size')
    methods.resetField('id_device')
    if (selectedLocation) {
      fetchSizesAtLocation()
    }
  }, [selectedLocation, mode, devices, isLoadingDevices])

  useEffect(() => {
    if (selectedLocation && methods.watch('id_size') && !reservation) {
      methods.setValue('id_device', lockerNumbers[0]?.value)
    }
  }, [selectedLocation, methods.watch('id_size')])

  useEffect(() => {
    const trackingNumber = methods.watch('tracking_number')
    if (trackingNumber) {
      methods.setValue('tracking_number', trackingNumber.toUpperCase())
    }
  }, [methods.watch('tracking_number')])

  useEffect(() => {
    if (!reservation) {
      const defaultMode = getSettingsProperty('default_device_mode')
      methods.setValue('mode', defaultMode)
    } else {
      methods.setValue('mode', DeviceMode[reservation.device.mode])
      setSelectedLocation({
        label: reservation.location.name,
        value: reservation?.location?.id || '',
      })
    }
  }, [])

  const reservationDetails = useMemo(() => {
    if (reservation) {
      const reservationMode = capitalizeFirstLetter(reservation.device.mode)

      const locationName = reservation.location.name

      const size = reservation.size
      const sizeLabel = `${size?.name} (${size?.width}" x ${size?.depth}" x ${size?.height}")`

      const lockerNumber = reservation.device.locker_number

      return {
        reservationMode,
        locationName,
        sizeLabel,
        lockerNumber,
      }
    }
  }, [])

  const DeliverForm = (
    <>
      <TextField
        name="tracking_number"
        label="Tracking Number*"
        id="reservation_form_delivery_input_tracking_number"
        placeholder="Enter tracking number here"
        rules={{
          required: 'Tracking number is required',
          validate: async (value) => {
            if (value) {
              const response = await checkUniqueTrackingNumber(value)
              return response
                ? true
                : 'Tracking Number is in use. Please enter a unique Tracking Number.'
            }
          },
        }}
      />
      {reservation && reservationDetails && (
        <>
          <MUITextField
            label="Location"
            defaultValue={reservationDetails.locationName}
            disabled={true}
          />
          <MUITextField
            label="Size"
            defaultValue={reservationDetails.sizeLabel}
            disabled={true}
          />
          <MUITextField
            label="Locker number"
            defaultValue={reservationDetails.lockerNumber}
            disabled={true}
          />
        </>
      )}
      {!reservation && (
        <>
          <Autocomplete
            value={selectedLocation}
            options={filteredOptions}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <MUITextField
                {...params}
                label="Location*"
                placeholder="Select a location"
              />
            )}
            onChange={handleSelectLocation}
            loading={loadingLocations}
            disabled={!!reservation}
            id="reservation_form_delivery_select_location"
          />
          {!selectedLocation && methods.formState.isSubmitted && (
            <Typography
              sx={{
                color: '#ff0000',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                margin: '0.1rem',
              }}
            >
              Location is required
            </Typography>
          )}
          <SelectField
            name="id_size"
            label="Size*"
            placeholder="Select a size"
            items={sizesByLocation}
            rules={{ required: 'Size is required' }}
            disabled={!selectedLocation || loadingSizes || !!reservation}
            id="reservation_form_delivery_select_size"
          />
          <SelectField
            name="id_device"
            label="Locker Number*"
            items={lockerNumbers || []}
            rules={{ required: 'Locker number is required' }}
            disabled={
              !methods.watch('id_size') ||
              !selectedLocation ||
              loadingSizes ||
              !!reservation
            }
            id="reservation_form_delivery_select_locker_number"
          />
        </>
      )}

      <TextField
        name="user_name"
        label="User name"
        placeholder="Enter user name here"
        id="reservation_form_delivery_input_user_name"
      />

      <>
        <TextField
          name="phone_number"
          label="Phone number*"
          placeholder="Enter phone number here"
          onlyInteger={true}
          helperText="Please add phone number with the country code and without the + symbol."
          id="reservation_form_delivery_input_phone_number"
        />
        {!methods.watch('phone_number') &&
          !methods.watch('email') &&
          methods.formState.isSubmitted && (
            <Typography
              sx={{
                color: '#ff0000',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                margin: '0.1rem',
              }}
            >
              Either phone number or email is required
            </Typography>
          )}
      </>

      <>
        <TextField
          name="email"
          label="Email*"
          placeholder="Enter email here"
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          }}
          id="reservation_form_delivery_input_email"
        />
        {!methods.watch('phone_number') &&
          !methods.watch('email') &&
          methods.formState.isSubmitted && (
            <Typography
              sx={{
                color: '#ff0000',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                margin: '0.1rem',
              }}
            >
              Either phone number or email is required
            </Typography>
          )}
      </>
      <TextField
        name="sender_details"
        label="Sender Details"
        placeholder="Enter sender details here"
        helperText="This sender details will be sent in the notification to the user"
        id="reservation_form_delivery_input_sender_details"
      />
    </>
  )

  const OtherModesForm = (
    <>
      <Autocomplete
        value={selectedUser}
        options={usersOptions}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <MUITextField {...params} label="Select a user..." />
        )}
        onChange={handleUserSelection}
        loading={usersOptions.length === 0}
      />
      <Typography variant="h5">Duration</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <FormControl sx={{ width: '50%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={durationCriteria === 'custom'}
                onChange={handleToggleDurationCriteria}
              />
            }
            label="Custom Range"
          />
        </FormControl>
        <FormControl sx={{ width: '50%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={durationCriteria === 'recurring'}
                onChange={handleToggleDurationCriteria}
              />
            }
            label="Recurring Periods"
          />
        </FormControl>
      </Box>
      <DateField
        name="start_date"
        label="Start Date"
        rules={{
          required: 'Start date is required',
        }}
      />
      <DateField
        name="end_date"
        label="End Date"
        rules={{
          required: 'End date is required',
        }}
      />
      {durationCriteria === 'recurring' && (
        <>
          <Typography variant="h5">
            Select the days for the reservation
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ width: '50%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="allDays"
                    checked={daySelectionCriteria === 'allDays'}
                    onChange={handleToggleDayCriteria}
                  />
                }
                label="All days of the week"
              />
            </FormControl>
            <FormControl sx={{ width: '50%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="custom"
                    checked={daySelectionCriteria === 'custom'}
                    onChange={handleToggleDayCriteria}
                  />
                }
                label="Custom"
              />
            </FormControl>
          </Box>
          {days}
        </>
      )}
      <Typography variant="h5">
        {' '}
        Select the time interval for the reservation{' '}
      </Typography>
      <Autocomplete
        value={selectedLocation}
        options={locationsOptions}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label="Location"
            placeholder="Select a location"
          />
        )}
        onChange={handleSelectLocation}
        loading={loadingLocations}
      />
      <SelectField
        name="id_size"
        label="Size"
        placeholder="Select a size"
        items={sizesByLocation}
        rules={{ required: 'Size is required' }}
      />
      {selectedLocation && (
        <SelectField
          name="id_device"
          label="Locker Number"
          items={lockerNumbers || []}
          rules={{ required: 'Locker Number is required' }}
        />
      )}
      {mode === 'rental' && (
        <SelectField
          name="id_product"
          label="Product"
          items={productsOptions}
          rules={{ required: 'Product is required' }}
        />
      )}
    </>
  )

  // Rental Mode Form starts
  const RentalModeForm = (
    <>
      <Autocomplete
        value={selectedUser}
        options={usersOptions}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <MUITextField {...params} label="Select a user..." />
        )}
        onChange={handleUserSelection}
        loading={usersOptions.length === 0}
      />
      <Typography variant="h5">Duration Rental</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <FormControl sx={{ width: '50%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={durationCriteria === 'custom'}
                onChange={handleToggleDurationCriteria}
              />
            }
            label="Custom Range"
          />
        </FormControl>
        <FormControl sx={{ width: '50%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={durationCriteria === 'recurring'}
                onChange={handleToggleDurationCriteria}
              />
            }
            label="Recurring Periods"
          />
        </FormControl>
      </Box>
      <DateField
        name="start_date"
        label="Start Date"
        rules={{
          required: 'Start date is required',
        }}
      />
      <DateField
        name="end_date"
        label="End Date"
        rules={{
          required: 'End date is required',
        }}
      />
      {durationCriteria === 'recurring' && (
        <>
          <Typography variant="h5">
            Select the days for the reservation
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ width: '50%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="allDays"
                    checked={daySelectionCriteria === 'allDays'}
                    onChange={handleToggleDayCriteria}
                  />
                }
                label="All days of the week"
              />
            </FormControl>
            <FormControl sx={{ width: '50%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="custom"
                    checked={daySelectionCriteria === 'custom'}
                    onChange={handleToggleDayCriteria}
                  />
                }
                label="Custom"
              />
            </FormControl>
          </Box>
          {days}
        </>
      )}
      <Typography variant="h5">
        {' '}
        Select the time interval for the reservation{' '}
      </Typography>
      <Autocomplete
        value={selectedLocation}
        options={locationsOptions}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label="Location"
            placeholder="Select a location"
          />
        )}
        onChange={handleSelectLocation}
        loading={loadingLocations}
      />
      <SelectField
        name="id_size_rental"
        label="Size"
        placeholder="Select a size"
        items={sizesByLocation}
      />
      {selectedLocation && (
        <SelectField
          name="id_device"
          label="Locker Number"
          items={lockerNumbers || []}
        />
      )}
      {mode === 'rental' && (
        <SelectField
          name="id_product"
          label="Product"
          items={productsOptions}
          rules={{ required: 'Product is required' }}
        />
      )}
    </>
  )
  // Rental Mode Form ends

  const FormRender = (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper
            helpText="Reservations will allow you to reserve a locker in advance for the users. You can set your own parameters from the reservation settings"
            title={reservation ? 'Edit Reservation' : 'Add Reservation'}
          >
            {!reservation && (
              <SelectField
                name="mode"
                label="Mode"
                id="reservation_form_delivery_select_mode"
                items={modes}
                rules={{ required: 'Mode is required' }}
              />
            )}
            {reservation && reservationDetails && (
              <MUITextField
                label="Mode"
                defaultValue={reservationDetails?.reservationMode}
                disabled={true}
              />
            )}

            {mode && mode === 'delivery' && DeliverForm}
            {mode && mode !== 'delivery' && mode === 'rental' && RentalModeForm}
            {mode && mode !== 'delivery' && mode !== 'rental' && OtherModesForm}

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={
                disableSubmit ||
                (lockerNumbers && lockerNumbers[0]?.value === 'no_lockers') ||
                methods.watch('id_size') === 'no_sizes'
              }
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )

  if (isLoadingModes && !reservation) {
    return <LoadingFormData />
  }

  return (
    <>
      {reservation && FormRender}
      {getUserRole() !== 'admin' && !reservation && FormRender}
      {getUserRole() === 'admin' && !reservation && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'form',
              children: FormRender,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="reservations"
                  successForm={() => success?.()}
                  onClose={onClose}
                  displayMessage={displayMessage}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}

export default ReservationForm
