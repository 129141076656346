import { IIssue, IIssueList } from 'models'
import { useApiRequest } from 'utils'
import { HTTP_CODES } from 'constants/HTTP'
import { getErrorMessage } from 'utils/helpers'

const IssueApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const create = async (issue: FormData, eventId: string): Promise<IIssue> => {
    const endpoint = `issues?id_event=${eventId}`
    const method = 'POST'
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: issue,
      })

      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (issueId: string, issue: FormData): Promise<IIssue> => {
    const endpoint = `issues/${issueId}`
    const method = 'PUT'
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: issue,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getMany = async (
    page: number,
    size: number,
    search: string,
  ): Promise<IIssueList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `issues?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const deleteMany = async (ids: string[]): Promise<IIssue> => {
    const endpoint = `issues`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patch = async (id: string, status): Promise<IIssue> => {
    const urlParams = new URLSearchParams({
      status,
    })
    const endpoint = `issues/${id}?${urlParams.toString()}`
    const method = 'PATCH'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    deleteMany,
    patch,
    create,
    update,
  }
}

export default IssueApi
