import { CircularProgress, Typography } from '@mui/material'

interface ISpinnerProps {
  size?: number
  loadingText?: string | null
}

const Spinner = ({ size = 80, loadingText = null }: ISpinnerProps) => {
  return (
    <>
      <CircularProgress size={size} color="primary" />
      {loadingText && (
        <Typography fontSize={12} color="text.secondary">
          {loadingText}...
        </Typography>
      )}
    </>
  )
}

export default Spinner
