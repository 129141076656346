/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { ILocation } from './Location'
import { PriceCurrencies } from './Price'
import { IUser } from './User'

export enum BillingPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export interface ISubscription {
  id: string
  created_at: Date
  expiration_date: Date
  name: string
  description: string
  active: boolean
  currency: PriceCurrencies
  amount: number
  billing_type: BillingType
  billing_period: BillingPeriod
  number_of_payments: number
  membership_type: SubscriptionType
  value: number
  stripe_product_id: string
  stripe_price_id: string
  card_on_file: boolean
  locations: ILocation[]
  users: IUser[]
}

export interface ISubscriptionList {
  items: ISubscription[]
  total: number
  pages: number
}

export type BillingType = 'one_time' | 'recurring'

export type SubscriptionType = 'unlimited' | 'limited' | 'fixed' | 'percentage'

export const SUBSCRIPTION_TYPES = [
  {
    value: 'unlimited',
    label: 'Unlimited',
  },
  {
    value: 'limited',
    label: 'Limited',
  },
  {
    value: 'fixed',
    label: 'Fixed',
  },
  {
    value: 'percentage',
    label: 'Percentage',
  },
]
