import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { TextField, IResetPasswordFieldsProps } from 'components'
import { RESET_PASSWORD_FORM_FIELDS } from '../../../../../constants'

const ResetPasswordFields = ({
  isCodeSent,
  isFirstLogin,
}: IResetPasswordFieldsProps): ReactElement => {
  const { watch } = useFormContext()

  const PASSWORD: string = watch('password')

  return (
    <>
      {(isCodeSent || isFirstLogin) && (
        <>
          {isCodeSent && (
            <TextField
              name={RESET_PASSWORD_FORM_FIELDS.CODE.NAME}
              label={RESET_PASSWORD_FORM_FIELDS.CODE.LABEL}
              placeholder={RESET_PASSWORD_FORM_FIELDS.CODE.PLACEHOLDER}
              rules={RESET_PASSWORD_FORM_FIELDS.CODE.RULES}
            />
          )}
          <TextField
            type="password"
            name={RESET_PASSWORD_FORM_FIELDS.PASSWORD.NAME}
            label={RESET_PASSWORD_FORM_FIELDS.PASSWORD.LABEL}
            placeholder={RESET_PASSWORD_FORM_FIELDS.PASSWORD.PLACEHOLDER}
            rules={RESET_PASSWORD_FORM_FIELDS.PASSWORD.RULES}
          />
          <TextField
            type="password"
            name={RESET_PASSWORD_FORM_FIELDS.CONFIRM_PASSWORD.NAME}
            label={RESET_PASSWORD_FORM_FIELDS.CONFIRM_PASSWORD.LABEL}
            placeholder={
              RESET_PASSWORD_FORM_FIELDS.CONFIRM_PASSWORD.PLACEHOLDER
            }
            rules={{
              ...RESET_PASSWORD_FORM_FIELDS.CONFIRM_PASSWORD.RULES,
              validate: (value) =>
                value === PASSWORD || 'The passwords do not match',
            }}
          />
        </>
      )}
    </>
  )
}
export default ResetPasswordFields
