import { useState } from 'react'
import { experimentalStyled, useMediaQuery, Box, Theme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useSelector, RootStateOrAny } from 'react-redux'
import Sidebar from './sidebar/Sidebar'
import Header from './header/Header'
import Footer from './footer/Footer'
import { TopbarHeight } from '../../assets/global/ThemeVariable'

const MainWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  overflow: 'hidden',
  width: '100%',
}))
const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  width: '100%',

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    paddingTop: TopbarHeight,
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '64px',
  },
}))

const FullLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true)
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState<boolean>(false)
  const customizer = useSelector(
    (state: RootStateOrAny) => state.CustomizerReducer,
  )
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  return (
    <MainWrapper className={customizer.activeMode === 'dark' ? 'darkbg' : ''}>
      <Header
        sx={{
          paddingLeft: isSidebarOpen && lgUp ? '265px' : '',
          backgroundColor:
            customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
        }}
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
        toggleMobileSidebar={() => setMobileSidebarOpen(true)}
      />

      <Sidebar
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
        onSidebarClose={() => setMobileSidebarOpen(false)}
      />

      <PageWrapper>
        <Box
          sx={{
            paddingTop: '20px',
            paddingLeft: isSidebarOpen && lgUp ? '265px!important' : '',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              height: 'auto',
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </PageWrapper>
    </MainWrapper>
  )
}

export default FullLayout
