import { Box, Divider, Typography } from '@mui/material'
import { ITransaction } from 'models'
import { parseCurrency } from 'utils/helpers'

interface IUserHistoryItem {
  transaction: ITransaction
}

interface IHistoryLine {
  label: string
  value: string
}

const HistoryLine = ({ label, value }: IHistoryLine) => (
  <Box sx={{ display: 'flex', gap: '0.5rem' }}>
    <Typography fontSize={12}>{label}</Typography>
    <Typography fontSize={12} color="text.secondary">
      {value}
    </Typography>
  </Box>
)

const UserHistoryItem = ({ transaction }: IUserHistoryItem) => {
  const { started_at, ended_at, device, total_time, total } = transaction
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.25rem',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        marginTop: '1.5rem',
      }}
    >
      <HistoryLine
        label="Transaction Start Time:"
        value={new Date(started_at).toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      />
      <HistoryLine
        label="Transaction End Time:"
        value={new Date(ended_at).toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      />
      <HistoryLine label="Location:" value={device?.location?.name} />
      <HistoryLine
        label="Locker Number:"
        value={String(device?.locker_number)}
      />
      <HistoryLine label="Duration:" value={total_time} />
      <HistoryLine
        label="Amount:"
        value={
          total
            ? `${parseCurrency(transaction.device?.price?.currency)}${
                total === 0 ? '0' : total
              }`
            : 'N/A'
        }
      />
      <Divider sx={{ marginTop: '1rem' }} />
    </Box>
  )
}

export default UserHistoryItem
