import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ILocker, ILockerWall } from 'models/LockerWall'
import { useEffect, useState } from 'react'
import Locker from './Locker'
import AssignLockerDialog from './AssignLockerDialog'
import { lockerWallApi } from 'resources/locker-wall'
import LockerDetails from './LockerDetails'
import { deviceApi } from 'resources'
import { ConfirmDialog, IDialogType, QRGenerator } from 'components'
import { API_URL } from '../../../constants'
import { IDevice } from 'models'
import { DropdownOption } from 'types'

interface IWallProps {
  lockerWall: ILockerWall | undefined
  wallMatrix: ILocker[][]
  lockerNumbers: DropdownOption[]
  displayMessage: (message: string, type?: IDialogType) => void
  setLockerWall: React.Dispatch<React.SetStateAction<ILockerWall | undefined>>
  success: () => void
  locationWalls: ILockerWall[]
  devicesForLocation: IDevice[]
}

const Wall = ({
  lockerWall,
  wallMatrix,
  lockerNumbers,
  displayMessage,
  setLockerWall,
  success,
  locationWalls,
  devicesForLocation,
}: IWallProps) => {
  const [openAssignLockerDialog, setOpenAssignLockerDialog] =
    useState<boolean>(false)
  const [openLockerDetailsDialog, setOpenLockerDetailsDialog] =
    useState<boolean>(false)
  const [selectedLocker, setSelectedLocker] = useState<ILocker | undefined>()
  const [successOperation, setSuccessOperation] = useState<
    boolean | undefined
  >()
  const [operationMessage, setOperationMessage] = useState<string>('')
  const [reassigningLocker, setReassigningLocker] = useState<boolean>(false)
  const [swapLocker, setSwapLocker] = useState<ILocker | undefined>()
  const [movingKiosk, setMovingKiosk] = useState<boolean>(false)
  const [hoverLocker, setHoverLocker] = useState<{
    row: number
    column: number
  } | null>(null)
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const isMobile: boolean = useMediaQuery('(max-width:990px)')

  const { update } = lockerWallApi()
  const { setMaintenanceMode } = deviceApi()
  const { generateQRCode } = QRGenerator()

  const handleLockerClick = (locker: ILocker): void => {
    if (locker.id) {
      setOpenLockerDetailsDialog(true)
      setSelectedLocker(locker)
    } else if (locker.kiosk) {
    } else {
      setOpenAssignLockerDialog(true)
      setSelectedLocker(locker)
    }
  }

  const handleCloseAssignDialog = (): void => {
    setOpenAssignLockerDialog(false)
    setSelectedLocker(undefined)
    if (reassigningLocker) setReassigningLocker(false)
  }

  const handleCloseLockerDetailsDialog = (): void => {
    setOpenLockerDetailsDialog(false)
    setSelectedLocker(undefined)
    if (successOperation) setSuccessOperation(undefined)
    if (operationMessage) setOperationMessage('')
  }

  const handleAssignLocker = async (deviceId: string): Promise<void> => {
    try {
      if (selectedLocker && lockerWall?.id) {
        const device = devicesForLocation.find(
          (device) => device.id === deviceId,
        )
        if (device) {
          const newLocker = {
            ...selectedLocker,
            id: deviceId,
            device,
          }
          const { lockers } = lockerWall
          const newLockers = [...lockers]
          const index = newLockers.findIndex(
            (locker) => locker.y === newLocker.y && locker.x === newLocker.x,
          )
          newLockers[index] = newLocker
          const devices = [...lockerWall.devices, device]
          const newLockerWall = { ...lockerWall, lockers: newLockers }
          const result = await update(
            lockerWall.id,
            newLockerWall,
            lockerWall.image,
          )
          success()
          setLockerWall({
            ...result,
            devices,
          })
          displayMessage(
            `Locker has been ${
              reassigningLocker ? 're' : ''
            }assigned correctly.`,
            'success',
          )
          handleCloseAssignDialog()
        }
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleReassignLocker = (): void => {
    setReassigningLocker(true)
    setOpenAssignLockerDialog(true)
    setOpenLockerDetailsDialog(false)
  }

  const handleUnassignLocker = async (): Promise<void> => {
    try {
      if (selectedLocker && lockerWall?.id) {
        const newLocker = {
          ...selectedLocker,
          id: undefined,
          device: undefined,
        }
        const { lockers } = lockerWall
        const newLockers = [...lockers]
        const index = newLockers.findIndex(
          (locker) => locker.y === newLocker.y && locker.x === newLocker.x,
        )
        newLockers[index] = newLocker
        const newLockerWall = { ...lockerWall, lockers: newLockers }
        const devices = lockerWall.devices.filter(
          (device) => device.id !== selectedLocker.id,
        )
        const result = await update(
          lockerWall.id,
          newLockerWall,
          lockerWall.image,
        )
        success()
        setLockerWall({
          ...result,
          devices,
        })
        displayMessage('Locker has been unassigned correctly.', 'success')
        handleCloseLockerDetailsDialog()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleQRCode = (): void => {
    generateQRCode(
      selectedLocker?.device?.id,
      `${API_URL?.replace('partner', 'mobile')}device/${
        selectedLocker?.device?.id
      }`,
    )
  }

  const handleMaintenanceMode = async (): Promise<void> => {
    try {
      if (
        selectedLocker?.device &&
        selectedLocker.device.status !== 'reserved'
      ) {
        const disabled =
          selectedLocker.device.status === 'available' ? true : false
        await setMaintenanceMode([selectedLocker.device.id], disabled)
        success()
        setLockerWall(locationWalls.find((wall) => wall.id === lockerWall?.id))
      } else {
        setSuccessOperation(false)
        setOperationMessage(
          'You cannot put a reserved device in maintenance mode.',
        )
      }
    } catch (error) {
      setSuccessOperation(false)
      setOperationMessage(`${(error as Error).message}`)
    }
  }

  const closeAlert = (): void => {
    setSuccessOperation(undefined)
    setOperationMessage('')
  }

  const moveKiosk = async (): Promise<void> => {
    try {
      if (swapLocker && lockerWall) {
        setMovingKiosk(true)
        const wallClone = { ...lockerWall }
        const kioskLocker = wallClone.lockers.find((locker) => locker.kiosk)
        const newKiosk = wallClone.lockers.find(
          (locker) => locker.x === swapLocker.x && locker.y === swapLocker.y,
        )
        if (newKiosk) {
          newKiosk.kiosk = true
        }
        if (kioskLocker) {
          kioskLocker.kiosk = false
        }
        const result = await update(wallClone.id, wallClone, wallClone.image)
        success()
        setLockerWall({
          ...result,
          devices: wallClone.devices,
        })
        displayMessage('Kiosk successfully moved.', 'success')
        setSwapLocker(undefined)
        setMovingKiosk(false)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const removeKiosk = async (): Promise<void> => {
    try {
      if (lockerWall) {
        setConfirmationMessage({ isOpen: false, message: '' })
        const wallClone = { ...lockerWall }
        wallClone.is_kiosk = false
        const kioskLocker = wallClone.lockers.find((locker) => locker.kiosk)
        if (kioskLocker) {
          kioskLocker.kiosk = false
        }
        const result = await update(
          wallClone.id || '',
          wallClone,
          wallClone.image,
        )
        success()
        setLockerWall({
          ...result,
          devices: wallClone.devices,
        })
        displayMessage('Kiosk has been deleted from wall.', 'success')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  useEffect(() => {
    if (selectedLocker) {
      const deviceUpdated = lockerWall?.devices.find(
        (device) => device.id === selectedLocker.id,
      )
      setSelectedLocker({ ...selectedLocker, device: deviceUpdated })
      setSuccessOperation(true)
      setOperationMessage(
        selectedLocker?.device?.status === 'maintenance'
          ? 'Device is now available.'
          : 'Device has been put into maintenance.',
      )
    }
  }, [lockerWall])

  useEffect(() => {
    if (swapLocker && swapLocker.device) {
      displayMessage('This locker is already assigned.', 'success')
      setSwapLocker(undefined)
    } else if (swapLocker && !swapLocker.kiosk) {
      moveKiosk()
    }
  }, [swapLocker])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent:
            !isMobile && lockerWall && lockerWall.qty_wide <= 9
              ? 'center'
              : undefined,
          alignItems:
            !isMobile && lockerWall && lockerWall?.qty_wide <= 9
              ? 'center'
              : undefined,
          overflowX: 'auto',
          paddingRight: '2rem',
        }}
      >
        {wallMatrix.map((row, rowIndex) => (
          <Box key={rowIndex} sx={{ display: 'flex' }}>
            {row.map((locker, columnIndex) => (
              <Box
                key={`${rowIndex}-${columnIndex}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {rowIndex === 0 && (
                  <Box
                    key={rowIndex}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width:
                        columnIndex === 0 ? 'calc(100% - -10px)' : undefined,
                    }}
                  >
                    <Typography
                      sx={{ heigth: '2rem' }}
                      fontSize="large"
                      color={
                        hoverLocker && hoverLocker.column === columnIndex
                          ? 'primary'
                          : 'text.secondary'
                      }
                      textAlign="center"
                    >
                      {columnIndex + 1}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {columnIndex === 0 && (
                    <Typography
                      sx={{ width: '1rem' }}
                      fontSize="large"
                      color={
                        hoverLocker && hoverLocker.row === rowIndex
                          ? 'primary'
                          : 'text.secondary'
                      }
                    >
                      {rowIndex + 1}
                    </Typography>
                  )}
                  <Locker
                    locker={locker}
                    onClick={() => handleLockerClick(locker)}
                    setSwapLocker={setSwapLocker}
                    setConfirmationMessage={setConfirmationMessage}
                    setHoverLocker={setHoverLocker}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <AssignLockerDialog
        isOpen={openAssignLockerDialog}
        onClose={handleCloseAssignDialog}
        lockerNumbers={lockerNumbers}
        handleAssignLocker={handleAssignLocker}
        reassigningLocker={reassigningLocker}
      />
      {selectedLocker && (
        <LockerDetails
          locker={selectedLocker}
          isOpen={openLockerDetailsDialog}
          onClose={handleCloseLockerDetailsDialog}
          handleMaintenanceMode={handleMaintenanceMode}
          handleQRCode={handleQRCode}
          handleReassignLocker={handleReassignLocker}
          handleUnassignLocker={handleUnassignLocker}
          successOperation={successOperation}
          operationMessage={operationMessage}
          closeAlert={closeAlert}
        />
      )}
      <Backdrop open={movingKiosk}>
        <CircularProgress size={70} />
      </Backdrop>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        onClickConfirm={removeKiosk}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default Wall
