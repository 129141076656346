export enum PAGES {
  '/home' = 'Dashboard',
  '/reporting' = 'Reporting',
  '/settings' = 'Settings',
  '/pricing' = 'Pricing',
  '/pay-per' = 'Pay-per',
  '/subscriptions' = 'Subscriptions',
  '/promo-codes' = 'Promo codes',
  '/locations' = 'Locations',
  '/locker-wall' = 'Locker Wall',
  '/devices' = 'Devices',
  '/sizes' = 'Sizes',
  '/transactions' = 'Transactions',
  '/reservations' = 'Reservations',
  '/users' = 'Users',
  '/team-members' = 'Team Members',
  '/groups' = 'Groups',
  '/issues' = 'Issues',
  '/notifications' = 'Notifications',
  '/inventory' = 'Inventory',
  '/product-groups' = 'Product Groups',
  '/conditions' = 'Conditions',
  '/subscribers' = 'Subscribers',
  '/multi-tenant' = 'Multi-Tenant',
  '/apis' = `API's`,
  '/apps-widgets' = 'Apps & Widgets',
  '/integrations' = 'Integrations',
}

export enum ORG_FILTERS_IDENTFIER {
  '/reporting' = 'reporting',
  '/pay-per' = 'pay-per',
  '/subscriptions' = 'subscriptions',
  '/promo-codes' = 'promo-codes',
  '/locations' = 'locations',
  '/devices' = 'devices',
  '/sizes' = 'sizes',
  '/transactions' = 'transactions',
  '/reservations' = 'reservations',
  '/users' = 'users',
  '/team-members' = 'members',
  '/groups' = 'groups',
  '/issues' = 'issues',
  '/notifications' = 'notifications',
  '/inventory' = 'inventory',
  '/product-groups' = 'product-groups',
  '/conditions' = 'conditions',
  '/subscribers' = 'subscribers',
}

export const ORG_FEATURE_PAGES = [
  'pricing',
  'product',
  'toolbox',
  'multi_tenant',
  'notifications',
]
