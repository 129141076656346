import { Grid, Theme, useMediaQuery } from '@mui/material'
import WelcomeCard from './WelcomeCard'
import MetricCard, { IMetricCard } from './MetricCard'
import TopUsers from './TopUsers'
import TopLocations from './TopLocations'
import { DropdownOption } from 'types'

interface IDashboardContent {
  visibleMetrics: IMetricCard[]
  buildMetricObject: (id: number, metric: string) => Promise<void>
  metricOptions: DropdownOption[]
  loading: boolean
  currentFetchingMetric: string
  setCurrentFetchingMetric: React.Dispatch<React.SetStateAction<string>>
  targetOrg: string | undefined
}

const DashboardContent = ({
  visibleMetrics,
  buildMetricObject,
  metricOptions,
  loading,
  currentFetchingMetric,
  setCurrentFetchingMetric,
  targetOrg,
}: IDashboardContent) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(${
            smDown ? '300px' : '380px'
          }, 1fr))`,
        }}
      >
        <WelcomeCard />
        {visibleMetrics.map((metric, index) => (
          <MetricCard
            key={index}
            metric={metric?.metric}
            id={metric.id}
            buildMetricObject={buildMetricObject}
            metricsOptions={metricOptions}
            loading={loading && currentFetchingMetric === metric.metric?.metric}
            setCurrentFetchingMetric={setCurrentFetchingMetric}
          />
        ))}
      </Grid>
      <Grid
        container
        spacing={0}
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(${
            smDown ? '300px' : '50%'
          }, 1fr))`,
        }}
      >
        <TopUsers targetOrg={targetOrg} />
        <TopLocations targetOrg={targetOrg} />
      </Grid>
    </>
  )
}

export default DashboardContent
