import { Box, Typography } from '@mui/material'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { Dialog, Form, AutocompleteField, IDialogType } from 'components'
import { IDevice, ITransaction } from 'models'
import { useMemo } from 'react'
import { transactionsApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { DropdownOption } from 'types'

interface IDropoffDialogProps {
  transaction: ITransaction
  open: boolean
  onClose: () => void
  devices: IDevice[]
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
}

const DropoffDialog = ({
  transaction,
  open,
  onClose,
  devices,
  displayMessage,
  success,
}: IDropoffDialogProps) => {
  const { loading, setLoading } = useLoadingState()
  const { service } = transactionsApi()

  const availableServiceDevices = useMemo(() => {
    const deviceList: DropdownOption[] = []
    devices
      .filter(
        (device) =>
          (device.status === 'available' &&
            device.mode === 'service' &&
            device.id_location === transaction.device.id_location) ||
          device.id === transaction.device.id,
      )
      .forEach((device) => {
        deviceList.push({
          value: device?.id,
          label: device?.name,
        })
      })
    return deviceList
  }, [devices])

  const onDropoff = async (deviceId: string): Promise<void> => {
    try {
      setLoading(true)
      await service({
        deviceId: deviceId,
        step: 'dropoff',
        transactionId: transaction.id,
      })
      success()
      displayMessage('Transaction dropoff successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<{ deviceId: string }> = (newDropoff): void => {
    try {
      onDropoff(newDropoff.deviceId)
      onClose()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const methods = useForm()

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Dropoff" isDialog={true}>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', marginBottom: '1rem' }}
            >
              Choose the locker for drop-off
            </Typography>

            <AutocompleteField
              name="deviceId"
              label="Device List"
              options={availableServiceDevices}
              rules={{ required: 'Device required' }}
            />

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={!methods.watch('deviceId')}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
export default DropoffDialog
