import { LoadingButton } from '@mui/lab'
import { Box, useTheme } from '@mui/material'
import { Button } from 'components/Buttons'
import { MouseEventHandler } from 'react'

interface IFormActionsProps {
  onClose: () => void
  submitText?: string
  cancelText?: string
  loading?: boolean
  loadingText?: string
  disableSubmit?: boolean
  onSubmit?: MouseEventHandler<HTMLButtonElement> | null
}

const FormActions = ({
  onClose,
  submitText = 'Submit',
  cancelText = 'Cancel',
  loading = false,
  loadingText = 'Loading...',
  disableSubmit = false,
  onSubmit = null,
}: IFormActionsProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        mt: '15px',
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {!onSubmit && (
        <LoadingButton
          sx={{ width: '50%' }}
          type="submit"
          loading={loading}
          loadingPosition="end"
          variant="contained"
          disabled={disableSubmit}
        >
          {loading ? loadingText : submitText}
        </LoadingButton>
      )}
      {onSubmit && (
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          sx={{ width: '50%' }}
          variant="contained"
          disabled={disableSubmit}
          onClick={onSubmit}
        >
          {loading ? loadingText : submitText}
        </LoadingButton>
      )}

      <Button
        disabled={loading}
        sx={{
          width: '50%',
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
        }}
        variant="contained"
        onClick={onClose}
        color={theme.palette.mode === 'dark' ? 'cancel' : 'inherit'}
      >
        {cancelText}
      </Button>
    </Box>
  )
}

export default FormActions
