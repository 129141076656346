import { useMemo, useState } from 'react'
import {
  Box,
  Typography,
  Dialog,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material'

const NotificationDefaultSettings = ({ open, onClose }): React.ReactElement => {
  const [defaultNotificationSettings, setDefaultNotificationSettings] =
    useState({
      primary: 'email',
      secondary: 'push',
    })

  const handleDefaultSettingsChange = (channel, e) => {
    // channel: primary or secondary
    const stateObj = {
      ...defaultNotificationSettings,
      [channel]: e.target.value,
    }

    setDefaultNotificationSettings(stateObj)

    localStorage.setItem('notificationSettings', JSON.stringify(stateObj))
  }

  useMemo(() => {
    const defaultSettings = localStorage.getItem('notificationSettings')

    if (defaultSettings) {
      setDefaultNotificationSettings(JSON.parse(defaultSettings))
    }
  }, [])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box
        sx={{
          padding: '1em',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          Default Settings
        </Typography>

        <Grid
          container
          sx={{
            textAlign: 'center',
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: '20px',
            }}
          >
            <FormControl>
              <Typography color="text.secondary">Primary</Typography>
              <RadioGroup
                aria-labelledby="primary-settings-group"
                defaultValue={defaultNotificationSettings.primary}
                onChange={(e) => handleDefaultSettingsChange('primary', e)}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={<Radio value="email" />}
                  label="Email"
                />
                <FormControlLabel control={<Radio value="sms" />} label="SMS" />
                <FormControlLabel
                  control={<Radio value="push" />}
                  label="Push"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              padding: '20px',
            }}
          >
            <Box>
              <FormControl>
                <Typography color="text.secondary">Secondary</Typography>
                <RadioGroup
                  aria-labelledby="secondary-settings-group"
                  defaultValue={defaultNotificationSettings.secondary}
                  onChange={(e) => handleDefaultSettingsChange('secondary', e)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    control={<Radio value="email" />}
                    label="Email"
                  />
                  <FormControlLabel
                    control={<Radio value="sms" />}
                    label="SMS"
                  />
                  <FormControlLabel
                    control={<Radio value="push" />}
                    label="Push"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default NotificationDefaultSettings
