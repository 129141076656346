import { Alert, Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Button, Dialog } from 'components'
import { ILocker } from 'models/LockerWall'
import { capitalizeFirstLetter } from 'utils/helpers'
import Detail from './Detail'
import { Build, QrCode, Edit, DeleteForever } from '@mui/icons-material'
import { useMemo } from 'react'

interface ILockerDetailsProps {
  locker: ILocker
  isOpen: boolean
  onClose: () => void
  handleQRCode: () => void
  handleMaintenanceMode: () => void
  handleReassignLocker: () => void
  handleUnassignLocker: () => void
  successOperation: boolean | undefined
  operationMessage: string
  closeAlert: () => void
}

const LockerDetails = ({
  locker,
  isOpen,
  onClose,
  handleQRCode,
  handleMaintenanceMode,
  handleReassignLocker,
  handleUnassignLocker,
  successOperation,
  operationMessage,
  closeAlert,
}: ILockerDetailsProps) => {
  const icons = useMemo(
    () => [
      {
        tooltip: 'Maintenance',
        render: <Build />,
        onClick: handleMaintenanceMode,
      },
      {
        tooltip: 'Reassign locker',
        render: <Edit />,
        onClick: handleReassignLocker,
      },
      {
        tooltip: 'Unassign locker',
        render: <DeleteForever />,
        onClick: handleUnassignLocker,
      },
      {
        tooltip: 'QR code',
        render: <QrCode />,
        onClick: handleQRCode,
      },
    ],
    [],
  )

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.25rem',
          padding: '0 0.25rem 1rem 0.25rem',
        }}
      >
        <Typography
          variant="h3"
          textAlign="center"
          sx={{
            fontWeight: 'bold',
          }}
        >
          Locker Details
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Detail label="Name" value={locker.device?.name} />
          <Detail
            label="Locker Number"
            value={locker.device?.locker_number.toString()}
          />
          <Detail
            label="Status"
            value={capitalizeFirstLetter(locker.device?.status || '')}
          />
          <Detail
            label="Mode"
            value={capitalizeFirstLetter(locker?.device?.mode || '')}
          />
          <Detail label="Size" value={locker.device?.size?.name} />
          <Detail label="Item" value={locker.device?.item} />
          <Detail label="Location Name" value={locker.device?.location.name} />
          <Detail
            label="Hardware Type"
            value={capitalizeFirstLetter(locker?.device?.hardware_type || '')}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {icons.map((icon) => (
            <IconButton
              key={icon.tooltip}
              onClick={icon.onClick}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <Tooltip title={icon.tooltip}>{icon.render}</Tooltip>
            </IconButton>
          ))}
        </Box>
        {operationMessage && (
          <Alert
            variant="filled"
            onClose={closeAlert}
            severity={successOperation ? 'success' : 'error'}
          >
            {operationMessage}
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button sx={{ width: '50%' }} variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default LockerDetails
