import {
  Fab,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Typography,
  Tooltip,
  Radio,
} from '@mui/material'
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux'
import { Check as CheckIcon } from '@mui/icons-material'
import { setTheme, setDarkMode } from '../../../../redux/customizer/Action'
import { parseSnakeCaseToTitleCase } from 'utils/helpers'

const ThemeCustomizer = () => {
  const customizer = useSelector(
    (state: RootStateOrAny) => state.CustomizerReducer,
  )
  const dispatch = useDispatch()
  const thColors = [
    {
      id: 0,
      bgColor: '#1a9bfc',
      disp: 'BLUE_THEME',
    },
    {
      id: 1,
      bgColor: '#00cec3',
      disp: 'GREEN_THEME',
    },
    {
      id: 2,
      bgColor: '#7352ff',
      disp: 'PURPLE_THEME',
    },
    {
      id: 3,
      bgColor: '#ff5c8e',
      disp: 'RED_THEME',
    },
    {
      id: 4,
      bgColor: '#1e4db7',
      disp: 'INDIGO_THEME',
    },
    {
      id: 5,
      bgColor: '#fb9678',
      disp: 'ORANGE_THEME',
    },
  ]

  const handleThemeModeChange = (color) => {
    dispatch(setDarkMode(color.target.value))
    localStorage.setItem('themeMode', color.target.value)
  }
  const handleThemeColorChange = (color) => {
    dispatch(setTheme(color))
    localStorage.setItem('themeColor', color)
  }

  return (
    <Box>
      <Box p={2}>
        {/* ------------ Dark light theme setting ------------- */}
        <Typography variant="h4" gutterBottom>
          Theme Option
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="theme"
            name="theme"
            value={customizer.activeMode}
            onChange={handleThemeModeChange}
          >
            <FormControlLabel
              value="light"
              control={
                <Radio
                  sx={{
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                />
              }
              label="Light"
            />
            <FormControlLabel
              value="dark"
              control={
                <Radio
                  sx={{
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                />
              }
              label="Dark"
            />
          </RadioGroup>
        </FormControl>
        <Box pt={3} />
        {/* ------------ Navbar Color setting ------------- */}
        <Typography variant="h4" gutterBottom>
          Theme Colors
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
            gap: '5px',
          }}
        >
          {thColors.map((thcolor) => (
            <Tooltip
              title={parseSnakeCaseToTitleCase(
                thcolor.disp.toLocaleLowerCase(),
              )}
              placement="top"
              key={thcolor.id}
            >
              <Fab
                color="primary"
                style={{ backgroundColor: thcolor.bgColor }}
                sx={{ marginRight: '3px' }}
                size="small"
                onClick={() => handleThemeColorChange(thcolor.disp)}
                aria-label={thcolor.bgColor}
              >
                {customizer.activeTheme === thcolor.disp ? (
                  <CheckIcon sx={{ width: '30px', height: '30px' }} />
                ) : (
                  ''
                )}
              </Fab>
            </Tooltip>
          ))}
        </Box>

        <Box pt={3} />
      </Box>
    </Box>
  )
}

export default ThemeCustomizer
