import { Typography } from '@mui/material'
import { IErrorMessageProps } from 'components'
import { ErrorMessage as HookformErrorMessage } from '@hookform/error-message'

const ErrorMessage = ({ name, errors }: IErrorMessageProps) => {
  return (
    <HookformErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Typography
          sx={{
            color: '#ff0000',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            margin: '0.1rem',
          }}
        >
          {message}
        </Typography>
      )}
    />
  )
}
export default ErrorMessage
