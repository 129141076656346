import { DEVICE_MODE_TYPES } from 'pages/Doors/constants'
import { useEffect, useState } from 'react'
import { organizationApi } from 'resources'
import { DropdownOption } from 'types'
import { getErrorMessage } from 'utils/helpers'

const useDeviceModes = () => {
  const [modes, setModes] = useState<DropdownOption[]>([])
  const [isLoadingModes, setIsLoadingModes] = useState<boolean>(false)

  const { getSelfOrg } = organizationApi()

  const fetchDeviceModes = async (): Promise<void> => {
    try {
      setIsLoadingModes(true)
      const selfOrg = await getSelfOrg()
      const enabledModes: DropdownOption[] = DEVICE_MODE_TYPES.filter(
        (type) => {
          return selfOrg[`${type.value}_mode`] === true
        },
      )

      setModes([...enabledModes])
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    } finally {
      setIsLoadingModes(false)
    }
  }

  useEffect(() => {
    fetchDeviceModes()
  }, [])

  return {
    modes,
    isLoadingModes,
  }
}

export default useDeviceModes
