import { Box, Theme, useMediaQuery } from '@mui/material'
import styles from './SearchBar.module.css'
import { ISearchBarProps } from 'components'
import { DebounceInput } from 'react-debounce-input'
import { useSelector, RootStateOrAny } from 'react-redux'

const SearchBar = ({ placeholder, onSearch }: ISearchBarProps) => {
  const customizer = useSelector(
    (state: RootStateOrAny) => state.CustomizerReducer,
  )

  return (
    <Box
      sx={{
        maxWidth: '200px',
      }}
    >
      <DebounceInput
        size={60}
        width={110}
        minLength={2}
        debounceTimeout={300}
        onChange={onSearch}
        className={
          customizer.activeMode === 'dark'
            ? styles.debounceInput
            : styles.debounceInputLight
        }
        placeholder={placeholder || 'Search...'}
      />
    </Box>
  )
}
export default SearchBar
