import { useApiRequest } from 'utils'
import { ISize, ISizeList } from 'models'
import { HTTP_CODES } from 'constants/HTTP'
import { getErrorMessage } from 'utils/helpers'

const sizeApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<ISizeList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `sizes?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (size: ISize, image: File | null = null) => {
    const endpoint = `sizes`
    const method = 'POST'

    try {
      if (image) {
        const imageEndpoint = `images`
        const imagePayload = new FormData()
        imagePayload.append('image', image)

        const imageResponse = await fetchPartnerEndpoint({
          endpoint: imageEndpoint,
          method: 'POST',
          body: imagePayload,
        })
        if (!imageResponse.ok) {
          const result = await imageResponse.json()
          throw result.detail
        }
        const result = await imageResponse.json()
        size.image = result.detail
      }

      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(size),
        headers: { 'Content-Type': 'application/json' },
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (
    sizeId: string,
    size: ISize,
    image: File | null = null,
  ) => {
    const endpoint = `sizes/${sizeId}`
    const method = 'PUT'

    try {
      if (image) {
        const imageEndpoint = `images`
        const imagePayload = new FormData()
        imagePayload.append('image', image)

        const imageResponse = await fetchPartnerEndpoint({
          endpoint: imageEndpoint,
          method: 'POST',
          body: imagePayload,
        })
        if (!imageResponse.ok) {
          const result = await imageResponse.json()
          throw result.detail
        }
        const result = await imageResponse.json()
        size.image = result.detail
      }

      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(size),
        headers: { 'Content-Type': 'application/json' },
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]) => {
    const endpoint = `sizes`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `sizes/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getMany, create, update, remove, uploadCSV }
}
export default sizeApi
