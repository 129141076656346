export const formatDate = (date: Date | string): string => {
  const newDate = new Date(date)
  return newDate.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}
