import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { Form, IDialogType, SelectField, TextField } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import LoadingFormData from 'components/PageBase/LoadingFormData'
import useLoadingState from 'hooks/useLoadingState'
import { IReservationSettings } from 'models/Reservations'
import { RESERVATION_SETTINGS_PERIODS } from 'pages/Events/constants'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { reservationApi } from 'resources/reservations'

interface ISettingsFormProps {
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
}

const ReservationSettingsForm = ({
  onClose,
  displayMessage,
}: ISettingsFormProps) => {
  const [toggleTransactionBufferTime, setTransactionBufferTimeEnabled] =
    useState(false)
  const [toggleLockerBufferTime, setLockerBufferTimeEnabled] = useState(false)

  const methods = useForm<IReservationSettings>()

  const { getReservationSettings, updateReservationSettings } = reservationApi()

  const { loading, setLoading } = useLoadingState()

  const handleTransactionBufferTimeToggle = (event) => {
    const { name } = event.target
    if (name !== 'yes_transaction_buffer_time') {
      setTransactionBufferTimeEnabled(false)

      methods.setValue('transaction_buffer_time', 0)
      methods.setValue('transaction_buffer_time_period', null)
    } else {
      setTransactionBufferTimeEnabled(true)
    }
  }

  const handleLockerBufferTimeToggle = (event) => {
    const { name } = event.target
    if (name !== 'yes_locker_buffer_time') {
      setLockerBufferTimeEnabled(false)

      methods.setValue('locker_buffer_time', 0)
      methods.setValue('locker_buffer_time_period', null)
    } else {
      setLockerBufferTimeEnabled(true)
    }
  }

  const fetchReservationSettings = async () => {
    try {
      setLoading(true)
      const reservationSettings = await getReservationSettings()

      methods.setValue('max_rental_time', reservationSettings.max_rental_time)

      methods.setValue(
        'max_rental_time_period',
        reservationSettings.max_rental_time_period,
      )

      methods.setValue(
        'max_reservation_time',
        reservationSettings.max_reservation_time,
      )
      methods.setValue(
        'max_reservation_time_period',
        reservationSettings.max_reservation_time_period,
      )

      if (!reservationSettings.transaction_buffer_time_period) {
        methods.setValue('transaction_buffer_time', 0)
        methods.setValue('transaction_buffer_time_period', null)
        setTransactionBufferTimeEnabled(false)
      } else {
        methods.setValue(
          'transaction_buffer_time',
          reservationSettings.transaction_buffer_time,
        )
        methods.setValue(
          'transaction_buffer_time_period',
          reservationSettings.transaction_buffer_time_period,
        )
        setTransactionBufferTimeEnabled(true)
      }

      if (!reservationSettings.locker_buffer_time_period) {
        methods.setValue('locker_buffer_time', 0)
        methods.setValue('locker_buffer_time_period', null)
        setLockerBufferTimeEnabled(false)
      } else {
        methods.setValue(
          'locker_buffer_time',
          reservationSettings.locker_buffer_time,
        )
        methods.setValue(
          'locker_buffer_time_period',
          reservationSettings.locker_buffer_time_period,
        )
        setLockerBufferTimeEnabled(true)
      }
    } catch (error) {
      displayMessage((error as Error).message, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (reservationSettingsForm: IReservationSettings) => {
    try {
      setLoading(true)

      const payload = {
        ...reservationSettingsForm,
        transaction_buffer_time:
          reservationSettingsForm.transaction_buffer_time,
        transaction_buffer_time_period:
          reservationSettingsForm.transaction_buffer_time_period
            ? reservationSettingsForm.transaction_buffer_time_period
            : null,
        locker_buffer_time: reservationSettingsForm.locker_buffer_time,
        locker_buffer_time_period:
          reservationSettingsForm.locker_buffer_time_period
            ? reservationSettingsForm.locker_buffer_time_period
            : null,
      }

      await updateReservationSettings(payload)
      await fetchReservationSettings()

      displayMessage('Reservation settings updated successfully', 'success')
      onClose()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReservationSettings()
  }, [])

  if (loading) return <LoadingFormData />

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Reservation Settings">
            <Typography variant="h5">Maximum Rental Time</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '50%' }}>
                <TextField
                  name="max_rental_time"
                  type="number"
                  label="Value"
                  placeholder="Value"
                  rules={{
                    required: 'Rental time value is required',
                  }}
                />
              </FormControl>

              <FormControl sx={{ width: '50%' }}>
                <SelectField
                  name="max_rental_time_period"
                  label="Period"
                  items={RESERVATION_SETTINGS_PERIODS}
                  rules={{ required: 'Period is required' }}
                />
              </FormControl>
            </Box>

            <Typography variant="h5">Maximum Reservation Time</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '50%' }}>
                <TextField
                  name="max_reservation_time"
                  type="number"
                  label="Value"
                  placeholder="Value"
                  rules={{
                    required: 'Reservation time value is required',
                  }}
                />
              </FormControl>

              <FormControl sx={{ width: '50%' }}>
                <SelectField
                  name="max_reservation_time_period"
                  label="Period"
                  items={RESERVATION_SETTINGS_PERIODS}
                  rules={{ required: 'Period is required' }}
                />
              </FormControl>
            </Box>

            <Typography variant="h5">
              Buffer time between each transaction
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="yes_transaction_buffer_time"
                      checked={toggleTransactionBufferTime}
                      onChange={handleTransactionBufferTimeToggle}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="no_transaction_buffer_time"
                      checked={!toggleTransactionBufferTime}
                      onChange={handleTransactionBufferTimeToggle}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>

            {toggleTransactionBufferTime && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <FormControl sx={{ width: '50%' }}>
                  <TextField
                    name="transaction_buffer_time"
                    type="number"
                    label="Add value"
                    placeholder="Add value"
                    required
                    rules={{
                      required: 'Transaction buffer time value is required',
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: '50%' }}>
                  <SelectField
                    name="transaction_buffer_time_period"
                    label="Period"
                    items={RESERVATION_SETTINGS_PERIODS}
                    rules={{ required: 'Period is required' }}
                  />
                </FormControl>
              </Box>
            )}

            <Typography variant="h5">Buffer time to assign locker</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="yes_locker_buffer_time"
                      checked={toggleLockerBufferTime}
                      onChange={handleLockerBufferTimeToggle}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="no_locker_buffer_time"
                      checked={!toggleLockerBufferTime}
                      onChange={handleLockerBufferTimeToggle}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>
            {toggleLockerBufferTime && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <FormControl sx={{ width: '50%' }}>
                  <TextField
                    name="locker_buffer_time"
                    type="number"
                    label="Add value"
                    placeholder="Add value"
                    required
                    rules={{
                      required: 'Locker buffer time value is required',
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: '50%' }}>
                  <SelectField
                    name="locker_buffer_time_period"
                    label="Period"
                    items={RESERVATION_SETTINGS_PERIODS}
                    rules={{ required: 'Period is required' }}
                  />
                </FormControl>
              </Box>
            )}

            <FormActions onClose={onClose} loading={loading} />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default ReservationSettingsForm
