import { DeleteForever } from '@mui/icons-material'
import { Checkbox, Link, Tooltip } from '@mui/material'
import {
  ConfirmDialog,
  IconButton,
  TableCell,
  TableRow,
  Image,
  IDialogType,
} from 'components'
import { ISize } from 'models'
import { useState } from 'react'
import { sizeApi } from 'resources'
import { getUserRole } from 'utils/helpers'

interface ISizeRowProps {
  size: ISize
  success: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  filteredColumns: any[]
  handleEditSize: (size: ISize) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const SizeRow = ({
  size,
  success,
  displayMessage,
  filteredColumns,
  handleEditSize,
  handleSelectRow,
  selected,
}: ISizeRowProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })
  const { remove } = sizeApi()
  const role = getUserRole()

  const handleRemoveSize = async () => {
    try {
      await remove([size?.id])
      displayMessage('Size deleted successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, size.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'image' && c.active) !==
          -1 && (
          <TableCell align="center">
            {size.image && (
              <Image
                src={size.image}
                alt="Location Image"
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                  objectFit: 'contain',
                }}
              />
            )}
          </TableCell>
        )}

        {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => handleEditSize(size)}
              >
                {size?.name}
              </Link>
            ) : (
              <>{size?.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'width' && c.active) !==
          -1 && <TableCell align="center">{size?.width}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'depth' && c.active) !==
          -1 && <TableCell align="center">{size?.depth}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'height' && c.active) !==
          -1 && <TableCell align="center">{size?.height}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={() => {
                setConfirmationMessage({
                  isOpen: true,
                  message: 'Are you sure you want to delete this size?',
                })
              }}
            >
              <Tooltip title="Delete">
                <DeleteForever />
              </Tooltip>
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        onClickConfirm={handleRemoveSize}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default SizeRow
