import { Box, LinearProgress, Typography } from '@mui/material'

const PasswordStrength = ({
  value,
  label,
  color,
}: {
  value: number
  label: string
  color: 'success' | 'warning' | 'error'
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} color={color} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

export default PasswordStrength
