import { ArrowForwardOutlined, Circle } from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router'
import GettingStartedItems from './GettingStartedItems'

interface IGettingStartedItem {
  icon: any
  path: string
  title: string
  desc: string
  nextPath: string | boolean
  sideBarIndex: number
  parentIndex: number
  child: boolean
  position: string | number
  step: number
}

const TourItem = ({
  tour,
  onClose,
}: {
  tour: IGettingStartedItem
  onClose: () => void
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: tour.position,
          left: '15em',
          maxWidth: 320,
          zIndex: 9999,
          // backgroundColor: theme.palette.background.default,

          ':before': {
            // This is the arrow pointing to the item on the left of the card
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            borderTop: `12px solid transparent`,
            borderRight: `30px solid ${theme.palette.background.paper}`,
            borderBottom: '12px solid transparent',
            left: '-6px',
            top: '2em',
            zIndex: 10000,
          },
        }}
      >
        <Card
          sx={{
            boxShadow: 10,
            backgroundImage: 'none',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <CardContent>
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                marginTop: '-1em',
              }}
            >
              Step {tour.step}:
            </Typography>

            <Box
              sx={{
                display: 'block',
                textAlign: 'center',
              }}
            >
              <Box>
                <tour.icon
                  sx={{
                    color: 'primary.main',
                    fontSize: '3em',
                  }}
                />
              </Box>

              <Typography variant="h4">{tour.title}</Typography>

              <Typography variant="caption">{tour.desc}</Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: tour.nextPath ? 'space-between' : 'flex-end',
                alignItems: 'center',
                marginTop: '1.5em',
                marginBottom: '-1em',
              }}
            >
              {tour.nextPath ? (
                <>
                  <Box
                    onClick={onClose}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Typography variant="body2">Skip</Typography>
                  </Box>

                  <Box>
                    {GettingStartedItems.map((item, index) => (
                      <Circle
                        key={index}
                        onClick={() => navigate(`${item.path}?tour=1`)}
                        sx={{
                          cursor: 'pointer',
                          color:
                            item.step == tour.step
                              ? 'primary.main'
                              : 'text.secondary',
                          fontSize: '8pt',

                          ':not(:last-child)': {
                            marginRight: '5px',
                          },
                        }}
                      />
                    ))}
                  </Box>

                  <ArrowForwardOutlined
                    onClick={() => navigate(`${tour.nextPath}?tour=1`)}
                    sx={{
                      cursor: 'pointer',
                      color: 'primary.main',
                      fontSize: '2em',
                    }}
                  />
                </>
              ) : (
                <Box
                  onClick={onClose}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="body2">Finish</Typography>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default TourItem
