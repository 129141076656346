import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import {
  Form,
  TextField,
  SelectField,
  DateField,
  Tabs,
  IDialogType,
} from 'components'
import { IPromo } from 'models'
import { promoApi } from 'resources'
import { PROMO_DISCOUNT } from '../../constants'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import { SyntheticEvent, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import useLoadingState from 'hooks/useLoadingState'
import { getUserRole, parseCurrency } from 'utils/helpers'
import { RootStateOrAny, useSelector } from 'react-redux'

interface IPromoFormProps {
  promo: IPromo | undefined
  onClose: () => void
  displayMessage: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
}

const PromoForm = ({
  promo,
  onClose,
  displayMessage,
  success,
}: IPromoFormProps) => {
  const { loading, setLoading } = useLoadingState()
  const { update, create } = promoApi()

  const [currentTab, setCurrentTab] = useState<string>('IndividualUpload')

  const methods = useForm<IPromo>({
    defaultValues: { ...promo },
  })

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const onSubmit: SubmitHandler<IPromo> = async (newPromo): Promise<void> => {
    try {
      setLoading(true)
      if (promo) {
        await update(promo.id, newPromo)
        onClose()
        displayMessage('Promo updated successfully!', 'success')
        success()
      } else {
        await create(newPromo)
        onClose()
        displayMessage('Promo created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    methods.resetField('end_time')
  }, [methods.watch('start_time')])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const PromoFormElement = (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title={promo ? 'Edit Promo' : 'Add Promo'}>
          <TextField
            name="name"
            label="Name"
            placeholder="Name"
            rules={{
              required: 'Name is required',
            }}
          />
          <TextField
            name="code"
            label="Code"
            placeholder="Code"
            rules={{
              required: 'Code is required',
            }}
          />

          <SelectField
            name="discount_type"
            label="Discount type"
            placeholder="Discount type"
            items={PROMO_DISCOUNT}
            rules={{ required: 'Discount type is required' }}
          />

          <TextField
            name="amount"
            label="Amount"
            placeholder="Amount"
            rules={{
              pattern: {
                value: /^[0-9]*$/i,
                message: 'Please enter a numeric value',
              },
              required: 'Amount is required',
            }}
          />

          <DateField
            name="start_time"
            label="Start time"
            rules={{
              required: 'Start time required',
            }}
          />
          <DateField
            name="end_time"
            label="End time"
            minDate={methods.watch('start_time')}
            rules={{
              required: 'End time required',
            }}
          />

          {methods.watch('amount') && (
            <Typography color="text.secondary" variant="h3" textAlign="center">
              {methods.watch('discount_type') === 'fixed'
                ? `-${parseCurrency(currency)}${methods.watch(
                    'amount',
                  )} per transaction`
                : `-${methods.watch('amount')}% per transaction`}
            </Typography>
          )}

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )

  return (
    <>
      {(promo || (getUserRole() !== 'admin' && !promo)) && PromoFormElement}
      {getUserRole() === 'admin' && !promo && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'IndividualUpload',
              children: PromoFormElement,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="promos"
                  successForm={success}
                  onClose={onClose}
                  displayMessage={displayMessage}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}
export default PromoForm
