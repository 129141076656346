import * as React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Typography } from '@mui/material'

interface IImageUploaderProps {
  buttonText: string
  helpText: string
  multiple?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const ImageUploader = ({
  buttonText,
  helpText,
  multiple = false,
  onChange,
}: IImageUploaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
      }}
    >
      <Button
        sx={{ whiteSpace: 'nowrap', width: '50%' }}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        onClick={() => document.getElementById('image')?.click()}
      >
        {buttonText}
        <VisuallyHiddenInput
          onChange={onChange}
          accept='accept="image/*'
          type="file"
          id="image"
          multiple={multiple}
        />
      </Button>
      <Typography
        sx={{ width: '50%', fontSize: 10 }}
        fontSize={12}
        color="text.secondary"
      >
        {helpText}
      </Typography>
    </Box>
  )
}

export default ImageUploader
