import { IStripeAccount } from 'models'
import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'

const financialApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const createStripeAccount = async (
    country: string = 'US',
  ): Promise<{ stripeLink: string }> => {
    const urlParams = new URLSearchParams({
      country: country,
    })
    const endpoint = `stripe?${urlParams.toString()}`
    const method = 'POST'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return { stripeLink: await response.json() }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getStripeAccount = async (): Promise<IStripeAccount> => {
    const endpoint = `stripe/account`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const deleteStripeAccount = async (): Promise<{
    detail: string
  }> => {
    const endpoint = `stripe`
    const method = 'DELETE'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    createStripeAccount,
    getStripeAccount,
    deleteStripeAccount,
  }
}
export default financialApi
