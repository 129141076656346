import { useState, SyntheticEvent, useEffect } from 'react'
import { Tabs, NotificationDialog } from 'components'
import { memberApi, settingsApi } from 'resources'
import { IMember } from 'models/Member'
import { SETTINGS_TABS } from './constants'
import ThemeCustomizer from './tabs/Theme/ThemeCustomizer'
import SettingsForm from './tabs/General/SettingsForm'
import APIKeysForm from './tabs/APIKeys/APIKeys'
import { Box, Card, Typography, useTheme } from '@mui/material'
import useDialog from 'hooks/useDialog'
import { ISettings } from 'models/Settings'

const Settings = () => {
  const [currentMember, setCurrentMember] = useState<IMember>()
  const [currentTab, setCurrentTab] = useState<string>(
    SETTINGS_TABS.GENERAL.value,
  )
  const [orgSettings, setOrgSettings] = useState<Partial<ISettings>>({})

  const { getSelf } = memberApi()

  const { dialog, displayMessage, closeDialog } = useDialog()

  const { getSettings, getWhiteLabel } = settingsApi()

  const theme = useTheme()

  const fetch = async () => {
    try {
      const response = await getSelf()
      setCurrentMember(response)
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
    }
  }

  const fetchOrgSettings = async () => {
    const settings: ISettings = await getSettings()
    const whitelabel = await getWhiteLabel()
    settings.terms_condition = whitelabel?.terms_condition
    settings.terms_condition_2nd = whitelabel?.terms_condition_2nd
    settings.terms_name_2nd = whitelabel?.terms_name_2nd
    localStorage.setItem('settings', JSON.stringify(settings))
    setOrgSettings(settings)
  }

  useEffect(() => {
    const isFirstTimeLogin = localStorage.getItem('firstTimeLogin') === 'true'
    if (isFirstTimeLogin) {
      localStorage.removeItem('firstTimeLogin')
      const welcomeMessage = (
        <Box
          sx={{
            display: 'block',
            color: 'white',
          }}
        >
          <Typography>
            You can fill out the fields on the Settings page to get started
          </Typography>
          <Typography>or</Typography>
          <Typography>
            We will load the default settings for your organization
          </Typography>
        </Box>
      )
      displayMessage(welcomeMessage, 'welcome', {
        titleColor: theme.palette.primary.main,
        titleText: 'Welcome!',
      })
    }
    try {
      fetch()
      fetchOrgSettings()
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
    }
  }, [])

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const TABS = [
    {
      label: SETTINGS_TABS.GENERAL.label,
      value: SETTINGS_TABS.GENERAL.value,
      children: (
        <SettingsForm
          orgSettings={orgSettings}
          fetchOrgSettings={fetchOrgSettings}
          displayMessage={displayMessage}
        />
      ),
    },
    {
      label: SETTINGS_TABS.THEME.label,
      value: SETTINGS_TABS.THEME.value,
      children: <ThemeCustomizer />,
    },
    {
      label: SETTINGS_TABS.API_KEYS.label,
      value: SETTINGS_TABS.API_KEYS.value,
      children: <APIKeysForm />,
    },
  ]

  return (
    <Card>
      {currentMember && Object.keys(orgSettings).length > 0 && (
        <Tabs
          tabs={TABS}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
      <NotificationDialog
        message={dialog.message}
        open={dialog.isOpen}
        onClose={closeDialog}
        type={dialog.type}
        customTitle={dialog.customTitle}
      />
    </Card>
  )
}
export default Settings
