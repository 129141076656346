/* eslint-disable react/display-name */
import { DeleteForever, CopyAll } from '@mui/icons-material'
import { Box, Checkbox, Chip, Link, Tooltip } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  TableCell,
  TableRow,
} from 'components'
import { ISubscription, IPrice, IPromo } from 'models'
import { useCallback, useMemo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { membershipApi, priceApi, promoApi } from 'resources'
import { getUserRole, parseCurrency } from 'utils/helpers'

interface IItemRowProps {
  item: IPrice | ISubscription | IPromo
  setCurrentItem: React.Dispatch<
    React.SetStateAction<IPrice | ISubscription | IPromo | undefined>
  >

  filteredColumns: any[] | undefined
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selectedRows: string[]
}

const ItemRow = ({
  item,
  setCurrentItem,
  filteredColumns,
  displayMessage,
  success,
  handleSelectRow,
  selectedRows,
}: IItemRowProps) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    message: '',
  })
  const { remove: deletePrice } = priceApi()

  const role = getUserRole()

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const data = useMemo(
    () => [
      {
        column: 'select',
        render: () =>
          role === 'admin' ? (
            <Checkbox
              checked={selectedRows.includes(item.id)}
              onChange={(event: React.ChangeEvent, checked: boolean) =>
                handleSelectRow(event, checked, item.id)
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          ) : (
            <Checkbox
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={true}
            />
          ),
      },
      {
        column: 'name',
        render: () =>
          role !== 'member' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => setCurrentItem(item as IPrice)}
              >
                {(item as IPrice).name}
              </Link>{' '}
              {(item as IPrice).default && (
                <Tooltip title="This is the default price for the organization">
                  <Chip color="default" label="Default" size="small" />
                </Tooltip>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              (item as IPrice).name
              {(item as IPrice).default && (
                <Tooltip title="This is the default price for the organization">
                  <Chip color="default" label="Default" size="small" />
                </Tooltip>
              )}
            </Box>
          ),
      },
      {
        column: 'price_type',
        render: () => {
          switch ((item as IPrice).price_type) {
            case 'pay_per_time':
              return 'Time'
            case 'pay_per_weight':
              return 'Weight'
          }
        },
      },
      {
        column: 'amount',
        render: () =>
          `${parseCurrency((item as IPrice).currency || currency)}${
            (item as IPrice).amount
          }`,
      },
      {
        column: 'unit_amount',
        render: () => (item as IPrice).unit_amount,
      },
      {
        column: 'unit',
        render: () =>
          (item as IPrice).unit?.charAt(0).toUpperCase() +
          (item as IPrice).unit?.slice(1),
      },
      {
        column: 'prorated',
        render: () => ((item as IPrice).prorated ? 'Yes' : 'No'),
      },
      {
        column: 'card_on_file',
        render: () => ((item as IPrice).card_on_file ? 'Yes' : 'No'),
      },
      {
        column: 'action',
        render: () => (
          <IconButton
            onClick={() =>
              setConfirmDialog({
                isOpen: true,
                message: 'Are you sure you want to delete this price?',
              })
            }
          >
            <Tooltip title="Delete">
              <DeleteForever />
            </Tooltip>
          </IconButton>
        ),
      },
      {
        column: 'id',
        render: () => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
            }}
          >
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(item.id)
              }}
            >
              <Tooltip title="Copy ID">
                <CopyAll />
              </Tooltip>
            </IconButton>
          </Box>
        ),
      },
    ],
    [item, selectedRows],
  )

  const dataMap = useMemo(() => {
    const map = new Map<string, () => React.ReactNode>()
    data.forEach((item) => {
      map.set(item.column, item.render)
    })
    return map
  }, [data])

  const deleteAction = async () => {
    try {
      await deletePrice([item.id])
      displayMessage('Price has been successfully deleted!', 'success')
      success()
      setConfirmDialog({ isOpen: false, message: '' })
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  return (
    <>
      <TableRow>
        {filteredColumns?.map(
          (c) =>
            c.active && (
              <TableCell key={c.value} align="center">
                {dataMap.has(c.value) ? (
                  dataMap.get(c.value)!()
                ) : typeof (item as any)[c.value] === 'boolean' ? (
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: (item as any)[c.value] ? 'green' : 'red',
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  (item as any)[c.value] || ''
                )}
              </TableCell>
            ),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmDialog.isOpen}
        message={confirmDialog.message}
        onClose={() => setConfirmDialog({ isOpen: false, message: '' })}
        onClickConfirm={() => {
          setConfirmDialog({ isOpen: false, message: '' })
          deleteAction()
        }}
        onClickCancel={() => setConfirmDialog({ isOpen: false, message: '' })}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ItemRow
