import { Box, Typography, Avatar, Divider, Link } from '@mui/material'
import { MailOutlineOutlined, SettingsOutlined } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import { parseRole } from 'utils/helpers'
import { ISession } from 'models/Session'

const ProfileDropdown = ({
  onClose,
  session,
}: {
  onClose: () => void
  session: ISession
}) => {
  return (
    <>
      {session.email && (
        <Box>
          <Box
            sx={{
              pb: 3,
              mt: 3,
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  ml: 2,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    lineHeight: '1.235',
                  }}
                >
                  {`${
                    session.email.includes('helpdesk') && !session.name
                      ? 'Helpdesk'
                      : session.name || session.first_name
                  }`}
                </Typography>

                <Typography sx={{ mt: 1 }} color="textSecondary" variant="h6">
                  {parseRole(session.role)}
                </Typography>

                <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                  <Typography
                    color="textSecondary"
                    display="flex"
                    alignItems="center"
                    sx={{
                      color: (theme) => theme.palette.grey.A200,
                      mr: 1,
                    }}
                  >
                    <MailOutlineOutlined sx={{ width: '18' }} />
                  </Typography>
                  <Typography color="textSecondary" variant="h6">
                    {session.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
          />
          {session?.role === 'admin' && (
            <Link
              to="/settings"
              component={NavLink}
              underline="none"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                margin: '15px 0',
                color: 'text.secondary',
              }}
              onClick={onClose}
            >
              <SettingsOutlined /> Settings
            </Link>
          )}
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
          />
        </Box>
      )}
    </>
  )
}
export default ProfileDropdown
