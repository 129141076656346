import { Autocomplete, TextField } from '@mui/material'
import { Dialog } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { useState } from 'react'
import { DropdownOption } from 'types'

interface IAssignLockerDialogProps {
  isOpen: boolean
  onClose: () => void
  lockerNumbers: DropdownOption[]
  handleAssignLocker: (deviceId: string) => void
  reassigningLocker: boolean
}

const AssignLockerDialog = ({
  isOpen,
  onClose,
  lockerNumbers,
  handleAssignLocker,
  reassigningLocker,
}: IAssignLockerDialogProps) => {
  const [selectedDevice, setSelectedDevice] = useState<string>('')

  const { loading, setLoading } = useLoadingState()

  const handleSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: DropdownOption | null,
  ) => {
    if (value) {
      setSelectedDevice(value.value)
    }
  }

  const handleSubmit = () => {
    setSelectedDevice('')
    handleAssignLocker(selectedDevice)
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <FormWrapper
        title={reassigningLocker ? 'Reassign Locker' : 'Assign Locker'}
        isDialog={true}
      >
        <Autocomplete
          onChange={handleSelect}
          options={lockerNumbers}
          renderInput={(params) => (
            <TextField {...params} label="Type the locker number here..." />
          )}
        />

        <FormActions
          submitText={reassigningLocker ? 'Reassign' : 'Assign'}
          onClose={() => {
            setSelectedDevice('')
            onClose()
          }}
          loading={loading}
          disableSubmit={!selectedDevice}
          onSubmit={handleSubmit}
        />
      </FormWrapper>
    </Dialog>
  )
}

export default AssignLockerDialog
