import { useEffect, useState } from 'react'
import { ILocation } from 'models'
import {
  DeleteForever,
  QrCode,
  ArrowDropDown,
  ArrowDropUp,
} from '@mui/icons-material'
import {
  ConfirmDialog,
  IconButton,
  NotificationDialog,
  QRGenerator,
  Image,
} from 'components'
import { Source, Layer } from '@urbica/react-map-gl'
import MapGL, { Marker, Popup } from '@urbica/react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { RENTAL_URL } from '../../../../constants'
import { locationApi } from 'resources'
import { Box, useTheme, Typography, Switch } from '@mui/material'
import useDialog from 'hooks/useDialog'
import CloseIcon from '@mui/icons-material/Close'
import { capitalizeFirstLetter } from 'utils/helpers'
import './map.css'
import { SummarizedText } from 'components/SummarizedText'

interface IMapViewProps {
  locations: ILocation[]
  controls: boolean
  closeControls?: () => void
}
interface TooltipInfo {
  activeId: string | null
  data: ILocation | null
}

const MapView = ({ locations, controls, closeControls }: IMapViewProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })
  const [locationToDelete, setLocationToDelete] = useState<string>('')
  const [selectedMarker, setSelectedMarker] = useState<string>('')
  const [showHeatmap, setShowHeatmap] = useState(false)
  const [showFakeHeatmap, setShowFakeHeatmap] = useState(false)
  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  })
  const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo>({
    activeId: null,
    data: null,
  })
  const [showSubTooltip, setShowSubTooltip] = useState<boolean>(false)

  const ORG_DATA: string | null = localStorage.getItem('currentOrg')
  const ORG_NAME: string = ORG_DATA ? JSON.parse(ORG_DATA).name : undefined

  const theme = useTheme()

  const { generateQRCode } = QRGenerator()
  const { remove } = locationApi()

  const { dialog, displayMessage, closeDialog } = useDialog()

  const handleQRCode = ({
    locationId,
    orgName,
  }: {
    locationId: string
    orgName: string
  }): void => {
    generateQRCode(
      locationId,
      `${RENTAL_URL}?location=${locationId}&org=${orgName}`,
    )
  }

  const handleDeleteLocation = async (id: string): Promise<void> => {
    try {
      await remove([id])
      displayMessage('Location deleted successfully!', 'success')
      setConfirmationMessage({
        isOpen: false,
        message: '',
      })
      setLocationToDelete('')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const closeToolTip = () => {
    setTooltipInfo({ activeId: null, data: null })
    setShowSubTooltip(false)
    setSelectedMarker('')
  }

  const openToolTip = (location: ILocation) => {
    setTooltipInfo({ activeId: location.id, data: location })
    setSelectedMarker(location.id)
  }

  const fakeLocations = Array.from({ length: 1000 }, (_, index) => ({
    id: `location-${index}`,
    latitude: 37.78 + Math.random() * 0.1 - 0.05, // Randomize around a central point
    longitude: -122.41 + Math.random() * 0.1 - 0.05,
    devices: Array.from({ length: Math.floor(Math.random() * 100) }), // Random number of devices up to 100
  }))

  const geoJsonData = {
    type: 'FeatureCollection',
    features: fakeLocations.map((location) => ({
      type: 'Feature',
      properties: {
        intensity: location.devices.length, // Use device count as intensity
      },
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude],
      },
    })),
  }

  useEffect(() => {
    setShowSubTooltip(false)
  }, [tooltipInfo])

  useEffect(() => {
    if (tooltipInfo) {
      const arrow = document.getElementsByClassName('mapboxgl-popup-tip')[0]
      console.log('arrow: ', arrow)
      if (arrow) {
        arrow.classList.add(
          `popup-arrow-${theme.palette.mode === 'dark' ? 'dark' : 'light'}`,
        )
        arrow.classList.remove(
          `popup-arrow-${theme.palette.mode === 'dark' ? 'light' : 'dark'}`,
        )
      }
    }
  }, [tooltipInfo, theme.palette.mode])

  return (
    <>
      <MapGL
        style={{
          width: '100%',
          height: 'calc(100vh - 230px)',
          borderRadius: '5px',
          margin: '15px',
          position: 'relative', // Ensure the map container allows absolute positioning
        }}
        mapStyle="mapbox://styles/mapbox/light-v10"
        accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string}
        latitude={locations[0].latitude}
        longitude={locations[0].longitude}
        zoom={viewport.zoom}
        onViewportChange={setViewport}
      >
        {showHeatmap && (
          <Source id="heatmapData" type="geojson" data={geoJsonData}>
            <Layer
              id="heatmapLayer"
              type="heatmap"
              source="heatmapData"
              maxZoom={15}
              paint={{
                'heatmap-weight': [
                  'interpolate',
                  ['linear'],
                  ['get', 'intensity'],
                  0,
                  0,
                  6,
                  1,
                ],
                'heatmap-intensity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,
                  1,
                  15,
                  3,
                ],
                'heatmap-color': [
                  'interpolate',
                  ['linear'],
                  ['heatmap-density'],
                  0,
                  'rgba(33,102,172,0)',
                  0.2,
                  'rgb(103,169,207)',
                  0.4,
                  'rgb(209,229,240)',
                  0.6,
                  'rgb(253,219,199)',
                  0.8,
                  'rgb(239,138,98)',
                  1,
                  'rgb(178,24,43)',
                ],
                'heatmap-radius': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,
                  2,
                  15,
                  20,
                ],
              }}
            />
          </Source>
        )}
        {showFakeHeatmap && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage:
                'url(https://static.vecteezy.com/system/resources/thumbnails/001/843/667/small_2x/light-green-red-gradient-blur-texture-vector.jpg)', // Path to your heatmap image
              backgroundSize: 'cover',
              opacity: 0.5, // Adjust opacity as needed
              pointerEvents: 'none', // Allows click events to pass through
            }}
          />
        )}

        {controls && (
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              background: theme.palette.mode === 'dark' ? '#33373E' : 'white',
              borderRadius: '5px',
              zIndex: 10,
              minWidth: '300px',
              minHeight: '300px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton onClick={closeControls}>
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography
                sx={{
                  paddingLeft: '10px',
                  fontWeight: 'bold',
                }}
                variant="h5"
              >
                Controls
              </Typography>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography> What Three Words</Typography>
                <Switch />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography> Google Address</Typography>
                <Switch />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography> Movement Data</Typography>
                <Switch />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography> Usage Heat Map</Typography>
                <Switch />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography> User Address Heat Map</Typography>
                <Switch onChange={() => setShowFakeHeatmap(!showFakeHeatmap)} />
              </div>
            </div>
          </Box>
        )}
        {locations.map((location) => {
          const isSelected = location.id === selectedMarker
          return (
            <Box key={location.id} sx={{ zIndex: 1 }}>
              <Marker
                latitude={location.latitude}
                longitude={location.longitude}
              >
                <div onClick={openToolTip.bind(null, location)}>
                  {location.id === selectedMarker ? (
                    <>
                      <svg
                        width="39"
                        height="51"
                        viewBox="0 0 39 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_194_19)">
                          <circle
                            cx="19.5"
                            cy="15.5"
                            r="15.5"
                            fill={theme.palette.primary.main}
                          />
                          <path
                            d="M22.0981 41.5C20.9434 43.5 18.0566 43.5 16.9019 41.5L5.21058 21.25C4.05588 19.25 5.49926 16.75 7.80866 16.75L31.1913 16.75C33.5007 16.75 34.9441 19.25 33.7894 21.25L22.0981 41.5Z"
                            fill={theme.palette.primary.main}
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_194_19"
                            x="0"
                            y="0"
                            width="39"
                            height="51"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_194_19"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_194_19"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        width="39"
                        height="51"
                        viewBox="0 0 39 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_194_19)">
                          <circle cx="19.5" cy="15.5" r="15.5" fill="#33373E" />
                          <path
                            d="M22.0981 41.5C20.9434 43.5 18.0566 43.5 16.9019 41.5L5.21058 21.25C4.05588 19.25 5.49926 16.75 7.80866 16.75L31.1913 16.75C33.5007 16.75 34.9441 19.25 33.7894 21.25L22.0981 41.5Z"
                            fill="#33373E"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_194_19"
                            x="0"
                            y="0"
                            width="39"
                            height="51"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_194_19"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_194_19"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </>
                  )}
                </div>
                {tooltipInfo.activeId === location.id && tooltipInfo.data && (
                  <Popup
                    longitude={location.longitude}
                    latitude={location.latitude}
                    closeOnClick={false}
                    anchor="bottom-left"
                    offset={-25}
                  >
                    <Box
                      sx={{
                        color: 'text.secondary',
                        padding: '0 0 0 1rem',
                        background:
                          theme.palette.mode === 'dark' ? '#33373E' : 'white',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        minWidth: '300px',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'flex-end !important',
                          justifyContent: 'flex-end !important',
                        }}
                      >
                        <IconButton onClick={closeToolTip}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <strong>Name:</strong> <>{tooltipInfo?.data?.name}</>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.25rem',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <strong>Address:</strong>{' '}
                        <SummarizedText
                          charLimit={33}
                          text={tooltipInfo?.data?.address}
                        />
                      </Box>
                      <Box>
                        <strong>No. of Devices:</strong>{' '}
                        {tooltipInfo?.data?.devices?.length ?? 0}
                      </Box>
                      <br />
                      {showSubTooltip && (
                        <>
                          {tooltipInfo?.data?.devices &&
                            tooltipInfo?.data?.devices?.length > 0 && (
                              <Box>
                                <strong>Modes:</strong>

                                {tooltipInfo?.data?.devices && (
                                  <ul style={{ paddingLeft: 10 }}>
                                    {tooltipInfo?.data?.devices
                                      .reduce((acc: any, device: any) => {
                                        const mode = device?.mode
                                        const found = acc.find(
                                          (item: any) => item?.mode === mode,
                                        )
                                        if (found) {
                                          found.count++
                                        } else {
                                          acc.push({ mode, count: 1 })
                                        }
                                        return acc
                                      }, [])
                                      .map((modeItem) => (
                                        <li key={modeItem?.mode}>
                                          {modeItem?.mode === 'rental'
                                            ? 'Asset'
                                            : capitalizeFirstLetter(
                                                modeItem?.mode,
                                              )}
                                          : {modeItem?.count}
                                        </li>
                                      ))}
                                  </ul>
                                )}
                              </Box>
                            )}

                          {tooltipInfo?.data?.devices &&
                            tooltipInfo?.data?.devices?.length > 1 && (
                              <Box>
                                <strong>Hardware types:</strong>
                                {tooltipInfo?.data?.devices && (
                                  <ul style={{ paddingLeft: 10 }}>
                                    {tooltipInfo?.data?.devices
                                      .reduce((acc: any, device: any) => {
                                        const hardwareType =
                                          device?.hardware_type
                                        const found = acc.find(
                                          (item: any) =>
                                            item?.hardwareType === hardwareType,
                                        )
                                        if (found) {
                                          found.count++
                                        } else {
                                          acc.push({ hardwareType, count: 1 })
                                        }
                                        return acc
                                      }, [])
                                      .map((hardwareItem: any) => (
                                        <li key={hardwareItem.hardwareType}>
                                          {capitalizeFirstLetter(
                                            hardwareItem?.hardwareType,
                                          )}
                                          : {hardwareItem?.count}
                                        </li>
                                      ))}
                                  </ul>
                                )}
                              </Box>
                            )}

                          {tooltipInfo?.data?.devices &&
                            tooltipInfo?.data?.devices?.length > 1 && (
                              <Box>
                                <strong>Sizes:</strong>
                                {tooltipInfo?.data?.devices && (
                                  <ul style={{ paddingLeft: 10 }}>
                                    {tooltipInfo?.data?.devices
                                      .reduce((acc: any, device: any) => {
                                        const sizeName = device?.size?.name
                                        const found = acc.find(
                                          (item) => item?.sizeName === sizeName,
                                        )
                                        if (found) {
                                          found.count++
                                        } else {
                                          acc.push({ sizeName, count: 1 })
                                        }
                                        return acc
                                      }, [])
                                      .map((sizeItem) => (
                                        <li key={sizeItem?.sizeName}>
                                          {sizeItem?.sizeName}:{' '}
                                          {sizeItem?.count}
                                        </li>
                                      ))}
                                  </ul>
                                )}
                              </Box>
                            )}

                          {tooltipInfo.data.image && (
                            <Image
                              src={tooltipInfo.data.image}
                              alt="Location Image"
                              style={{
                                outline: 'none',
                                borderRadius: '5px',
                              }}
                            />
                          )}
                        </>
                      )}

                      <Box
                        style={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setShowSubTooltip((previousValue) => !previousValue)
                          }}
                        >
                          {showSubTooltip ? <ArrowDropUp /> : <ArrowDropDown />}
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleQRCode({
                              locationId: location.id,
                              orgName: ORG_NAME,
                            })
                          }}
                        >
                          <QrCode />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setConfirmationMessage({
                              isOpen: true,
                              message:
                                'Are you sure you want to delete this location?',
                            })
                            setLocationToDelete(location.id)
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Box>
                    </Box>
                  </Popup>
                )}
              </Marker>
            </Box>
          )
        })}
      </MapGL>
      <NotificationDialog
        message={dialog.message}
        open={dialog.isOpen}
        onClose={closeDialog}
        type={dialog.type}
      />
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => {
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
          setLocationToDelete('')
        }}
        onClickConfirm={() => handleDeleteLocation(locationToDelete)}
        onClickCancel={() => {
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
          setLocationToDelete('')
        }}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default MapView
