import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import { IIntegration } from 'models/Integration'
import IntegrationCard from './IntegrationCard'

interface IIntegrationBlockProps {
  title: string
  integrations: IIntegration[]
}

const IntegrationsBlock = ({ title, integrations }: IIntegrationBlockProps) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        justifyContent: 'center',
        alignItems: mdDown ? 'center' : undefined,
      }}
    >
      <Typography variant="h3">{title}</Typography>
      <Grid
        container
        xs={12}
        sm={12}
        lg={12}
        sx={{
          gap: mdDown ? '3rem' : '3rem',
          alignItems: mdDown ? 'center' : undefined,
          justifyContent: mdDown ? 'center' : undefined,
          gridAutoRows: 'min-content',
        }}
      >
        {integrations.map((integration) => (
          <IntegrationCard key={integration.name} integration={integration} />
        ))}
      </Grid>
    </Box>
  )
}

export default IntegrationsBlock
