import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField as MUITextField,
  Typography,
} from '@mui/material'
import {
  Form,
  IDialogType,
  SelectField,
  TextField,
  ToggleField,
} from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useEffect, useState } from 'react'
import { IMember } from 'models'
import { IReport, Recurrence } from 'models/Report'
import { reportApi } from 'resources'
import { DAYS, METRICS, MONTHS, WHEN_OPTIONS } from '../constants'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DropdownOption } from 'types'

interface IReportFormProps {
  report: IReport | undefined
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
  members: IMember[]
}

const ReportForm = ({
  report,
  onClose,
  displayMessage,
  success,
  members,
}: IReportFormProps) => {
  const [metrics, setMetrics] = useState<string[]>(report?.contents || [])
  const [defaultMetrics, setDefaultMetrics] = useState<DropdownOption[]>(() =>
    report
      ? METRICS.filter((m) => report.contents.some((c) => c === m.value))
      : [],
  )
  const [membersOptions, setMembersOptions] = useState<
    { label: string; id: string; email: string }[]
  >([])
  const [defaultMembers, setDefaultMembers] = useState<
    { label: string; id: string; email: string }[]
  >(() =>
    report
      ? members
          .filter((member) =>
            report.assign_to.some((id) => member.user_id === id),
          )
          .map((member) => ({
            label: member.name,
            id: member.user_id,
            email: member.email,
          }))
      : [],
  )

  const [recurrence, setRecurrence] = useState<Recurrence>(
    report?.recurrence || '',
  )

  const [selectedMembers, setSelectedMembers] = useState<string[]>(
    report?.assign_to || [],
  )
  const [sendTime, setSendTime] = useState<Date | null>(() => {
    if (report) {
      const date = new Date()
      date.setUTCHours(Number(report.send_time.split(':')[0]))
      date.setUTCMinutes(Number(report.send_time.split(':')[1]))
      return date
    }
    return null
  })

  const { loading, setLoading } = useLoadingState()

  const { createReport, updateReport } = reportApi()

  const methods = useForm<IReport>({
    defaultValues: { ...report },
  })

  const handleToggleMetrics = (event: React.SyntheticEvent, value: any) => {
    const metrics: string[] = value.map((option) => option.value)
    setMetrics(metrics)
  }

  const handleMemberSelection = (event: React.SyntheticEvent, value: any) => {
    const membersSelected: string[] = value.map((option) => option.id)
    setSelectedMembers(membersSelected)
  }

  const buildMembersOptions = () => {
    if (members.length > 0) {
      const autoCompleteOptions = members.map((member) => ({
        label: member.name,
        id: member.user_id,
        email: member.email,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setMembersOptions(sortedOptions)
    }
  }

  const disableSubmit =
    Object.keys(methods.formState.errors).length > 0 ||
    !sendTime ||
    !recurrence ||
    selectedMembers.length === 0 ||
    metrics.length === 0

  const onSubmit: SubmitHandler<Partial<IReport>> = async (
    newReport: Partial<IReport>,
  ) => {
    try {
      setLoading(true)

      if (!sendTime) {
        return
      }

      const date = new Date(sendTime)
      const hours = date.getUTCHours()
      const minutes = date.getUTCMinutes()

      const payload = {
        ...newReport,
        weekday: ['weekly', 'biweekly'].includes(recurrence)
          ? newReport.weekday
          : undefined,
        month: ['monthly', 'bimonthly'].includes(recurrence)
          ? newReport.month
          : undefined,
        when: ['monthly', 'bimonthly'].includes(recurrence)
          ? newReport.when
          : undefined,
        contents: metrics,
        assign_to: selectedMembers,
        recurrence,
        send_time: `${hours < 10 ? '0' : ''}${hours}:${
          minutes < 10 ? '0' : ''
        }${minutes}`,
      }

      if (report) {
        await updateReport(report.id, payload)
        onClose()
        displayMessage('Report updated successfully!', 'success')
        success(true)
      } else {
        await createReport(payload)
        onClose()
        displayMessage('Report created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    buildMembersOptions()
  }, [members])

  useEffect(() => {
    const month = methods.watch('month')
    const when = methods.watch('when')
    const weekday = methods.watch('weekday')
    if (['weekly', 'biweekly'].includes(recurrence) && when && month) {
      methods.resetField('month')
      methods.resetField('when')
    } else if (['monthly', 'bimonthly'].includes(recurrence) && weekday) {
      methods.resetField('weekday')
    }
  }, [recurrence])

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title={report ? 'Edit Report' : 'Create Report'}>
            <TextField
              name="name"
              label="Report Name"
              placeholder="Report Name"
              rules={{
                required: 'Report name is required',
              }}
            />
            <Autocomplete
              defaultValue={defaultMetrics}
              multiple
              disableCloseOnSelect
              options={METRICS}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Contents..." />
              )}
              onChange={handleToggleMetrics}
              loading={METRICS.length === 0}
            />

            <Autocomplete
              defaultValue={defaultMembers}
              multiple
              options={membersOptions}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>{option.label}</Typography>
                    <Typography color="text.secondary">
                      {option.email}
                    </Typography>
                  </Box>
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Assignees..." />
              )}
              onChange={handleMemberSelection}
              loading={members.length === 0}
              disableCloseOnSelect={true}
              getOptionLabel={(option) => option.label}
            />

            <Typography textAlign="center" variant="h5">
              Recurrence
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recurrence === 'weekly'}
                      onChange={() => setRecurrence('weekly')}
                    />
                  }
                  label="Weekly"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recurrence === 'monthly'}
                      onChange={() => setRecurrence('monthly')}
                    />
                  }
                  label="Monthly"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recurrence === 'biweekly'}
                      onChange={() => setRecurrence('biweekly')}
                    />
                  }
                  label="Biweekly"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recurrence === 'bimonthly'}
                      onChange={() => setRecurrence('bimonthly')}
                    />
                  }
                  label="Bimonthly"
                />
              </FormGroup>
            </Box>

            {['weekly', 'biweekly'].includes(recurrence) && (
              <SelectField label="Select Day" items={DAYS} name="weekday" />
            )}

            {['monthly', 'bimonthly'].includes(recurrence) && (
              <SelectField
                label="Select Start Month"
                items={MONTHS}
                name="month"
              />
            )}

            {['monthly', 'bimonthly'].includes(recurrence) && (
              <SelectField
                label="When do you want to send the report"
                items={WHEN_OPTIONS}
                name="when"
              />
            )}

            <TimePicker
              label="Send Time"
              value={sendTime}
              onChange={(newValue) => setSendTime(newValue)}
              renderInput={(params) => <MUITextField {...params} />}
            />

            <ToggleField name="include_sub_orgs" label="Include Sub-Orgs" />

            {report && (
              <Typography textAlign="center" color="text.secondary">
                {report.last_sent ? (
                  `Last report was sent on ${new Date(
                    report.last_sent,
                  ).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}`
                ) : (
                  <>Report not sent yet</>
                )}
              </Typography>
            )}

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default ReportForm
