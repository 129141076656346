export const formatLocationFormData = (data) => {
  const payload = new FormData()
  payload.append('name', data.name)
  payload.append('address', data.address)
  payload.append('hidden', data.hidden)
  payload.append('latitude', data.latitude.toString())
  payload.append('longitude', data.longitude.toString())
  payload.append('contact_phone', data.contact_phone)
  payload.append('contact_email', data.contact_email)
  payload.append('shared', data.shared)
  payload.append('require_image', data.require_image)

  if (data.image !== null) payload.append('image', data.image)

  if (data.assign_to && data.assign_to.length > 0) {
    payload.append('assignment_type', data.assignment_type)
    payload.append('assign_to', data.assign_to)
  }

  return payload
}
