export const RETURN_TO_OPTIONS = [
  {
    value: 'one_to_one',
    label: 'Same Locker',
  },
  {
    value: 'any_locker_of_same_size_and_location',
    label: 'Any locker of same size and location',
  },
  // {
  //   value: 'any_locker_of_same_size_at_any_location',
  //   label: 'Any locker of same size at any location',
  // },
]
