import { useApiRequest } from 'utils'
import { ReponseDetail } from 'types'
import { IMemberList, IMember } from 'models'
import { HTTP_CODES } from 'constants/HTTP'
import { getErrorMessage } from 'utils/helpers'

const memberApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<IMemberList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `members?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getSelf = async (): Promise<IMember> => {
    const endpoint = `members/self`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (
    member: Partial<IMember>,
    memberId: string,
  ): Promise<IMember> => {
    const endpoint = `members/${memberId}`
    const method = 'PUT'
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(member),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (member: Partial<IMember>): Promise<IMember> => {
    const endpoint = `members?email=${member.email}`
    const method = 'POST'
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(member),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]): Promise<any> => {
    const endpoint = `members`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const status = async ({ memberId }: { memberId: string }) => {
    const endpoint = `members/${memberId}/status`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const verify = async ({ memberId }: { memberId: string }) => {
    const endpoint = `members/${memberId}/verify`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `members/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patch = async (ids: string[], member: IMember) => {
    const endpoint = `members/`
    const method = 'PATCH'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify({
      user_ids: ids,
      member,
    })

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    update,
    create,
    remove,
    status,
    verify,
    getSelf,
    uploadCSV,
    patch,
  }
}
export default memberApi
