import React from 'react'
import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import { Help } from 'components/Help'
import { PropsWithChildren } from 'react'

interface IFormWrapperProps {
  title?: string
  helpText?: string
  isDialog?: boolean
}

const FormWrapper = ({
  title = '',
  helpText = '',
  isDialog = false,
  children,
}: PropsWithChildren<IFormWrapperProps>) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: smDown ? '1rem' : 0,
        width: smDown ? '100%' : isDialog ? '350px' : '100%',
      }}
    >
      {title && helpText && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Help helpText={helpText}>
            <Typography
              variant="h3"
              sx={{ mt: '10px', textAlign: 'center !important' }}
            >
              {title}
            </Typography>
          </Help>
        </Box>
      )}
      {title && !helpText && (
        <Typography variant="h3" sx={{ mt: '10px', textAlign: 'center' }}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  )
}

export default FormWrapper
