import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Form, IDialogType, SelectField } from 'components'
import { IDevice, IMember } from 'models'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { deviceApi, memberApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { ROLES } from 'pages/People/constants'

interface IMultiEditFormProps {
  members: string[]
  success: () => void
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
}

const MultiEditForm = ({
  members,
  success,
  onClose,
  displayMessage,
}: IMultiEditFormProps): React.ReactElement => {
  const { patch } = memberApi()

  const { loading, setLoading } = useLoadingState()

  const methods = useForm<IMember>({})

  const onSubmit = async (newDevice: IMember): Promise<void> => {
    try {
      setLoading(true)
      await patch(members, newDevice)
      onClose()
      displayMessage(
        `Successfully updated ${members.length} member${
          members.length > 1 ? 's' : ''
        }`,
        'success',
      )
      success()
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title="Edit Team Members">
          <Typography color="text.secondary">{`${members.length} member${
            members.length > 1 ? 's' : ''
          } selected`}</Typography>
          <SelectField
            name="role"
            label="Role"
            placeholder="Role"
            items={ROLES}
            sx={{
              width: '100%',
            }}
            rules={{
              required: 'Role is required',
            }}
          />

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )
}

export default MultiEditForm
