export const DATE_RANGES_OPTIONS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'this_week',
    label: 'This Week',
  },
  {
    value: 'this_month',
    label: 'This Month',
  },
  {
    value: 'this_quarter',
    label: 'This Quarter',
  },
  {
    value: 'this_year',
    label: 'This year',
  },
  {
    value: 'custom',
    label: 'Custom Range',
  },
]

export const CRITERIAS_OPTIONS = [
  {
    value: 'locations',
    label: 'Locations',
  },
  {
    value: 'devices',
    label: 'Devices',
  },
  {
    value: 'users',
    label: 'Users',
  },
  {
    value: 'products',
    label: 'Products',
  },
  {
    value: 'transactions',
    label: 'Transactions',
  },
  {
    value: 'all',
    label: 'All',
  },
]

export const CRITERIAS_METRICS = {
  locations: [
    'doorHealth',
    'reportedIssuesRate',
    'occupancyRate',
    'totalLocations',
    'totalDoors',
    'totalTransactions',
    'transactionsPerLocker',
  ],
  devices: ['doorHealth', 'transactionsPerLocker', 'totalDoors'],
  users: ['totalUsers', 'newUserGrowth'],
  products: ['', ''],
  transactions: [
    'earnings',
    'newTransaction',
    'transactionsPerLocker',
    'totalTransactions',
    'transactionTimeAverage',
    'averageRevenuePerTransaction',
  ],
  all: [
    'earnings',
    'newUserGrowth',
    'doorHealth',
    'reportedIssuesRate',
    'occupancyRate',
    'newTransaction',
    'transactionsPerLocker',
    'totalTransactions',
    'transactionTimeAverage',
    'totalUsers',
    'totalLocations',
    'averageRevenuePerTransaction',
    'totalDoors',
  ],
}

export const TOP_USERS_HEADERS: {
  label: string
  align: any
}[] = [
  { label: 'User', align: 'left' },
  { label: 'Location', align: 'center' },
  { label: 'Purchases', align: 'right' },
]

export const TOP_LOCATIONS_HEADERS: {
  label: string
  align: any
}[] = [
  { label: 'Location Name', align: 'left' },
  { label: 'Address', align: 'center' },
  { label: 'Transactions', align: 'right' },
]

export const METRICS = [
  {
    label: 'Earnings',
    value: 'earnings',
  },
  {
    label: 'User Growth',
    value: 'user_growth',
  },
  {
    label: 'System Health',
    value: 'system_health',
  },
  {
    label: 'Issue Rate',
    value: 'issue_rate',
  },
  {
    label: 'Occupancy Rate',
    value: 'occupancy_rate',
  },
  {
    label: 'Transaction Rate',
    value: 'transaction_rate',
  },
  {
    label: 'Users',
    value: 'users',
  },
  {
    label: 'Top Users',
    value: 'top_users',
  },
  {
    label: 'Top Locations',
    value: 'top_locations',
  },
]
