import {
  Autocomplete,
  Box,
  Checkbox,
  TextField as MUITextField,
  Typography,
} from '@mui/material'
import {
  Form,
  Image,
  NotificationDialog,
  SelectField,
  SideBar,
  TextField,
} from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { ChangeEvent, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { deviceApi, locationApi, userApi } from 'resources'
import { IDevice, ILocation, IUser } from 'models'
import useDialog from 'hooks/useDialog'
import { DEPARTMENTS } from './constants'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import { IFeedback } from 'models/Feedback'
import { SUCCESS_DIALOG } from '../../../constants'
import { feedbackApi } from 'resources/feedback'
import { DropdownOption } from 'types'

interface IFeedbackForm {
  isOpen: boolean
  onClose: () => void
}

const FeedbackForm = ({ isOpen, onClose }: IFeedbackForm) => {
  const [images, setImages] = useState<File[]>([])
  const [imagesPreviews, setImagesPreviews] = useState<string[]>([])
  const [locationsOptions, setLocationsOptions] = useState<DropdownOption[]>([])
  const [devicesOptions, setDevicesOptions] = useState<DropdownOption[]>([])
  const [usersOptions, setUsersOptions] = useState<DropdownOption[]>([])
  const [locationId, setLocationId] = useState<string>('')
  const [deviceId, setDeviceId] = useState<string>('')
  const [userId, setUserId] = useState<string>('')

  const methods = useForm<IFeedback>()

  const { dialog, displayMessage, closeDialog } = useDialog()

  const { loading, setLoading } = useLoadingState()
  const { getMany: getLocations } = locationApi()
  const { getMany: getDevices } = deviceApi()
  const { getMany: getUsers } = userApi()
  const { create } = feedbackApi()

  const fetchLocations = async (): Promise<ILocation[]> => {
    try {
      const locations = await getLocations(1, 10000)
      return locations.items
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
      return []
    }
  }

  const fetchDevices = async (): Promise<IDevice[]> => {
    try {
      const devices = await getDevices(1, 10000)
      return devices.items
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
      return []
    }
  }

  const fetchUsers = async (): Promise<IUser[]> => {
    try {
      const users = await getUsers(1, 10000)
      return users.items
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
      return []
    }
  }

  const buildLocationsOptions = (locations: ILocation[]) => {
    if (locations.length > 0) {
      const autoCompleteOptions = locations.map((location) => ({
        label: location.name,
        value: location.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setLocationsOptions(sortedOptions)
    }
  }

  const buildDevicesOptions = (devices: IDevice[]) => {
    if (devices.length > 0) {
      const autoCompleteOptions = devices.map((device) => ({
        label: device.name,
        value: device.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setDevicesOptions(sortedOptions)
    }
  }

  const buildUsersOptions = (users: IUser[]) => {
    if (users.length > 0) {
      const autoCompleteOptions = users.map((user) => ({
        label: `${user.name || ''} (${user.email || user.phone_number})`,
        value: user.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setUsersOptions(sortedOptions)
    }
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = event?.target?.files

    if (files) {
      const fileArray = Array.from(files)

      fileArray.forEach((file) => {
        const isImage = file && /^image\//.test(file.type)
        if (!isImage) {
          displayMessage?.(
            'One or more of the selected files are not images. Please try again.',
            'success',
          )
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = (e) => {
          if (e.target && e.target.result) {
            const preview = e.target.result as string
            setImagesPreviews((previousValue) => [...previousValue, preview])
          }
        }
      })

      setImages([...images, ...fileArray])
    }
  }

  const handleLocationSelection = (event: React.SyntheticEvent, value: any) => {
    setLocationId(value.label)
  }

  const handleDeviceSelection = (event: React.SyntheticEvent, value: any) => {
    setDeviceId(value.label)
  }

  const handleUserSelection = (event: React.SyntheticEvent, value: any) => {
    setUserId(value.label)
  }

  const onSubmit: SubmitHandler<IFeedback> = async (feedback: IFeedback) => {
    try {
      setLoading(true)

      const payload = new FormData()

      payload.append('location', locationId)
      payload.append('device', deviceId)
      payload.append('member', userId)
      payload.append('department', feedback.department)
      payload.append('description', feedback.description)
      payload.append('notes', feedback.notes)

      if (images.length > 0) {
        images.forEach((image) => {
          payload.append('images', image)
        })
      }

      await create(payload)

      displayMessage('We will review it as soon as possible.', 'success', {
        titleColor: SUCCESS_DIALOG,
        titleText: 'Thanks for your Feedback!',
      })

      methods.reset()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
      methods.reset()
      setImages([])
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const locations = await fetchLocations()
      buildLocationsOptions(locations)

      const devices = await fetchDevices()
      buildDevicesOptions(devices)

      const users = await fetchUsers()
      buildUsersOptions(users)
    }
    fetch()
  }, [])

  const helpText = `Click the button below to upload the images
  related to the issue. You can upload one or
  multiple images.`

  return (
    <SideBar
      open={isOpen}
      onClose={() => {
        setImages([])
        setImagesPreviews([])
        onClose
      }}
    >
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Send Feedback">
            {imagesPreviews.length > 0 && (
              <>
                <Typography variant="h5">Attached images</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    textAlign: 'center',
                    justifyContent: 'left',
                    width: '100%',
                    gap: '1rem',
                  }}
                >
                  {imagesPreviews.map((picture, index) => (
                    <Image
                      key={index}
                      src={picture}
                      alt="img"
                      style={{
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  ))}
                </Box>
              </>
            )}

            <ImageUploader
              buttonText="Upload Images"
              helpText={helpText}
              onChange={handleImageUpload}
              multiple
            />

            <Typography variant="caption" color="text.secondary">
              {`${imagesPreviews.length} image${
                imagesPreviews.length === 1 ? '' : 's'
              } selected`}
            </Typography>
            <Autocomplete
              options={locationsOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              onChange={handleLocationSelection}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Select a location..." />
              )}
              loading={locationsOptions.length === 0}
            />

            <Autocomplete
              options={devicesOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              onChange={handleDeviceSelection}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Select a device..." />
              )}
              loading={devicesOptions.length === 0}
            />

            <Autocomplete
              options={usersOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              onChange={handleUserSelection}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Select a user..." />
              )}
              loading={usersOptions.length === 0}
            />

            <SelectField
              name="department"
              label="Department"
              items={DEPARTMENTS}
              defaultValue="Sales"
              value="Sales"
              required
            />

            <TextField
              multiline
              name="description"
              label="Description"
              placeholder="Description"
              rules={{
                maxLength: {
                  value: 200,
                  message: 'Description must be 200 characters long maximum.',
                },
                required: 'Description is required',
              }}
              helperText={`${methods.watch('description')?.length || 0}/${200}`}
            />

            <TextField
              multiline
              name="notes"
              label="Notes"
              placeholder="Notes"
              rules={{
                maxLength: {
                  value: 300,
                  message: 'Notes must be 300 characters long maximum.',
                },
              }}
              helperText={`${methods.watch('notes')?.length || 0}/${300}`}
            />

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={false}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
      <NotificationDialog
        open={dialog.isOpen}
        onClose={() => {
          closeDialog()
          onClose()
        }}
        message={dialog.message}
        customTitle={dialog.customTitle}
      />
    </SideBar>
  )
}

export default FeedbackForm
