import { useNavigate, useRoutes } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import ThemeSettings from './layouts/FullLayout/customizer/ThemeSettings'
import { useEffect } from 'react'
import Router from './routes/Router'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { setTheme, setDarkMode } from './redux/customizer/Action'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { NotificationDialog } from 'components'
import { setMessage } from 'redux/message/Action'
import { settingsApi } from 'resources'
import { setCurrency } from 'redux/currency/Action'

const App = () => {
  const navigate = useNavigate()
  const routing = useRoutes(Router())
  const theme = ThemeSettings()
  const dispatch = useDispatch()
  const messageReducer = useSelector(
    (state: RootStateOrAny) => state.messageReducer,
  )

  const currentUser = localStorage.getItem('currentUser')
  const { getSettings } = settingsApi()

  const fetchCurrency = async () => {
    const settings = await getSettings()
    const { default_currency } = settings
    dispatch(setCurrency(default_currency))
  }

  useEffect(() => {
    const themeMode = localStorage.getItem('themeMode')
    const themeColor = localStorage.getItem('themeColor')
    if (themeMode) {
      dispatch(setDarkMode(themeMode))
    }
    if (themeColor) {
      dispatch(setTheme(themeColor))
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      const currentUserObj = JSON.parse(currentUser)
      if (currentUserObj.challengeName === 'NEW_PASSWORD_REQUIRED') return

      // Set the current currency on the global state
      fetchCurrency()
    }
  }, [currentUser])

  return (
    <ThemeProvider theme={theme}>
      <NotificationDialog
        open={Boolean(messageReducer.message)}
        onClose={() => {
          dispatch(setMessage({ message: '', type: null, customTitle: null }))
        }}
        message={messageReducer.message}
        customTitle={messageReducer.customTitle}
      />
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {routing}
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
