import { IFetchEndpoint } from 'utils'
import { API_URL, PUBLIC_API_URL } from '../../constants'
import { Auth } from 'aws-amplify'
import { useAuth } from 'resources'

const useApiRequest = () => {
  const { getJwtToken } = useAuth()

  const fetchPartnerEndpoint = async ({
    endpoint,
    method,
    body,
    headers,
  }: IFetchEndpoint) => {
    try {
      const jwtToken = await getJwtToken()
      const response = await fetch(`${API_URL}${endpoint}`, {
        method,
        body,
        headers: { ...headers, Authorization: `Bearer ${jwtToken}` },
      })
      return response
    } catch (error) {
      throw new Error(
        `An error has occured while processing your request. Please try again later or contact customer support. ${
          (error as Error).message
        }`,
      )
    }
  }

  const fetchEndpoint = async ({
    endpoint,
    method,
    body,
    headers,
  }: IFetchEndpoint) => {
    try {
      const jwtToken = await getJwtToken()
      const response = await fetch(`${PUBLIC_API_URL}${endpoint}`, {
        method,
        body,
        headers: { ...headers, Authorization: `Bearer ${jwtToken}` },
      })
      return response
    } catch (error) {
      throw new Error(
        `An error has occured while processing your request. Please try again later or contact customer support. ${
          (error as Error).message
        }`,
      )
    }
  }

  const fetchEndpointUnauthorized = async ({
    endpoint,
    method,
    body,
    headers,
  }: IFetchEndpoint) => {
    try {
      const response = await fetch(`${PUBLIC_API_URL}${endpoint}`, {
        method,
        body,
        headers,
      })
      return response
    } catch (error) {
      throw new Error(
        `An error has occured while processing your request. Please try again later or contact customer support. ${
          (error as Error).message
        }`,
      )
    }
  }

  return {
    fetchEndpoint,
    fetchPartnerEndpoint,
    fetchEndpointUnauthorized,
  }
}

export default useApiRequest
