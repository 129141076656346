import { useApiRequest } from 'utils'
import { IPromoList, IPromo } from 'models'
import { HTTP_CODES } from 'constants/HTTP'
import { getErrorMessage } from 'utils/helpers'
const GET_ERRORS = {
  [HTTP_CODES.NOT_FOUND]: 'No prices found',
  [HTTP_CODES.INTERNAL_SERVER_ERROR]: 'An internal server error has occurred',
}
const promoApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<IPromoList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `promos?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (promo) => {
    const endpoint = `promos`
    const method = 'POST'
    const body = JSON.stringify(promo)
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (promoId: string, promo: IPromo) => {
    const endpoint = `promos/${promoId}`
    const method = 'PUT'
    const body = JSON.stringify(promo)
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]) => {
    const endpoint = `promos`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `promos/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getMany, create, update, remove, uploadCSV }
}
export default promoApi
