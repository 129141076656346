import { ISx } from 'types'

export const SignInFormtyles = (): { SX: ISx } => {
  const SX: ISx = {
    CONTAINER: {
      p: 4,
    },
    FIELDS_CONTAINER: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      mt: 4,
    },
    BUTTONS_CONTAINER: {
      display: 'flex',
      gap: '0 15px',
      justifyContent: 'center',
      mt: 4,
    },
  }

  return { SX }
}
