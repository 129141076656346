import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Image } from 'components'
import { ChangeEvent, useState } from 'react'
import signInScreen from '../../../assets/images/apps-widgets/signin.png'
import multipleRentals from '../../../assets/images/apps-widgets/multiple-rentals.png'
import payment from '../../../assets/images/apps-widgets/payment.png'
import photoEndRental from '../../../assets/images/apps-widgets/photo-end-rental.png'

type SignInMethod = 'phone_number' | 'email' | 'both' | ''

const LiteAppSettings = () => {
  const [currentImage, setCurrentImage] = useState<string>(signInScreen)
  const [signInMethod, setSignInMethod] = useState<SignInMethod>('')
  const [primaryColor, setPrimaryColor] = useState<string>('#000000')
  const [secondaryColor, setSecondaryColor] = useState<string>('#000000')

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const handleSignInMethod = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    method: SignInMethod,
  ) => {
    setSignInMethod(checked ? method : '')
  }

  const handlePrimaryColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPrimaryColor(event.target.value)
  }

  const handleSecondaryColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSecondaryColor(event.target.value)
  }

  return (
    <Grid container>
      <Grid
        xs={12}
        sm={12}
        lg={6}
        sx={{
          padding: !mdDown ? '1.5rem 0' : undefined,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: mdDown ? 'center' : undefined,
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography textAlign="left" variant="h3">
            Customize your Web App
          </Typography>

          {/* Sign in method */}
          <Typography
            color="text.secondary"
            sx={{ marginTop: '1.25rem' }}
            variant="h5"
          >
            Choose a sign in method
          </Typography>
          <Box onClick={() => setCurrentImage(signInScreen)}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={signInMethod === 'phone_number'}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => handleSignInMethod(event, checked, 'phone_number')}
                />
              }
              label="Phone Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={signInMethod === 'email'}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => handleSignInMethod(event, checked, 'email')}
                />
              }
              label="Email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={signInMethod === 'both'}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => handleSignInMethod(event, checked, 'both')}
                />
              }
              label="Both"
            />
          </Box>

          {/* Multiple rentals */}
          <Typography
            sx={{ marginTop: '1rem' }}
            color="text.secondary"
            variant="h5"
          >
            Allow multiple assets
          </Typography>
          <Stack
            onClick={() => setCurrentImage(multipleRentals)}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography>{`Don't Allow`}</Typography>
            <Switch />
            <Typography>Allow</Typography>
          </Stack>

          {/* Allow lockers reservations */}
          <Typography
            sx={{ marginTop: '1rem' }}
            color="text.secondary"
            variant="h5"
          >
            Allow users to reserve a locker
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{`Don't Allow`}</Typography>
            <Switch />
            <Typography>Allow</Typography>
          </Stack>

          {/* Allow products tracking */}
          <Typography
            sx={{ marginTop: '1rem' }}
            color="text.secondary"
            variant="h5"
          >
            Track conditions of the products
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{`Don't Allow`}</Typography>
            <Switch />
            <Typography>Allow</Typography>
          </Stack>

          {/* Allow photo to end rental */}
          <Typography
            sx={{ marginTop: '1rem' }}
            color="text.secondary"
            variant="h5"
          >
            Allow photo to end the asset rental
          </Typography>
          <Stack
            onClick={() => setCurrentImage(photoEndRental)}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography>{`Don't Allow`}</Typography>
            <Switch />
            <Typography>Allow</Typography>
          </Stack>

          {/* Payment */}
          <Typography
            sx={{ marginTop: '1rem' }}
            color="text.secondary"
            variant="h5"
          >
            Set up payment in the app
          </Typography>
          <Stack
            onClick={() => setCurrentImage(payment)}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography>No</Typography>
            <Switch />
            <Typography>Yes</Typography>
          </Stack>

          {/* App colors */}
          <Typography
            sx={{ marginTop: '1rem', marginBottom: '0.5rem' }}
            color="text.secondary"
            variant="h5"
          >
            Set colors for web app
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <TextField
                label="Primary Color"
                placeholder="#HEX"
                type="color"
                sx={{
                  height: '4em',
                  width: '100px',
                  position: 'relative',

                  input: {
                    cursor: 'pointer',
                    height: '2.75rem',
                    margin: 0,
                    padding: '10px 25px',
                  },
                }}
                onChange={handlePrimaryColorChange}
              />
              <Typography color="text.secondary">{primaryColor}</Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <TextField
                label="Secondary Color"
                placeholder="#HEX"
                type="color"
                sx={{
                  height: '4em',
                  width: '100px',
                  position: 'relative',

                  input: {
                    cursor: 'pointer',
                    height: '2.75rem',
                    margin: 0,
                    padding: '10px 25px',
                  },
                }}
                onChange={handleSecondaryColorChange}
              />
              <Typography color="text.secondary">{secondaryColor}</Typography>
            </Box>
          </Stack>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        lg={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Image
          src={currentImage}
          alt="logo"
          style={{
            width: '300px',
            height: '800px',
            objectFit: 'contain',
            marginLeft: !mdDown ? '-250px' : 0,
          }}
          isLogo={true}
        />
      </Grid>
    </Grid>

    // </Box>
  )
}

export default LiteAppSettings
