import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Form, IDialogType, SelectField } from 'components'
import { IDevice } from 'models'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DEVICE_STATUSES } from '../../constants'
import { deviceApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { DropdownOption } from 'types'
import useDeviceModes from 'hooks/useDeviceModes'

interface IMultiEditFormProps {
  devices: string[]
  success: () => void
  onClose: () => void
  sizeOptions: DropdownOption[]
  priceOptions: { value: string; label: string; priceType?: string }[]
  locationOptions: DropdownOption[]
  displayMessage: (message: string, type?: IDialogType) => void
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>
}

const MultiEditForm = ({
  devices,
  success,
  onClose,
  sizeOptions,
  priceOptions,
  locationOptions,
  displayMessage,
  setSelectedRows,
}: IMultiEditFormProps): React.ReactElement => {
  const [priceRequired, setPriceRequired] = useState<boolean>(true)

  const { patch } = deviceApi()
  const { modes, isLoadingModes } = useDeviceModes()
  const { loading, setLoading } = useLoadingState()

  const methods = useForm<IDevice>({})

  const handlePriceRequiredChange = (e: any) => {
    setPriceRequired(e.target.checked)
  }

  const onSubmit = async (newDevice: IDevice): Promise<void> => {
    try {
      setLoading(true)
      await patch(devices, {
        ...newDevice,
        price_required: priceRequired,
        id_price: priceRequired ? newDevice.id_price : null,
      })
      setSelectedRows([])
      onClose()
      displayMessage(
        `Successfully updated ${devices.length} device${
          devices.length > 1 ? 's' : ''
        }`,
        'success',
      )
      success()
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    methods.clearErrors('id_price')
  }, [priceRequired])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title="Edit Devices">
          <Typography color="text.secondary">{`${devices.length} device${
            devices.length > 1 ? 's' : ''
          } selected`}</Typography>
          <SelectField name="mode" label="Mode" items={modes} />
          <FormControlLabel
            control={
              <Checkbox
                onClick={handlePriceRequiredChange}
                checked={priceRequired}
              />
            }
            label="Price required"
          />
          {priceRequired && (
            <SelectField
              name="id_price"
              label="Price"
              items={
                priceOptions.filter((p) => ({
                  label: p.label,
                  value: p.value,
                })) || []
              }
              rules={
                priceRequired ? { required: 'Price is required' } : undefined
              }
            />
          )}

          <SelectField name="id_size" label="Size" items={sizeOptions} />
          <SelectField
            name="id_location"
            label="Location"
            items={locationOptions}
          />
          <SelectField name="status" label="Status" items={DEVICE_STATUSES} />

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )
}

export default MultiEditForm
