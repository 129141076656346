export const METRICS = [
  {
    label: 'Earnings',
    value: 'earnings',
  },
  {
    label: 'User Growth',
    value: 'user_growth',
  },
  {
    label: 'System Health',
    value: 'system_health',
  },
  {
    label: 'Issue Rate',
    value: 'issue_rate',
  },
  {
    label: 'Occupancy Rate',
    value: 'occupancy_rate',
  },
  {
    label: 'Transaction Rate',
    value: 'transaction_rate',
  },
  {
    label: 'Users',
    value: 'users',
  },
  {
    label: 'Top Users',
    value: 'top_users',
  },
  {
    label: 'Top Locations',
    value: 'top_locations',
  },
  {
    label: 'Active Locks',
    value: 'active_locks',
  },
]

export const DAYS = [
  {
    label: 'Monday',
    value: '0',
  },
  {
    label: 'Tuesday',
    value: '1',
  },
  {
    label: 'Wednesday',
    value: '2',
  },
  {
    label: 'Thursday',
    value: '3',
  },
  {
    label: 'Friday',
    value: '4',
  },
  {
    label: 'Saturday',
    value: '5',
  },
  {
    label: 'Sunday',
    value: '6',
  },
]

export const MONTHS = [
  {
    label: 'January',
    value: '1',
  },
  {
    label: 'February',
    value: '2',
  },
  {
    label: 'March',
    value: '3',
  },
  {
    label: 'April',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'June',
    value: '6',
  },
  {
    label: 'July',
    value: '7',
  },
  {
    label: 'August',
    value: '8',
  },
  {
    label: 'September',
    value: '9',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
]

export const WHEN_OPTIONS = [
  {
    label: 'Beginning of month',
    value: 'start',
  },
  {
    label: 'End of month',
    value: 'end',
  },
]
