export const NOTIFICATIONS_TABLE_HEADERS = [
  {
    value: 'name',
    label: 'Name',
    active: true,
  },
  {
    value: 'type',
    label: 'Type',
    active: true,
  },
  {
    value: 'email',
    label: 'Email',
    active: true,
  },
  {
    value: 'sms',
    label: 'SMS',
    active: true,
  },
  {
    value: 'push',
    label: 'Push',
    active: true,
  },
  {
    value: 'action',
    label: 'Action',
    active: true,
  },
]

export const TIME_UNITS = [
  { value: 'immediately', label: 'Immediately' },
  { value: 'minute', label: 'Minute' },
  { value: 'hour', label: 'Hour' },
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
]

export const NOTIFICATION_TYPES_BY_MODE = {
  'all-modes': ['welcome', 'on_start', 'reminder', 'complete'],
  delivery: ['welcome', 'on_start', 'reminder', 'complete', 'on_expired'],
  service: [
    'welcome',
    'start',
    'pickup',
    'charge',
    'user_pickup',
    'reminder',
    'complete',
  ],
}

export const NOTIFICATION_EVENTS_BY_MODE = {
  service: [
    {
      value: 'on_signup',
      label: 'Sign Up / In',
    },
    {
      value: 'on_service_dropoff',
      label: 'Items Dropped Off',
    },
    {
      value: 'on_service_pickup',
      label: 'Operator Pickup',
    },
    {
      value: 'on_service_charge',
      label: 'Charged',
    },
    {
      value: 'on_service_dropoff',
      label: 'User Pickup',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
    },
    {
      value: 'on_complete',
      label: 'Order Completion',
    },
    {
      value: 'marketing',
      label: 'Marketing',
    },
    {
      value: 'instructions',
      label: 'Instructions',
    },
  ],
  rental: [
    {
      value: 'on_signup',
      label: 'Sign-Up / In',
    },
    {
      value: 'on_start',
      label: 'Asset Rental Start',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
    },
    {
      value: 'on_complete',
      label: 'Asset Rental End',
    },
    {
      value: 'marketing',
      label: 'Marketing',
    },
    {
      value: 'instructions',
      label: 'Instructions',
    },
  ],
  storage: [
    {
      value: 'on_signup',
      label: 'Sign-Up / In',
    },
    {
      value: 'on_start',
      label: 'Order Start',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
    },
    {
      value: 'on_complete',
      label: 'Order Completion',
    },
    {
      value: 'marketing',
      label: 'Marketing',
    },
    {
      value: 'instructions',
      label: 'Instructions',
    },
  ],
  delivery: [
    {
      value: 'on_signup',
      label: 'Sign-Up / In',
    },
    {
      value: 'on_start',
      label: 'User pick up',
    },
    {
      value: 'on_service_dropoff',
      label: 'Package Delivered',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
    },
    {
      value: 'on_complete',
      label: 'Order Completion',
    },
    {
      value: 'in_progress',
      label: 'Package delivered but ongoing transaction',
    },
    {
      value: 'marketing',
      label: 'Marketing',
    },
    {
      value: 'instructions',
      label: 'Instructions',
    },
    {
      value: 'non_locker_delivery',
      label: 'Message for delivered but not in the locker',
    },
    {
      value: 'on_expired',
      label: 'Expired Parcels',
    },
  ],
}
