import { Navigate } from 'react-router-dom'
import { IAuthenticatedProps } from 'components'

const Authenticated = ({ children }: IAuthenticatedProps) => {
  const USER_DATA = localStorage.getItem('currentUser')

  if (USER_DATA) {
    const isLoggedIn =
      JSON.parse(USER_DATA).challengeName !== 'NEW_PASSWORD_REQUIRED'

    return isLoggedIn ? children : <Navigate to="/auth/login" />
  }

  return <Navigate to="/auth/login" />
}
export default Authenticated
