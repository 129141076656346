import { TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useFormContext, Controller } from 'react-hook-form'
import { ErrorMessage, IDateFieldProps } from 'components'

const DateField = ({
  name,
  label,
  rules,
  minDate,
  maxDate,
  disablePast,
  defaultValue = null,
}: IDateFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <DateTimePicker
            disablePast={disablePast}
            maxDate={maxDate}
            minDate={minDate}
            value={value ? value : defaultValue ? defaultValue : null}
            onChange={(data) => onChange(data?.toISOString())}
            renderInput={(props) => <TextField {...props} />}
            label={label}
          />
        )}
      />
      <ErrorMessage errors={errors} name={name} />
    </>
  )
}
export default DateField
