import { Grid, Box } from '@mui/material'
import img1 from '../../assets/images/backgrounds/login-bg.png'
import LogoIcon from '../../layouts/FullLayout/logo/LogoIcon'

import { SetNewPasswordStyles } from './styles'

import { SetNewPasswordForm, PageContainer, Image } from 'components'

const SetNewPassword = () => {
  const { SX, STYLES } = SetNewPasswordStyles()

  return (
    <PageContainer
      title="Reset Password"
      description="This is the Reset Password page"
    >
      <Grid container spacing={0} sx={SX.GRID_CONTAINER}>
        <Grid item xs={12} sm={12} lg={6} sx={SX.GRID_ITEM}>
          <Box sx={SX.BOX_IMAGE_CONTAINER}>
            <Box sx={SX.BOX_IMAGE}>
              <Image src={img1} alt="bg" style={STYLES.IMG} />
            </Box>
            <Box sx={SX.BOX_LOGO}>
              <LogoIcon />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
          <Grid container spacing={0} display="flex" justifyContent="center">
            <Grid item xs={12} lg={9} xl={6}>
              <SetNewPasswordForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default SetNewPassword
