import { ILocation } from 'models'

export interface InitialState {
  lastLocationId: ILocation | null
}

const initialState: InitialState = {
  lastLocationId: null,
}

const lastLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setLastLocation':
      return {
        lastLocationId: action.payload,
      }
    default:
      return state
  }
}

export default lastLocationReducer
