import { Box, Typography } from '@mui/material'
import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { promoApi } from 'resources'
import ItemRow from './ItemRow'

import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import { generateFilters } from 'utils/helpers'
import { Toolbar } from 'components/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from '../ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'
import PromoForm from './PromoForm'

import { PROMO_DEFAULTS } from '../../constants'

const response = {
  items: [],
  total: 0,
  pages: 0,
}

const Promo = () => {
  const [data, setData] = useState<any>(response)
  const [dataBackup, setDataBackup] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [currentItem, setCurrentItem] = useState<any>()
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const { loading, setLoading } = useLoadingState(true)
  const { getMany: getPromos, remove: deletePromo } = promoApi()

  const { dialog, displayMessage, closeDialog } = useDialog()

  const { filteredColumns, setFilteredColumns } = useColumnFiltering({
    displayMessage,
  })

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'promo-codes',
      nestedProps: [],
    })

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    console.log(event, checked, id)
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? data.items.map((device) => device.id) : [],
    )
  }

  const deleteMany = useCallback(async () => {
    try {
      await deletePromo(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      fetchPromos()
      closeDialog()
      setSelectedRows([])
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }, [selectedRows])

  const handleClose = () => {
    setOpenForm(false)
    setCurrentItem(undefined)
  }

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const fetchPromos = async (showNewestFirst: boolean = false) => {
    try {
      setLoading(true)
      const data = await getPromos(currentPage + 1, rowsPerPage, search)
      setDataBackup([...data.items])
      setData(data)
      const promo_filters = generateFilters(data.items[0], PROMO_DEFAULTS)
      setFilteredColumns(promo_filters)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenForm = async () => {
    setOpenForm(true)
  }

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(data.items, dataBackup),
    [data, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          key={'addDevice'}
          variant="contained"
          name="addDevice"
          onClick={handleOpenForm}
          disabled={loading}
        >
          Add Promo
        </Button>
      ),
    },
  ]

  useEffect(() => {
    if (currentItem) {
      setOpenForm(true)
    }
  }, [currentItem])

  useEffect(() => {
    fetchPromos()
  }, [search, currentPage, rowsPerPage])

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>

      <MultiSelectToolbar
        show={selectedRows.length > 0}
        itemsSelected={selectedRows.length}
        handleAction={deleteMany}
      />

      <>
        <Table loading={loading}>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={filteredColumns?.filter((c) => c.active)}
            handleSelectAll={handleSelectAll}
          />
          <TableBody>
            {visibleRows.map((item) => (
              <ItemRow
                key={item.id}
                item={item}
                setCurrentItem={setCurrentItem}
                filteredColumns={filteredColumns}
                displayMessage={displayMessage}
                success={fetchPromos}
                handleSelectRow={handleSelectRow}
                selectedRows={selectedRows}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          totalItems={data.total}
          currentPage={currentPage}
          itemsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setRowsPerPage}
        />
      </>

      <SideBar open={openForm} onClose={handleClose}>
        <PromoForm
          onClose={handleClose}
          displayMessage={displayMessage}
          success={fetchPromos}
          promo={currentItem}
        />
      </SideBar>

      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        buttonText={'Ok'}
        type={dialog.type}
      />
    </>
  )
}

export default Promo
