import { Box, Link, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import { userApi } from 'resources'
import { Form, IDialogType } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { Help } from 'components/Help'
import { FormProvider, useForm } from 'react-hook-form'

interface IBulkUploadEntityProps {
  successForm: () => void
  onClose: () => void
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
}

const BulkUploadEntity = ({
  successForm,
  onClose,
  displayMessage,
}: IBulkUploadEntityProps) => {
  const [csvData, setCsvData] = useState<any[]>([])
  const [csvFile, setCsvFile] = useState<any>(null)
  const csvString =
    'name,email,phone_number,user_id,pin_code\n' +
    'James,james@gmail.com,1234567890,1010\n'

  const theme = useTheme()
  const methods = useForm<any>({})

  const { loading: uploadingRows, setLoading } = useLoadingState()
  const { uploadCSV: uploadUsersCSV } = userApi()

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    file ? handleFileUpload({ target: { files: [file] } }) : null
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    try {
      if (file) {
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === 'string') {
            const text = e.target.result
            parseCSV(text)
            setCsvFile(file)
          }
        }
        reader.readAsText(file)
      }
    } catch (error) {
      displayMessage?.("Couldn't read the file. Please try again.", 'error')
    }
  }

  const parseCSV = (csvText: string) => {
    const rows = csvText.split('\n')
    const headerRow = rows.shift()?.split(',')
    const file_list: any[] = []

    if (!headerRow) return
    try {
      rows.map((row) => {
        const rowData = row.split(',')
        const obj: { [key: string]: string } = {}
        headerRow.forEach((key, index) => {
          const cleanKey = key.replace(/"/g, '').trim()
          const cleanValue = rowData[index]?.replace(/"/g, '').trim()
          if (cleanValue) {
            obj[cleanKey] = cleanValue
          }
        })
        file_list.push(obj)
      })

      setCsvData(file_list)
    } catch (error) {
      displayMessage?.("Couldn't read the file. Please try again.", 'error')
    }
  }

  const createCsvBlob = (csvString) => {
    const csvData = Papa.unparse(Papa.parse(csvString).data)
    const blob = new Blob([csvData], { type: 'text/csv' })
    return blob
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let response: any[] = []
      response = await uploadUsersCSV(csvFile)
      const successRows = response.filter((r) => r.detail === true).length
      const errorRows = response.filter((r) => r.detail !== true).length

      displayMessage?.(
        <>
          {successRows > 0 && errorRows > 0 && (
            <>
              <Typography>
                {successRows} row{successRows === 1 ? '' : 's'} was successfully
                added.
              </Typography>
              <Typography>
                {errorRows} row{errorRows === 1 ? '' : 's'} has failed.
              </Typography>{' '}
              <br />
              {response.map((r, index) =>
                r.detail === true ? null : (
                  <Typography key={index}>
                    <strong>Error ocurred on row {index + 1}:</strong>{' '}
                    {r.detail}
                  </Typography>
                ),
              )}
            </>
          )}
          {successRows > 0 && errorRows === 0 && (
            <Typography>
              {successRows} row{`${successRows === 1 ? '' : 's'}`}{' '}
              {successRows === 1 ? 'has' : 'have'} been uploaded successfully!
            </Typography>
          )}
          {errorRows > 0 && successRows === 0 && (
            <>
              <Typography>Upload was unsuccessful!</Typography>
              <br />
              {response
                .filter((r) => r.detail !== true)
                .map((r, index) => (
                  <Typography textAlign="left" key={index}>
                    <strong>Error ocurred on row {index + 1}:</strong>{' '}
                    {r.detail}
                  </Typography>
                ))}
            </>
          )}
        </>,
        successRows === 0 ? 'error' : 'success',
      )
      if (successRows > 0) successForm()
      onClose()
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const downloadCSVTemplate = () => {
    try {
      const csvBlob = createCsvBlob(csvString)

      saveAs(csvBlob, 'user_template.csv')
    } catch (error) {
      displayMessage?.("Couldn't download the file. Please try again.", 'error')
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <FormWrapper>
            <Box sx={{ marginTop: 1 }}>
              <Typography color="text.secondary">
                <strong>Note:</strong> The CSV file must include the following
                headers to ensure proper processing:
              </Typography>
              <ul
                style={{
                  paddingLeft: '20px',
                  color: theme.palette.text.secondary,
                }}
              >
                <li>name</li>
                <li>email</li>
                <li>phone_number</li>
                <li>user_id</li>
                <li>pin_code</li>
              </ul>
              <Typography color="text.secondary">
                Please make sure your CSV file contains these headers in the
                correct format. You can download the template below to get a
                sample CSV file.
              </Typography>

              <Help helpText="CSV files allow bulk upload. You can see items in a list view. Upload your CSV file here to see your data in the table">
                <Link
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={downloadCSVTemplate}
                >
                  Download CSV Template
                </Link>
              </Help>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  border: '2px dashed #aaa',
                  borderRadius: '4px',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  width: '100%',
                }}
              >
                {csvData.length === 0
                  ? 'Drag and drop a CSV file here or click to upload.'
                  : `${csvData.length} row(s) successfully imported. Click the "Submit" button to upload the imported prices.`}
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                />
              </div>
            </Box>
            <FormActions
              onClose={onClose}
              disableSubmit={!csvFile || uploadingRows}
              loading={uploadingRows}
              loadingText="Processing rows..."
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default BulkUploadEntity
