import { CSSProperties, ReactElement, useState } from 'react'
import { Dialog } from '@mui/material'
import {
  LOGO_DISPLAY_WIDTH,
  IMAGE_DEFAULT_DISPLAY_WIDTH,
  LOGO_DISPLAY_HEIGHT,
  IMAGE_DEFAULT_DISPLAY_HEIGHT,
} from '../../constants'

export interface IImageProps {
  src: string
  alt: string
  style?: CSSProperties
  isLogo?: boolean
}

const Image = ({
  src,
  alt,
  style,
  isLogo = false,
}: IImageProps): ReactElement => {
  const [open, setOpen] = useState(false)

  const getWidth = () => {
    const { width } = style || {}
    if (isLogo) {
      return style && width ? width : LOGO_DISPLAY_WIDTH
    } else {
      return style && width ? width : IMAGE_DEFAULT_DISPLAY_WIDTH
    }
  }

  const getHeight = () => {
    const { height } = style || {}
    if (isLogo) {
      return style && height ? height : LOGO_DISPLAY_HEIGHT
    } else {
      return style && height ? height : IMAGE_DEFAULT_DISPLAY_HEIGHT
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <img
          src={src}
          alt="image"
          style={{
            width: 'auto',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Dialog>
      <picture
        style={{
          objectFit: 'contain',
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            ...style,
            width: getWidth(),
            height: getHeight(),
            cursor: 'pointer',
          }}
          onClick={() => alt !== 'bg' && setOpen(true)}
        />
      </picture>
    </>
  )
}

export default Image
