import { useState } from 'react'

const useLoadingState = (initialValue: boolean = false) => {
  const [loading, setLoading] = useState<boolean>(initialValue)

  return {
    loading,
    setLoading,
  }
}

export default useLoadingState
