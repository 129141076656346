import { Autocomplete, TextField } from '@mui/material'
import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import { DEVICES_PER_PAGE, DEFAULT_PAGE } from '../../constants'
import useColumnFiltering from 'hooks/useColumnFiltering'
import useDialog from 'hooks/useDialog'
import useSortableHeader from 'hooks/useSortableHeader'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ReportRow from './components/ReportRow'
import ReportForm from './components/ReportForm'
import { IMember, IOrganization } from 'models'
import { memberApi, organizationApi, reportApi } from 'resources'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import { IReport, IReportList } from 'models/Report'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { DropdownOption, ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'

const Reporting = () => {
  const [reports, setReports] = useState<IReportList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [reportsBackup, setReportsBackup] = useState<IReport[]>([])
  const [members, setMembers] = useState<IMember[]>([])
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEVICES_PER_PAGE)
  const [search, setSearch] = useState<string>('')
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [currentReport, setCurrentReport] = useState<IReport | undefined>()
  const [subOrgs, setSubOrgs] = useState<IOrganization[]>([])
  const [selectedSubOrg, setSelectedSubOrg] = useState<string | null>(null)

  const { dialog, displayMessage, closeDialog } = useDialog()

  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'reporting',
      nestedProps: [
        {
          columnValue: 'assignee',
          path: 'assignee.name',
          defaultValue: '',
        },
      ],
    })

  const { getMany } = memberApi()
  const { getAllReports, remove } = reportApi()
  const { getOrgs } = organizationApi()

  const { loading, setLoading } = useLoadingState(true)
  const { loading: loadingSubOrgs, setLoading: setLoadingSubOrgs } =
    useLoadingState(true)

  const organizationOptions: DropdownOption[] = useMemo(
    () => [
      ...subOrgs.map((org) => ({
        value: org.id,
        label: org.name,
      })),
    ],
    [subOrgs],
  )

  const handleCloseSidebar = () => {
    setOpenSidebar(false)
    setCurrentReport(undefined)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? reports.items.map((report) => report.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchReports()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchReports = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const reports = await getAllReports(
        currentPage + 1,
        rowsPerPage,
        search,
        selectedSubOrg,
      )
      setReportsBackup([...reports.items])
      setReports(reports)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchSubOrgs = async (): Promise<void> => {
    try {
      setLoadingSubOrgs(true)
      const subOrgs = await getOrgs(1, 1000)
      setSubOrgs(subOrgs.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoadingSubOrgs(false)
    }
  }

  const fetchMembers = async (): Promise<void> => {
    try {
      const members = await getMany(1, 1000)
      setMembers(members.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleEditReport = (report: IReport) => {
    setCurrentReport(report)
    setOpenSidebar(true)
  }

  const handleSelectOrganization = (
    event: React.SyntheticEvent,
    value: {
      value: string
      label: string
    } | null,
  ) => {
    const org = subOrgs.find((o) => o.id === value?.value)
    if (!org) {
      // Get information from the main organization if the user
      // clears out the target selector
      setSelectedSubOrg(null)
    } else {
      setSelectedSubOrg(org.id)
    }
  }

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(reports.items, reportsBackup),
    [reports, order, orderBy],
  )

  useEffect(() => {
    fetchReports()
  }, [search, currentPage, rowsPerPage])

  useEffect(() => {
    fetchReports()
  }, [selectedSubOrg])

  useEffect(() => {
    fetchReports()
    fetchMembers()
    fetchSubOrgs()
    getDefaultFilters()
  }, [])

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          key="1"
          variant="contained"
          name="addSize"
          onClick={() => setOpenSidebar(true)}
          disabled={loading || loadingSubOrgs}
        >
          Create Report
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Autocomplete
          disablePortal
          options={organizationOptions}
          renderInput={(params) => (
            <TextField {...params} label="Organization..." />
          )}
          onChange={handleSelectOrganization}
          size="small"
          disabled={loading || loadingSubOrgs}
        />
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls controls={controls} />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
        />
      )}
      {!loading && reports.items.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((report) => (
                <ReportRow
                  key={report.id}
                  report={report}
                  filteredColumns={filteredColumns.filter((c) => c.active)}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(report.id)}
                  displayMessage={displayMessage}
                  success={fetchReports}
                  handleEditReport={handleEditReport}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={reports.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingTableData isLoading={loading || loadingSubOrgs} />

      <NoData
        condition={!loading && !loadingSubOrgs && reports.items.length === 0}
      />

      {openSidebar && (
        <SideBar open={openSidebar} onClose={handleCloseSidebar}>
          <ReportForm
            report={currentReport}
            onClose={handleCloseSidebar}
            displayMessage={displayMessage}
            success={fetchReports}
            members={members}
          />
        </SideBar>
      )}
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default Reporting
