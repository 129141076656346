import { IconButton as MUIIconButton } from '@mui/material'
import { IIconButtonProps } from 'components'

const IconButton = ({ onClick, disabled, children, sx }: IIconButtonProps) => {
  return (
    <MUIIconButton sx={sx} onClick={onClick} disabled={disabled}>
      {children}
    </MUIIconButton>
  )
}
export default IconButton
