import { ISession } from 'models/Session'
import { API_URL, PUBLIC_API_URL } from '../../../constants'

export const getMyself = async (): Promise<ISession> => {
  const CURRENT_USER: string | null = localStorage.getItem('currentUser')

  const TOKEN: string = CURRENT_USER
    ? JSON.parse(CURRENT_USER).signInUserSession?.idToken?.jwtToken
    : undefined

  if (!TOKEN) {
    throw new Error('No token found')
  }

  const response = await fetch(API_URL + 'members/self', {
    headers: { Authorization: `Bearer ${TOKEN}` },
  })

  return response.json()
}

export const loadSession = async () => {
  const session = await getMyself()
  return session
}
