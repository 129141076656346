/* eslint-disable */
/* prettier-ignore */
import { useEffect, useState} from 'react'
import { Box, TextField } from '@mui/material'
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useLoadScript,
} from '@react-google-maps/api'
import _ from 'lodash'
import { IMapFieldProps } from 'components'
import './MapField.css'
import { GOOGLE_API_KEY } from '../../../../constants'
import { useFormContext } from 'react-hook-form'

const MapField = ({
  currentPosition,
  setCurrentPosition,
  address,
  setAddress,
  isReadOnly = false,
}: IMapFieldProps) => {
  const [maps, setMaps] = useState<google.maps.Map>()
  const [searchBox, setSearchBox] = useState<
    google.maps.places.SearchBox | undefined
  >()
  const [searchParams, setSearchParams] = useState<string>(address || '')

  const { clearErrors, register } = useFormContext()

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY || '',
    libraries: ['places'],
  })

  const onPlacesChanged = () => {
    const places = searchBox?.getPlaces()

    const nextMarkers = places?.map((place, index) => ({
      position: place?.geometry?.location,
      address: place.formatted_address,
      index,
    }))
    const nextCenter = _.get(nextMarkers, '0.position', currentPosition)
    setCurrentPosition({ lat: nextCenter.lat(), lng: nextCenter.lng() })
    if (nextMarkers?.length === 1) setAddress(nextMarkers[0].address)
  }

  const onMarkerDragEnd = async (e) => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    await setCurrentPosition({ lat, lng })
    // fetching the currentAddress

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`,
    )
    const data = await response.json()
    await setAddress(
      data.results[0]?.formatted_address
        ? data.results[0]?.formatted_address
        : '',
    )
  }

  const handleLoad = (ref) => {
    setSearchBox(ref)
  }

  useEffect(() => {
    if (address) {
      clearErrors('address')
    }
  }, [address])

  return (
    <Box style={{ position: 'relative' }}>
      {isLoaded && (
        <Box sx={{ width: '100%' }}>
          <Box className="assets-edit-id" sx={{ marginBottom: '2rem' }}>
            {!isReadOnly && (
              <StandaloneSearchBox
                onLoad={(ref) => handleLoad(ref)}
                onPlacesChanged={onPlacesChanged}
              >
                <TextField
                  {...register('address', {
                    required: 'Address required',
                  })}
                  fullWidth
                  name="address"
                  label="Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value)
                  }}
                  type="text"
                  placeholder="Search the address"
                  disabled={isReadOnly}
                />
              </StandaloneSearchBox>
            )}
            {isReadOnly && (
              <TextField
                {...register('address', {
                  required: 'Address required',
                })}
                fullWidth
                name="address"
                label="Address"
                value={address}
                onChange={async (e) => {
                  setAddress(e.target.value)
                }}
                type="text"
                placeholder="Search the address"
                required
                disabled={isReadOnly}
              />
            )}
          </Box>
          <GoogleMap
            onLoad={(ref) => setMaps(ref)}
            options={{ mapId: '730db22e84fe0212' }}
            mapContainerStyle={{ height: '38vh', width: '100%' }}
            zoom={10}
            center={currentPosition}
          >
            <Marker
              position={currentPosition}
              onDragEnd={(e) => onMarkerDragEnd(e)}
              draggable
            />
          </GoogleMap>
        </Box>
      )}
    </Box>
  )
}

export default MapField
