import { HTTP_CODES } from 'constants/HTTP'
import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'

const GET_ERRORS = {
  [HTTP_CODES.NOT_FOUND]: 'No API Keys found',
  [HTTP_CODES.INTERNAL_SERVER_ERROR]: 'An internal error occurred',
}

const apikeysApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const get = async () => {
    try {
      const response = await fetchPartnerEndpoint({
        endpoint: 'api-keys',
        method: 'GET',
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const createApiKey = async () => {
    try {
      const response = await fetchPartnerEndpoint({
        endpoint: 'api-keys',
        method: 'POST',
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    get,
    createApiKey,
  }
}

export default apikeysApi
