import { Typography } from '@mui/material'
import { ILocation } from 'models'
import {
  IAverageRevenuePerTransaction,
  IDoorHealth,
  IEarnings,
  INewUserGrowth,
  IOccupancyRate,
  IReportedIssueRate,
  ITotalDoors,
  ITotalLocations,
  ITotalTransactions,
  ITransactionRate,
  ITransactionsPerLocker,
} from 'models/Analytics'
import { average, parseCurrency, secondsToTimeFormat } from 'utils/helpers'

export const parseEarningsValue = (earningsObject: IEarnings) => {
  if (typeof earningsObject !== 'string') {
    const { currency, earnings } = earningsObject
    return `${parseCurrency(currency)} ${earnings}`
  }
  return 'No data found'
}

export const getPercentajeWithColor = (percentaje: number): JSX.Element => {
  return percentaje < 0 ? (
    <Typography color="#c4474b">{percentaje.toFixed(2)}%</Typography>
  ) : percentaje > 0 ? (
    <Typography color="#2b8247">+{percentaje.toFixed(2)}%</Typography>
  ) : (
    <Typography>{percentaje.toFixed(2)}%</Typography>
  )
}

export const parseNewUserGrowthValue = (newUserGrowth: INewUserGrowth) => {
  if (typeof newUserGrowth !== 'string') {
    const { percentage } = newUserGrowth
    return getPercentajeWithColor(percentage)
  }
  return 'No data found'
}

export const parseDoorHealthValue = (
  doorHealth: IDoorHealth[],
  selectedLocation?: string,
) => {
  if (typeof doorHealth !== 'string') {
    if (selectedLocation) {
      const percentage = doorHealth.find(
        (location) => location.location_id === selectedLocation,
      )?.health_percentage
      return `${percentage?.toFixed(2)}%`
    } else {
      const averageValue = average(
        doorHealth.map((location) => location.health_percentage),
      )
      return `${averageValue.toFixed(2)}%`
    }
  }
  return 'No data found'
}

export const parseReportedIssuesRateValue = (
  reportedIssuesRate: IReportedIssueRate,
) => {
  if (typeof reportedIssuesRate !== 'string') {
    const { issue_rate } = reportedIssuesRate
    return `${issue_rate.toFixed(2)}%`
  }
  return 'No data found'
}

export const parseOccupancyRateValue = (
  occupancyRate: IOccupancyRate[],
  selectedLocation?: string,
) => {
  if (typeof occupancyRate !== 'string') {
    if (selectedLocation) {
      const percentage = occupancyRate.find(
        (location) => location.id === selectedLocation,
      )?.occupancy_rate
      return `${percentage?.toFixed(2) || '0.00'}%`
    } else {
      const averageValue = average(
        occupancyRate.map((item) => item.occupancy_rate),
      )
      return `${averageValue.toFixed(2)}%`
    }
  }
  return 'No data found'
}

export const parseNewTransactionsValue = (newTransaction: ITransactionRate) => {
  if (typeof newTransaction !== 'string') {
    const { new_transaction_percentage } = newTransaction
    return getPercentajeWithColor(new_transaction_percentage)
  }
  return 'No data found'
}

export const parseTransactionPerLockerValue = (
  transactionsPerLocker: ITransactionsPerLocker,
) => {
  if (typeof transactionsPerLocker !== 'string') {
    const { avg_transactions_per_locker } = transactionsPerLocker
    return `${avg_transactions_per_locker.toFixed(2) || '0.00'}%`
  }
  return 'No data found'
}

export const parseTotalTransactionsValue = (
  totalTransactions: ITotalTransactions,
) => {
  if (typeof totalTransactions !== 'string') {
    return `${totalTransactions.breakdown.organization_total}`
  }
  return 'No data found'
}

export const parseTransactionTimeAverageValue = (
  transactionTimeAverage: number | string,
) => {
  if (typeof transactionTimeAverage !== 'string') {
    return `${secondsToTimeFormat(transactionTimeAverage as number)}`
  }
  return 'No data found'
}

export const parseAverageRevenuePerTransactionValue = (
  averageRevenuePerTransaction: IAverageRevenuePerTransaction,
) => {
  if (typeof averageRevenuePerTransaction !== 'string') {
    const { avg_revenue_per_transaction } = averageRevenuePerTransaction
    return `${avg_revenue_per_transaction.toFixed(2)}%`
  }
  return 'No data found'
}

export const parseTotalUsersValue = (totalUsers: number | string) => {
  if (typeof totalUsers !== 'string') {
    return `${totalUsers}`
  }
  return 'No data found'
}

export const parseTotalLocationsValue = (totalLocations: ITotalLocations) => {
  if (typeof totalLocations !== 'string') {
    const { main_org_total_locations } = totalLocations
    return `${main_org_total_locations}`
  }
  return 'No data found'
}

export const parseTotalDoorsValue = (
  totalDoors: ITotalDoors,
  selectedLocation?: string,
) => {
  if (typeof totalDoors !== 'string') {
    if (selectedLocation && selectedLocation !== 'all') {
      return `${totalDoors.main_org.door_counts_by_location[selectedLocation]}`
    }
    return `${totalDoors.main_org.main_org_total_doors}`
  }
  return 'No data found'
}

export const getTodayStart = (): Date => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

export const getThisWeekStart = (): Date => {
  const today = new Date()
  const currentWeek = new Date(today.setDate(today.getDate() - today.getDay()))
  currentWeek.setHours(0, 0, 0, 0)
  return currentWeek
}

export const getThisMonthStart = (): Date => {
  const today = new Date()
  const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  currentMonth.setHours(0, 0, 0, 0)
  return currentMonth
}

export const getThisQuarterStart = (): Date => {
  const today = new Date()
  const quarterNumber = Math.floor(today.getMonth() / 3)
  const year = today.getFullYear()
  const currentQuarter = new Date(year, quarterNumber * 3, 1)
  currentQuarter.setHours(0, 0, 0, 0)
  return currentQuarter
}

export const getThisYearStart = (): Date => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const firstDay = new Date(currentYear, 0, 1)
  return firstDay
}

export const parseOptionToDateRange = (selectedRange: string): Date | null => {
  switch (selectedRange) {
    case 'today':
      return getTodayStart()
    case 'this_week':
      return getThisWeekStart()
    case 'this_month':
      return getThisMonthStart()
    case 'this_quarter':
      return getThisQuarterStart()
    case 'this_year':
      return getThisYearStart()
    default:
      return null
  }
}

export const parseOptionToInterval = (selectedRange: string): string => {
  switch (selectedRange) {
    case 'today':
      return 'day'
    case 'this_week':
      return 'week'
    case 'this_month':
      return 'month'
    case 'this_quarter':
      return 'month'
    case 'this_year':
      return 'year'
    default:
      return ''
  }
}
