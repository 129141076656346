import { Typography } from '@mui/material'
import { Dialog, Form, IDialogType, TextField } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { ITransaction } from 'models'
import { useEffect } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { transactionsApi } from 'resources'

interface IRefundDialogProps {
  open: boolean
  onClose: () => void
  transaction: ITransaction
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
}

const RefundDialog = ({
  open,
  onClose,
  transaction,
  displayMessage,
  success,
}: IRefundDialogProps) => {
  const { loading, setLoading } = useLoadingState()
  const { refund } = transactionsApi()

  const onRefund = async (amount: number, currency: string): Promise<void> => {
    try {
      setLoading(true)
      await refund({ amount, transactionId: transaction.id })
      success()
      displayMessage('Transaction refunded successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<{ amount: number; currency: string }> = (
    newRefund,
  ) => {
    onRefund(
      newRefund.amount,
      transaction.device?.price?.currency || newRefund.currency,
    )

    onClose()
  }

  const methods = useForm()

  useEffect(() => {
    const transactionCurrency = transaction.device?.price?.currency

    if (transactionCurrency) {
      methods.setValue('currency', transactionCurrency)
    }
  }, [transaction])

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Refund" isDialog={true}>
            <Typography textAlign="center" variant="h5" color="text.secondary">
              Choose the amount you want to refund
            </Typography>
            <TextField
              name="amount"
              label="Amount"
              placeholder={
                transaction.total ? transaction.total.toString() : '0'
              }
              rules={{
                required: 'Amount required',
                max: {
                  value: transaction.total,
                  message:
                    'Refund amount cannot be greater than the paid amount.',
                },
              }}
              isNumeric={true}
            />

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={
                !methods.watch('amount') || !methods.watch('currency')
              }
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
export default RefundDialog
