import { Button as MUIButton } from '@mui/material'
import { ButtonProps } from 'components'

export const Button = ({
  name,
  disabled,
  onClick,
  variant,
  children,
  color,
  sx,
  type,
}: ButtonProps) => {
  return (
    <MUIButton
      name={name}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
      type={type}
      color={color}
    >
      {children}
    </MUIButton>
  )
}
export default Button
