import { useState } from 'react'
import { Link, Checkbox, Tooltip, Chip, Box } from '@mui/material'
import { DeleteForever, ContentCopy } from '@mui/icons-material'
import {
  TableCell,
  TableRow,
  IconButton,
  ConfirmDialog,
  IDialogType,
} from 'components'
import { INotification } from 'models/Notification'
import { notificationApi } from 'resources/notification'
import {
  capitalizeFirstLetter,
  getUserRole,
  snakeCaseToHumanReadable,
} from 'utils/helpers'

const role = getUserRole()
interface INotificationRowProps {
  notification: INotification
  success: () => void
  filteredColumns: any[]
  handleOpen: () => void
  setCurrentNotification: React.Dispatch<
    React.SetStateAction<INotification | undefined>
  >
  displayMessage: (message: string, type?: IDialogType) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

const NotificationRow = ({
  notification,
  success,
  filteredColumns,
  handleOpen,
  setCurrentNotification,
  displayMessage,
  handleSelectRow,
  selected,
}: INotificationRowProps): React.ReactElement => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)

  const { create, update, remove } = notificationApi()

  const handleDelete = async (): Promise<void> => {
    try {
      await remove([notification.id])
      displayMessage('Notification deleted successfully!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleDuplicate = async (): Promise<void> => {
    try {
      await create({
        ...notification,
        name: `${notification.name} (copy)`,
        is_template: false,
      })
      displayMessage(`Notification duplicated successfully!`, 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleCheck = async (type: string) => {
    try {
      await update(notification.id, {
        ...notification,
        [type]: !notification[type],
        locations: notification.locations.map((location) => location.id),
      })
      displayMessage('Notification updated successfully!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleConfirmAction = async (action: string) => {
    switch (action) {
      case 'delete':
        await handleDelete()
        break
      case 'duplicate':
        await handleDuplicate()
        break
      default:
        return
    }
  }

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, notification.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
          -1 && (
          <TableCell align="center">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentNotification(notification)
                  handleOpen()
                }}
              >
                {notification.name}
              </Link>
              {notification.is_template && (
                <Tooltip title="This is a default notification.">
                  <Chip color="default" label="Default" size="small" />
                </Tooltip>
              )}
            </Box>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'mode' && c.active) !==
          -1 && (
          <TableCell align="center">
            {notification.mode === 'rental'
              ? 'Asset'
              : capitalizeFirstLetter(notification.mode)}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'type' && c.active) !==
          -1 && (
          <TableCell align="center">
            {notification.notification_type &&
              snakeCaseToHumanReadable(notification.notification_type)}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            {notification.is_template && (
              <IconButton
                onClick={() => {
                  setConfirmationMessage({
                    isOpen: true,
                    message: `Are you sure you want to create a copy of this notification?`,
                    action: 'duplicate',
                  })
                }}
              >
                <Tooltip title="Duplicate">
                  <ContentCopy />
                </Tooltip>
              </IconButton>
            )}
            <IconButton
              disabled={notification.is_template}
              onClick={() => {
                setConfirmationMessage({
                  isOpen: true,
                  message: `Are you sure you want to delete this notification? This cannot be undone.`,
                  action: 'delete',
                })
              }}
            >
              <DeleteForever />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={() => handleConfirmAction(confirmationMessage.action)}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default NotificationRow
