import { ReactElement, useState } from 'react'
import { IChangePassword, IForgotPassword } from 'models'
import { Box, Typography } from '@mui/material'
import { ResetPasswordFormStyles } from './styles'
import {
  RESET_PASSWORD_FORM_FIELDS,
  RESET_PASSWORD_FORM,
} from '../../../constants'
import {
  Form,
  TextField,
  Button,
  ResetPasswordFields,
  NotificationDialog,
} from 'components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import useDialog from 'hooks/useDialog'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'resources'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
const currentUserData = localStorage.getItem('currentUser')
const isFirstLogin = localStorage.getItem('firstTimeLogin') === 'true'

const ResetPasswordForm = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [resetingPassword, setResetingPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false)

  const { SX, STYLES } = ResetPasswordFormStyles()
  const navigate = useNavigate()

  const methods = useForm<IChangePassword>()
  const { dialog, displayMessage, closeDialog } = useDialog()
  const { forgotPassword, forgotPasswordSubmit, completePassword } = useAuth()

  const sendCode = async ({ username }: IForgotPassword): Promise<void> => {
    try {
      await forgotPassword({ username })
      displayMessage('Verification code bas been sent to your email')
      setIsCodeSent(true)
    } catch (error) {
      setError(`${(error as Error).message}`)
      displayMessage(`${(error as Error).message}`)
    }
  }

  const changePassword = async ({
    username,
    code,
    password,
  }: IChangePassword): Promise<void> => {
    try {
      if (code === '') {
        await completePassword({ email: username, newPassword: password })
      } else {
        await forgotPasswordSubmit({ username, code, password })
        displayMessage('Password changed successfully')
      }
    } catch (error) {
      setError(`${(error as Error).message}`)
      displayMessage(`${(error as Error).message}`)
    }
  }

  const onSubmit: SubmitHandler<IChangePassword> = (newPassword): void => {
    if (error) {
      setError('')
    }
    setLoading(true)
    try {
      if (isFirstLogin && currentUserData) {
        const email =
          JSON.parse(currentUserData).challengeParam.userAttributes.email
        changePassword({
          username: email.toLowerCase().trim(),
          code: '',
          password: newPassword.password,
        })
        return
      }
      if (isCodeSent) {
        setResetingPassword(true)
        changePassword({
          username: newPassword.username.toLowerCase().trim(),
          code: newPassword.code,
          password: newPassword.password,
        })
      } else {
        sendCode({ username: newPassword.username })
      }
    } catch (error) {
      setError(`${(error as Error).message}`)
      displayMessage(`${(error as Error).message}`)
    } finally {
      setLoading(false)
    }
  }

  const TITLE_LABEL: string = isCodeSent
    ? RESET_PASSWORD_FORM.FORGOT_TITLE
    : RESET_PASSWORD_FORM.RESET_TITLE

  const BUTTON_LABEL: string =
    isCodeSent || isFirstLogin
      ? RESET_PASSWORD_FORM.SUBMIT_BUTTON_LABEL
      : RESET_PASSWORD_FORM.SEND_CODE_BUTTON_LABEL

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <Box sx={SX.CONTAINER}>
          <Button
            onClick={() => {
              navigate('/login')
            }}
          >
            <ArrowBackIosIcon />
            back
          </Button>
          <Typography sx={SX.TITLE}>{TITLE_LABEL}</Typography>
          <Typography sx={SX.DESCRIPTION}>
            {RESET_PASSWORD_FORM.DESCRIPTION}
          </Typography>
          <Box sx={SX.FIELDS_CONTAINER}>
            {!isFirstLogin && (
              <TextField
                name={RESET_PASSWORD_FORM_FIELDS.USERNAME.NAME}
                label={RESET_PASSWORD_FORM_FIELDS.USERNAME.LABEL}
                placeholder={RESET_PASSWORD_FORM_FIELDS.USERNAME.PLACEHOLDER}
                rules={RESET_PASSWORD_FORM_FIELDS.USERNAME.RULES}
              />
            )}
            <ResetPasswordFields
              isCodeSent={isCodeSent}
              isFirstLogin={isFirstLogin}
            />
          </Box>
          <Box sx={SX.BUTTONS_CONTAINER}>
            <Button disabled={loading} variant="contained" type="submit">
              {BUTTON_LABEL}
            </Button>
          </Box>
        </Box>
      </Form>
      <NotificationDialog
        message={dialog.message}
        open={dialog.isOpen}
        onClose={() => {
          closeDialog()
          if (!error && resetingPassword) {
            navigate('/login')
          }
        }}
      />
    </FormProvider>
  )
}
export default ResetPasswordForm
