import { DeleteForever } from '@mui/icons-material'
import { Checkbox, Link, Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  TableCell,
  TableRow,
} from 'components'
import { IReport } from 'models/Report'
import { useState } from 'react'
import { reportApi } from 'resources'
import { getUserRole, parseSnakeCaseToTitleCase } from 'utils/helpers'
import { SummarizedText } from 'components/SummarizedText'

interface IReportRowProps {
  report: IReport
  filteredColumns: any[]
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
  handleEditReport: (report: IReport) => void
}

const ReportRow = ({
  report,
  filteredColumns,
  handleSelectRow,
  selected,
  displayMessage,
  success,
  handleEditReport,
}: IReportRowProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const { remove, downloadReport } = reportApi()

  const role = getUserRole()

  const handleDeleteReport = async () => {
    try {
      await remove([report.id])
      displayMessage('Report deleted successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleDownloadReport = async () => {
    try {
      const file = await downloadReport(report.id)
      const element = document.getElementById(`${report.id}-download`)

      if (element && element instanceof HTMLAnchorElement) {
        element.setAttribute('download', `${report.id}-report.csv`)
        const href = URL.createObjectURL(file)
        element.href = href
        element.setAttribute('target', '_blank')
        element.click()
        URL.revokeObjectURL(href)
        displayMessage('Report downloaded successfully', 'success')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const formatContents = () =>
    report.contents
      .map((content) => parseSnakeCaseToTitleCase(content))
      .join(', ')

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, report.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
          -1 && (
          <TableCell align="center">
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => handleEditReport(report)}
            >
              {report.name}
            </Link>
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'contents' && c.active,
        ) !== -1 && <TableCell align="center">{formatContents()}</TableCell>}
        {filteredColumns.findIndex(
          (c) => c.value === 'assignees' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            <SummarizedText
              text={report.assignees.map((member) => member.name).join(', ')}
            />
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'last_sent' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {report.last_sent ? (
              new Date(report.last_sent).toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            ) : (
              <>Report not sent yet</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'previous' && c.active,
        ) !== -1 && <TableCell align="center">-</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            <a id={`${report.id}-download`}>
              <IconButton onClick={handleDownloadReport}>
                <Tooltip title="Download Report">
                  <DownloadIcon />
                </Tooltip>
              </IconButton>
            </a>
            <IconButton
              onClick={() => {
                setConfirmationMessage({
                  isOpen: true,
                  message: 'Are you sure you want to delete this report?',
                })
              }}
            >
              <Tooltip title="Delete">
                <DeleteForever />
              </Tooltip>
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        onClickConfirm={handleDeleteReport}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ReportRow
