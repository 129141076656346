import { DeleteForever, QrCode } from '@mui/icons-material'
import { Checkbox, Link, Tooltip, Typography } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  TableCell,
  TableRow,
} from 'components'
import { IProductGroup } from 'models/ProductGroup'
import { useMemo, useState } from 'react'
import productGroupApi from 'resources/product-group/api/productGroupApi'
import { getUserRole } from 'utils/helpers'

interface IProductGroupRowProps {
  group: IProductGroup
  success: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  filteredColumns: any[]
  handleEditGroup: (product: IProductGroup) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const ProductGroupRow = ({
  group,
  success,
  displayMessage,
  filteredColumns,
  handleEditGroup,
  handleSelectRow,
  selected,
}: IProductGroupRowProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })
  const { remove } = productGroupApi()
  const role = getUserRole()

  const products = useMemo(() => {
    const skus = group.products.map((product) => product.sku)
    const legend = skus.slice(0, 2).join(', ')
    return legend
  }, [group])

  const handleRemoveGroup = async () => {
    try {
      await remove([group?.id])
      displayMessage('Product group deleted successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, group.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'group_name' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => handleEditGroup(group)}
              >
                {group?.name}
              </Link>
            ) : (
              <>{group?.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'locker_size' && c.active,
        ) !== -1 && (
          <TableCell align="center">{group.size?.name || '-'}</TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'total_inventory' && c.active,
        ) !== -1 && (
          <TableCell align="center">{group?.products.length}</TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'sku' && c.active) !==
          -1 && (
          <TableCell align="center">
            {products}
            {group.products.length > 2 && (
              <Tooltip
                sx={{ cursor: 'pointer' }}
                title={group.products
                  .slice(2, group.products.length)
                  .map((product) => product.sku)
                  .join(', ')}
              >
                <Typography variant="caption" color="text.secondary">
                  {`  +${group.products.length - 2} more`}
                </Typography>
              </Tooltip>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'qr_code' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={() => {}}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <QrCode />
            </IconButton>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={() => {
                setConfirmationMessage({
                  isOpen: true,
                  message:
                    'Are you sure you want to delete this product group?',
                })
              }}
            >
              <Tooltip title="Delete">
                <DeleteForever />
              </Tooltip>
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        onClickConfirm={handleRemoveGroup}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ProductGroupRow
