import { Box, CircularProgress, Typography } from '@mui/material'

interface ILoadingFormData {
  loadingText?: string
}

const LoadingFormData = ({ loadingText = 'Loading...' }: ILoadingFormData) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        minHeight: '700px',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <CircularProgress size={80} color="primary" />
      <Typography fontSize={12} color="text.secondary">
        {loadingText}
      </Typography>
    </Box>
  )
}

export default LoadingFormData
