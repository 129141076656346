import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getCurrentOrgProperty } from 'utils/helpers'

import KoloniBlack from '../../../assets/images/logos/koloni-logo-black.png'
import KoloniWhite from '../../../assets/images/logos/koloni-logo-white.png'

const LogoIcon = () => {
  const customizer = useSelector((state) => state.CustomizerReducer)

  const oemLogo = getCurrentOrgProperty('oem_logo')

  return (
    <Link underline="none" to="/">
      <picture
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '40px',
          objectFit: 'fit',
        }}
      >
        <img
          src={
            customizer.activeMode === 'dark'
              ? oemLogo || KoloniWhite
              : oemLogo || KoloniBlack
          }
          alt="Koloni Logo"
        />
      </picture>
    </Link>
  )
}

export default LogoIcon
