import { ButtonProps } from 'components'

/* eslint-disable no-unused-vars */
export enum BUTTON_VARIANT {
  text = 'text',
  outlined = 'outlined',
  contained = 'contained',
}

export interface IButton
  extends Pick<ButtonProps, 'name' | 'children' | 'variant' | 'color'> {}

export interface ISubmitButton extends Exclude<IButton, 'name'> {}

export type FormButtons = {
  SUBMIT: ISubmitButton
  CANCEL: IButton
}
