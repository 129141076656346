import { Autocomplete, TextField } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'
import { ErrorMessage, IAutocompleteFieldProps } from 'components'

const AutocompleteField = ({
  name,
  label,
  options,
  rules,
}: IAutocompleteFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Controller
        render={({ field: { onChange } }) => (
          <Autocomplete
            options={options}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={(_, item) => onChange(item?.value)}
          />
        )}
        name={name}
        control={control}
        rules={rules}
      />
      <ErrorMessage errors={errors} name={name} />
    </>
  )
}
export default AutocompleteField
