import React, { useState } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import { Dialog, Button, IDeleteDialogProps } from 'components'
import { a } from 'react-spring'

const DeleteDialog = ({
  title,
  description,
  rule,
  onClickCancel,
  onClickConfirm,
  open,
  onClose,
}: IDeleteDialogProps) => {
  const [confirm, setConfirm] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  const handleConfirm = () => {
    if (confirm === rule) {
      setError(false)
      onClickConfirm()
      setConfirm('')
    } else {
      setError(true)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          minWidth: '400px',
          minHeight: '200px',
        }}
      >
        <Typography
          variant="h3"
          component="h3"
          sx={{
            marginBottom: '1rem',
          }}
        >
          {title}
        </Typography>

        <Typography variant="h6" component="h3">
          {description}
        </Typography>

        <Typography variant="h6" component="h3">
          Write <strong>&quot;{rule}&quot;</strong> to confirm
        </Typography>

        <TextField
          sx={{
            marginTop: '1rem',
          }}
          label={rule}
          value={confirm}
          onChange={(e) => setConfirm(e.target.value)}
          type="text"
          placeholder={`Write ${rule} to confirm`}
          error={error ? true : false}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            padding: '1rem 0',
            gap: '0 15px',
          }}
        >
          <Button variant="contained" onClick={() => handleConfirm()}>
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => onClickCancel()}
            color="cancel"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
export default DeleteDialog
