import { Navigate } from 'react-router'

const ProtectedRoute = ({ restricted, children }) => {
  try {
    if (restricted) {
      return <Navigate to="/home" replace />
    }
    return children
  } catch (error) {
    return null
  }
}

export default ProtectedRoute
