import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Dialog, Button, INotificationDialogProps } from 'components'
import {
  ERROR_DIALOG,
  INFO_DIALOG,
  SUCCESS_DIALOG,
  WARNING_DIALOG,
} from '../../../constants'
import { capitalizeFirstLetter } from 'utils/helpers'

const NotificationDialog = ({
  open,
  onClose,
  message,
  buttonText = 'Ok',
  type = '',
  customTitle = null,
}: INotificationDialogProps) => {
  const theme = useTheme()

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const getTypeColor = () => {
    switch (type) {
      case 'success':
        return SUCCESS_DIALOG
      case 'error':
        return ERROR_DIALOG
      case 'warning':
        return WARNING_DIALOG
      case 'info':
        return INFO_DIALOG
      case 'welcome':
        return theme.palette.primary.main
      default:
        return ''
    }
  }

  return (
    <Dialog open={open} onClose={onClose} type={type}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          padding: smDown ? '0 2rem 2rem 2rem' : 0,
          width: '100%',
        }}
      >
        {type && !customTitle && (
          <Typography variant="h2" color={getTypeColor()}>
            {capitalizeFirstLetter(type)}
          </Typography>
        )}
        {customTitle && (
          <Typography
            variant="h1"
            color={customTitle.titleColor}
            sx={{
              fontWeight: 'bold',
            }}
          >
            {customTitle.titleText}
          </Typography>
        )}
        <Typography>{message}</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            sx={{ width: smDown ? '100%' : '40%' }}
            variant="contained"
            onClick={() => onClose()}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
export default NotificationDialog
