import { IButton, BUTTON_VARIANT } from 'types/button'

export const STRIPE_BUTTONS: { [key: string]: IButton } = {
  CONNECT: {
    children: 'Create Stripe Account',
    name: 'createStripeAccount',
    variant: BUTTON_VARIANT.contained,
  },
  LINK: {
    children: 'Link Stripe Account',
    name: 'linkStripeAccount',
    variant: BUTTON_VARIANT.contained,
  },
}
