import { useState } from 'react'
import {
  TableCell,
  TableRow,
  IconButton,
  QRGenerator,
  Image,
  ConfirmDialog,
  IDialogType,
} from 'components'
import { Box, Checkbox, Chip, Link, Tooltip } from '@mui/material'
import { DeleteForever, QrCode } from '@mui/icons-material'
import { ILocation } from 'models'
import { RENTAL_URL } from '../../../constants'
import { locationApi } from 'resources'
import { getUserRole } from 'utils/helpers'
import useIsSuperTenantOrg from 'hooks/useIsSuperTenantOrg'
import { SummarizedText } from 'components/SummarizedText'
import { LocationsContext, useLocationsContext } from '../context'

interface ILocationRowProps {
  location: ILocation
  handleOpen: () => void
  setCurrentLocation: (location: ILocation) => void
  filteredColumns: any[]
  displayMessage: (message: string, type?: IDialogType) => void
  setCreatingLocation: (value: boolean) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

const LocationRow = ({
  location,
  handleOpen,
  setCurrentLocation,
  filteredColumns,
  displayMessage,
  setCreatingLocation,
  handleSelectRow,
  selected,
}: ILocationRowProps): React.ReactElement => {
  const ORG_DATA: string | null = localStorage.getItem('currentOrg')
  const ORG_NAME: string = ORG_DATA ? JSON.parse(ORG_DATA).name : undefined
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)

  const { fetchLocations } = useLocationsContext()
  const isSuperTenantOrg = useIsSuperTenantOrg()
  const { generateQRCode } = QRGenerator()
  const { patch, remove } = locationApi()

  const handleDeleteLocation = async (): Promise<void> => {
    try {
      await remove([location.id])
      fetchLocations()
      displayMessage('Location deleted successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleHideLocation = async (): Promise<void> => {
    try {
      await patch(location.id, { hidden: !location.hidden })
      fetchLocations()
      displayMessage('Saved successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  // const handleShareLocation = async (): Promise<void> => {
  //   try {
  //     await patch(location.id, { shared: !location.shared })
  //     success()
  //     displayMessage('Saved successfully!', 'success')
  //   } catch (error) {
  //     displayMessage(`${(error as Error).message}`, 'error')
  //   }
  // }

  const handleQRCode = ({
    locationId,
    orgName,
  }: {
    locationId: string
    orgName: string
  }): void => {
    generateQRCode(
      locationId,
      `${RENTAL_URL}?location=${locationId}&org=${orgName}`,
    )
  }

  const handleConfirmAction = async (action: string) => {
    switch (action) {
      case 'delete':
        await handleDeleteLocation()
        break
      case 'hide':
        await handleHideLocation()
        break
      // case 'share':
      //   await handleShareLocation()
      //   break
      default:
        return
    }
  }

  const label = { inputProps: { 'aria-label': 'Hide Location' } }
  const role = getUserRole()

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, location.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
          -1 && (
          <TableCell align="center">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              {role !== 'member' ? (
                <Link
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleOpen()
                    setCurrentLocation(location)
                    setCreatingLocation(false)
                  }}
                >
                  {location.name}
                </Link>
              ) : (
                <>{location.name}</>
              )}
              {/* {location.shared && (
                <Tooltip title="This device is a shared resource">
                  <Chip color="default" label="Shared" size="small" />
                </Tooltip>
              )} */}
            </Box>
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'no_of_devices' && c.active,
        ) !== -1 && (
          <TableCell align="center">{location.devices?.length}</TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'address' && c.active) !==
          -1 && (
          <TableCell align="center">
            <SummarizedText text={location.address} />
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'image' && c.active) !==
          -1 && (
          <TableCell align="center">
            {location.image && (
              <Image
                src={location.image}
                alt="Location Image"
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                }}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'hidden' && c.active) !==
          -1 && (
          <TableCell align="center">
            {isSuperTenantOrg ? (
              <Checkbox
                {...label}
                name="hidden"
                checked={location.hidden}
                onChange={() => {
                  setConfirmationMessage({
                    isOpen: true,
                    message: `Are you sure you want to ${
                      location.hidden ? 'unhide' : 'hide'
                    } this location?`,
                    action: 'hide',
                  })
                }}
              />
            ) : (
              <>{location.hidden ? 'Yes' : 'No'}</>
            )}
          </TableCell>
        )}
        {/* {filteredColumns.findIndex((c) => c.value === 'shared' && c.active) !==
          -1 && (
          <TableCell align="center">
            {isSuperTenantOrg ? (
              <Checkbox
                {...label}
                name="shared"
                checked={location.shared}
                onChange={() => {
                  setConfirmationMessage({
                    isOpen: true,
                    message: `Are you sure you want to ${
                      location.hidden ? 'unshare' : 'share'
                    } this location?`,
                    action: 'share',
                  })
                }}
              />
            ) : (
              <>{location.shared ? 'Yes' : 'No'}</>
            )}
          </TableCell>
        )} */}
        {filteredColumns.findIndex((c) => c.value === 'qrCode' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={() => {
                handleQRCode({ locationId: location.id, orgName: ORG_NAME })
              }}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <QrCode />
            </IconButton>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <IconButton
                onClick={() => {
                  setConfirmationMessage({
                    isOpen: true,
                    message: `Are you sure you want to delete this location? This cannot be undone.`,
                    action: 'delete',
                  })
                }}
              >
                <DeleteForever />
              </IconButton>
            ) : (
              <></>
            )}
          </TableCell>
        )}
      </TableRow>

      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={() => handleConfirmAction(confirmationMessage.action)}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default LocationRow
