import { ChangeEvent, useState, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import {
  Table,
  TableBody,
  TablePagination,
  Button,
  SideBar,
  NotificationDialog,
} from 'components'
import LocationRow from './components/LocationRow'
import { ILocation } from 'models'
import { locationApi } from 'resources'
import LocationForm from './components/LocationForm'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import MapView from './components/Map/MapView'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLocations from 'hooks/useLocations'
import { getPaginatedSlice } from 'utils/helpers'
import { LocationsContext } from './context'

const Locations = (): React.ReactElement => {
  const [currentLocation, setCurrentLocation] = useState<ILocation>()
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [creatingLocation, setCreatingLocation] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [mapView, setMapView] = useState<boolean>(false)
  const [showControls, setShowControls] = useState<boolean>(false)
  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'locations',
      nestedProps: [
        {
          columnValue: 'no_of_devices',
          path: 'devices.length',
          defaultValue: 0,
        },
      ],
    })

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })
  const { locations, loadingLocations, fetchLocations } = useLocations()

  const { remove } = locationApi()

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? locations.map((location) => location.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchLocations()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleOpen = (): void => {
    setOpenSidebar(true)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    getDefaultFilters()
    fetchLocations()
  }, [])

  useEffect(() => {
    fetchLocations(search)
  }, [search])

  useEffect(() => {
    if (!openSidebar && currentLocation) setCurrentLocation(undefined)
  }, [openSidebar])

  const visibleRows = useMemo(() => {
    return getVisibleRowsSorted(
      getPaginatedSlice(currentPage, rowsPerPage, locations),
      locations,
    )
  }, [locations, search, order, orderBy, rowsPerPage, currentPage])

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          key="1"
          variant="contained"
          name="addLocation"
          onClick={() => {
            setCreatingLocation(true)
            handleOpen()
          }}
          disabled={loadingLocations}
        >
          Add Location
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Button
          key="2"
          variant="contained"
          name="toggleMapView"
          onClick={() => setMapView((prev) => !prev)}
          disabled={loadingLocations}
        >
          {mapView ? 'Table View' : 'Map View'}
        </Button>
      ),
    },
    {
      display: false,
      render: (
        <Button
          key="3"
          onClick={() => setShowControls((prev) => !prev)}
          variant="contained"
          name="toggleMapControls"
        >
          Map Controls
        </Button>
      ),
    },
  ]

  return (
    <LocationsContext.Provider value={{ fetchLocations }}>
      <Box>
        <Toolbar controls={controls.filter((control) => control.display)}>
          <ToolbarControls
            controls={controls.filter((control) => control.display)}
          />
          <ToolbarSearchbar
            handleSearch={handleSearch}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
          />
        </Toolbar>
        {selectedRows.length > 0 && (
          <MultiSelectToolbar
            itemsSelected={selectedRows.length}
            handleAction={handleDeleteMany}
          />
        )}
        {!loadingLocations && locations.length > 0 && (
          <>
            {mapView ? (
              <>
                <MapView
                  closeControls={() => setShowControls(false)}
                  controls={showControls}
                  locations={locations}
                />
              </>
            ) : (
              <>
                <Table>
                  <SortableTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headers={filteredColumns.filter(
                      (c) => c.active && c.value !== 'shared',
                    )}
                    handleSelectAll={handleSelectAll}
                  />
                  <TableBody>
                    {visibleRows.map((location) => (
                      <LocationRow
                        key={location.id}
                        location={location}
                        handleOpen={handleOpen}
                        setCurrentLocation={(location) => {
                          setCurrentLocation(location)
                        }}
                        filteredColumns={filteredColumns}
                        displayMessage={displayMessage}
                        setCreatingLocation={(value: boolean) =>
                          setCreatingLocation(value)
                        }
                        handleSelectRow={handleSelectRow}
                        selected={selectedRows.includes(location.id)}
                      />
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  totalItems={locations.length}
                  currentPage={currentPage}
                  itemsPerPage={rowsPerPage}
                  setCurrentPage={setCurrentPage}
                  setItemsPerPage={setRowsPerPage}
                />
              </>
            )}
          </>
        )}

        <LoadingTableData isLoading={loadingLocations} />

        <NoData condition={!loadingLocations && locations.length === 0} />

        {openSidebar && (
          <SideBar open={openSidebar} onClose={() => setOpenSidebar(false)}>
            <LocationForm
              location={currentLocation}
              onClose={() => {
                setOpenSidebar(false)
                setCurrentLocation(undefined)
              }}
              // success={fetchLocations}
              displayMessage={displayMessage}
              creatingLocation={creatingLocation}
            />
          </SideBar>
        )}
        <NotificationDialog
          message={dialog.message}
          open={dialog.isOpen}
          onClose={closeDialog}
          type={dialog.type}
        />
      </Box>
    </LocationsContext.Provider>
  )
}

export default Locations
