import { Box, useTheme } from '@mui/material'
import { Button, Image, SideBar } from 'components'
import { IIntegration } from 'models/Integration'
import { useState } from 'react'
import TwilioSidebar from './TwilioSidebar'

interface IIntegrationCardProps {
  integration: IIntegration
}

const IntegrationCard = ({ integration }: IIntegrationCardProps) => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.75rem',
        }}
        onClick={
          integration.name === 'Twilio' ? () => setOpenSidebar(true) : undefined
        }
      >
        <Box
          boxShadow={3}
          sx={{
            borderRadius: '12px',
            width: 160,
            height: 160,
          }}
        >
          <Image
            key={integration.name}
            src={integration.image}
            alt="bg"
            style={{
              width: 160,
              height: 160,
              outline: 'none',
              borderRadius: '12px',
            }}
          />
        </Box>
        {integration.name}
        <Button
          variant="contained"
          onClick={() => {}}
          sx={{ minWidth: '100%', height: '2rem' }}
          color={
            integration.active &&
            integration.active &&
            theme.palette.mode === 'light'
              ? 'inherit'
              : integration.active &&
                integration.active &&
                theme.palette.mode === 'dark'
              ? 'cancel'
              : 'primary'
          }
        >
          {integration.active === null && 'Coming Soon'}
          {integration.active && integration.active !== null && 'Deactivate'}
          {!integration.active && integration.active !== null && 'Activate'}
        </Button>
      </Box>
      {integration.name === 'Twilio' && openSidebar && (
        <SideBar open={openSidebar} onClose={() => setOpenSidebar(false)}>
          <TwilioSidebar integration={integration} />
        </SideBar>
      )}
    </>
  )
}

export default IntegrationCard
