import { ICustomTitle } from 'components'

export interface InitialState {
  message: string
  type?: 'success' | 'error' | 'info' | 'warning' | null
  customTitle?: ICustomTitle | null
}

const initialState: InitialState = {
  message: '',
  type: null,
}

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setMessage':
      return {
        message: action.payload.message,
        type: action.payload.type,
        customTitle: action.payload.customTitle,
      }
    default:
      return state
  }
}

export default messageReducer
