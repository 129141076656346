import {
  Card,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Button, NotificationDialog } from 'components'
import useDialog from 'hooks/useDialog'
import { useEffect, useState } from 'react'
import { apikeysApi } from 'resources/apikeys'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { openDoc } from 'utils/helpers'
import { API_DOCS_LINK } from 'constants/help.docs'

const APIs = () => {
  const [apiKey, setApiKey] = useState<string | null>(null)
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { get: getApiKeys, createApiKey } = apikeysApi()

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(apiKey || '')
    setIsCopied(true)
  }

  const handleGenerateAPIKey = async () => {
    try {
      const response = await createApiKey()
      setApiKey(response.key)
      displayMessage('API key has been generated.', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await getApiKeys()
        setApiKey(response[0].key || null)
      } catch (error) {
        displayMessage(`${(error as Error).message}`, 'error')
      }
    }
    fetchApiKey()
  }, [])

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '5px',
        }}
      >
        <Button
          variant="contained"
          name="addSize"
          onClick={() => openDoc(`${API_DOCS_LINK}/redoc`)}
          sx={{ minWidth: '111px' }}
        >
          API Documentation
        </Button>
      </Card>
      <Card
        sx={{
          borderRadius: '5px',
          minHeight: '600px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Typography sx={{ marginTop: '3rem' }} variant="h2">
          API Token
        </Typography>
        <Button
          variant="contained"
          sx={{
            marginRight: '1em',
          }}
          onClick={handleGenerateAPIKey}
          disabled={Boolean(apiKey)}
        >
          Generate Token
        </Button>
        <TextField
          sx={{
            width: '400px',
          }}
          value={apiKey || 'No API Key found. Please generate one.'}
          disabled
          InputProps={
            apiKey
              ? {
                  endAdornment: isCopied ? (
                    <InputAdornment position="end">
                      <ClickAwayListener
                        onClickAway={() => {
                          setIsCopied(false)
                        }}
                      >
                        <Tooltip
                          title="Copied!"
                          disableFocusListener={isCopied}
                          disableHoverListener={isCopied}
                          disableTouchListener={isCopied}
                          open={isCopied}
                        >
                          <IconButton
                            onClick={handleCopyToClipboard}
                            edge="end"
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <Tooltip title="Copy to clipboard">
                        <IconButton onClick={handleCopyToClipboard} edge="end">
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        />
        {/* <Link>See instructions here</Link> */}
      </Card>
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default APIs
