import { HTTP_CODES } from 'constants/HTTP'
import { INotificationList } from 'models/Notification'
import { DropdownOption } from 'types'
import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'

const GET_ERRORS = {
  [HTTP_CODES.NOT_FOUND]: 'No notifcations found',
  [HTTP_CODES.INTERNAL_SERVER_ERROR]: 'An internal server error has occurred',
}

const notificationApi = () => {
  const { fetchPartnerEndpoint, fetchEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<INotificationList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `notifications?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (notification) => {
    const endpoint = `notifications`
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(notification),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (notificationId, notification) => {
    const endpoint = `notifications/${notificationId}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(notification),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]) => {
    const endpoint = `notifications`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getNotificationTypes = async (): Promise<DropdownOption[]> => {
    const endpoint = `types/notification/type`
    const method = 'GET'
    try {
      const response = await fetchEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getMany, create, update, remove, getNotificationTypes }
}
export default notificationApi
