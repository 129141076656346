import { useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import { Checkbox, FormControl, Link, MenuItem, Select } from '@mui/material'
import { DeleteForever } from '@mui/icons-material'
import {
  TableCell,
  TableRow,
  DeleteDialog,
  IconButton,
  IDialogType,
} from 'components'
import { IssueApi } from 'resources'
import { IIssue } from 'models'
import { getUserRole } from 'utils/helpers'
import { ISSUE_STATUSES } from '../constants'
import { formatDate } from '../helpers'

const role = getUserRole()
interface IIssueRowProps {
  issue: IIssue
  success: () => void
  filteredColumns: any[]
  displayMessage: (message: string, type?: IDialogType) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
  handleEditIssue: (issue: IIssue) => void
}

const IssueRow = ({
  issue,
  success,
  filteredColumns,
  displayMessage,
  handleSelectRow,
  selected,
  handleEditIssue,
}: IIssueRowProps): React.ReactElement => {
  const [dialog, setDialog] = useState({
    isOpen: false,
    message: '',
  })
  const [status, setStatus] = useState(issue.status)

  const { deleteMany, patch } = IssueApi()

  const handleDelete = async (): Promise<void> => {
    try {
      await deleteMany([issue.id])
      setDialog({ isOpen: false, message: '' })
      success()
      displayMessage('Issue deleted successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleStatusChange = async (
    event: SelectChangeEvent<string>,
  ): Promise<void> => {
    try {
      const { value } = event.target
      setStatus(value)
      await patch(issue.id, value)
      success()
      displayMessage('Issue updated successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, issue.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'issueId' && c.active) !==
          -1 && (
          <TableCell align="center">
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                handleEditIssue(issue)
              }}
            >
              {issue.issue_id}
            </Link>
          </TableCell>
        )}

        {filteredColumns.findIndex(
          (c) => c.value === 'userName' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {issue.user?.name ||
              issue.user?.email ||
              issue.user?.phone_number ||
              'N/A'}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'orderNumber' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {issue.event?.invoice_id ? issue.event?.invoice_id : 'N/A'}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'status' && c.active) !==
          -1 && (
          <TableCell align="center">
            <FormControl sx={{ width: 140 }} size="small">
              <Select
                value={issue.status || status}
                onChange={handleStatusChange}
              >
                {ISSUE_STATUSES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
        )}

        {filteredColumns.findIndex(
          (c) => c.value === 'reportTime' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {new Date(issue.created_at).toLocaleString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={() => {
                setDialog({
                  isOpen: true,
                  message: `Are you sure you want to delete this issue This cannot be undone.`,
                })
              }}
            >
              <DeleteForever />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {dialog.isOpen && (
        <DeleteDialog
          title="Delete Issue"
          description="Are you sure you want to delete this issue This cannot be undone."
          rule="delete"
          onClickCancel={() => setDialog({ isOpen: false, message: '' })}
          onClickConfirm={handleDelete}
          open={dialog.isOpen}
          onClose={() => setDialog({ isOpen: false, message: '' })}
        />
      )}
    </>
  )
}

export default IssueRow
