import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { TableBody } from 'components'
import useDialog from 'hooks/useDialog'
import { useEffect, useState } from 'react'
import { reportApi } from 'resources'
import { TOP_USERS_HEADERS } from '../constants'
import { ITopUsers } from 'models/Analytics'
import { RootStateOrAny, useSelector } from 'react-redux'
import { parseCurrency } from 'utils/helpers'

interface ITopUsersProps {
  targetOrg: string | undefined
}

const TopUsers = ({ targetOrg }: ITopUsersProps) => {
  const { getTopUsers } = reportApi()

  const { dialog, displayMessage, closeDialog } = useDialog()

  const [topUsers, setTopUsers] = useState<ITopUsers[]>([])

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const fetchTopUsers = async () => {
    try {
      const topUsers = await getTopUsers(targetOrg)
      if (topUsers.length === 0) {
        setTopUsers([])
      } else {
        setTopUsers(topUsers.map((item) => ({ ...item, user: item.User })))
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
    }
  }

  useEffect(() => {
    fetchTopUsers()
  }, [targetOrg])

  return (
    <Card>
      <CardHeader
        title={
          <Typography color="primary" variant="h3">
            Top Users
          </Typography>
        }
      />
      <CardContent>
        <Box
          sx={{
            overflow: 'auto',
            mt: -3,
          }}
        >
          <Table
            aria-label="top users table"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <TableHead>
              <TableRow>
                {TOP_USERS_HEADERS.map((header) => (
                  <TableCell key={header.label}>
                    <Typography
                      color="primary"
                      variant="h5"
                      textAlign={header.align}
                    >
                      {header.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {topUsers.length > 0 &&
                topUsers.map((row) => (
                  <TableRow key={row.user.name}>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Typography variant="h6">{row.user.name}</Typography>
                        <Typography variant="h6">{row.user.email}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="h6">
                        {row.location}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography textAlign="right" variant="h6">
                        {row.purchases} {parseCurrency(currency)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              {topUsers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>No data to show</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TopUsers
