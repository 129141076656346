import { SxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ISx, IStyles } from 'types'

export const ResetPasswordStyles = (): {
  SX: ISx
  STYLES: IStyles
} => {
  const theme = useTheme()

  const SX: { [key: string]: SxProps } = {
    GRID_CONTAINER: {
      height: '100vh',
      justifyContent: 'center',
    },
    GRID_ITEM: {
      background: `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
    },
    BOX_IMAGE_CONTAINER: {
      position: 'relative',
    },
    BOX_IMAGE: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: {
        xs: 'relative',
        lg: 'absolute',
      },
      top: {
        xs: '5rem',
        lg: '0',
      },
      height: { xs: 'auto', lg: '100vh' },
      right: { xs: 'auto' },
      margin: '0 auto',
      width: '100%',
    },
    BOX_LOGO: {
      display: 'flex',
      alignItems: 'center',
      p: 4,
      position: 'absolute',
      top: '0',
    },
  }

  const STYLES: IStyles = {
    IMG: {
      width: '90%',
      height: '100%',
    },
  }

  return { SX, STYLES }
}
