import { IChangePassword, IForgotPassword, Org, PublicOrg } from 'models'
import {
  AWS_COGNITO_REGION,
  AWS_PROJECT_REGION,
  HTTP_CODES,
} from '../../../constants'
import { Amplify, Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { getMyself } from 'resources/dashboard'
import { getOrganizationFromHost, getUserRole } from 'utils/helpers'
import { organizationApi } from 'resources/org'
import { useDispatch } from 'react-redux'
import { setMessage } from 'redux/message/Action'

const useAuth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const RESPONSE_MESSAGES = {
    [HTTP_CODES.OK]: (data) => data,
    [HTTP_CODES.BAD_REQUEST]: (data: string) => data,
    [HTTP_CODES.INTERNAL_SERVER_ERROR]: () => 'Server error',
  }

  const authResponse = (status: number, data: string): string => {
    return RESPONSE_MESSAGES[status](data)
  }

  const configureAmplify = async (): Promise<void> => {
    let userPoolId: string,
      clientId: string = ''
    const currentUser: string | null = localStorage.getItem('currentUser')
    if (currentUser) {
      const userObject = JSON.parse(currentUser)
      userPoolId = userObject.pool.userPoolId
      clientId = userObject.pool.clientId
    } else {
      const { getOrgDetailsPublic } = organizationApi()
      const orgName = getOrganizationFromHost()
      const publicOrgData = await getOrgDetailsPublic(orgName)
      userPoolId = publicOrgData.user_pool
      clientId = publicOrgData.client_id
    }

    const config = {
      aws_project_region: AWS_PROJECT_REGION,
      aws_cognito_region: AWS_COGNITO_REGION,
      aws_user_pools_id: userPoolId,
      aws_user_pools_web_client_id: clientId,
    }
    Amplify.configure(config)
  }

  const signIn = async (
    email: string,
    password: string,
    org: PublicOrg | undefined,
  ): Promise<void> => {
    try {
      const user: CognitoUser = await Auth.signIn(email, password)
      const organization = {
        name: org?.name,
        logo: org?.app_logo,
        oem_logo: org?.oem_logo || null,
        isSuperTenant: org?.super_tenant,
        shared_locations: org?.shared_locations,
      }
      localStorage.setItem('currentOrg', JSON.stringify(organization))
      localStorage.setItem('currentUser', JSON.stringify(user))
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        localStorage.setItem('oldPassword', password)
        navigate('/auth/welcome')
      } else {
        const session = await getMyself()
        localStorage.setItem('userSession', JSON.stringify(session))
        const isFirstTimeLogin =
          localStorage.getItem('firstTimeLogin') === 'true'
        if (isFirstTimeLogin && getUserRole() === 'admin') {
          navigate('/settings')
        } else {
          window.location.href = '/'
        }
      }
    } catch (error) {
      throw new Error(`${(error as Error).message}`)
    }
  }
  const completePassword = async ({
    email,
    newPassword,
  }: {
    email: string
    newPassword: string
  }): Promise<void> => {
    try {
      const oldPassword: string | null = localStorage.getItem('oldPassword')
      if (!oldPassword) {
        throw new Error('User not found')
      }
      const user: CognitoUser = await Auth.signIn(email, oldPassword)
      if (user) {
        await Auth.completeNewPassword(user, newPassword)
        localStorage.removeItem('oldPassword')
        navigate('/')
      }
    } catch (error) {
      throw new Error(`${(error as Error).message}`)
    }
  }

  const forgotPassword = async ({
    username,
  }: IForgotPassword): Promise<void> => {
    try {
      return await Auth.forgotPassword(username)
    } catch (error) {
      throw new Error(`${(error as Error).message}`)
    }
  }

  const forgotPasswordSubmit = async ({
    username,
    code,
    password,
  }: IChangePassword): Promise<string> => {
    try {
      return await Auth.forgotPasswordSubmit(username, code, password)
    } catch (error) {
      throw new Error(`${(error as Error).message}`)
    }
  }

  const getJwtToken = async () => {
    try {
      configureAmplify()
      const currentSession = await Auth.currentSession()
      const idToken = currentSession.getIdToken()
      const jwtToken = idToken.getJwtToken()

      const currentUser: string | null = localStorage.getItem('currentUser')
      if (
        currentUser &&
        JSON.parse(currentUser).challengeName !== 'NEW_PASSWORD_REQUIRED'
      ) {
        const userObject = JSON.parse(currentUser)
        const idTokenKey = Object.keys(localStorage).find((key) =>
          key.includes('idToken'),
        )
        if (
          idTokenKey &&
          localStorage[idTokenKey] !==
            userObject.signInUserSession?.idToken?.jwtToken
        ) {
          const cognitoUser = await Auth.currentAuthenticatedUser()
          localStorage.removeItem('currentUser')
          localStorage.setItem('currentUser', JSON.stringify(cognitoUser))
        }

        return jwtToken
      }
    } catch (error) {
      localStorage.removeItem('currentUser')
      dispatch(
        setMessage({
          message: 'Your session has expired. Please log in again.',
          customTitle: {
            titleColor: '#ff3535',
            titleText: 'Alert!',
          },
        }),
      )
      navigate('/auth/login')
    }
  }

  return {
    authResponse,
    configureAmplify,
    signIn,
    forgotPassword,
    forgotPasswordSubmit,
    completePassword,
    getJwtToken,
  }
}

export default useAuth
