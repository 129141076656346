import { INotification } from 'models/Notification'

export const addStepsToAssetNotifications = (
  notifications: INotification[],
): INotification[] => {
  const notificationsWithStep = notifications.map((notification) => {
    if (notification.name === 'Welcome') {
      return { ...notification, step: 1 }
    } else if (notification.name === 'Transaction Starts') {
      return { ...notification, step: 2 }
    } else if (notification.name === 'Complete') {
      return { ...notification, step: 3 }
    }
    return { ...notification, step: 4 }
  })
  return notificationsWithStep.sort((a, b) => a.step - b.step)
}

export const addStepsToServiceNotifications = (
  notifications: INotification[],
): INotification[] => {
  const notificationsWithStep = notifications.map((notification) => {
    if (notification.name === 'Welcome') {
      return { ...notification, step: 1 }
    } else if (notification.name === 'Ready for pick-up') {
      return { ...notification, step: 2 }
    } else if (notification.name === 'Picked up') {
      return { ...notification, step: 3 }
    } else if (notification.name === 'Charge') {
      return { ...notification, step: 4 }
    } else if (notification.name === 'User Pickup') {
      return { ...notification, step: 5 }
    } else if (notification.name === 'Complete') {
      return { ...notification, step: 6 }
    }
    return { ...notification, step: 7 }
  })
  return notificationsWithStep.sort((a, b) => a.step - b.step)
}

export const addStepsToStorageNotifications = (
  notifications: INotification[],
): INotification[] => {
  const notificationsWithStep = notifications.map((notification) => {
    if (notification.name === 'Welcome') {
      return { ...notification, step: 1 }
    } else if (notification.name === 'Transaction Starts') {
      return { ...notification, step: 2 }
    } else if (notification.name === 'Complete') {
      return { ...notification, step: 3 }
    }
    return { ...notification, step: 4 }
  })
  return notificationsWithStep.sort((a, b) => a.step - b.step)
}

export const addStepsToDeliveryNotifications = (
  notifications: INotification[],
): INotification[] => {
  const notificationsWithStep = notifications.map((notification) => {
    if (notification.name === 'User Sign Up') {
      return { ...notification, step: 1 }
    } else if (notification.name === 'Transaction Starts') {
      return { ...notification, step: 2 }
    } else if (notification.name === 'Complete') {
      return { ...notification, step: 3 }
    }
    return { ...notification, step: 4 }
  })
  return notificationsWithStep.sort((a, b) => a.step - b.step)
}
