/* eslint-disable react/display-name */
import { DeleteForever, CopyAll } from '@mui/icons-material'
import { Checkbox, Link, Tooltip, Box } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  TableCell,
  TableRow,
} from 'components'
import { ISubscription, IPrice, IPromo } from 'models'
import { useMemo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { promoApi } from 'resources'
import { getUserRole, parseCurrency } from 'utils/helpers'

interface IItemRowProps {
  item: IPrice | ISubscription | IPromo
  setCurrentItem: React.Dispatch<
    React.SetStateAction<IPrice | ISubscription | IPromo | undefined>
  >
  filteredColumns: any[] | undefined
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selectedRows: string[]
}

const ItemRow = ({
  item,
  setCurrentItem,
  filteredColumns,
  displayMessage,
  success,
  handleSelectRow,
  selectedRows,
}: IItemRowProps) => {
  const initialDialog = {
    isOpen: false,
    message: '',
  }
  const [confirmDialog, setConfirmDialog] = useState(initialDialog)

  const role = getUserRole()
  const { remove: deletePromo } = promoApi()

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const data = useMemo(
    () => [
      {
        column: 'select',
        render: () =>
          role === 'admin' ? (
            <Checkbox
              checked={selectedRows.includes(item.id)}
              onChange={(event: React.ChangeEvent, checked: boolean) =>
                handleSelectRow(event, checked, item.id)
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          ) : (
            <Checkbox
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={true}
            />
          ),
      },
      {
        column: 'name',
        render: () =>
          role !== 'member' ? (
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => setCurrentItem(item as IPromo)}
            >
              {(item as IPromo).name}
            </Link>
          ) : (
            (item as IPromo).name
          ),
      },
      {
        column: 'code',
        render: () => (item as IPromo).code,
      },
      {
        column: 'discount_type',
        render: () =>
          (item as IPromo).discount_type?.charAt(0).toUpperCase() +
          (item as IPromo).discount_type?.slice(1),
      },
      {
        column: 'amount',
        render: () =>
          (item as IPromo).discount_type === 'fixed'
            ? `${parseCurrency(currency)}${(item as IPromo).amount}`
            : `${(item as IPromo).amount}%`,
      },
      {
        column: 'start_time',
        render: () =>
          new Date((item as IPromo).created_at).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
      },
      {
        column: 'end_time',
        render: () =>
          new Date((item as IPromo).end_time).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
      },
      {
        column: 'action',
        render: () => (
          <IconButton
            onClick={() =>
              setConfirmDialog({
                isOpen: true,
                message: 'Are you sure you want to delete this promo?',
              })
            }
          >
            <Tooltip title="Delete">
              <DeleteForever />
            </Tooltip>
          </IconButton>
        ),
      },

      {
        column: 'id',
        render: () => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
            }}
          >
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(item.id)
              }}
            >
              <Tooltip title="Copy ID">
                <CopyAll />
              </Tooltip>
            </IconButton>
          </Box>
        ),
      },
    ],
    [item, selectedRows],
  )

  const dataMap = useMemo(() => {
    const map = new Map<string, () => React.ReactNode>()
    data.forEach((item) => {
      map.set(item.column, item.render)
    })
    return map
  }, [data])

  const deleteAction = async () => {
    try {
      await deletePromo([item.id])
      displayMessage('Promo has been successfully deleted!', 'success')
      success()
      setConfirmDialog({ isOpen: false, message: '' })
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  return (
    <>
      <TableRow>
        {filteredColumns?.map(
          (c) =>
            c.active && (
              <TableCell key={c.value} align="center">
                {dataMap.has(c.value) ? (
                  dataMap.get(c.value)!()
                ) : typeof (item as any)[c.value] === 'boolean' ? (
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: (item as any)[c.value] ? 'green' : 'red',
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  (item as any)[c.value] || ''
                )}
              </TableCell>
            ),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmDialog.isOpen}
        message={confirmDialog.message}
        onClose={() => setConfirmDialog({ isOpen: false, message: '' })}
        onClickConfirm={() => {
          setConfirmDialog({ isOpen: false, message: '' })
          deleteAction()
        }}
        onClickCancel={() => setConfirmDialog({ isOpen: false, message: '' })}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ItemRow
