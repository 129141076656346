import {
  Box,
  Grid,
  Stack,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  ClickAwayListener,
  Tooltip,
  IconButton,
} from '@mui/material'
import { Image, NotificationDialog } from 'components'
import { ChangeEvent, useEffect, useState } from 'react'
import reservationWidget from '../../../assets/images/apps-widgets/reservation-widget.png'
import { TIME_UNITS } from 'pages/Pricing/constants'
import { apikeysApi } from 'resources/apikeys'
import useDialog from 'hooks/useDialog'
import { RESERVATION_WIDGET_BASE_URL } from 'constants/reservation.widget'
import { getCurrentOrgProperty } from 'utils/helpers'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const ReservationWidgetSettings = () => {
  const [apiKey, setApiKey] = useState<string | null>(null)
  const [reservationWidgetUrl, setReservationWidgetUrl] = useState<string>('')
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [primaryColor, setPrimaryColor] = useState<string>('#000000')
  const [secondaryColor, setSecondaryColor] = useState<string>('#000000')
  const [backgroundColor, setBackgroundColor] = useState<string>('#000000')

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { get: getApiKeys } = apikeysApi()

  const handlePrimaryColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPrimaryColor(event.target.value)
  }

  const handleSecondaryColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSecondaryColor(event.target.value)
  }

  const handleBackgroundColorChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setBackgroundColor(event.target.value)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(reservationWidgetUrl)
    setIsCopied(true)
  }

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await getApiKeys()
        setApiKey(response[0].key)
        const url = response[0].key
          ? `${RESERVATION_WIDGET_BASE_URL}api_key=${
              response[0].key
            }&org_name=${getCurrentOrgProperty('name')}`
          : `No API Token found. Please go to Toolbox -> API's to generater your API Token so we can generate your reservation widget url.`
        setReservationWidgetUrl(url)
      } catch (error) {
        displayMessage(`${(error as Error).message}`, 'error')
      }
    }
    fetchApiKey()
  }, [])

  return (
    <>
      <Grid
        container
        sx={{
          gap: mdDown ? '3rem' : undefined,
        }}
      >
        <Grid
          xs={12}
          sm={12}
          lg={6}
          sx={{
            padding: !mdDown ? '5rem 0' : undefined,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: mdDown ? 'center' : undefined,
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography textAlign="left" variant="h3">
              Customize your Reservation Widget
            </Typography>

            {/* App colors */}
            <Typography
              sx={{ marginTop: '1rem', marginBottom: '0.5rem' }}
              color="text.secondary"
              variant="h5"
            >
              Set colors for web app
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <TextField
                  label="Primary Color"
                  placeholder="#HEX"
                  type="color"
                  sx={{
                    height: '4em',
                    width: '100px',
                    position: 'relative',

                    input: {
                      cursor: 'pointer',
                      height: '2.75rem',
                      margin: 0,
                      padding: '10px 25px',
                    },
                  }}
                  onChange={handlePrimaryColorChange}
                />
                <Typography color="text.secondary">{primaryColor}</Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <TextField
                  label="Secondary Color"
                  placeholder="#HEX"
                  type="color"
                  sx={{
                    height: '4em',
                    width: '100px',
                    position: 'relative',

                    input: {
                      cursor: 'pointer',
                      height: '2.75rem',
                      margin: 0,
                      padding: '10px 25px',
                    },
                  }}
                  onChange={handleSecondaryColorChange}
                />
                <Typography color="text.secondary">{secondaryColor}</Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <TextField
                  label="Background Color"
                  placeholder="#HEX"
                  type="color"
                  sx={{
                    height: '4em',
                    width: '100px',
                    position: 'relative',

                    input: {
                      cursor: 'pointer',
                      height: '2.75rem',
                      margin: 0,
                      padding: '10px 25px',
                    },
                  }}
                  onChange={handleBackgroundColorChange}
                />
                <Typography color="text.secondary">
                  {backgroundColor}
                </Typography>
              </Box>
            </Stack>

            {/* Payment */}
            <Typography
              sx={{ marginTop: '1rem' }}
              color="text.secondary"
              variant="h5"
            >
              Set up payment in the app
            </Typography>
            <Stack
              // onClick={() => setCurrentImage(payment)}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Typography>No</Typography>
              <Switch />
              <Typography>Yes</Typography>
            </Stack>

            <Typography
              sx={{ marginTop: '1rem' }}
              color="text.secondary"
              variant="h5"
            >
              Maximum duration for reservation
            </Typography>
            <Box
              // onClick={() => setCurrentImage(payment)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              <TextField
                sx={{ marginTop: '1rem', width: '30%' }}
                name="duration"
                label="Duration"
                type="number"
              />
              <FormControl sx={{ width: '70%', marginTop: '1rem' }}>
                <InputLabel>Unit</InputLabel>
                <Select label="Unit">
                  {TIME_UNITS.map((unit) => (
                    <MenuItem key={unit.value} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Typography
              sx={{ marginTop: '1rem' }}
              color="text.secondary"
              variant="h5"
            >
              Copy and Paste this code to your website to use the reservation
              widget
            </Typography>
            <TextField
              value={reservationWidgetUrl}
              sx={{ marginTop: '1rem' }}
              multiline
              disabled={true}
              InputProps={
                apiKey
                  ? {
                      endAdornment: isCopied ? (
                        <InputAdornment position="end">
                          <ClickAwayListener
                            onClickAway={() => {
                              setIsCopied(false)
                            }}
                          >
                            <Tooltip
                              title="Copied!"
                              disableFocusListener={isCopied}
                              disableHoverListener={isCopied}
                              disableTouchListener={isCopied}
                              open={isCopied}
                            >
                              <IconButton
                                onClick={handleCopyToClipboard}
                                edge="end"
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </ClickAwayListener>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <Tooltip title="Copy to clipboard">
                            <IconButton
                              onClick={handleCopyToClipboard}
                              edge="end"
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <Image
            src={reservationWidget}
            alt="reservation-widget"
            style={{ width: 'auto', height: 'auto' }}
            isLogo={true}
          />
        </Grid>
      </Grid>
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default ReservationWidgetSettings
