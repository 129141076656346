import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Dialog, Button, IConfirmDialogProps } from 'components'

const ConfirmDialog = ({
  open,
  onClose,
  title,
  message,
  onClickConfirm,
  onClickCancel,
  confirmText = 'Yes',
  cancelText = 'No',
}: IConfirmDialogProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const theme = useTheme()
  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          padding: smDown ? '0 2rem 2rem 2rem' : 0,
          width: '100%',
        }}
      >
        {title && (
          <Typography
            variant="h3"
            sx={{
              marginTop: '-2rem',
              marginBottom: '1rem',
            }}
          >
            {title}
          </Typography>
        )}
        {message}
        <Box
          sx={{
            mt: '15px',
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            sx={{ width: '50%' }}
            variant="contained"
            onClick={() => onClickConfirm()}
          >
            {confirmText}
          </Button>
          <Button
            sx={{
              width: '50%',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            }}
            variant="contained"
            onClick={() => onClickCancel()}
            color={theme.palette.mode === 'dark' ? 'cancel' : 'inherit'}
          >
            {cancelText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
export default ConfirmDialog
