import { ISideBarProps, SideBarStyles } from 'components'
import { Box, Drawer } from '@mui/material'

const SideBar = ({ open, onClose, children }: ISideBarProps) => {
  const { SX } = SideBarStyles()
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: SX.DRAWER,
      }}
    >
      <Box sx={SX.CONTAINER}>{children}</Box>
    </Drawer>
  )
}
export default SideBar
