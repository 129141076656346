import { Card } from '@mui/material'
import { Spinner } from 'components/Spinner'

interface ILoadingTableDataProps {
  isLoading: boolean
  loadingText?: string
}

const LoadingTableData = ({
  isLoading,
  loadingText = 'Fetching data...',
}: ILoadingTableDataProps) => {
  return (
    <>
      {isLoading ? (
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            minHeight: '700px',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Spinner loadingText={loadingText} />
        </Card>
      ) : null}
    </>
  )
}

export default LoadingTableData
