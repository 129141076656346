import { CSSProperties } from 'react'
import { SxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const SetNewPasswordFormStyles = () => {
  const theme = useTheme()

  const SX: { [key: string]: SxProps } = {
    CONTAINER: {
      p: 4,
    },
    TITLE: {
      fontSize: theme.typography.h2,
      fontWeight: 700,
    },
    DESCRIPTION: {
      mt: 2,
      fontSize: theme.typography.h5,
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    FIELDS_CONTAINER: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      mt: 4,
    },
    BUTTONS_CONTAINER: {
      display: 'flex',
      gap: '0 15px',
      justifyContent: 'center',
      alignItems: 'center',
      mt: 4,
    },
  }

  const STYLES: { [key: string]: CSSProperties } = {
    BACK_BUTTON: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  }
  return { SX, STYLES }
}
