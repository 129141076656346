import {
  AttachMoneyOutlined,
  DashboardOutlined,
  DevicesOutlined,
  Inventory2Outlined,
  InventoryOutlined,
  RoomOutlined,
  SquareFootOutlined,
} from '@mui/icons-material'

export default [
  {
    step: 1,
    icon: AttachMoneyOutlined,
    path: '/pricing-set-up',
    title: 'Set default pricing',
    desc: 'This will be the default price for all transactions',
    nextPath: '/locations',
    sideBarIndex: 0,
    parentIndex: 1,
    child: true,
    position: '17.5em',
  },
  {
    step: 2,
    icon: RoomOutlined,
    path: '/locations',
    title: 'Set up a Location',
    desc: 'This location is where your lockers are set up',
    nextPath: '/sizes',
    sideBarIndex: 0,
    parentIndex: 2,
    child: true,
    position: '21em',
  },
  {
    step: 3,
    icon: SquareFootOutlined,
    path: '/sizes',
    title: 'Create locker sizes',
    desc: 'Add your locker dimensions here',
    nextPath: '/devices',
    sideBarIndex: 0,
    parentIndex: 3,
    child: true,
    position: '25em',
  },
  {
    step: 4,
    icon: DevicesOutlined,
    path: '/devices',
    title: 'Set devices',
    desc: 'Add device individually or bulk upload all devices at once',
    nextPath: '/inventory',
    sideBarIndex: 1,
    parentIndex: 3,
    child: true,
    position: '27.5em',
  },
  {
    step: 5,
    icon: InventoryOutlined,
    path: '/inventory',
    title: 'Add inventory',
    desc: 'A great way to manage your inventory for asset and vending lockers',
    nextPath: '/product-groups',
    sideBarIndex: 0,
    parentIndex: 4,
    child: true,
    position: '28em',
  },
  {
    step: 6,
    icon: Inventory2Outlined,
    path: '/product-groups',
    title: 'Create Product Groups',
    desc: 'Group inventory items together for easy access',
    nextPath: '/locker-wall',
    sideBarIndex: 1,
    parentIndex: 4,
    child: true,
    position: '31em',
  },
  {
    step: 7,
    icon: DashboardOutlined,
    path: '/locker-wall',
    title: 'Add locker wall',
    desc: 'A visual representation to manage your lockers',
    nextPath: false,
    sideBarIndex: 2,
    parentIndex: 2,
    child: true,
    position: '24em',
  },
]
