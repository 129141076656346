import { useState } from 'react'
import {
  TableRow,
  IconButton,
  TableCell,
  IDialogType,
  ConfirmDialog,
} from 'components'
import { IGroup } from 'models'
import { DeleteForever } from '@mui/icons-material'
import { Checkbox, Link } from '@mui/material'
import { getUserRole } from 'utils/helpers'
import { groupApi } from 'resources'

interface IGroupRowProps {
  group: IGroup
  success: () => void
  filteredColumns: any[]
  displayMessage: (message: string, type?: IDialogType) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
  handleEditGroup: (group: IGroup) => void
}

const GroupRow = ({
  group,
  success,
  filteredColumns,
  displayMessage,
  handleSelectRow,
  selected,
  handleEditGroup,
}: IGroupRowProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const { deleteGroup } = groupApi()

  const handleDelete = async () => {
    try {
      await deleteGroup([group.id])
      displayMessage('Group has been deleted successfully.', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const role = getUserRole()

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, group.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => handleEditGroup(group)}
              >
                {group.name}
              </Link>
            ) : (
              <>{group.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'users' && c.active) !==
          -1 && <TableCell align="center">{group.users.length}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'devices' && c.active) !==
          -1 && <TableCell align="center">{group.devices}</TableCell>}
        {filteredColumns.findIndex(
          (c) => c.value === 'locations' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {group.locations.map((location) => location.name).join(', ')}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell>
            {role !== 'member' ? (
              <IconButton
                onClick={() =>
                  setConfirmationMessage({
                    isOpen: true,
                    message: 'Are you sure you want to delete this group?',
                  })
                }
              >
                <DeleteForever />
              </IconButton>
            ) : (
              <></>
            )}
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        onClickConfirm={handleDelete}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default GroupRow
