import { Box, Typography } from '@mui/material'
import { ITransactionLog } from 'models'
import React from 'react'
import { capitalizeFirstLetter } from 'utils/helpers'

interface ITransactionLogProps {
  log: ITransactionLog
}

const TransactionLog = ({ log }: ITransactionLogProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '0.5rem',
        whiteSpace: 'nowrap',
        marginTop: '1px',
      }}
    >
      <Typography fontSize={14}>
        {log.log_type === 'report_issue'
          ? 'Report Issue'
          : capitalizeFirstLetter(log.log_type)}
      </Typography>
      <Typography fontSize={14}>-</Typography>
      <Typography fontSize={14}>
        {new Date(log.created_at).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
      <Typography fontSize={14}>-</Typography>
      <Typography fontSize={14}>Order {log.event.invoice_id}</Typography>
    </Box>
  )
}

export default TransactionLog
