import { DialogProps, IconButton } from 'components'
import { Close as CloseIcon } from '@mui/icons-material'
import { Dialog as MUIDialog, Box, useMediaQuery, Theme } from '@mui/material'

const Dialog = ({ open, onClose, children, type = 'success' }: DialogProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <MUIDialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: smDown ? '100%' : '500px',
          backgroundColor: type === 'welcome' ? 'black' : undefined,
        }}
      >
        <Box
          sx={{
            width: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: !smDown ? '0 2rem 2rem 2rem' : 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1.25rem',
            textAlign: 'center',
          }}
        >
          {children}
        </Box>
      </Box>
    </MUIDialog>
  )
}

export default Dialog
