import { Box, Typography } from '@mui/material'
import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import { useEffect, useMemo, useState } from 'react'
import { organizationApi, priceApi } from 'resources'
import ItemRow from './ItemRow'
import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import { getOrganizationFromHost, generateFilters } from 'utils/helpers'
import { Toolbar } from 'components/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from '../ToolbarSearchbar'
import useLoadingState from 'hooks/useLoadingState'
import PriceForm from './PriceForm'
import { PRICE_DEFAULTS } from '../../constants'

const response = { items: [], total: 0, pages: 0 }

const Pricing = () => {
  const [data, setData] = useState<any>(response)
  const [dataBackup, setDataBackup] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [currentItem, setCurrentItem] = useState<any>()
  const [openForm, setOpenForm] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const { loading, setLoading } = useLoadingState(true)
  const { getOrgDetailsPublic } = organizationApi()
  const { dialog, displayMessage, closeDialog } = useDialog()
  const { getMany: getPrices, remove: deletePrice } = priceApi()

  const { filteredColumns, setFilteredColumns } = useColumnFiltering({
    displayMessage,
  })
  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'pay-per',
      nestedProps: [],
    })

  const defaultPriceExists = useMemo(
    () => data.items.some((price) => price.default),
    [data],
  )

  const handleSelectRow = (event, checked, id) => {
    setSelectedRows((prev) =>
      checked ? [...prev, id] : prev.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event, checked) => {
    setSelectedRows(() => (checked ? data.items.map((item) => item.id) : []))
  }

  const deleteMany = async () => {
    await deletePrice(selectedRows)
    displayMessage(
      `${selectedRows.length} item${
        selectedRows.length > 1 ? 's' : ''
      } deleted successfully`,
      'success',
    )
    fetchPrices()
    closeDialog()
    setSelectedRows([])
  }

  const handleDeleteMany = async () => {
    try {
      await deleteMany()
    } catch (error) {
      displayMessage((error as Error).message, 'error')
    }
  }

  const handleClose = () => {
    setOpenForm(false)
    setCurrentItem(undefined)
  }

  const fetchPrices = async (showNewestFirst = false) => {
    try {
      setLoading(true)
      const fetchedData = await getPrices(currentPage + 1, rowsPerPage, search)
      setDataBackup(fetchedData.items)
      setData(fetchedData)
      setFilteredColumns(generateFilters(fetchedData.items[0], PRICE_DEFAULTS))
      if (showNewestFirst) setOrder('default')
    } catch (error) {
      displayMessage((error as Error).message, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenForm = async () => {
    setOpenForm(true)
  }

  const controls = useMemo(
    () => [
      {
        display: true,
        render: (
          <Button
            key="addDevice"
            variant="contained"
            onClick={handleOpenForm}
            disabled={loading}
          >
            Add Price
          </Button>
        ),
      },
    ],
    [loading],
  )

  useEffect(() => {
    fetchPrices()
  }, [search, currentPage, rowsPerPage])

  useEffect(() => {
    if (currentItem) {
      setOpenForm(true)
    }
  }, [currentItem])

  return (
    <>
      <Toolbar controls={controls}>
        <ToolbarControls controls={controls} />
        <ToolbarSearchbar
          handleSearch={(e) => setSearch(e.target.value)}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>

      <MultiSelectToolbar
        show={selectedRows.length > 0}
        itemsSelected={selectedRows.length}
        handleAction={handleDeleteMany}
      />

      <Table loading={loading} hidden={!loading && data.items.length > 0}>
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headers={filteredColumns?.filter((c) => c.active)}
          handleSelectAll={handleSelectAll}
        />
        <TableBody>
          {getVisibleRowsSorted(data.items, dataBackup).map((item) => (
            <ItemRow
              key={item.id}
              item={item}
              setCurrentItem={setCurrentItem}
              filteredColumns={filteredColumns}
              displayMessage={displayMessage}
              success={fetchPrices}
              handleSelectRow={handleSelectRow}
              selectedRows={selectedRows}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        totalItems={data.total}
        currentPage={currentPage}
        itemsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setRowsPerPage}
      />

      <SideBar open={openForm} onClose={handleClose}>
        <PriceForm
          price={currentItem}
          defaultPriceExists={defaultPriceExists}
          onClose={handleClose}
          displayMessage={displayMessage}
          success={fetchPrices}
        />
      </SideBar>
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        buttonText={'Ok'}
        type={dialog.type}
      />
    </>
  )
}

export default Pricing
