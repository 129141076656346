import { Box, Typography } from '@mui/material'

interface IDetailProps {
  label: string
  value: string | undefined
}

const Detail = ({ label, value }: IDetailProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '120px',
      width: '100%',
      padding: '0 1rem',
    }}
  >
    <Typography sx={{ fontSize: 18 }}>
      <b>{label}: </b>
    </Typography>
    <Typography sx={{ fontSize: 18 }} color="text.secondary">
      {value}
    </Typography>
  </Box>
)

export default Detail
