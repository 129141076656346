import { ILocation } from 'models'
import { useEffect, useState } from 'react'
import useLoadingState from './useLoadingState'
import { locationApi } from 'resources'
import { DropdownOption } from 'types'

const useLocations = () => {
  const [locations, setLocations] = useState<ILocation[]>([])
  const [locationsOptions, setLocationsOptions] = useState<DropdownOption[]>([])

  const { getMany: getLocations } = locationApi()
  const { loading: loadingLocations, setLoading: setLoadingLocations } =
    useLoadingState()

  const fetchLocations = async (searchTerm: string = ''): Promise<void> => {
    try {
      setLoadingLocations(true)
      const locations = await getLocations(1, 10000, searchTerm)
      setLocations(locations.items)
    } catch (error) {
      //   displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoadingLocations(false)
    }
  }

  const buildLocationsOptions = () => {
    if (locations.length > 0) {
      const autoCompleteOptions = locations
        .map((location) => ({
          label: location.name,
          value: location.id,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
      return autoCompleteOptions
    }
    return []
  }

  useEffect(() => {
    setLocationsOptions(buildLocationsOptions())
  }, [locations])

  useEffect(() => {
    fetchLocations()
  }, [])

  return {
    locations,
    loadingLocations,
    locationsOptions,
    setLocations,
    setLoadingLocations,
    fetchLocations,
  }
}

export default useLocations
