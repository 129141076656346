import { FormField } from 'types'

export const RESET_PASSWORD_FORM_FIELDS: { [key: string]: FormField } = {
  USERNAME: {
    NAME: 'username',
    LABEL: 'Email',
    PLACEHOLDER: 'Email',
    RULES: {
      required: 'Email required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  CODE: {
    NAME: 'code',
    LABEL: 'Verification code',
    PLACEHOLDER: 'Verification code',
    RULES: {
      required: 'Code required',
    },
  },
  PASSWORD: {
    NAME: 'password',
    LABEL: 'New password',
    PLACEHOLDER: 'New password',
    RULES: {
      required: 'New password required',
    },
  },
  CONFIRM_PASSWORD: {
    NAME: 'confirmPassword',
    LABEL: 'Confirm password',
    PLACEHOLDER: 'Confirm password',
    RULES: {
      required: 'Confirm password required',
    },
  },
}

export const RESET_PASSWORD_FORM: { [key: string]: string } = {
  FORGOT_TITLE: ' Forgot your password?',
  RESET_TITLE: 'Reset your password',
  DESCRIPTION:
    'Please enter the email address associated with your account and we will email you a code to verify your account.',
  SEND_CODE_BUTTON_LABEL: 'Send Code',
  SUBMIT_BUTTON_LABEL: 'Change Password',
  BACK_BUTTON: 'Back to Login',
}
