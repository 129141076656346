import { Card, Divider, List, ListItem } from '@mui/material'

import IntegrationsBlock from './IntegrationsBlock'
import { ChangeEvent, useMemo, useState } from 'react'
import sclogic from './images/sclogic.png'
import packagex from './images/packagex.png'
import trax from './images/trax.png'
import send from './images/send.png'
import aftership from './images/aftership.png'
import stripe from './images/stripe.png'
import adyen from './images/adyen.png'
import twilio from './images/twilio.png'
import zendesk from './images/zendesk.png'
import servicenow from './images/servicenow.png'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'

const blocks = [
  {
    title: 'Shipping & Tracking',
    integrations: [
      {
        image: sclogic,
        name: 'SC Logic',
        active: null,
      },
      {
        image: packagex,
        name: 'Package X',
        active: null,
      },
      {
        image: trax,
        name: 'Trax',
        active: false,
      },
      {
        image: send,
        name: 'Send',
        active: true,
      },
      {
        image: aftership,
        name: 'Aftership',
        active: null,
      },
    ],
  },
  {
    title: 'Payment',
    integrations: [
      {
        image: stripe,
        name: 'Stripe',
        active: true,
      },
      {
        image: adyen,
        name: 'Adyen',
        active: null,
      },
    ],
  },
  {
    title: 'Messaging',
    integrations: [
      {
        image: twilio,
        name: 'Twilio',
        active: true,
      },
    ],
  },
  {
    title: 'Service & Support',
    integrations: [
      {
        image: zendesk,
        name: 'Zendesk',
        active: true,
      },
      {
        image: servicenow,
        name: 'Service Now',
        active: true,
      },
    ],
  },
]

const Integrations = () => {
  const [search, setSearch] = useState<string>('')

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const renderIntegrations = useMemo(
    () =>
      search
        ? blocks
            .map((block) => ({
              ...block,
              integrations: block.integrations.filter((integration) =>
                integration.name
                  .toLowerCase()
                  .includes(search.toLocaleLowerCase()),
              ),
            }))
            .filter((block) => block.integrations.length > 0)
        : blocks,
    [, search],
  )

  return (
    <>
      <Toolbar controls={[]}>
        <ToolbarSearchbar handleSearch={handleSearch} enableFilters={false} />
      </Toolbar>
      <Card
        sx={{
          borderRadius: '5px',
          minHeight: '600px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <List>
          {renderIntegrations.map((block, index) => (
            <>
              <ListItem>
                <IntegrationsBlock
                  title={block.title}
                  integrations={block.integrations}
                />
              </ListItem>
              {index !== renderIntegrations.length - 1 && (
                <Divider sx={{ margin: '2rem 0' }} component="li" />
              )}
            </>
          ))}
        </List>
      </Card>
    </>
  )
}

export default Integrations
