import { Box, Typography } from '@mui/material'
import { Dialog, Button, IDialogType, Form } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { ITransaction } from 'models'
import { FormProvider, useForm } from 'react-hook-form'
import { transactionsApi } from 'resources'

interface IPickUpDialogProps {
  transaction: ITransaction
  open: boolean
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
}

const PickUpDialog = ({
  transaction,
  open,
  onClose,
  displayMessage,
  success,
}: IPickUpDialogProps) => {
  const { loading, setLoading } = useLoadingState()
  const { service } = transactionsApi()

  const onPickup = async (): Promise<void> => {
    try {
      setLoading(true)
      await service({ transactionId: transaction.id, step: 'pickup' })
      success()
      displayMessage('Transaction pickup successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = (): void => {
    try {
      setLoading(true)
      onPickup()
      onClose()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const methods = useForm()

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Pickup Transaction" isDialog={true}>
            <Typography
              variant="h5"
              sx={{
                fontSize: '30px',
                color: 'red',
                fontWeight: 'bold',
              }}
            >
              Alert!
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ textAlign: 'center', marginBottom: '1rem' }}>
                You are about to unlock
                <br />
                {`${transaction.device?.name ?? 'this locker'}`}. Please ensure
                you are
                <br />
                nearby to retrieve your items.
                <br />
              </Typography>
            </Box>

            <FormActions onClose={onClose} loading={loading} />
          </FormWrapper>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
export default PickUpDialog
