import { Theme, useMediaQuery } from '@mui/material'
import { ISx } from 'types'

export const TablePaginationStyles = (): { SX: ISx } => {
  const SM: boolean = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )

  const SX: ISx = {
    PAGINATION: SM
      ? {
          '& .MuiToolbar-root': { padding: '0px' },
          '& .MuiInputBase-root': { marginRight: '10px' },
        }
      : {},
  }

  return { SX }
}
