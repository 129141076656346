import { Box } from '@mui/material'
import { ITabsProps, TabList, TabContext, TabPanel } from 'components'

const Tabs = ({ tabs, currentTab, handleChange }: ITabsProps) => {
  return (
    <TabContext value={currentTab}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <TabList tabs={tabs} onChange={handleChange} />
      </Box>
      <TabPanel tabs={tabs} />
    </TabContext>
  )
}
export default Tabs
