import { MouseEvent, ChangeEvent } from 'react'
import { ITablePaginationProps, TablePaginationStyles } from 'components'
import { TablePagination as MUITablePagination } from '@mui/material'

const TablePagination = ({
  totalItems,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  rowsPerPageOptions,
}: ITablePaginationProps) => {
  const { SX } = TablePaginationStyles()

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCurrentPage(0)
    setItemsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <MUITablePagination
      component="div"
      count={totalItems}
      page={currentPage}
      onPageChange={handleChangePage}
      rowsPerPage={itemsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={SX.PAGINATION}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  )
}

export default TablePagination
