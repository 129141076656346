import { ITableProps } from 'components'
import { Table as MUITable, TableContainer, Card } from '@mui/material'

import { Spinner } from 'components/Spinner'
const Table = ({ children, loading }: ITableProps) => {
  return (
    <>
      {loading ? (
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            minHeight: '700px',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Spinner />
        </Card>
      ) : (
        <>
          <Card
            sx={{
              borderRadius: '5px',
              minHeight: '600px',
            }}
          >
            <TableContainer sx={{ maxHeight: 800 }}>
              <MUITable stickyHeader>{children}</MUITable>
            </TableContainer>
          </Card>
        </>
      )}
    </>
  )
}
export default Table
