import { Text, View, Image } from '@react-pdf/renderer'
import { QRGenerator } from 'components'
import { IServicePDFProps } from 'templates'
import { HOST } from '../../constants'
import { STYLES } from './styles'
import { useState, useEffect, ReactElement } from 'react'
import {
  DETAIL_PAGE_LIST_ITEMS,
  DETAIL_PAGE_TITLE,
} from 'pages/Events/constants'

const ServicePDF = ({ transaction }: IServicePDFProps): ReactElement => {
  const { generateQRCodePDF } = QRGenerator()

  const [qr, setQr] = useState<string>('')

  useEffect(() => {
    generateQRCodePDF(`${HOST}/transactions/${transaction.id}/details`).then(
      (res) => {
        setQr(res)
      },
    )
  }, [])

  return (
    <>
      <View>
        <Image style={STYLES.QR} src={qr} />
      </View>
      <View>
        <Text style={STYLES.TITLE}>{`${DETAIL_PAGE_TITLE} ${
          transaction?.invoice_id ?? '-'
        }`}</Text>
        <View style={STYLES.TRANSACTION_DETAILS_CONTAINER}>
          <View>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {DETAIL_PAGE_LIST_ITEMS.STATUS}:
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {DETAIL_PAGE_LIST_ITEMS.LOCATION}:
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {DETAIL_PAGE_LIST_ITEMS.DEVICE}:
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {DETAIL_PAGE_LIST_ITEMS.START_DATE}:
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {DETAIL_PAGE_LIST_ITEMS.USER}:
            </Text>
          </View>
          <View>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {transaction?.event_status}
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {transaction?.device?.location?.name}
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {transaction?.device?.name}
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {/* {transaction?.created_at?.toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })} */}
              {transaction.created_at}
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {transaction?.user?.name}
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {transaction?.user?.phone_number}
            </Text>
            <Text style={STYLES.TRANSACTION_DETAILS}>
              {transaction?.user?.email}
            </Text>
          </View>
        </View>
      </View>
    </>
  )
}
export default ServicePDF
