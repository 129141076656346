import { IFeedback } from 'models/Feedback'
import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'

const feedbackApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const create = async (feedback: FormData): Promise<IFeedback> => {
    const endpoint = `feedback`
    const method = 'POST'
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: feedback,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    create,
  }
}
export default feedbackApi
