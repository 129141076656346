import {
  CreditCard,
  MapOutlined,
  Devices,
  PublishedWithChanges,
  PersonOutline,
  WarningOutlined,
  Notifications,
  PlaceOutlined,
  Dashboard,
  Groups,
  Diversity1,
} from '@mui/icons-material'
import { ReactComponent as PricingSetUp } from '../../../assets/images/icons/pricing-set-up.svg'
import { ReactComponent as PayPer } from '../../../assets/images/icons/pay-per.svg'
import { ReactComponent as Memberships } from '../../../assets/images/icons/memberships.svg'
import { ReactComponent as PromoCode } from '../../../assets/images/icons/promo-code.svg'
import { ReactComponent as Inventory } from '../../../assets/images/icons/inventory.svg'
import { ReactComponent as ProductGroup } from '../../../assets/images/icons/product-groups.svg'
import { SvgIcon } from '@mui/material'
import GroupsIcon from '@mui/icons-material/Groups'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import { getUserRole, isSuperTenant } from 'utils/helpers'
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined'
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined'
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined'
import CategoryIcon from '@mui/icons-material/Category'
import RuleIcon from '@mui/icons-material/Rule'
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined'
import FlagIcon from '@mui/icons-material/Flag'
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined'
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined'

const MenuItems = [
  {
    title: 'Analytics',
    featureName: 'analytics',
    icon: <EqualizerOutlinedIcon />,
    restriction: null,
    children: [
      {
        title: 'Dashboard',
        icon: <Dashboard />,
        href: '/home',
        restriction: null,
      },
      {
        title: 'Reporting',
        icon: <FlagIcon />,
        href: '/reporting',
        restriction: null,
      },
    ],
  },
  {
    title: 'Pricing',
    featureName: 'pricing',
    icon: <CreditCard />,
    children: [
      {
        title: 'Pricing set-up',
        icon: (
          <SvgIcon sx={{ width: '25px', height: '25px' }}>
            <PricingSetUp />
          </SvgIcon>
        ),
        href: '/pricing-set-up',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Pay-per',
        icon: (
          <SvgIcon sx={{ width: '25px', height: '25px' }}>
            <PayPer />
          </SvgIcon>
        ),
        href: '/pay-per',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Subscriptions',
        icon: (
          <SvgIcon sx={{ width: '25px', height: '25px' }}>
            <Memberships />
          </SvgIcon>
        ),
        href: '/subscriptions',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Promo codes',
        icon: (
          <SvgIcon sx={{ width: '25px', height: '25px' }}>
            <PromoCode />
          </SvgIcon>
        ),
        href: '/promo-codes',
        restriction: getUserRole() === 'viewer',
      },
    ],
  },
  {
    title: 'Places',
    featureName: 'places',
    icon: <PlaceOutlined />,
    restriction: getUserRole() === 'viewer',
    children: [
      {
        title: 'Locations',
        icon: <MapOutlined />,
        href: '/locations',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Locker Wall',
        icon: <ViewModuleOutlinedIcon />,
        href: '/locker-wall',
        restriction: getUserRole() === 'viewer',
      },
    ],
  },
  {
    title: 'Doors',
    featureName: 'doors',
    icon: <SensorDoorOutlinedIcon />,
    restriction: getUserRole() === 'viewer',
    children: [
      {
        title: 'Sizes',
        icon: <SquareFootOutlinedIcon sx={{ width: '30px', height: '30px' }} />,
        href: '/sizes',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Devices',
        icon: <Devices />,
        href: '/devices',
        restriction: getUserRole() === 'viewer',
      },
    ],
  },
  {
    title: 'Products',
    featureName: 'product',
    icon: <CategoryIcon sx={{ width: '25px', height: '25px' }} />,
    restriction: getUserRole() === 'viewer',
    children: [
      {
        title: 'Inventory',
        icon: (
          <SvgIcon sx={{ width: '25px', height: '25px' }}>
            <Inventory />
          </SvgIcon>
        ),
        href: '/inventory',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Products Groups',
        icon: (
          <SvgIcon sx={{ width: '25px', height: '25px' }}>
            <ProductGroup />
          </SvgIcon>
        ),
        href: '/product-groups',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Conditions',
        icon: <RuleIcon />,
        href: '/conditions',
        restriction: getUserRole() === 'viewer',
      },
    ],
  },
  {
    title: 'People',
    featureName: 'people',
    icon: <GroupsIcon sx={{ width: '20px', height: '20px' }} />,
    restriction: getUserRole() === 'viewer',
    children: [
      {
        title: 'Users',
        icon: <PersonOutline sx={{ width: '20px', height: '20px' }} />,
        href: '/users',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Groups',
        icon: <Groups />,
        href: '/groups',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Team Members',
        icon: <Diversity1 />,
        href: '/team-members',
        restriction: getUserRole() === 'viewer',
      },
    ],
  },
  {
    title: 'Events',
    featureName: 'event',
    icon: <EventAvailableOutlinedIcon />,
    restriction: getUserRole() === 'viewer',
    children: [
      {
        title: 'Transactions',
        icon: <PublishedWithChanges />,
        href: '/transactions',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Reservations',
        icon: <BookmarkAddedOutlinedIcon />,
        href: '/reservations',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Subscribers',
        icon: <SubscriptionsIcon sx={{ width: '20px', height: '20px' }} />,
        href: '/subscribers',
        restriction: getUserRole() === 'viewer',
      },
    ],
  },
  {
    title: 'Issues',
    featureName: 'issue',
    icon: <WarningOutlined sx={{ width: '20px', height: '20px' }} />,
    href: '/issues',
    restriction: getUserRole() === 'viewer',
  },
  {
    title: 'Notifications',
    featureName: 'notifications',
    icon: <Notifications sx={{ width: '20px', height: '20px' }} />,
    href: '/notifications',
    restriction: getUserRole() === 'viewer',
  },
  {
    title: 'Multi-Tenant',
    featureName: 'multi_tenant',
    icon: <AccountTreeOutlinedIcon sx={{ width: '20px', height: '20px' }} />,
    href: '/multi-tenant',
    restriction: getUserRole() !== 'admin',
  },
  {
    title: 'Toolbox',
    featureName: 'toolbox',
    icon: <HomeRepairServiceOutlinedIcon />,
    // Hide access from UI only in production environment at the moment:
    restriction: process.env.REACT_APP_CURRENT_ENV === 'production',
    children: [
      {
        title: `API's`,
        icon: <ApiOutlinedIcon />,
        href: '/apis',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Apps & Widgets',
        icon: <WidgetsOutlinedIcon />,
        href: '/apps-widgets',
        restriction: getUserRole() === 'viewer',
      },
      {
        title: 'Integrations',
        icon: <IntegrationInstructionsOutlinedIcon />,
        href: '/integrations',
        restriction: getUserRole() === 'viewer',
      },
    ],
  },
]

export default MenuItems
