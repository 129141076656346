import { ReactElement } from 'react'
import { Page, Document, PDFDownloadLink } from '@react-pdf/renderer'
import { IPDFGeneratorProps } from 'components'
import { CircularProgress, Link } from '@mui/material'

const PDFGenerator = ({
  fileName,
  icon,
  pageSize,
  pageContent,
}: IPDFGeneratorProps) => {
  const MyDocument: any = Document
  const MyPage: any = Page

  const PDF: ReactElement = (
    <MyDocument>
      <MyPage pageMode="singlePage" size={pageSize}>
        {pageContent}
      </MyPage>
    </MyDocument>
  )

  return (
    <Link sx={{ color: 'inherit' }}>
      <PDFDownloadLink document={PDF} fileName={fileName}>
        {({ blob, url, loading, error }) => icon ?? 'Download now!'}
      </PDFDownloadLink>
    </Link>
  )
}
export default PDFGenerator
