import { ISubscriptionList } from 'models'
import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'

const membershipApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
    subscribed: boolean = false,
  ): Promise<ISubscriptionList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
      subscribed: subscribed.toString(),
    })
    const endpoint = `memberships?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (membership) => {
    const endpoint = `memberships`
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(membership),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (membershipId, membership) => {
    const endpoint = `memberships/${membershipId}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: JSON.stringify(membership),
        headers,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]) => {
    const endpoint = `memberships`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `memberships/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getMany, create, update, remove, uploadCSV }
}
export default membershipApi
