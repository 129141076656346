import { IGroupList, IGroup } from 'models'
import { useApiRequest } from 'utils'
import { HTTP_CODES } from 'constants/HTTP'
import { getErrorMessage } from 'utils/helpers'

const GET_ERRORS = {
  [HTTP_CODES.NOT_FOUND]: 'No groups found',
  [HTTP_CODES.INTERNAL_SERVER_ERROR]: 'An internal error occurred',
}

const groupApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number = 1,
    size: number = 10000,
    search: string = '',
  ): Promise<IGroupList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `groups?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const postGroup = async (group: { name: string }): Promise<IGroup> => {
    const endpoint = `groups?name=${group.name}`
    const method = 'POST'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const deleteGroup = async (ids: string[]): Promise<any> => {
    const endpoint = `groups`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const assignUser = async (id: string, userId: string): Promise<any> => {
    const endpoint = `groups/${id}/assign-user?id_user=${userId}`
    const method = 'PATCH'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const removeUser = async (id: string, userId: string): Promise<any> => {
    const endpoint = `groups/${id}/remove-user?id_user=${userId}`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const assign = async (
    id: string | undefined,
    groupId: string,
    type,
  ): Promise<any> => {
    const endpoint = `groups/${groupId}/assign?id_resource=${id}&resource_type=${type}`
    const method = 'PATCH'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const updateGroup = async (id, groupName) => {
    const endpoint = `groups/${id}?name=${groupName}`
    const method = 'PUT'
    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const assigned = async (id, type) => {
    const endpoint = `resources/${id}/groups?resource_type=${type}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const unassign = async (resourceId, groupId, type) => {
    const endpoint = `groups/${groupId}}/remove?id_resource=${resourceId}&resource_type=${type}`
    const method = 'PATCH'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    postGroup,
    deleteGroup,
    assignUser,
    removeUser,
    assign,
    assigned,
    unassign,
    updateGroup,
  }
}

export default groupApi
