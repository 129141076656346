export interface InitialState {
  currency: string | null
}

const initialState: InitialState = {
  currency: null,
}

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setCurrency':
      return {
        currency: action.payload,
      }
    default:
      return state
  }
}

export default currencyReducer
