import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import { Image } from 'components'
import { ILockerWall } from 'models/LockerWall'

interface IWallDetails {
  lockerWall: ILockerWall
}

const WallDetails = ({ lockerWall }: IWallDetails) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: smDown ? 'column' : 'row',
        marginTop: '3rem',
        padding: '0 1.5rem ',
      }}
    >
      <Image
        src={lockerWall.image}
        alt={lockerWall.name}
        style={{
          width: '150px',
          height: 'auto',
          borderRadius: '5px',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 1.25rem',
          marginTop: smDown ? '1rem' : 0,
        }}
      >
        <Typography
          color="text.secondary"
          textAlign={smDown ? 'center' : 'left'}
          variant="h3"
        >
          {lockerWall.name}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{
            whiteSpace: 'no-wrap',
            fontStyle: 'italic',
            fontSize: 14,
          }}
          variant="body1"
          textAlign={smDown ? 'center' : 'left'}
        >
          Custom ID:{' '}
          {lockerWall.custom_id || 'No custom ID for this locker wall'}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{
            whiteSpace: 'no-wrap',
          }}
          variant="body1"
          textAlign={'left'}
        >
          <br />
          {lockerWall.description || 'No description for this locker wall'}
        </Typography>
      </Box>
    </Box>
  )
}

export default WallDetails
