export const SETTINGS_TABS = {
  THEME: {
    label: 'Theme',
    value: 'theme',
  },
  GENERAL: {
    label: 'General',
    value: 'general',
  },
  ORGANIZATION: {
    label: 'Organization',
    value: 'organization',
  },
  API_KEYS: {
    label: 'API Keys',
    value: 'api_keys',
  },
}

export const MANAGE_TEAM_TABLE_HEADERS = [
  { label: 'Email', value: 'Email' },
  { label: 'First name', value: 'First name' },
  { label: 'Last name', value: 'Last name' },
  { label: 'Role', value: 'Role' },
  { label: 'Action', value: 'action' },
]

export const COUNTRIES_OPTIONS = [
  { label: 'Austria', value: 'AT' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Spain', value: 'ES' },
  { label: 'United Kingdom / Great Britain', value: 'GB' },
  { label: 'Australia', value: 'AU' },
  { label: 'Canada', value: 'CA' },
  { label: 'United States', value: 'US' },
]

export const ORGANIZATION_TABLE_HEADERS = [
  { value: 'name', label: 'Name' },
  { value: 'domain', label: 'Domain' },
  { value: 'owner', label: 'Owner' },
  { value: 'created_at', label: 'Created At' },
  { value: 'action', label: 'Action' },
]

export const PARCEL_EXPIRATION_UNITS = [
  { value: 'days', label: 'Days' },
  { value: 'hours', label: 'Hours' },
]
