import QRCode from 'qrcode'

const QRGenerator = () => {
  const onClickDownload = (fileName, fileData): void => {
    const link = document.createElement('a')
    link.href = fileData
    link.download = `${fileName}-QR.png`
    link.click()
  }

  const generateQRCode = (name, data): void => {
    QRCode.toDataURL(data).then((fileData) => {
      onClickDownload(name, fileData)
    })
  }

  const generateQRCodePDF = async (data): Promise<string> => {
    return await QRCode.toDataURL(data)
  }

  return { generateQRCode, generateQRCodePDF }
}

export default QRGenerator
