import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { IAnalyticsFilters } from 'models/Analytics'
import { Dispatch, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface ICustomDateRange {
  isOpen: boolean
  onClose: () => void
  selectedRange: string
  filters: IAnalyticsFilters
  setFilters: Dispatch<React.SetStateAction<IAnalyticsFilters>>
}

const CustomDateRange = ({
  isOpen,
  onClose,
  selectedRange,
  filters,
  setFilters,
}: ICustomDateRange) => {
  const [from, setFrom] = useState<Date | null>(filters.from || null)
  const [to, setTo] = useState<Date | null>(filters.to || null)

  const handleFromChange = (date) => {
    setFrom(date)
  }

  const handleToChange = (date) => {
    setTo(date)
  }

  const handleSelectDateRange = () => {
    setFilters((previousFilters) => ({ ...previousFilters, from, to }))
    onClose()
  }

  const handleReset = () => {
    setFrom(null)
    setTo(null)
  }

  useEffect(() => {
    if (
      (!isOpen && !filters.from && !filters.to) ||
      selectedRange !== 'custom'
    ) {
      handleReset()
    }
  }, [isOpen, selectedRange])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ padding: '15px 24px 0px 24px' }}>
        <Typography textAlign="left" variant="h4">
          Select a date range to display data
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          marginTop: '0',
          display: 'flex',
          gap: '1rem',
        }}
      >
        <FormControl size="small">
          <br />
          <DatePicker
            value={from}
            onChange={handleFromChange}
            renderInput={(props) => <TextField {...props} />}
            label="From"
            disableFuture={true}
          />
        </FormControl>
        <FormControl size="small">
          <br />
          <DatePicker
            value={to}
            onChange={handleToChange}
            renderInput={(props) => <TextField {...props} />}
            label="To"
            disableFuture={true}
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          width: '100%',
        }}
      >
        <Button
          sx={{ width: '30%' }}
          variant="contained"
          onClick={handleSelectDateRange}
        >
          Select
        </Button>
        <Button sx={{ width: '30%' }} variant="contained" onClick={handleReset}>
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomDateRange
