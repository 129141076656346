import { DEVICE_TYPES } from 'pages/Doors/constants'
import { useEffect, useState } from 'react'
import { organizationApi } from 'resources'
import { DropdownOption } from 'types'
import { getErrorMessage } from 'utils/helpers'

const useHardwareTypes = () => {
  const [orgHardware, setOrgHardware] = useState<DropdownOption[]>([])
  const [isLoadingHardware, setIsLoadingHardware] = useState<boolean>(false)
  const { getSelfOrg } = organizationApi()

  const fetchOrgHardwareTypes = async (): Promise<void> => {
    try {
      setIsLoadingHardware(true)
      const selfOrg = await getSelfOrg()
      const deviceTypes: DropdownOption[] = DEVICE_TYPES.filter((type) => {
        return selfOrg[`${type.value}_hardware`] === true
      })
      setOrgHardware([...orgHardware, ...deviceTypes])
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    } finally {
      setIsLoadingHardware(false)
    }
  }

  useEffect(() => {
    fetchOrgHardwareTypes()
  }, [])

  return {
    orgHardware,
    isLoadingHardware,
  }
}

export default useHardwareTypes
