import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'
import { IConditionList } from 'models/Condition'

const conditionApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<IConditionList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })

    const endpoint = `conditions?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (condition) => {
    const endpoint = `conditions`
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body: JSON.stringify(condition),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        result: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (condition, conditionId) => {
    const endpoint = `conditions/${conditionId}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body: JSON.stringify(condition),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        result: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]): Promise<any> => {
    const endpoint = `conditions`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patch = async (condition, conditionId): Promise<any> => {
    const endpoint = `conditions/${conditionId}`
    const method = 'PATCH'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body: JSON.stringify(condition),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    create,
    update,
    remove,
    patch,
  }
}

export default conditionApi
