import { MoneyOff, QrCode } from '@mui/icons-material'
import { Badge, BadgeProps, Box, Checkbox, Link, Tooltip } from '@mui/material'
import {
  IDialogType,
  IconButton,
  Image,
  PDFGenerator,
  TableCell,
  TableRow,
} from 'components'
import {
  IDevice,
  ITransaction,
  TransactionStatus,
  TransactionType,
  IUser,
  DeviceType,
  CodeDeliveryResponse,
} from 'models'
import { ServicePDF } from 'templates'
import ChargeDialog from '../ChargeDialog'
import { useMemo, useState } from 'react'
import DropoffDialog from '../DropoffDialog'
import PickUpDialog from '../PickUpDialog'
import RefundDialog from '../RefundDialog'
import EndDialog from '../EndDialog'
import { transactionsApi } from 'resources'
import moment from 'moment'
import {
  capitalizeFirstLetter,
  getUserRole,
  parseCurrency,
  parseHexToRGB,
} from 'utils/helpers'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import CloseIcon from '@mui/icons-material/Close'
import {
  EXPIRED_TRANSACTION_COLOR,
  ON_GOING_TRANSACTION_COLOR,
} from 'pages/Events/constants'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import PenaltyDialog from '../PenaltyDialog'
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import SendToMobileIcon from '@mui/icons-material/SendToMobile'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { styled } from '@mui/material/styles'

interface ITransactionRowProps {
  transaction: ITransaction
  setCurrentUser: (user: IUser) => void
  devices: IDevice[]
  setCurrentDevice: (device: IDevice) => void
  setCurrentTransaction: (transaction: ITransaction) => void
  filteredColumns: any[]
  displayMessage: (message: string, type?: IDialogType) => void
  fetchTransactions: () => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    transaction: ITransaction,
  ) => void
  selected: boolean
}

interface ExtendedBadgeProps extends BadgeProps {
  badgeColor: string
}

const CustomBadge = styled(Badge)<ExtendedBadgeProps>(({ badgeColor }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 24,
    background: 'none',
    color: badgeColor,
  },
}))

const TransactionRow = ({
  transaction,
  setCurrentUser,
  devices,
  setCurrentDevice,
  setCurrentTransaction,
  filteredColumns,
  displayMessage,
  fetchTransactions,
  handleSelectRow,
  selected,
}: ITransactionRowProps) => {
  const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(false)
  const [openPickupDialog, setOpenPickupDialog] = useState<boolean>(false)
  const [openChargeDialog, setOpenChargeDialog] = useState<boolean>(false)
  const [openDropoffDialog, setOpenDropoffDialog] = useState<boolean>(false)
  const [openEndDialog, setOpenEndDialog] = useState<boolean>(false)
  const [openPenaltyDialog, setOpenPenaltyDialog] = useState<boolean>(false)
  const [codeDeliveryStatus, setCodeDeliveryStatus] =
    useState<CodeDeliveryResponse | null>(null)

  const orgSettings = JSON.parse(localStorage.getItem('settings') || '{}')

  const { sendCode } = transactionsApi()
  const role = getUserRole()

  const ojmarDialogMsj = useMemo(
    () =>
      'If the transaction is finished remotely, the old reservation user will ' +
      'still be available to use the locker via Keypad. To return to the default ' +
      'working mode please finish the transaction properly with ' +
      'a Smartphone and Spherio app.',
    [],
  )

  const handleSendCode = async (
    email: string | null,
    phoneNumber: string | null,
    channel?: 'email' | 'sms',
  ) => {
    try {
      const response = await sendCode(transaction.id, phoneNumber, email)
      setCodeDeliveryStatus((previousState) =>
        !previousState
          ? response
          : {
              message: response.message,
              sms_success:
                previousState.sms_success !== null &&
                response.sms_success === null
                  ? previousState.sms_success
                  : response.sms_success,
              email_success:
                previousState.email_success !== null &&
                response.email_success === null
                  ? previousState.email_success
                  : response.email_success,
            },
      )
      displayMessage(response.message, 'success')
    } catch (error) {
      const response = error as CodeDeliveryResponse
      setCodeDeliveryStatus((previousState) =>
        !previousState
          ? response
          : {
              message: response.message,
              sms_success:
                previousState.sms_success !== null &&
                response.sms_success === null
                  ? previousState.sms_success
                  : response.sms_success,
              email_success:
                previousState.email_success !== null &&
                response.email_success === null
                  ? previousState.email_success
                  : response.email_success,
            },
      )
      if (channel === 'email' && !email) {
        displayMessage('User Email does not exist', 'error')
        return
      } else if (channel === 'sms' && !phoneNumber) {
        displayMessage('User Phone does not exist', 'error')
        return
      }
    }
  }

  if (orgSettings?.default_country === 'US') {
    moment.updateLocale('en', {
      meridiem: (hours) => {
        if (hours > 11) {
          return 'p'
        } else {
          return 'a'
        }
      },
    })
  }

  const getRowBackground = () => {
    if (
      ![
        TransactionStatus.finished,
        TransactionStatus.canceled,
        TransactionStatus.refunded,
        TransactionStatus.expired,
      ].includes(TransactionStatus[transaction.event_status])
    ) {
      return parseHexToRGB(ON_GOING_TRANSACTION_COLOR, '0.1')
    } else if (
      TransactionStatus[transaction.event_status] === TransactionStatus.expired
    ) {
      return parseHexToRGB(EXPIRED_TRANSACTION_COLOR, '0.1')
    } else {
      return undefined
    }
  }

  const getBadgeContent = (property: 'email_success' | 'sms_success') => {
    // no code has been sent therefore no badge is rendered
    if (!codeDeliveryStatus || codeDeliveryStatus[property] === null) {
      return null
    }

    // successful code delivery
    if (codeDeliveryStatus && codeDeliveryStatus[property] === true) {
      return {
        render: <CheckCircleOutlinedIcon fontSize="small" />,
        color: '#4dff88',
      }
    }

    // unsuccessful code delivery
    if (codeDeliveryStatus && codeDeliveryStatus[property] === false) {
      return {
        render: <CancelOutlinedIcon fontSize="small" />,
        color: '#ff471a',
      }
    }

    return null
  }

  const getTooltip = (property: 'email_success' | 'sms_success') => {
    return !codeDeliveryStatus
      ? `Click to resend pin code via ${
          property.includes('email') ? 'email' : 'sms'
        }`
      : `${
          codeDeliveryStatus[property] === true
            ? `Code has been successfully sent via ${
                property.includes('email') ? 'email' : 'sms'
              }. Click to send again.`
            : codeDeliveryStatus[property] === false
            ? `We were unable to send the code via ${
                property.includes('email') ? 'email' : 'sms'
              }. Please try again.`
            : `Click to resend pin code via ${
                property.includes('email') ? 'email' : 'sms'
              }`
        }`
  }

  return (
    <>
      <TableRow
        sx={{
          background: getRowBackground(),
        }}
      >
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, transaction)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'invoice_id' && c.active,
        ) !== -1 && (
          <TableCell>
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setCurrentTransaction(transaction)
              }}
            >
              {transaction?.invoice_id}
            </Link>
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'order_id' && c.active,
        ) !== -1 && (
          <TableCell>
            <Link
              sx={{
                cursor: 'pointer',
              }}
            >
              {transaction?.order_id}
            </Link>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell>
            {role !== 'member' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.awaiting_user_pickup &&
                  transaction.event_type === TransactionType.delivery && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="resend code email"
                      onClick={() =>
                        handleSendCode(transaction.user.email, null, 'email')
                      }
                    >
                      <Tooltip title={getTooltip('email_success')}>
                        <CustomBadge
                          badgeColor={
                            getBadgeContent('email_success')?.color || 'inherit'
                          }
                          badgeContent={
                            getBadgeContent('email_success')?.render
                          }
                        >
                          <ForwardToInboxIcon />
                        </CustomBadge>
                      </Tooltip>
                    </IconButton>
                  )}
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.awaiting_user_pickup &&
                  transaction.event_type === TransactionType.delivery && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="resend code sms"
                      onClick={() =>
                        handleSendCode(
                          null,
                          transaction.user.phone_number,
                          'sms',
                        )
                      }
                    >
                      <Tooltip title={getTooltip('sms_success')}>
                        <CustomBadge
                          badgeColor={
                            getBadgeContent('sms_success')?.color || 'inherit'
                          }
                          badgeContent={getBadgeContent('sms_success')?.render}
                        >
                          <SendToMobileIcon />
                        </CustomBadge>
                      </Tooltip>
                    </IconButton>
                  )}
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.in_progress &&
                  TransactionType[transaction.event_type] ===
                    TransactionType.service && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="charge"
                      onClick={() => setOpenChargeDialog(true)}
                    >
                      <Tooltip title="Charge user">
                        <AddCardOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.awaiting_service_pickup &&
                  TransactionType[transaction.event_type] ===
                    TransactionType.service && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="pickup"
                      onClick={() => setOpenPickupDialog(true)}
                    >
                      <Tooltip title="Pickup">
                        <UnarchiveOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {TransactionStatus[transaction.event_status] ===
                  TransactionStatus.awaiting_service_dropoff &&
                  TransactionType[transaction.event_type] !==
                    TransactionType.delivery && (
                    <IconButton
                      sx={{
                        padding: '0.6rem 0.5rem',
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                      name="dropoff"
                      onClick={() => setOpenDropoffDialog(true)}
                    >
                      <Tooltip title="Dropoff">
                        <ArchiveOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {[
                  TransactionStatus.finished,
                  TransactionStatus.canceled,
                  TransactionStatus.refunded,
                ].includes(TransactionStatus[transaction.event_status]) && (
                  <IconButton onClick={() => setOpenPenaltyDialog(true)}>
                    <Tooltip title="Charge Penalty">
                      <CreditCardOutlinedIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </Box>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'user_name' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction?.user?.name ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentUser(transaction?.user)
                }}
              >
                {transaction?.user?.name}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'user_phone' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction?.user?.phone_number ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentUser(transaction?.user)
                }}
              >
                {transaction?.user?.phone_number}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'user_email' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction?.user?.email ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentUser(transaction?.user)
                }}
              >
                {transaction?.user?.email}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'device_name' && c.active,
        ) !== -1 && (
          <TableCell>
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentDevice(transaction?.device)
                }}
              >
                {transaction?.device?.name}
              </Link>
            ) : (
              <>{transaction?.device?.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'locker_number' && c.active,
        ) !== -1 && <TableCell>{transaction?.device?.locker_number}</TableCell>}
        {filteredColumns.findIndex(
          (c) => c.value === 'location' && c.active,
        ) !== -1 && (
          <TableCell>{transaction?.device?.location?.name}</TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'mode' && c.active) !==
          -1 && (
          <TableCell>
            {capitalizeFirstLetter(
              transaction.device?.mode !== 'rental'
                ? transaction.device?.mode
                : 'asset',
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'status' && c.active) !==
          -1 && (
          <TableCell>{TransactionStatus[transaction?.event_status]}</TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'start_date' && c.active,
        ) !== -1 && (
          <TableCell>
            {moment(transaction.created_at).format(
              orgSettings?.default_date_format || 'MM/DD/YYYY hh:mm A',
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'end_date' && c.active,
        ) !== -1 && (
          <TableCell>
            {transaction.ended_at
              ? moment(transaction.ended_at).format(
                  orgSettings?.default_date_format || 'MM/DD/YYYY hh:mm A',
                )
              : ''}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'duration' && c.active,
        ) !== -1 && <TableCell>{transaction?.total_time}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'refund' && c.active) !==
          -1 && (
          <TableCell>
            {TransactionStatus[transaction.event_status] ===
              TransactionStatus.finished &&
              Number(transaction?.total) > 0 && (
                <IconButton
                  onClick={() => setOpenRefundDialog(true)}
                  sx={{
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  <MoneyOff />
                </IconButton>
              )}
          </TableCell>
        )}

        {filteredColumns.findIndex((c) => c.value === 'amount' && c.active) !==
          -1 && (
          <TableCell>
            {Number(transaction?.total) > 0 &&
              parseCurrency(transaction?.device?.price?.currency)}
            {transaction?.total === 0
              ? `${parseCurrency(transaction?.device?.price?.currency)}0`
              : transaction?.total}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'end' && c.active) !==
          -1 && (
          <TableCell>
            {role !== 'member' && (
              <>
                {![
                  TransactionStatus.finished,
                  TransactionStatus.canceled,
                  TransactionStatus.refunded,
                ].includes(TransactionStatus[transaction.event_status]) && (
                  <IconButton
                    name="end"
                    onClick={() => {
                      if (
                        transaction.device.hardware_type == DeviceType.ojmar
                      ) {
                        displayMessage(ojmarDialogMsj, 'warning')
                      }
                      setOpenEndDialog(true)
                    }}
                  >
                    <Tooltip title="End Transaction">
                      <CloseIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'qr_code' && c.active) !==
          -1 && (
          <TableCell>
            <IconButton>
              <PDFGenerator
                fileName={transaction?.id}
                pageContent={<ServicePDF transaction={transaction} />}
                pageSize={'A6'}
                icon={
                  <QrCode
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark' ? '#ffffff' : '#0000008a',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  />
                }
              />
            </IconButton>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'image' && c.active) !==
          -1 && (
          <TableCell align="center">
            {transaction.image_url ? (
              <Image
                src={transaction.image_url}
                alt={transaction.invoice_id}
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                }}
              />
            ) : (
              <></>
            )}
          </TableCell>
        )}
      </TableRow>
      <ChargeDialog
        open={openChargeDialog}
        onClose={() => setOpenChargeDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <DropoffDialog
        open={openDropoffDialog}
        onClose={() => setOpenDropoffDialog(false)}
        transaction={transaction}
        devices={devices}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <PickUpDialog
        open={openPickupDialog}
        onClose={() => setOpenPickupDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <RefundDialog
        open={openRefundDialog}
        onClose={() => setOpenRefundDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
      <EndDialog
        open={openEndDialog}
        onClose={() => setOpenEndDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />

      <PenaltyDialog
        open={openPenaltyDialog}
        onClose={() => setOpenPenaltyDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={fetchTransactions}
      />
    </>
  )
}

export default TransactionRow
