import { ORG_FEATURE_PAGES } from 'constants/pages'
import { useEffect, useState } from 'react'
import { organizationApi } from 'resources'
import { getErrorMessage } from 'utils/helpers'

const useOrgFeaturePages = () => {
  const [orgFeaturePages, setOrgFeaturePages] = useState<any>(null)
  const [isLoadingFeaturePages, setIsLoadingFeaturePages] =
    useState<boolean>(false)
  const { getSelfOrg } = organizationApi()

  const fetchOrgFeaturePagesTypes = async (): Promise<void> => {
    try {
      setIsLoadingFeaturePages(true)
      const selfOrg = await getSelfOrg()
      setOrgFeaturePages(selfOrg)
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    } finally {
      setIsLoadingFeaturePages(false)
    }
  }

  useEffect(() => {
    fetchOrgFeaturePagesTypes()
  }, [])

  return {
    orgFeaturePages,
    isLoadingFeaturePages,
  }
}

export default useOrgFeaturePages
