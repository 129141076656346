import { DropdownOption } from 'types'

export const PAY_PER_TABLE_HEADERS = [
  {
    value: 'name',
    label: 'Name',
    active: true,
  },
  {
    value: 'price_type',
    label: 'Price Type',
    active: true,
  },
  {
    value: 'amount',
    label: 'Amount',
    active: true,
  },
  {
    value: 'unit',
    label: 'Unit',
    active: true,
  },
  {
    value: 'prorated',
    label: 'Prorated',
    active: true,
  },
  {
    value: 'card_on_file',
    label: 'Card on file',
    active: true,
  },
  {
    value: 'actions',
    label: 'Actions',
    active: true,
  },
]

export const WEIGHT_UNITS: DropdownOption[] = [
  { label: 'Lb', value: 'lb' },
  { label: 'Kg', value: 'kg' },
]

export const TIME_UNITS: DropdownOption[] = [
  { label: 'Minute', value: 'minute' },
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
]

export const PRICE_TYPES: DropdownOption[] = [
  { label: 'Weight', value: 'pay_per_weight' },
  { label: 'Time', value: 'pay_per_time' },
]

export const MEMBERSHIPS_TABLE_HEADERS = [
  {
    value: 'name',
    label: 'Name',
    active: true,
  },
  {
    value: 'membership_type',
    label: 'Membership Type',
    active: true,
  },
  {
    value: 'amount',
    label: 'Amount',
    active: true,
  },
  {
    value: 'billing_type',
    label: 'Billing Type',
    active: true,
  },
  {
    value: 'billing_period',
    label: 'Billing Period',
    active: true,
  },
  {
    value: 'number_of_payments',
    label: 'No. of Payments',
    active: true,
  },
  {
    value: 'value',
    label: 'Value',
    active: true,
  },
  {
    value: 'actions',
    label: 'Actions',
    active: true,
  },
]

export const PROMO_CODES_TABLE_HEADERS = [
  {
    value: 'name',
    label: 'Name',
    active: true,
  },
  {
    value: 'code',
    label: 'Code',
    active: true,
  },
  {
    value: 'discount_type',
    label: 'Discount Type',
    active: true,
  },
  {
    value: 'amount',
    label: 'Amount',
    active: true,
  },
  {
    value: 'start_time',
    label: 'Start Time',
    active: true,
  },
  {
    value: 'end_time',
    label: 'End Time',
    active: true,
  },
  {
    value: 'actions',
    label: 'Actions',
    active: true,
  },
]

export const PROMO_DISCOUNT: DropdownOption[] = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Percentage', value: 'percentage' },
]

export const COUNTRY_OPTIONS: DropdownOption[] = [
  { label: 'Austria', value: 'AT' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Spain', value: 'ES' },
  { label: 'United Kingdom / Great Britain', value: 'GB' },
  { label: 'Australia', value: 'AU' },
  { label: 'Canada', value: 'CA' },
  { label: 'United States', value: 'US' },
]

export const PRICE_CURRENCIES: DropdownOption[] = [
  { label: '$ USD', value: 'usd' },
  { label: '€ EUR', value: 'eur' },
  { label: '£ GBP', value: 'gbp' },
  { label: '$ CAD', value: 'cad' },
  { label: '$ AUD', value: 'aud' },
]

export const PROMO_DEFAULTS = [
  'select',
  'name',
  'code',
  'discount_type',
  'amount',
  'start_time',
  'end_time',
  'action',
  'id',
]

export const SUBSCRIPTION_DEFAULTS = [
  'select',
  'name',
  'membership_type',
  'amount',
  'billing_type',
  'billing_period',
  'number_of_payments',
  'value',
  'action',
  'id',
]

export const PRICE_DEFAULTS = [
  'select',
  'name',
  'price_type',
  'amount',
  'unit_amount',
  'unit',
  'prorated',
  'card_on_file',
  'action',
  'id',
]
