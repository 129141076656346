import { Box, Card, Typography } from '@mui/material'
import { ILockerWall } from 'models/LockerWall'
import { lockerColors } from '../constants'
import { useMemo } from 'react'

interface IColorLegendsProps {
  lockerWall: ILockerWall
  isMobile: boolean
}

const ColorLegends = ({ lockerWall, isMobile }: IColorLegendsProps) => {
  const render = useMemo(
    () => (
      <Card
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: isMobile ? '0' : '25px',
          borderRadius: '5px',
          background: 'inherit',
          justifyContent: isMobile ? 'flex-start' : 'center',
          alignItems: isMobile ? 'flex-start' : 'center',
          boxShadow: 'none',
          padding: '0 1.5rem',
        }}
      >
        {lockerColors.map((color) => (
          <Box
            key={color.color}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <Box
              boxShadow={3}
              key={color.color}
              sx={{
                borderRadius: '5px',
                backgroundColor: color.color,
                width: '1.5rem',
                height: '1.5rem',
              }}
            />
            <Typography color="text.secondary">
              {color.label} {'('}
              {color.accesor !== 'unassigned'
                ? lockerWall.devices?.filter(
                    (device) => device.status === color.accesor,
                  ).length
                : lockerWall.lockers.length - lockerWall.devices?.length}
              {')'}
            </Typography>
          </Box>
        ))}
      </Card>
    ),
    [lockerWall],
  )
  return render
}

export default ColorLegends
