import { Box } from '@mui/material'
import { SubmitButton, Button, ISubmitCancelBtnsProps } from 'components'

const SubmitCancelBtns = ({
  submitBtnLbl,
  cancelBtnLbl,
  cancelBtnOnClick,
}: ISubmitCancelBtnsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0 15px',
        justifyContent: 'center',
        marginBottom: '20px',
      }}
    >
      <SubmitButton variant="contained">{submitBtnLbl}</SubmitButton>
      <Button variant="contained" onClick={cancelBtnOnClick} color="cancel">
        {cancelBtnLbl}
      </Button>
    </Box>
  )
}
export default SubmitCancelBtns
