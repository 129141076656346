import {
  Box,
  Checkbox,
  TableHead as MUITableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { ITableHeadProps, TableCell } from 'components'
import { getUserRole } from 'utils/helpers'

const TableHead = ({ headers, handleSelectAll }: ITableHeadProps) => {
  const role = getUserRole()
  return (
    <MUITableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header.value}>
            <Typography variant="h5">
              {header.label === 'Select' ? (
                <Tooltip title="Click to select all rows">
                  <Checkbox
                    disabled={role !== 'admin'}
                    onChange={handleSelectAll}
                  />
                </Tooltip>
              ) : (
                <>{header.label}</>
              )}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  )
}
export default TableHead
