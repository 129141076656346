export const lockerColors = [
  {
    color: '#7352ff',
    label: 'Available',
    accesor: 'available',
  },
  {
    color: '#F9D233',
    label: 'Under Maintenance',
    accesor: 'maintenance',
  },
  {
    color: '#F02607',
    label: 'Reserved',
    accesor: 'reserved',
  },
  {
    color: '#7E7D7D',
    label: 'Unassigned',
    accesor: 'unassigned',
  },
]
