import { TabPanel as MuiTabPanel } from '@mui/lab'
import { ITabPanelProps } from 'components'

const TabPanel = ({ tabs }: ITabPanelProps) => {
  return (
    <>
      {tabs.map((tab) => (
        <MuiTabPanel
          sx={{ padding: '24px 0 0 0' }}
          key={tab.value}
          value={tab.value}
        >
          {tab.children}
        </MuiTabPanel>
      ))}
    </>
  )
}

export default TabPanel
