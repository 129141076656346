import { createContext, ReactNode, useContext } from 'react'

interface LocationsContext {
  fetchLocations: (searchTerm?: string) => Promise<void>
}

const defaultValue = {
  fetchLocations: async (searchTerm?: string) => {
    return Promise.resolve()
  },
}

const LocationsContext = createContext<LocationsContext>(defaultValue)

const useLocationsContext = (): LocationsContext => {
  try {
    const context = useContext(LocationsContext)
    if (!context) {
      throw new Error('Child must be used within a LocationsContextProvider')
    }
    return context
  } catch (error) {}
  return defaultValue
}

export { LocationsContext, useLocationsContext }
