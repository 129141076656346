import { Theme, useMediaQuery } from '@mui/material'
import { ISx } from 'types'

export const SideBarStyles = (): { SX: ISx } => {
  const SM: boolean = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )

  const SX: ISx = {
    DRAWER: SM ? { width: '400px' } : { width: '500px' },
    CONTAINER: {
      height: '100%',
      width: '100%',
      padding: '1.25rem',
      overflowY: 'auto',
    },
  }

  return { SX }
}
