import {
  Autocomplete,
  Box,
  TextField,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { Button } from 'components'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import { ILocation } from 'models'
import { ILockerWall } from 'models/LockerWall'
import React from 'react'
import { DropdownOption, ToolbarControl } from 'types'

interface IWallToolbar {
  setConfirmationMessage: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean
      message: string
      action: string
    }>
  >
  selectedLocation: ILocation | undefined
  selectedLocationValue: any
  selectedWallValue: any
  locationOptions: DropdownOption[]
  loadingLocations: boolean
  lockerWallOptions: DropdownOption[]
  handleSelectLocation: (event: React.SyntheticEvent, value: any) => void
  handleSelectWall: (event: React.SyntheticEvent, value: any) => void
  setMode: React.Dispatch<React.SetStateAction<'' | 'edit' | 'add'>>
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>
  lockerWall: ILockerWall | undefined
}

const WallToolbar = ({
  setConfirmationMessage,
  selectedLocation,
  selectedLocationValue,
  selectedWallValue,
  locationOptions,
  loadingLocations,
  lockerWallOptions,
  handleSelectLocation,
  handleSelectWall,
  setMode,
  setOpenSidebar,
  lockerWall,
}: IWallToolbar) => {
  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Autocomplete
          size="small"
          value={selectedLocationValue}
          options={locationOptions}
          renderInput={(params) => (
            <TextField {...params} label="Location..." />
          )}
          onChange={handleSelectLocation}
          noOptionsText="No locations..."
          loading={loadingLocations}
          loadingText="Loading..."
        />
      ),
    },
    {
      display: true,
      render: (
        <Autocomplete
          disabled={!selectedLocation && !selectedLocationValue}
          size="small"
          value={selectedWallValue}
          options={lockerWallOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label="Locker Wall..." />
          )}
          onChange={handleSelectWall}
          noOptionsText="No locker walls for this location..."
        />
      ),
    },
    {
      display: true,
      render: (
        <Button
          disabled={!selectedLocation && !selectedLocationValue}
          variant="contained"
          onClick={() => {
            setMode('add')
            setOpenSidebar(true)
          }}
        >
          Add Locker Wall
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Button
          disabled={!lockerWall}
          variant="contained"
          onClick={() => {
            setMode('edit')
            if (lockerWall && lockerWall?.devices.length > 0) {
              setConfirmationMessage({
                isOpen: true,
                message:
                  'Please take into consideration that this locker wall has lockers already assigned, if you edit the dimensions of this wall all lockers will be automatically unassigned. Do you want to continue?',
                action: 'edit',
              })
            } else {
              setOpenSidebar(true)
            }
          }}
        >
          Edit Locker Wall
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Button
          disabled={!lockerWall}
          variant="contained"
          onClick={() => {
            setConfirmationMessage({
              isOpen: true,
              message: 'Are you sure that you want to delete this locker wall?',
              action: 'delete',
            })
          }}
        >
          Delete Locker Wall
        </Button>
      ),
    },
  ]

  return (
    <Toolbar
      controls={controls.filter(
        (control) =>
          control.display && (control.isMenu === undefined || !control.isMenu),
      )}
    >
      <ToolbarControls
        controls={controls.filter((control) => control.display)}
      />
    </Toolbar>
  )
}

export default WallToolbar
