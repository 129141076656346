import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Typography from '@mui/material/Typography'
import { Box, Checkbox, Link, Tooltip } from '@mui/material'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { getUserRole } from 'utils/helpers'

export type Order = 'asc' | 'desc' | 'default'

export interface ISortableHeader {
  value: string
  label: string
  sortable: boolean
  active: boolean
}

export interface ISortableTableHead {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order | false
  orderBy: string
  headers: ISortableHeader[]
  handleSelectAll: (event: React.ChangeEvent, checked: boolean) => void
}

const SortableTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headers,
  handleSelectAll,
}: ISortableTableHead) => {
  const role = getUserRole()

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headers?.map((header) => (
          <TableCell
            key={header.value}
            align="center"
            sortDirection={
              orderBy === header.value && order !== 'default' ? order : false
            }
          >
            {header.sortable ? (
              <TableSortLabel
                active={orderBy === header.value && order !== 'default'}
                direction={
                  orderBy === header.value && order && order !== 'default'
                    ? order
                    : undefined
                }
                onClick={createSortHandler(header.value)}
                IconComponent={() => (
                  <SortIcon isActive={orderBy === header.value} order={order} />
                )}
              >
                <Typography
                  sx={{
                    textDecoration: 'underline',
                  }}
                  variant="h5"
                >
                  {header.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="h5">
                {header.label === 'Select' ? (
                  <Tooltip title="Click to select all rows">
                    <Checkbox
                      disabled={role !== 'admin'}
                      onChange={handleSelectAll}
                    />
                  </Tooltip>
                ) : (
                  <>{header.label}</>
                )}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

//TODO: export this into a SFC if it grows too long, by now this should suffice though.
const SortIcon: React.FC<{
  order: Order | false
  isActive: boolean
}> = ({ order, isActive }) => {
  return (
    <Box position="relative" width="1rem" height="1rem" display="flex">
      <ArrowDropUpRoundedIcon
        sx={{
          position: 'absolute',
          bottom: 0,
          opacity: isActive && order === 'asc' ? '1' : '0.5',
        }}
      />
      <ArrowDropDownRoundedIcon
        sx={{
          position: 'absolute',
          top: 0,
          opacity: isActive && order === 'desc' ? '1' : '0.5',
        }}
      />
    </Box>
  )
}

export default SortableTableHead
