export const RESOURCES_DOCS_LINK =
  'https://www.notion.so/koloni-resources/f8e56af57a664540b1e96d12bea03067?v=b76b51cb59d043ad83663903f27a4f13'

export const TRAINING_CENTER_LINK =
  'https://www.notion.so/koloni-resources/Training-9c4bd0607dd84928b428c3239be41356'

export const SEND_FEEDBACK_LINK = 'https://forms.gle/mvJGrp1GQHZvxbKw7'

export const WHATS_NEW_LINK =
  'https://www.notion.so/koloni-resources/What-s-New-b41268708bd846648198c51cad245515'

export const API_DOCS_LINK =
  process.env.REACT_APP_CURRENT_ENV === 'production'
    ? 'https://api.koloni.io'
    : `https://${process.env.REACT_APP_CURRENT_ENV}.api.koloni.io`
