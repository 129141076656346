import { DeleteForever } from '@mui/icons-material'
import { Checkbox, IconButton, Link } from '@mui/material'
import { ConfirmDialog, IDialogType, TableCell, TableRow } from 'components'
import { IMember } from 'models'
import { useState } from 'react'
import { memberApi } from 'resources'
import {
  capitalizeFirstLetter,
  getCurrentUserProperty,
  getUserRole,
} from 'utils/helpers'

interface IMemberRowProps {
  member: IMember
  handleEdit: () => void
  filteredColumns: {
    value: string
    label: string
    active: boolean
  }[]
  success: () => Promise<void>
  displayMessage: (message: string, type?: IDialogType) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const MemberRow = ({
  member,
  handleEdit,
  filteredColumns,
  success,
  displayMessage,
  handleSelectRow,
  selected,
}: IMemberRowProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })
  const role = getUserRole()
  const user_id = getCurrentUserProperty('user_id')
  const { remove } = memberApi()

  const handleRemove = async () => {
    try {
      await remove([member.user_id])
      success()
      displayMessage('Member deleted successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  return (
    <>
      <TableRow key={member.user_id}>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, member.user_id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'email' && c.active) !==
          -1 && (
          <TableCell>
            {role === 'admin' ? (
              <Link
                onClick={handleEdit}
                sx={{
                  cursor: 'pointer',
                }}
              >
                {member?.email ?? 'N/A'}
              </Link>
            ) : (
              <>{member?.email ?? 'N/A'}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'first_name' && c.active,
        ) !== -1 && <TableCell>{member?.first_name ?? 'N/A'}</TableCell>}
        {filteredColumns.findIndex(
          (c) => c.value === 'last_name' && c.active,
        ) !== -1 && <TableCell>{member?.last_name ?? 'N/A'}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'role' && c.active) !==
          -1 && (
          <TableCell>{capitalizeFirstLetter(member?.role) ?? 'N/A'}</TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'pin_code' && c.active,
        ) !== -1 && <TableCell>{member?.pin_code || ''}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell>
            {role === 'admin' && member?.user_id !== user_id && (
              <IconButton
                onClick={() => {
                  setConfirmationMessage({
                    isOpen: true,
                    message: `Are you sure you want to delete ${member.email} from the organization?`,
                  })
                }}
              >
                <DeleteForever />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage({ isOpen: false, message: '' })}
        onClickConfirm={handleRemove}
        onClickCancel={() =>
          setConfirmationMessage({ isOpen: false, message: '' })
        }
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default MemberRow
