import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import useLoadingState from 'hooks/useLoadingState'
import { IDevice, IDeviceLog } from 'models'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import { deviceApi } from 'resources'
import DeviceLog from './DeviceLog'

interface IDeviceHistory {
  device: IDevice
}

const DeviceHistory = ({ device }: IDeviceHistory) => {
  const [deviceHistory, setDeviceHistory] = useState<IDeviceLog[]>([])

  const { loading, setLoading } = useLoadingState()

  const { getDeviceHistory } = deviceApi()

  const fetchDeviceHistory = async () => {
    try {
      setLoading(true)
      const history = await getDeviceHistory(device.id)
      setDeviceHistory(history)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDeviceHistory()
  }, [])

  const logs = deviceHistory.filter((log) => Boolean(log.event))

  return (
    <>
      {loading && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && logs.length === 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Typography variant="h3">Device History</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h5">Device: {device.name}</Typography>
            <Typography variant="h5">
              Door number: {device.locker_number}
            </Typography>
            <Typography variant="h5">
              Location: {device.location.name}
            </Typography>
          </Box>
          <Typography
            sx={{ fontStyle: 'italic', marginTop: '1rem' }}
            color="text.secondary"
          >
            No history to show
          </Typography>
        </Box>
      )}

      {!loading && logs.length > 0 && (
        <Box
          sx={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '1.5rem',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Typography variant="h3">Device History</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5">Device: {device.name}</Typography>
              <Typography variant="h5">
                Door number: {device.locker_number}
              </Typography>
              <Typography variant="h5">
                Location: {device.location.name}
              </Typography>
            </Box>
          </Box>
          <Accordion defaultExpanded sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Logs...</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: 0,
              }}
            >
              <Timeline sx={{ padding: 0 }}>
                {logs.map((log, index) => (
                  <TimelineItem
                    key={index}
                    sx={{
                      '&::before': {
                        flex: 'none',
                      },
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {index + 1 !== logs.length && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <DeviceLog log={log} />
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  )
}

export default DeviceHistory
