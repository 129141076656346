import { OrgFilter } from 'models'

export const ORG_FILTERS: { label: string; value: OrgFilter }[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
  {
    label: 'Show All',
    value: 'all',
  },
]
