import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { Form, TextField, Tabs, IDialogType, Image } from 'components'
import { ISize } from 'models'
import { sizeApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import { SyntheticEvent, useState, ChangeEvent } from 'react'
import useLoadingState from 'hooks/useLoadingState'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import { getUserRole } from 'utils/helpers'

interface ISizeFormProps {
  size: ISize | undefined
  onClose: () => void
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
}

const SizeForm = ({
  size,
  onClose,
  displayMessage,
  success,
}: ISizeFormProps) => {
  const { create, update } = sizeApi()
  const { loading, setLoading } = useLoadingState()

  const methods = useForm<ISize>({
    defaultValues: { ...size },
  })

  const [currentTab, setCurrentTab] = useState<string>('IndividualUpload')
  const [image, setImage] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string>('')

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file: File | undefined = event.target.files?.[0]
    if (file && /^image\//.test(file.type)) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const previewUrl = e.target?.result as string
        setImagePreview(previewUrl)

        setImage(file)
      }
    } else {
      displayMessage?.('Only images are allowed!', 'info')
    }
  }

  const onSubmit: SubmitHandler<ISize> = async (newPrice: ISize) => {
    try {
      setLoading(true)
      if (size) {
        await update(newPrice.id, newPrice, image)
        onClose()
        displayMessage?.('Size updated successfully!', 'success')
        success()
      } else {
        await create(newPrice, image)
        onClose()
        displayMessage?.('Size created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const validateDimension = (value: number, label: string): string | true => {
    if (value <= 0) {
      return `${label} must be greater than 0`
    }
    if (!/^\d+(\.\d{1,2})?$/.test(value.toString())) {
      return `${label} must have up to 2 decimal places`
    }
    return true
  }

  const SizeFormElement = (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title={size ? 'Edit Size' : 'Add Size'}>
          {size?.image && imagePreview === '' && (
            <Image
              src={size.image}
              alt={size.name}
              style={{ outline: 'none' }}
            />
          )}
          {imagePreview && (
            <Image
              src={imagePreview}
              alt="preview"
              style={{ outline: 'none' }}
            />
          )}
          <ImageUploader
            buttonText={size?.image ? 'Update Image' : 'Upload Image *'}
            helpText="Upload a rectangular image preferably of size 400x250 in high resolution for better display results."
            onChange={handleImageUpload}
          />

          <TextField
            name="name"
            label="Name"
            placeholder="Name"
            rules={{
              required: 'Name required',
            }}
          />
          <TextField
            name="description"
            label="Description"
            placeholder="Description"
          />

          <TextField
            name="width"
            label="Width"
            placeholder="Width"
            rules={{
              required: 'Width required',
              validate: (value: number) => validateDimension(value, 'Width'),
            }}
            isNumeric={true}
          />

          <TextField
            name="depth"
            label="Depth"
            placeholder="Depth"
            rules={{
              required: 'Depth required',
              validate: (value: number) => validateDimension(value, 'Depth'),
            }}
            isNumeric={true}
          />
          <TextField
            name="height"
            label="Height"
            placeholder="Height"
            rules={{
              required: 'Height required',
              validate: (value: number) => validateDimension(value, 'Height'),
            }}
            isNumeric={true}
          />
          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )

  return (
    <>
      {size && SizeFormElement}
      {getUserRole() !== 'admin' && !size && SizeFormElement}
      {getUserRole() === 'admin' && !size && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'IndividualUpload',
              children: SizeFormElement,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="sizes"
                  successForm={() => success?.()}
                  onClose={onClose}
                  displayMessage={displayMessage}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}
export default SizeForm
