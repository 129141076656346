import { Checkbox, FormControlLabel, FormControl } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'
import { IToggleFieldProps, ErrorMessage } from 'components'

const ToggleField = ({ name, label, disabled, checked }: IToggleFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(event) => onChange(event.target.checked)}
                disabled={disabled}
              />
            }
            label={label}
          />
        )}
      />
      <ErrorMessage errors={errors} name={name} />
    </FormControl>
  )
}
export default ToggleField
