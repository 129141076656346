import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Amplify, Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Alert, Box, Typography } from '@mui/material'

import { Button, NotificationDialog } from 'components'
import { TextField as MUITextField, FormControl } from '@mui/material'
import { SetNewPasswordFormStyles } from './styles'

import { AWS_COGNITO_REGION, AWS_PROJECT_REGION } from '../../../constants'
import PasswordStrength from 'components/PasswordStrength/PasswordStrength'
import { useAuth } from 'resources'

const MEDIUM_REGEX =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})./

const STRONG_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{12,}$/

const SetNewPassword = (): ReactElement => {
  const [inputValues, setInputValues] = useState({
    newPassword: '',
    confirmPassword: '',
  })
  const [passwordStrengthLabel, setPasswordStrengthLabel] = useState('Weak')
  const [dialog, setDialog] = useState<{
    isOpen: boolean
    message: string
  }>({
    isOpen: false,
    message: '',
  })
  const [error, setError] = useState('')
  const { SX } = SetNewPasswordFormStyles()
  const navigate = useNavigate()
  const { configureAmplify } = useAuth()

  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
  const oldPassword = localStorage.getItem('oldPassword') || ''

  const passwordStrength = useMemo(() => {
    if (!inputValues.newPassword) return null
    if (STRONG_REGEX.test(inputValues.newPassword)) {
      setPasswordStrengthLabel('Strong')
      return (
        <Box sx={{ marginTop: '10px' }}>
          <PasswordStrength value={100} label="Strong" color="success" />
        </Box>
      )
    }
    if (MEDIUM_REGEX.test(inputValues.newPassword)) {
      setPasswordStrengthLabel('Strong')
      return (
        <Box sx={{ marginTop: '10px' }}>
          <PasswordStrength value={50} label="Medium" color="warning" />
        </Box>
      )
    }
    setPasswordStrengthLabel('Weak')
    return (
      <Box sx={{ marginTop: '10px' }}>
        <PasswordStrength value={25} label="Weak" color="error" />
      </Box>
    )
  }, [inputValues.newPassword])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (error) {
      setError('')
    }

    const user: CognitoUser = await Auth.signIn(
      currentUser.challengeParam.userAttributes.email.toLowerCase().trim(),
      oldPassword,
    )

    if (user) {
      try {
        await Auth.completeNewPassword(user, inputValues.newPassword)
        setDialog({
          isOpen: true,
          message: 'Password reset was successful. Please login',
        })
        localStorage.removeItem('oldPassword')
        localStorage.setItem('firstTimeLogin', 'true')
      } catch (error) {
        setDialog({ isOpen: true, message: (error as Error).message })
        setError((error as Error).message)
      }
    }
  }

  const passwordsMatch =
    inputValues.newPassword === inputValues.confirmPassword &&
    inputValues.newPassword !== ''

  const initAmplify = () => {
    configureAmplify()
  }

  initAmplify()

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={SX.CONTAINER}>
        <Typography sx={SX.TITLE}>Reset your password</Typography>
        <Typography sx={SX.DESCRIPTION}>
          Before continuing, please reset your password.
        </Typography>

        <Typography sx={SX.DESCRIPTION}>
          Your password needs to have at least 8 characters, contain at least
          one uppercase letter, one lowercase letter, one number and one special
          character.
        </Typography>

        <Box sx={SX.FIELDS_CONTAINER}>
          <FormControl fullWidth>
            <MUITextField
              onChange={handleInputChange}
              name="newPassword"
              fullWidth
              label="Password"
              placeholder="Password"
              type="password"
            />
          </FormControl>
        </Box>
        {passwordStrength}

        <Box sx={SX.FIELDS_CONTAINER}>
          <FormControl fullWidth>
            <MUITextField
              onChange={handleInputChange}
              name="confirmPassword"
              fullWidth
              label="Confirm password"
              placeholder="Confirm password"
              type="password"
            />
          </FormControl>
          {!passwordsMatch &&
            inputValues.newPassword !== '' &&
            inputValues.confirmPassword !== '' && (
              <Alert variant="filled" severity="error">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Passwords do not match!
                </Box>
              </Alert>
            )}
          {passwordsMatch && (
            <Alert variant="filled" severity="success">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Passwords match!
              </Box>
            </Alert>
          )}
        </Box>
        <Box sx={SX.BUTTONS_CONTAINER}>
          <Button
            variant="contained"
            type="submit"
            disabled={!passwordsMatch || passwordStrengthLabel === 'Weak'}
            sx={{ width: 'auto' }}
          >
            Reset Password
          </Button>
          <Button
            variant="contained"
            type="reset"
            sx={{ width: 'auto' }}
            onClick={() => {
              setInputValues({
                newPassword: '',
                confirmPassword: '',
              })
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
      <NotificationDialog
        open={dialog.isOpen}
        onClose={() => {
          setDialog({
            isOpen: false,
            message: '',
          })
          if (!error) {
            navigate('/home')
          }
        }}
        message={dialog.message}
      />
    </form>
  )
}

export default SetNewPassword
