import { useApiRequest } from 'utils'
import { IDevice, IDeviceList, IDeviceLog } from 'models'
import { getErrorMessage } from 'utils/helpers'
import { DropdownOption } from 'types'

const deviceApi = () => {
  const { fetchPartnerEndpoint, fetchEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
    by_status: string = '',
    id_location: string = '',
  ): Promise<IDeviceList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
      ...(by_status !== '' && { by_status }),
      ...(id_location !== '' && {
        id_location,
      }),
    })

    const endpoint = `devices?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (device) => {
    const endpoint = `devices`
    const method = 'POST'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: device,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        result: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (device, deviceId) => {
    const endpoint = `devices/${deviceId}`
    const method = 'PUT'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: device,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        result: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patch = async (ids: string[], device: IDevice) => {
    const endpoint = `devices`
    const method = 'PATCH'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify({
      id_devices: ids,
      device,
    })

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        result: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]): Promise<any> => {
    const endpoint = `devices`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const unlockAll = async (devices: string[]): Promise<any> => {
    const endpoint = `devices/unlock`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(devices),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const setMaintenanceMode = async (
    ids: string[],
    disable: boolean,
  ): Promise<any> => {
    const endpoint = `devices/maintain-all?disable=${disable}`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ids),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        message: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getDeviceModes = async (): Promise<DropdownOption[]> => {
    const endpoint = `types/device/mode`
    const method = 'GET'
    try {
      const response = await fetchEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getHardwareTypes = async (): Promise<
    { key: string; value: string }[]
  > => {
    const endpoint = `types/device/hardware`
    const method = 'GET'
    try {
      const response = await fetchEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `devices/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getDeviceHistory = async (deviceId: string): Promise<IDeviceLog[]> => {
    const endpoint = `device-logs/${deviceId}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    create,
    update,
    remove,
    patch,
    setMaintenanceMode,
    getDeviceModes,
    getHardwareTypes,
    unlockAll,
    uploadCSV,
    getDeviceHistory,
  }
}
export default deviceApi
