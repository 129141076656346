import {
  Typography,
  TextField as MUITextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { Dialog, Form, IDialogType, TextField } from 'components'
import { ITransaction, PenaltyReason } from 'models'
import { transactionsApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { capitalizeFirstLetter } from 'utils/helpers'
import { PENALTY_REASONS } from 'pages/Events/constants'
import { useEffect } from 'react'

interface IPenaltyDialogProps {
  transaction: ITransaction
  open: boolean
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
}

const PenaltyDialog = ({
  transaction,
  open,
  onClose,
  displayMessage,
  success,
}: IPenaltyDialogProps) => {
  const { loading, setLoading } = useLoadingState()
  const { penalize } = transactionsApi()

  const onChargePenalty = async ({
    amount,
    reason,
  }: {
    amount: number
    reason: PenaltyReason
  }): Promise<void> => {
    try {
      setLoading(true)
      await penalize(transaction.id, amount, reason)
      success()
      displayMessage('Transaction was penalized successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<{ amount: number; reason: PenaltyReason }> = (
    newCharge,
  ): void => {
    try {
      onChargePenalty(newCharge)
      onClose()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const methods = useForm<{ amount: number; reason: PenaltyReason }>()

  useEffect(() => {
    if (!open) methods.reset()
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Charge Penalty" isDialog={true}>
            <Typography textAlign="center" color="text.secondary" variant="h5">
              Enter amount to penalize
            </Typography>

            <TextField
              name="amount"
              label="Amount"
              placeholder="Amount"
              isNumeric={true}
            />
            <Typography variant="h5">Why are you charging the user?</Typography>

            <FormControl>
              <InputLabel>Select Metric</InputLabel>
              <Select {...methods.register('reason')} label="Reason">
                {PENALTY_REASONS.map((reason) => (
                  <MenuItem key={reason.value} value={reason.value}>
                    {reason.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormActions
              submitText="Charge"
              onClose={onClose}
              loading={loading}
              disableSubmit={
                !methods.watch('amount') || !methods.watch('reason')
              }
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
export default PenaltyDialog
