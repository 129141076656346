import { Card, Typography } from '@mui/material'
import { Image } from 'components/Image'
import noDataImg from '../../assets/images/backgrounds/no-data.png'

interface INoDataProps {
  condition: boolean
  text?: string
}

const NoData = ({ condition, text = 'No Data to Show' }: INoDataProps) => {
  return (
    <>
      {condition && (
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            minHeight: '700px',
            flexDirection: 'column',
          }}
        >
          <Image
            src={noDataImg}
            alt="bg"
            style={{ width: '200px', height: 'auto' }}
          />
          <Typography variant="h5">{text}</Typography>
        </Card>
      )}
    </>
  )
}

export default NoData
