import {
  Autocomplete,
  Checkbox,
  Typography,
  TextField as MUITextField,
  Box,
  Chip,
} from '@mui/material'
import { Form, TextField, IDialogType } from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { groupApi, userApi } from 'resources'
import { IGroup, IUser } from 'models'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { useEffect, useState } from 'react'
import { DropdownOption } from 'types'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'

interface IGroupFormProps {
  group: IGroup | undefined
  success: (showNewestFirst?: boolean) => void
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
}

const GroupForm = ({
  group,
  success,
  onClose,
  displayMessage,
}: IGroupFormProps) => {
  const [usersToAdd, setUsersToAdd] = useState<string[]>([])
  const [usersToRemove, setUsersToRemove] = useState<string[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [options, setOptions] = useState<DropdownOption[]>([])

  const methods = useForm<IGroup>({ defaultValues: { ...group } })

  const { loading, setLoading } = useLoadingState()
  const { loading: loadingUsers, setLoading: setLoadingUsers } =
    useLoadingState()
  const { getMany } = userApi()
  const { postGroup, assignUser, removeUser, updateGroup } = groupApi()

  const fetchUsers = async (): Promise<void> => {
    try {
      setLoadingUsers(true)
      const users = await getMany(1, 10000, '')
      setUsers(users.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoadingUsers(false)
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const usersSelected: string[] = value.map((option) => option.value)
    setUsersToAdd(usersSelected)
  }

  const handleSubmit = async (data: IGroup) => {
    try {
      setLoading(true)
      if (group) {
        if (group.name !== data.name) {
          await updateGroup(group.id, data.name)
        }
        if (usersToAdd.length > 0) {
          const promises = usersToAdd.map(async (id) => {
            return await assignUser(group.id, id)
          })
          await Promise.all(promises)
        }
        if (usersToRemove.length > 0) {
          const promises = usersToRemove.map(async (id) => {
            return await removeUser(group.id, id)
          })
          await Promise.all(promises)
        }
        displayMessage('Group updated successfully', 'success')
        success(true)
        onClose()
        setUsersToAdd([])
        setUsersToRemove([])
      } else {
        const result = await postGroup(data)
        if (usersToAdd.length > 0) {
          const promises = usersToAdd.map(async (id) => {
            return await assignUser(result.id, id)
          })
          await Promise.all(promises)
        }
        displayMessage('Group created successfully', 'success')
        success(true)
        onClose()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }
  const buildOptions = () => {
    if (users.length > 0) {
      const notAssignedUsers = users.filter(
        (user) => !group?.users.some((u) => u.id === user.id),
      )
      const autoCompleteOptions = notAssignedUsers.map((user) => ({
        label: `${user.name || ''} (${user.email || user.phone_number})`,
        value: user.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(sortedOptions)
    }
  }

  const handleSelect = (userId: string) => {
    if (usersToRemove.includes(userId)) {
      setUsersToRemove(usersToRemove.filter((id) => id !== userId))
    } else {
      setUsersToRemove((prevState) => [...prevState, userId])
    }
  }

  useEffect(() => {
    buildOptions()
  }, [users])

  useEffect(() => {
    fetchUsers()
  }, [])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit}>
        <FormWrapper title={group ? 'Edit Group' : 'Add Group'}>
          <TextField
            name="name"
            label="Name"
            placeholder="Name"
            rules={{
              required: 'name is required',
            }}
          />

          <Typography variant="h5">Add users to group</Typography>
          <Autocomplete
            multiple
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <MUITextField {...params} label="Search users..." />
            )}
            onChange={handleToggle}
            loading={options.length === 0}
          />

          {group && (
            <>
              <Typography variant="caption" color="rgb(173, 176, 187)">
                {group?.users.length > 0
                  ? 'Select the users that you want to remove from this group and hit submit'
                  : 'No users assigned to this group'}
              </Typography>
              <Typography variant="h5">Users assigned to group</Typography>
              {group.users.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '0.75rem',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {group.users.map((user) => (
                    <Chip
                      key={user.id}
                      label={`${user.name || ''} (${
                        user.email || user.phone_number
                      })`}
                      onClick={() => handleSelect(user.id)}
                      onDelete={() => handleSelect(user.id)}
                      deleteIcon={
                        usersToRemove.includes(user.id) ? (
                          <DoneIcon />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                      variant={
                        usersToRemove.includes(user.id) ? 'filled' : 'outlined'
                      }
                      color={
                        usersToRemove.includes(user.id) ? 'primary' : 'default'
                      }
                    />
                  ))}
                </Box>
              )}
            </>
          )}

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )
}

export default GroupForm
