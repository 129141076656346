import { DeviceMode } from 'models'

type InitialState = DeviceMode | 'all'

const lastModeFilter: InitialState = 'all'

const lastModeFilterReducer = (state = lastModeFilter, action) => {
  switch (action.type) {
    case 'setLastModeFilter':
      return action.payload
    default:
      return state
  }
}

export default lastModeFilterReducer
