import { Tab } from '@mui/material'
import { TabList as MUITabList } from '@mui/lab'
import { ITabListProps } from 'components'

const TabList = ({ onChange, tabs }: ITabListProps) => {
  return (
    <MUITabList onChange={onChange}>
      {tabs.map(
        (tab) =>
          !tab.disabled && (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              disabled={tab.disabled}
              id={tab.id}
            />
          ),
      )}
    </MUITabList>
  )
}
export default TabList
