import { ISx, IStyles } from 'types'
import { useTheme } from '@mui/material/styles'

export const SignInStyles = (): {
  SX: ISx
  STYLES: IStyles
} => {
  const theme = useTheme()

  const SX: ISx = {
    GRID_CONTAINER: {
      height: '100vh',
      justifyContent: 'center',
    },
    GRID_ITEM: {
      background: theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff',
    },
    RESET_LINK_CONTAINER: {
      display: {
        xs: 'block',
        sm: 'flex',
        lg: 'flex',
      },
      alignItems: 'center',
    },
    BOX_LOGO: {
      position: 'relative',
      top: '2em',
    },
    BOX_WELCOME: {
      p: 4,
    },
    BOX_IMAGE_CONTAINER: {
      position: 'relative',
    },
    BOX_IMAGE: {
      position: 'relative',
      display: {
        xs: 'block',
        sm: 'block',
        md: 'block',
        lg: 'flex',
      },
      paddingTop: {
        xs: '6rem',
        sm: '6rem',
        md: '6rem',
        lg: 0,
      },
      alignItems: 'center',
      justifyContent: 'center',
      height: { xs: 'auto', lg: '100vh' },
      right: { xs: 'auto', lg: '0px' },
      textAlign: 'center',
    },
  }

  const STYLES: IStyles = {
    RESET_LINK: {
      color: theme.palette.primary.main,
    },
    IMG: {
      width: 400,
      height: 250,
    },
  }
  return { SX, STYLES }
}
