import { Form, IDialogType, Image, SelectField, TextField } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import { IIssue, IMember, ITransaction, IUser } from 'models'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { ISSUE_STATUSES } from '../constants'
import {
  Autocomplete,
  Box,
  Checkbox,
  TextField as MUITextField,
  Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { formatDate } from '../helpers'
import { IssueApi, transactionsApi } from 'resources'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import { DropdownOption } from 'types'
import LoadingFormData from 'components/PageBase/LoadingFormData'

interface IIssueFormProps {
  issue: IIssue | undefined
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
  users: IUser[]
  teamMembers: IMember[]
}

const IssueForm = ({
  issue,
  onClose,
  displayMessage,
  success,
  users,
  teamMembers,
}: IIssueFormProps) => {
  const [usersOptions, setUserOptions] = useState<DropdownOption[]>([])
  const [loadingUserTransactions, setLoadingUserTransactions] =
    useState<boolean>(false)

  const [invoiceIdOptions, setInvoiceIdOptions] = useState<
    { label: string; id: string; id_event: string }[]
  >([])

  const [selectedUser, setSelectedUser] = useState<DropdownOption | null>(
    () => {
      if (issue && issue.id_user) {
        const user = users.find((user) => user.id === issue.id_user)
        if (user) {
          return {
            label: `${user.name || ''} (${user.email || user.phone_number})`,
            value: user.id,
          }
        }
        return null
      } else {
        return null
      }
    },
  )

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<{
    label: string
    id: string
    id_event: string
  } | null>(() => {
    if (issue && issue?.event?.invoice_id) {
      return {
        id: issue.event.invoice_id,
        label: issue.event.invoice_id,
        id_event: issue.event.id,
      }
    } else {
      return null
    }
  })

  const [selectedTeamMember, setSelectedTeamMember] =
    useState<DropdownOption | null>(() => {
      const foundTeamMember = teamMembers.find(
        (member) => member.user_id === issue?.team_member_id,
      )

      if (foundTeamMember) {
        return {
          label: `${foundTeamMember.name || ''} (${foundTeamMember.email})`,
          value: foundTeamMember.user_id,
        }
      } else {
        return null
      }
    })

  const [images, setImages] = useState<File[]>([])
  const [imagesPreviews, setImagesPreviews] = useState<string[]>([])
  const [isValidInvoiceID, setIsValidInvoiceID] = useState<boolean>(true)

  const { loading, setLoading } = useLoadingState()
  const { getEventsByUserId } = transactionsApi()
  const { create, update } = IssueApi()

  const methods = useForm<IIssue>({
    defaultValues: {
      ...issue,
      invoice_id: issue?.event?.invoice_id || '',
    },
  })

  const handleUserSelection = (event: React.SyntheticEvent, value: any) => {
    setSelectedUser(value)
  }

  const handleInvoiceIdSelection = (
    event: React.SyntheticEvent,
    value: any,
  ) => {
    setSelectedInvoiceId(value)
  }

  const handleTeamMemberSelection = (event, value) => {
    setSelectedTeamMember(value)
  }

  const onSubmit: SubmitHandler<IIssue> = async (newIssue) => {
    try {
      setLoading(true)

      const payload = new FormData()

      Object.keys(newIssue).forEach((key) => {
        if (!['issue_id', 'user', 'event'].includes(key)) {
          payload.append(key, newIssue[key])
        }
      })

      if (selectedTeamMember) {
        payload.set('team_member_id', selectedTeamMember?.value as any)
      }

      if (selectedUser) {
        payload.append('id_user', selectedUser.value)
      }

      if (selectedInvoiceId) {
        payload.append('invoice_id', selectedInvoiceId.id)
      }

      if (images.length > 0) {
        images.forEach((image) => {
          payload.append('images', image)
        })
      }

      if (issue) {
        // FormData automatically fetches value from Autocomplete field present in the
        // update form. This is a workaround that unassigns the selected team member
        if (payload.get('team_member_id') && !selectedTeamMember) {
          payload.delete('team_member_id')
          payload.delete('team_member')
        }

        if (payload.get('team_member_id') && selectedTeamMember) {
          payload.set('team_member_id', selectedTeamMember?.value)
        }

        await update(issue.id, payload)
        onClose()
        displayMessage('Issue updated successfully!', 'success')
        success()
      } else {
        if (!selectedInvoiceId) {
          setIsValidInvoiceID(false)
          return
        }

        await create(payload, selectedInvoiceId.id_event)
        onClose()

        displayMessage('Issue created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const buildUsersOptions = () => {
    if (users.length > 0) {
      const autoCompleteOptions = users.map((user) => ({
        label: `${user.name || ''} (${user.email || user.phone_number})`,
        value: user.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setUserOptions(sortedOptions)
    }
  }

  const buildInvoiceIdOptions = async (idUser: string) => {
    try {
      setLoadingUserTransactions(true)
      const events = await getEventsByUserId(idUser, false)

      setInvoiceIdOptions(
        events.length === 0
          ? []
          : events.map((e: ITransaction) => ({
              id: e.invoice_id,
              label: e.invoice_id,
              id_event: e.id,
            })),
      )
    } catch {
      displayMessage('Failed to fetch transactions from selected user', 'error')
    } finally {
      setLoadingUserTransactions(false)
    }
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = event?.target?.files

    if (files) {
      const fileArray = Array.from(files)
      fileArray.forEach((file) => {
        const isImage = file && /^image\//.test(file.type)
        if (!isImage) {
          displayMessage?.(
            'One or more of the selected files are not images. Please try again.',
            'success',
          )
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = (e) => {
          if (e.target && e.target.result) {
            const preview = e.target.result as string
            setImagesPreviews((previousValue) => [...previousValue, preview])
          }
        }
      })
      setImages(fileArray)
    }
  }

  const handleImageClick = (): void => {
    document.getElementById('image')?.click()
  }

  useEffect(() => {
    buildUsersOptions()
  }, [users])

  useEffect(() => {
    if (!issue) return

    setSelectedInvoiceId({
      id: issue.event?.invoice_id,
      label: issue.event?.invoice_id,
      id_event: issue.event?.id,
    })
  }, [selectedUser])

  useEffect(() => {
    const fetchUserTransactions = async () => {
      if (selectedUser) {
        if (selectedInvoiceId) setSelectedInvoiceId(null)
        const idUser = selectedUser.value
        await buildInvoiceIdOptions(idUser)
      } else {
        setSelectedInvoiceId(null)
        setInvoiceIdOptions([])
      }
    }
    fetchUserTransactions()
  }, [selectedUser])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const helpText = `Click the button below to ${
    issue ? 'update' : 'upload'
  } the images
  related to the issue. You can ${issue ? 'update' : 'upload'} one or
  multiple images.${' '}
  ${issue ? 'Old pictures will be replaced by the new images you select.' : ''}`

  if (loadingUserTransactions) {
    return <LoadingFormData />
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title={issue ? 'Edit Issue' : 'Add Issue'}>
          <>
            {!issue && imagesPreviews.length > 0 && (
              <>
                <Typography variant="h5">Issue images</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    textAlign: 'center',
                    justifyContent: 'left',
                    width: '100%',
                    gap: '1rem',
                  }}
                >
                  {imagesPreviews.map((picture, index) => (
                    <Image
                      key={index}
                      src={picture}
                      alt="issue"
                      style={{
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
            <ImageUploader
              buttonText={issue ? 'Update Images' : 'Upload Images'}
              helpText={helpText}
              onChange={handleImageUpload}
              multiple
            />

            <Typography variant="caption" color="text.secondary">
              {`${images.length} image${
                images.length === 1 ? '' : 's'
              } selected`}
            </Typography>
          </>

          {issue && (
            <TextField
              name="issue_id"
              label="Issue ID"
              placeholder="Issue ID"
              isReadOnly={true}
              disabled={true}
            />
          )}
          <Autocomplete
            value={selectedUser}
            options={usersOptions}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => <MUITextField {...params} label="User" />}
            onChange={handleUserSelection}
            loading={usersOptions.length === 0}
          />

          {!issue && (
            <Autocomplete
              value={selectedInvoiceId}
              options={invoiceIdOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Order Number" />
              )}
              onChange={handleInvoiceIdSelection}
              loading={loadingUserTransactions}
              loadingText="Loading user transactions..."
              disabled={!selectedUser}
              noOptionsText="No transactions for this user..."
            />
          )}

          {issue && issue.event?.invoice_id && (
            <MUITextField
              label="Order Number"
              placeholder="Order Number"
              disabled={true}
              value={issue.event.invoice_id}
            />
          )}

          {!isValidInvoiceID && (
            <Typography
              sx={{
                color: '#ff0000',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                margin: '-10px 0 0',
              }}
            >
              Order No. does not exist.
            </Typography>
          )}

          <SelectField label="Status" items={ISSUE_STATUSES} name="status" />

          <Autocomplete
            value={selectedTeamMember}
            options={teamMembers.map((member) => ({
              label: `${member.name} (${member.email})`,
              value: member.user_id,
            }))}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <MUITextField {...params} label="Assign Team Member" />
            )}
            onChange={handleTeamMemberSelection}
            loading={teamMembers.length === 0}
          />

          <TextField
            multiline
            name="description"
            label="Description"
            placeholder="Description"
            rules={{
              maxLength: {
                value: 200,
                message: 'Description must be 200 characters long maximum.',
              },
              required: 'Description is required',
            }}
            helperText={`${methods.watch('description')?.length || 0}/${200}`}
          />
          {issue && (
            <>
              <Typography variant="h5">Issue Report Time</Typography>
              <Typography variant="caption" color="text.secondary">
                {formatDate(issue.created_at)}
              </Typography>
            </>
          )}

          {issue && issue.pictures && (
            <>
              <Typography variant="h5">Issue images</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  textAlign: 'center',
                  justifyContent: 'left',
                  width: '100%',
                  gap: '1rem',
                }}
              >
                {issue?.pictures?.map((picture, index) => (
                  <Image
                    key={index}
                    src={picture}
                    alt="issue"
                    style={{
                      objectFit: 'cover',
                      borderRadius: '10px',
                    }}
                  />
                ))}
              </Box>
            </>
          )}

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )
}

export default IssueForm
