import React, { useRef, useEffect, useState } from 'react'
import { Card, CardContent, Box, Typography, Skeleton } from '@mui/material'
import { Button, Image, NotificationDialog } from 'components'
import { API_URL } from '../../../constants'
import { reportApi, settingsApi } from '../../../resources'
import { CSVLink } from 'react-csv'
import useDialog from 'hooks/useDialog'

const WelcomeCard = () => {
  const ORG_DATA: string | null = localStorage.getItem('currentOrg')
  const ORG_NAME: string = ORG_DATA ? JSON.parse(ORG_DATA).name : undefined
  const CURRENT_USER: string | null = localStorage.getItem('currentUser')

  const { getReports } = reportApi()

  type UserType = {
    User: {
      id: string
      created_at: string
      name: string
      email: string
      phone_number: string
      active: boolean
    }
    location: string
    purchases: number
  }

  type LocationType = {
    Location: {
      id: string
      name: string
      address: string
    }
    count: number
  }

  type MonthlyData = {
    month: number
    count: number
  }

  type ReportType = {
    earnings: {
      earnings: number
      currency: string
    }
    users: {
      total: number
      data: MonthlyData[]
    }
    transactions: {
      total: number
      data: MonthlyData[]
    }
    top_users: UserType[]
    top_locations: LocationType[]
  }

  const [data, setData] = useState<any[]>([])

  const TOKEN: string = CURRENT_USER
    ? JSON.parse(CURRENT_USER).signInUserSession?.idToken?.jwtToken
    : undefined

  const fileInput = useRef<HTMLInputElement>(null)

  const [whitelabelData, setWhitelabelData] = React.useState({
    logo: null,
  })

  const [loading, setLoading] = useState<boolean>(true)

  const { getWhiteLabel } = settingsApi()
  const { dialog, displayMessage, closeDialog } = useDialog()

  const handleFileInputChange = async (event) => {
    const selectedFile = event.target.files[0]

    const isImage = /^image\//.test(selectedFile.type)

    if (!isImage) {
      displayMessage('Only images are allowed')
      return
    }

    const formData = new FormData()
    formData.append('image', selectedFile)

    const whiteLabelData = await fetch(API_URL + 'white-label/logo', {
      method: 'PATCH',
      body: formData,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => res.json())
      .catch(console.error)

    setWhitelabelData({
      logo: whiteLabelData.app_logo,
    })
  }

  const parseJSONToCSVFormat = (json: ReportType) => {
    const parsedData = [
      {
        earnings_earnings: json.earnings.earnings,
        earnings_currency: json.earnings.currency,
        users_total: json.users.total,
        users_month_1: json.users.data[0]?.month || '',
        users_count_1: json.users.data[0]?.count || '',
        users_month_2: json.users.data[1]?.month || '',
        users_count_2: json.users.data[1]?.count || '',
        transactions_total: json.transactions.total,
        transactions_month_1: json.transactions.data[0]?.month || '',
        transactions_count_1: json.transactions.data[0]?.count || '',
        top_users_User_id: json.top_users[0]?.User.id || '',
        top_users_User_created_at: json.top_users[0]?.User.created_at || '',
        top_users_User_name: json.top_users[0]?.User.name || '',
        top_users_User_email: json.top_users[0]?.User.email || '',
        top_users_User_phone_number: json.top_users[0]?.User.phone_number || '',
        top_users_User_active: json.top_users[0]?.User.active || '',
        top_users_location: json.top_users[0]?.location || '',
        top_users_purchases: json.top_users[0]?.purchases || '',
        top_locations_Location_id: json.top_locations[0]?.Location.id || '',
        top_locations_Location_name: json.top_locations[0]?.Location.name || '',
        top_locations_Location_address:
          json.top_locations[0]?.Location.address || '',
        top_locations_count: json.top_locations[0]?.count || '',
      },
    ]
    setData(parsedData)
  }

  const HEADERS = [
    { label: 'earnings_earnings', key: 'earnings_earnings' },
    { label: 'earnings_currency', key: 'earnings_currency' },
    { label: 'users_total', key: 'users_total' },
    { label: 'users_month_1', key: 'users_month_1' },
    { label: 'users_count_1', key: 'users_count_1' },
    { label: 'users_month_2', key: 'users_month_2' },
    { label: 'users_count_2', key: 'users_count_2' },
    { label: 'transactions_total', key: 'transactions_total' },
    { label: 'transactions_month_1', key: 'transactions_month_1' },
    { label: 'transactions_count_1', key: 'transactions_count_1' },
    { label: 'top_users_User_id', key: 'top_users_User_id' },
    { label: 'top_users_User_created_at', key: 'top_users_User_created_at' },
    { label: 'top_users_User_name', key: 'top_users_User_name' },
    { label: 'top_users_User_email', key: 'top_users_User_email' },
    {
      label: 'top_users_User_phone_number',
      key: 'top_users_User_phone_number',
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const whiteLabelData = await getWhiteLabel()
        setWhitelabelData({
          logo: whiteLabelData?.app_logo,
        })
        const reportsData = await getReports()
        parseJSONToCSVFormat(reportsData)
        setLoading(false)
      } catch (error) {
        displayMessage(`${(error as Error).message}`, 'error')
      }
    }
    fetchData()
  }, [])

  return (
    <Card
      elevation={0}
      sx={{
        position: 'relative',
        backgroundColor: (theme) =>
          `${theme.palette.mode === 'dark' ? '#32363e' : ''}`,
        borderWidth: '0px',
        height: '200px',
      }}
    >
      <input
        type="file"
        ref={fileInput}
        onChange={handleFileInputChange}
        accept="image/*"
        style={{
          display: 'none',
        }}
      />
      <CardContent>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                marginTop: '8px',
                marginBottom: '0px',
                lineHeight: '35px',
                position: 'relative',
                zIndex: 9,
                whiteSpace: 'nowrap',
              }}
              variant="h5"
              gutterBottom
            >
              {!loading ? (
                <>
                  Hey {ORG_NAME} <br />
                </>
              ) : (
                <Skeleton width="50%" />
              )}
              {!loading ? (
                <>Download Latest Report</>
              ) : (
                <Skeleton width="50%" />
              )}
            </Typography>
            {!loading ? (
              <Box sx={{ width: '100%', mt: 2, height: '35px' }}>
                <Button variant="contained">
                  <CSVLink
                    data={data}
                    headers={HEADERS}
                    filename="general_report.csv"
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                  >
                    Download
                  </CSVLink>
                </Button>
              </Box>
            ) : (
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={40}
                width="30%"
                sx={{ mt: 1 }}
              />
            )}
          </Box>
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '20px',
            }}
          >
            {!whitelabelData.logo && (
              <Skeleton
                animation="wave"
                variant="circular"
                width={120}
                height={120}
              />
            )}
            {whitelabelData.logo && (
              <Image src={whitelabelData.logo} alt="logo" isLogo={true} />
            )}
          </Box>
        </Box>
      </CardContent>
      <NotificationDialog
        open={dialog.isOpen}
        message={dialog.message}
        onClose={closeDialog}
      />
    </Card>
  )
}

export default WelcomeCard
