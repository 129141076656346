/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
export enum PriceTypes {
  pay_per_weight = 'pay_per_weight',
  pay_per_time = 'pay_per_time',
}

export enum PriceUnits {
  kg = 'kg',
  lb = 'lb',
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  week = 'week',
}

export enum PriceCurrencies {
  usd = 'usd',
  eur = 'eur',
  gbp = 'gbp',
  cad = 'cad',
  aud = 'aud',
}

export interface IPrice {
  id: string
  created_at: Date
  name: string
  amount: number
  unit: PriceUnits
  unit_amount: number
  currency: PriceCurrencies
  prorated: boolean
  price_type: PriceTypes
  card_on_file: boolean
  default: boolean
}

export interface IPriceList {
  items: IPrice[]
  total: number
  pages: number
}
