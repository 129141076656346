import { ISortableHeader } from 'components/Table/components/TableHead/SortableTableHead'
import { ORG_FILTERS_IDENTFIER } from '../constants'
import { useState } from 'react'
import { settingsApi } from 'resources'

interface IUseColumnFiltering {
  displayMessage: (message: string | JSX.Element) => void
}

const useColumnFiltering = ({ displayMessage }: IUseColumnFiltering) => {
  const [filteredColumns, setFilteredColumns] = useState<ISortableHeader[]>([])
  const { getFilters } = settingsApi()

  const getDefaultFilters = async (): Promise<void> => {
    try {
      const defaultFilters = await getFilters(
        ORG_FILTERS_IDENTFIER[location.pathname],
      )
      setFilteredColumns(defaultFilters)
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
    }
  }
  return {
    filteredColumns,
    setFilteredColumns,
    getDefaultFilters,
  }
}

export default useColumnFiltering
