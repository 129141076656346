import { ICustomTitle, IDialogType } from 'components'
import { useState } from 'react'

interface IDialog {
  isOpen: boolean
  message: string | JSX.Element
  type?: IDialogType
  customTitle?: ICustomTitle | null
}

const useDialog = () => {
  const [dialog, setDialog] = useState<IDialog>({
    isOpen: false,
    message: '',
    type: '',
    customTitle: null,
  })

  const displayMessage = (
    message: string | JSX.Element,
    type: IDialogType = '',
    customTitle: ICustomTitle | null = null,
  ) => {
    setDialog({
      isOpen: true,
      message,
      type,
      customTitle,
    })
  }

  const closeDialog = () => {
    setDialog({ isOpen: false, message: '', type: '', customTitle: null })
  }

  return {
    dialog,
    displayMessage,
    closeDialog,
  }
}

export default useDialog
