import {
  Box,
  Typography,
  TextField as MUITexField,
  Chip,
  Autocomplete,
  Checkbox,
  Button as MUIButton,
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { membershipApi } from 'resources'
import {
  BillingPeriod,
  BillingType,
  ILocation,
  ISubscription,
  SUBSCRIPTION_TYPES,
} from 'models'
import { SyntheticEvent, useEffect, useState } from 'react'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { Form, IDialogType, SelectField, Tabs, TextField } from 'components'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import useLoadingState from 'hooks/useLoadingState'
import { RootStateOrAny, useSelector } from 'react-redux'
import { getUserRole, parseCurrency } from 'utils/helpers'
import { ToggleSwitch } from 'components/ToggleSwitch'
import LoadingFormData from 'components/PageBase/LoadingFormData'
import useLocations from 'hooks/useLocations'

interface IMembershipFormProps {
  membership: ISubscription | undefined
  onClose: () => void
  displayMessage: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
}

const MembershipForm = ({
  membership,
  onClose,
  displayMessage,
  success,
}: IMembershipFormProps) => {
  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const snakeCaseToHumanReadable = (snakeCase: string) => {
    // Replace underscores with spaces
    const withSpaces = snakeCase.replace(/_/g, ' ')

    // Capitalize the first letter of each word
    const capitalized = withSpaces.replace(/\b\w/g, (match) =>
      match.toUpperCase(),
    )

    return capitalized
  }

  const { loading, setLoading } = useLoadingState()
  const [currentTab, setCurrentTab] = useState<string>('IndividualUpload')

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const [assignedLocations, setAssignedLocations] = useState<ILocation[]>([])
  const [locationsToAdd, setLocationsToAdd] = useState<string[]>([])
  const [locationsToRemove, setLocationsToRemove] = useState<string[]>([])
  const [billingType, setBillingType] = useState<BillingType>(
    membership?.billing_type || 'one_time',
  )

  const { update, create } = membershipApi()
  const { loadingLocations, locationsOptions, locations, fetchLocations } =
    useLocations()

  const methods = useForm<ISubscription>({
    defaultValues: { ...membership },
  })

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const locationsSelected: string[] = value.map((option) => option.value)
    setLocationsToAdd(locationsSelected)
  }

  const handleLocationSelect = (locationId: string) => {
    // This does not affect API payload only the view:
    setAssignedLocations((prevState) =>
      prevState.filter((location) => location.id !== locationId),
    )

    // This affects the API payload and it's used in the onSubmit function:
    setLocationsToRemove((prevState) => [...prevState, locationId])
  }

  const onSubmit = async (data: ISubscription) => {
    try {
      setLoading(true)
      const locationsArr = !membership
        ? [...locationsToAdd]
        : [
            ...locationsToAdd,
            ...membership?.locations
              .filter((location) => !locationsToRemove.includes(location.id))
              .map((location) => location.id),
          ]
      const newMembership = {
        ...data,
        active: true,
        currency,
        locations: locationsArr,
        billing_type: billingType,
      }

      if (membership) {
        await update(membership.id, newMembership)
        onClose()
        displayMessage('Subscription updated successfully!', 'success')
        success()
      } else {
        await create(newMembership)
        onClose()
        displayMessage('Subscription created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (membership) {
      const ids = membership.locations.map((location) => location.id)
      setAssignedLocations(
        locations.filter((location) => ids.includes(location.id)),
      )
    } else {
      setAssignedLocations([])
    }
  }, [locations])

  useEffect(() => {
    fetchLocations()
  }, [])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const MembershipFormElement = (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper
          title={membership ? 'Edit Subscription' : 'Add Subscription'}
        >
          <TextField
            id="subscription_form_input_name"
            label="Name"
            name="name"
            required
          />
          {methods.watch('name') && (
            <TextField
              id="subscription_form_input_description"
              label="Description"
              name="description"
            />
          )}

          {methods.watch('description') && (
            <ToggleSwitch
              leftItem={{
                label: 'One Time',
                onClick: () => setBillingType('one_time'),
                selected: billingType === 'one_time',
                id: 'subscription_form_toggle_one_time',
              }}
              rightItem={{
                label: 'Recurring',
                onClick: () => setBillingType('recurring'),
                selected: billingType === 'recurring',
                id: 'subscription_form_toggle_recurring',
              }}
            />
          )}

          {methods.watch('description') && (
            <SelectField
              id="subscription_form_select_billing_period"
              name="billing_period"
              label="Billing Period"
              items={Object.values(BillingPeriod).map((option) => ({
                label: snakeCaseToHumanReadable(option),
                value: option,
              }))}
              rules={{ required: 'Billing period is required' }}
            />
          )}

          {methods.watch('billing_period') && (
            <SelectField
              id="subscription_form_select_membership_type"
              name="membership_type"
              label="Subscription Type"
              items={SUBSCRIPTION_TYPES}
              rules={{ required: 'Subscription type is required' }}
            />
          )}

          {methods.watch('billing_period') && (
            <TextField
              id="subscription_form_input_value"
              label="Subscription Value"
              type="number"
              name="value"
              required
              helperText="Subscription Value"
            />
          )}

          {methods.watch('value') && (
            <TextField
              id="subscription_form_input_amount"
              label="Charge Amount"
              type="number"
              name="amount"
              required
            />
          )}

          {methods.watch('amount') && (
            <TextField
              id="subscription_form_input_number_of_payments"
              label="Duration"
              type="number"
              name="number_of_payments"
              required
              helperText={`How many ${methods
                .watch('billing_period')
                .toLowerCase()}s will it be charged.`}
            />
          )}

          {methods.watch('number_of_payments') && (
            <Typography color="text.secondary" variant="h3" textAlign="center">
              {methods.watch('membership_type') === 'limited' &&
                `${methods.watch('value')} free transactions.`}
              {methods.watch('membership_type') === 'unlimited' &&
                `Unlimited free transactions while the membership still active.`}
              {methods.watch('membership_type') === 'fixed' &&
                `Charge -${methods.watch('value')}${parseCurrency(
                  currency,
                )} from ${methods.watch('amount')}$ for ${methods.watch(
                  'number_of_payments',
                )} ${methods.watch('billing_period').toLowerCase()}s`}
              {methods.watch('membership_type') === 'percentage' &&
                `Charge -${methods.watch('value')}% from ${methods.watch(
                  'amount',
                )}$ for ${methods.watch('number_of_payments')} ${methods
                  .watch('billing_period')
                  .toLowerCase()}s`}
            </Typography>
          )}

          {membership && (
            <>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'left',
                }}
              >
                Assigned locations
              </Typography>
              {!loadingLocations && (
                <Typography variant="caption" color="rgb(173, 176, 187)">
                  {assignedLocations.length > 0
                    ? 'Select the locations that you want to remove from this membership and hit submit'
                    : `No locations assigned to this membership.`}
                </Typography>
              )}

              {assignedLocations.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '0.25rem',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {assignedLocations.map((location) => (
                    <Chip
                      key={location.id}
                      label={location.name}
                      onDelete={() => handleLocationSelect(location.id)}
                      deleteIcon={<DeleteIcon />}
                      variant="outlined"
                      color="default"
                    />
                  ))}
                </Box>
              )}
            </>
          )}

          {methods.watch('value') && methods.watch('membership_type') && (
            <>
              <Typography variant="h5">Assign Locations</Typography>
              <Typography variant="caption" color="rgb(173, 176, 187)">
                Select locations that you want to assign to this membership to
                and hit submit
              </Typography>
              <Autocomplete
                multiple
                id="subscription_form_input_locations"
                filterSelectedOptions={true}
                options={locationsOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <MUITexField {...params} label="Search locations..." />
                )}
                onChange={handleToggle}
                loading={loadingLocations}
              />
            </>
          )}

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )

  if (loadingLocations) {
    return <LoadingFormData />
  }

  return (
    <>
      {(membership || (getUserRole() !== 'admin' && !membership)) &&
        MembershipFormElement}
      {getUserRole() === 'admin' && !membership && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'IndividualUpload',
              children: MembershipFormElement,
              id: 'individual_upload_tab',
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="subscriptions"
                  successForm={success}
                  onClose={onClose}
                  displayMessage={displayMessage}
                />
              ),
              id: 'bulk_upload_tab',
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}

export default MembershipForm
