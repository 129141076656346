import {
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { NotificationDialog } from 'components'
import { ILocation } from 'models'
import { useState } from 'react'
import { locationApi } from 'resources'

interface IUnlockByLocationDialogProps {
  locations: ILocation[]
  open: boolean
  setOpenUnlockByLocation: (open: boolean) => void
}

const UnlockByLocationDialog = ({
  locations,
  open,
  setOpenUnlockByLocation,
}: IUnlockByLocationDialogProps) => {
  const { unlockByLocation } = locationApi()

  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [messageDialog, setMessageDialog] = useState('')

  const handleCheckboxClick = async (locationId: string) => {
    const unlockResponse = await unlockByLocation(locationId)
    setOpenMessageDialog(true)
    setMessageDialog(unlockResponse.detail)
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpenUnlockByLocation(false)}>
        <Paper
          sx={{
            padding: '2em',
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="body1">Select location to Unlock</Typography>

              <FormGroup
                sx={{
                  marginTop: '1em',
                }}
              >
                {locations.map((location, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onClick={() => handleCheckboxClick(location.id)}
                      />
                    }
                    label={location.name}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <NotificationDialog
        open={openMessageDialog}
        onClose={() => {
          setOpenUnlockByLocation(false)
          setOpenMessageDialog(false)
        }}
        message={messageDialog}
      />
    </>
  )
}

export default UnlockByLocationDialog
