import {
  Box,
  IconButton,
  Menu,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { PropsWithChildren, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'

const Filters = ({ children }: PropsWithChildren) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const displayFilters = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Tooltip title="Filter table">
        <IconButton
          aria-label="menu"
          onClick={handleClick}
          aria-controls={displayFilters ? 'columns-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={displayFilters ? 'true' : undefined}
          size="large"
          sx={{ padding: 0, cursor: 'pointer' }}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="columns-menu"
        anchorEl={anchorEl}
        keepMounted
        open={displayFilters}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: '500px',
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 2.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </Menu>
    </Box>
  )
}

export default Filters
